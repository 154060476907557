const styles = () => ({
    fontSizeControl: {
        paddingTop: 30,
        paddingLeft: 30,
        alignItems: 'center',
    },
    fontSizeButton: {
        borderRadius: 0,

        '&:not(:last-child)': {
            borderRight: 'none',
        },
    },
    fontSizeDecrease: {
        fontSize: '1rem',
        fontWeight: 'normal',
    },
    fontSizeIncrease: {
        fontSize: '1.5rem',
        fontWeight: 'normal',
    },
});

export default styles;
