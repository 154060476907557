import KeyMirror from 'keymirror';
import orderBy from 'lodash/orderBy';

import ReportsTypes from '../action-types/reports';
import AuthTypes from '../action-types/auth';
import TesterTypes from '../action-types/testers';

import {
    INITIAL_SECTION_PROGRESS,
    SECTION_PROGRESS,
    SECTION_QUESTIONS,
    TOTAL_SECTIONS,
} from './current-report/constants';

export const REQUEST_STATUS = KeyMirror({
    ACTIVE: true,
    SUCCESS: true,
    ERROR: true,
    SESSION_EXPIRED: true,
});
export const REPORT_STATUS = KeyMirror({
    DRAFT: true,
    SUBMITTED: true,
    REOPENED: true,
});
export const REPORT_OUTCOME = KeyMirror({
    POSITIVE: true,
    NEGATIVE: true,
    INCONCLUSIVE: true,
    PENDING: true,
});

export const getCurrentVersion = report => report.versions.find(version => version.isActiveVersion);

export const getFlaggedVersion = (report) => {
    if (!report?.versions) return null;

    const currentVersion = getCurrentVersion(report);
    if (currentVersion.versionNumber === 1 || report.status !== REPORT_STATUS.REOPENED) {
        return currentVersion;
    }
    return report.versions.find(version => version.versionNumber === currentVersion.versionNumber - 1);
};

const internals = {
    initial: () => ({
        data: {},
        requestStatus: null,
        errorMsg: null,
        total: 0,
        attachmentUploadStatus: {
            inProgress: false,
        },
    }),
    initialReport: {
        status: REPORT_STATUS.DRAFT,
        testNumber: null,
        testCoordinator: null,
        testDate: null,
        outcome: null,
        outcomeFile: null,
        submittedDate: null,
        percentComplete: 0,
        testSite: {},
        tester: {},
        sectionsProgress: INITIAL_SECTION_PROGRESS,
        reportAnswers: {},
        isSyncNeeded: true,
        requestStatus: null,
        errorMsg: null,
    },
};

const determinePercentComplete = (sectionsProgress) => {
    const percentComplete = Object.values(sectionsProgress).reduce(
        (accumulator, status) => {
            // give 100% credit for each section completed
            if (status === SECTION_PROGRESS.COMPLETED) return accumulator + (100 / TOTAL_SECTIONS);
            // give 50% credit if a section has at least been started
            if (status === SECTION_PROGRESS.IN_PROGRESS) return accumulator + (100 / (TOTAL_SECTIONS * 2));

            // otherwise section has not been started so nothing to add
            return accumulator;
        },
        0,
    );

    if (percentComplete > 100) return 100;

    return Math.round(percentComplete);
};

export const determineSectionsProgress = (reportAnswers, flaggedAnswers) => {
    const sectionsProgress = { ...INITIAL_SECTION_PROGRESS };

    if (Object.values(reportAnswers).length > 0) {
        Object.entries(SECTION_QUESTIONS).forEach(([sectionId, sectionQuestions]) => {
            let totalRequiredAnswers = 0;
            let totalAnswers = 0;
            let totalRequiredQuestions = 0;
            let totalFlaggedAnswers = 0;
            const sectionQuestionsArray = Object.values(sectionQuestions);
            sectionQuestionsArray.forEach((question) => {
                // if this question was flagged, mark it as flagged there is no need to check for completeness
                if (flaggedAnswers && flaggedAnswers[question.id]) {
                    totalFlaggedAnswers += 1;
                } else if (question.validation?.required) {
                    // if all required questions have answers, mark this section as complete
                    if ((
                        question.validation?.conditional
                        && reportAnswers[sectionQuestions[question.validation.conditional.on].id] === question.validation.conditional.value
                    ) || !question.validation?.conditional
                    ) {
                        totalRequiredQuestions += 1;
                        if (reportAnswers[question.id]) {
                            totalRequiredAnswers += 1;
                            totalAnswers += 1;
                        }
                    }
                } else if (reportAnswers[question.id]) {
                    totalAnswers += 1;
                }
            });
            if (totalFlaggedAnswers > 0) {
                sectionsProgress[sectionId] = SECTION_PROGRESS.FLAGGED;
            } else if (totalRequiredAnswers === totalRequiredQuestions) {
                sectionsProgress[sectionId] = SECTION_PROGRESS.COMPLETED;
            } else if (totalAnswers > 0) {
                // if at least one question was answered, mark this as in progress
                sectionsProgress[sectionId] = SECTION_PROGRESS.IN_PROGRESS;
            }
        });
    }

    return sectionsProgress;
};

const parseFetchedAnswers = (report) => {
    const sortedVersions = orderBy(report.versions, ['versionNumber'], ['asc']);
    const parsedAnswers = {};

    const allAnwers = sortedVersions.reduce((acc, version) => [...acc, ...version.reportAnswers], []);

    // transform the fetched answers into an object keyed by question id
    allAnwers.forEach((reportAnswer) => {
        const { questionId, answer } = reportAnswer;
        parsedAnswers[questionId] = answer;
    });

    return parsedAnswers;
};

const parseFetchedReports = (reports) => {
    const parsedReports = {};

    // transform the fetched reports data into the shape and formats we need
    // NOTE: this will break any sorting that was done on the server because reports are now listed by id
    reports.forEach((report) => {
        const { id, ...reportData } = report;
        const reportAnswers = parseFetchedAnswers(report);
        const flaggedAnswers = getFlaggedVersion(report)?.flaggedAnswers;
        const sectionsProgress = determineSectionsProgress(reportAnswers, flaggedAnswers);
        const percentComplete = determinePercentComplete(sectionsProgress);

        parsedReports[id] = {
            id,
            ...reportData,
            reportAnswers,
            sectionsProgress,
            percentComplete,
        };
    });

    return parsedReports;
};

const ReportsReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { type, payload } = action;

    switch (type) {
    case ReportsTypes.GET_REPORTS_BEGIN:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ACTIVE,
        };
    case ReportsTypes.GET_REPORTS_SUCCESS:
        return {
            ...state,
            total: payload.total,
            // TODO: we will probably need to merge this data in a more sophisticated way
            // when we support offline data in the case the tester has created or edited a report
            // but hasn't been able to sync the changes yet, we don't want to overwrite them
            data: parseFetchedReports(payload.reports),
            requestStatus: REQUEST_STATUS.SUCCESS,
        };
    case ReportsTypes.GET_REPORTS_ERROR:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ERROR,
            errorMsg: payload.errorMsg,
        };
    case ReportsTypes.POST_CREATE_REPORT_BEGIN:
        return {
            ...state,
            data: {
                ...state.data,
                [payload.reportTempId]: { // save with a tempId until we receive the new Id from the API
                    ...internals.initialReport,
                    ...payload.newReport,
                    isSyncNeeded: true,
                    requestStatus: REQUEST_STATUS.ACTIVE,
                },
            },
        };
    case ReportsTypes.POST_CREATE_REPORT_SUCCESS:
        // eslint-disable-next-line no-case-declarations
        const newReport = {
            ...state.data[payload.reportTempId],
        };

        // now that the report has been synced remove the tempId
        delete state.data[payload.reportTempId];

        return {
            ...state,
            data: {
                ...state.data,
                // save with the new Id from the database
                [payload.data.id]: {
                    ...newReport,
                    isSyncNeeded: false,
                    requestStatus: REQUEST_STATUS.SUCCESS,
                    ...payload.data,
                },
            },
        };
    case ReportsTypes.POST_CREATE_REPORT_ERROR:
        return {
            ...state,
            data: {
                ...state.data,
                [payload.reportTempId]: {
                    ...state.data[payload.reportTempId],
                    isSyncNeeded: true,
                    requestStatus: REQUEST_STATUS.ERROR,
                    errorMsg: payload.errorMsg,
                },
            },
        };
    case ReportsTypes.PATCH_REPORT_BEGIN:
        return {
            ...state,
            data: {
                ...state.data,
                [payload.reportId]: {
                    ...state.data[payload.reportId],
                    requestStatus: REQUEST_STATUS.ACTIVE,
                },
            },
        };
    case ReportsTypes.PATCH_REPORT_SUCCESS:
        return {
            ...state,
            data: {
                ...state.data,
                [payload.reportId]: {
                    ...state.data[payload.reportId],
                    ...payload.patchedData,
                    requestStatus: REQUEST_STATUS.SUCCESS,
                },
            },
        };
    case ReportsTypes.PATCH_REPORT_ERROR:
        return {
            ...state,
            data: {
                ...state.data,
                [payload.reportId]: {
                    ...state.data[payload.reportId],
                    requestStatus: payload.requestStatus,
                    errorMsg: payload.errorMsg || REPORT_STATUS.ERROR,
                },
            },
        };
    case ReportsTypes.DELETE_REPORT_SUCCESS:
        // eslint-disable-next-line no-case-declarations
        const updatedData = { ...state.data };
        delete updatedData[payload.reportId];

        return {
            ...state,
            data: {
                ...updatedData,
            },
        };
    case ReportsTypes.UPLOAD_ATTACHMENTS_BEGIN:
        return {
            ...state,
            attachmentUploadStatus: {
                inProgress: true,
            },
        };
    case ReportsTypes.UPLOAD_ATTACHMENTS_ERROR:
        return {
            ...state,
            attachmentUploadStatus: {
                inProgress: false,
            },
        };
    case ReportsTypes.UPLOAD_ATTACHMENTS_SUCCESS:
        return {
            ...state,
            attachmentUploadStatus: {
                inProgress: false,
            },
            data: {
                ...state.data,
                [payload.reportId]: {
                    ...state.data[payload.reportId],
                    attachments: payload.attachments,
                },
            },
        };
    case ReportsTypes.SAVE_REPORT_ANSWERS:
        return {
            ...state,
            data: {
                ...state.data,
                [payload.reportId]: {
                    ...state.data[payload.reportId],
                    reportAnswers: {
                        ...state.data[payload.reportId].reportAnswers,
                        ...payload.reportAnswers,
                    },
                },
            },
        };
    case ReportsTypes.PATCH_REPORT_ANSWERS_BEGIN:
        return {
            ...state,
            data: {
                ...state.data,
                [payload.reportId]: {
                    ...state.data[payload.reportId],
                    isSyncNeeded: true,
                    requestStatus: REQUEST_STATUS.ACTIVE,
                },
            },
        };
    case ReportsTypes.PATCH_REPORT_ANSWERS_SUCCESS:
        return {
            ...state,
            data: {
                ...state.data,
                [payload.reportId]: {
                    ...state.data[payload.reportId],
                    isSyncNeeded: false,
                    requestStatus: REQUEST_STATUS.SUCCESS,
                },
            },
        };
    case ReportsTypes.PATCH_REPORT_ANSWERS_ERROR:
        return {
            ...state,
            data: {
                ...state.data,
                [payload.reportId]: {
                    ...state.data[payload.reportId],
                    isSyncNeeded: true,
                    requestStatus: payload.requestStatus,
                    errorMsg: payload.errorMsg,
                },
            },
        };
    case ReportsTypes.UPDATE_SECTION_PROGRESS:
        // eslint-disable-next-line no-case-declarations
        const updatedSectionsProgress = {
            ...state.data[payload.reportId].sectionsProgress,
            [payload.sectionId]: payload.progress,
        };

        return {
            ...state,
            data: {
                ...state.data,
                [payload.reportId]: {
                    ...state.data[payload.reportId],
                    percentComplete: determinePercentComplete(updatedSectionsProgress),
                    sectionsProgress: updatedSectionsProgress,
                },
            },
        };
    case ReportsTypes.PATCH_REPORT_VERSION_SUCCESS: {
        const { reportId, versionId, ...updates } = payload;
        return {
            ...state,
            data: {
                ...state.data,
                [reportId]: {
                    ...state.data[reportId],
                    versions: state.data[reportId].versions.map((version) => {
                        if (version.id === versionId) {
                            return {
                                ...version,
                                ...updates,
                            };
                        }
                        return version;
                    }),
                },
            },
        };
    }
    case ReportsTypes.CREATE_REPORT_VERSION_SUCCESS: {
        const { reportId, ...newVersion } = payload;
        return {
            ...state,
            data: {
                ...state.data,
                [reportId]: {
                    ...state.data[reportId],
                    versions: [
                        ...state.data[reportId].versions,
                        newVersion,
                    ],
                },
            },
        };
    }
    case AuthTypes.LOGOUT:
        return internals.initial();
    case TesterTypes.DELETE_TESTER_SUCCESS: {
        const data = { ...state.data };
        const testerId = payload.id;
        Object.entries(state.data).forEach((reportId, report) => {
            if (report.testerId === testerId) {
                delete data[reportId];
            }
        });
        return {
            ...state,
            data,
        };
    }

    default:
        // do nothing
    }
    return state;
};

export default ReportsReducer;
