import KeyMirror from 'keymirror';

export default KeyMirror({
    GET_TESTERS_FOR_FILTERS_BEGIN: true,
    GET_TESTERS_FOR_FILTERS_SUCCESS: true,
    GET_TESTERS_FOR_FILTERS_ERROR: true,
    GET_TESTERS_BEGIN: true,
    GET_TESTERS_SUCCESS: true,
    GET_TESTERS_ERROR: true,
    POST_CREATE_TESTER_SUCCESS: true,
    PATCH_TESTER_BEGIN: true,
    PATCH_TESTER_SUCCESS: true,
    PATCH_TESTER_ERROR: true,
    DELETE_TESTER_SUCCESS: true,
});
