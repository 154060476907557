import { REPORT_HISTORY_ACTIONS } from './constants';

const transformReportHistoryData = (reportId, audits) => {
    // Take audit data and turn it into a human readable format
    const transformedData = audits.reduce((acc, {
        route,
        action,
        user,
        createdAt,
        data,
    }) => {
        const returnData = {
            user,
            date: typeof createdAt === 'string' ? new Date(createdAt) : createdAt,
        };
        // a POST to reports/reportId means that a report was submitted
        if (route === `/${process.env.REACT_APP_API_PREFIX}/reports/${reportId}` && action === 'post') {
            acc.push({
                action: REPORT_HISTORY_ACTIONS.reportSubmitted.value,
                ...returnData,
            });
        }

        // a POST to reports/reportId/versions means that a report was reopened
        if (route === `/${process.env.REACT_APP_API_PREFIX}/reports/${reportId}/versions` && action === 'post') {
            acc.push({
                action: REPORT_HISTORY_ACTIONS.reportReopened.value,
                ...returnData,
            });
        }

        // a PATCH to reports/reportId/outcome means that a report was assigned an outcome
        if (route === `/${process.env.REACT_APP_API_PREFIX}/reports/${reportId}/outcome` && action === 'patch') {
            acc.push({
                action: REPORT_HISTORY_ACTIONS.reportAssignedOutcome.value,
                value: data.outcome,
                ...returnData,
            });
        }

        return acc;
    }, []);

    return transformedData;
};

export default transformReportHistoryData;
