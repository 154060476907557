import noop from 'lodash/noop';

import UserTypes from '../action-types/user';
import WebClient from '../utils/web-client';
import { APIError } from './app';

export const fetchUsersSuccess = payload => ({
    type: UserTypes.FETCH_USERS_SUCCESS,
    payload,
});

export const fetchUsersError = error => ({
    type: UserTypes.FETCH_USERS_ERROR,
    payload: error,
});

export const inviteUserSuccess = userId => ({
    payload: userId,
    type: UserTypes.INVITE_USER_SUCCESS,
});

export const clearUserSuccessMessage = () => ({
    type: UserTypes.CLEAR_USER_SUCCESS_MESSAGE,
});

// update user actions
export const updateUserSuccess = user => ({
    type: UserTypes.UPDATE_USER_SUCCESS,
    payload: user,
});

export const updateUserError = error => ({
    type: UserTypes.UPDATE_USER_ERROR,
    payload: error,
});

// create user actions
export const createUserSuccess = user => ({
    type: UserTypes.CREATE_USER_SUCCESS,
    payload: user,
});

export const createUserError = error => ({
    type: UserTypes.CREATE_USER_ERROR,
    payload: error,
});

export const fetchInternalUsers = payload => async (dispatch) => {
    try {
        const { data } = await WebClient.post('/users/internal/list', payload);

        dispatch(fetchUsersSuccess(data));
    } catch (error) {
        dispatch(fetchUsersError(error));
    }
};

// PATCH a user
export const updateInternalUser = (id, payload, onSuccess = noop, onError = noop) => async (dispatch) => {
    try {
        const { data } = await WebClient.patch(`/users/${id}`, payload);

        dispatch(updateUserSuccess(data));
        onSuccess();
    } catch (error) {
        dispatch(APIError('Error inviting user.'));
        dispatch(updateUserError(error));
        onError();
    }
};

// POST a user
export const createInternalUser = (payload, onSuccess = noop, onError = noop) => async (dispatch) => {
    try {
        const { data } = await WebClient.post('/users', payload);

        dispatch(createUserSuccess(data));
        onSuccess();
    } catch (error) {
        dispatch(APIError('Error creating user.'));
        dispatch(createUserError(error));
        onError();
    }
};

export const inviteUser = (userId, onSuccess = noop, onError = noop) => (
    async (dispatch) => {
        try {
            await WebClient.post(`/users/${userId}/resend-invite`);
            dispatch(inviteUserSuccess(userId));
            onSuccess();
        } catch (error) {
            dispatch(APIError('Error inviting user.'));
            onError();
        }
    }
);
