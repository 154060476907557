const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    SETUP_USER: true,
    FETCH_USERS: true,
    FETCH_USERS_SUCCESS: true,
    FETCH_USERS_ERROR: true,
    UPDATE_USER: true,
    UPDATE_USER_SUCCESS: true,
    UPDATE_USER_ERROR: true,
    CREATE_USER: true,
    CREATE_USER_SUCCESS: true,
    CREATE_USER_ERROR: true,
    INVITE_USER_SUCCESS: true,
    CLEAR_USER_SUCCESS_MESSAGE: true,
});
