import KeyMirror from 'keymirror';

import TestersTypes from '../action-types/testers';
import UserTypes from '../action-types/user';
import { USER_STATUS } from './user';

const REQUEST_STATUS = KeyMirror({
    ACTIVE: true,
    SUCCESS: true,
    ERROR: true,
});

const internals = {
    initial: () => ({
        data: {},
        requestStatus: null,
        errorMsg: null,
        total: 0,
    }),
};

const parseFetchedTesters = (testers) => {
    const parsedTesters = {};

    // transform the fetched testers data into the shape and formats we need
    testers.forEach((tester) => {
        const { id, ...testerData } = tester;
        parsedTesters[id] = {
            ...testerData,
            id,
        };
    });

    return parsedTesters;
};

const TestersReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { type, payload } = action;

    switch (type) {
    case TestersTypes.GET_TESTERS_BEGIN:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ACTIVE,
        };
    case TestersTypes.GET_TESTERS_SUCCESS:
        return {
            ...state,
            data: parseFetchedTesters(payload.testers),
            requestStatus: REQUEST_STATUS.SUCCESS,
            total: payload.total,
        };
    case TestersTypes.GET_TESTERS_ERROR:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ERROR,
            errorMsg: payload.errorMsg,
        };
    case TestersTypes.POST_CREATE_TESTER_SUCCESS:
        return {
            ...state,
            data: {
                ...state.data,
                [payload.testerId]: {
                    ...payload.data,
                    status: USER_STATUS.INVITED,
                    requestStatus: REQUEST_STATUS.SUCCESS,
                },
            },
        };
    case TestersTypes.PATCH_TESTER_BEGIN:
        return {
            ...state,
            data: {
                ...state.data,
                [payload.testerId]: {
                    ...state.data[payload.testerId],
                    requestStatus: REQUEST_STATUS.ACTIVE,
                },
            },
        };
    case TestersTypes.PATCH_TESTER_SUCCESS:
        return {
            ...state,
            data: {
                ...state.data,
                [payload.testerId]: {
                    ...state.data[payload.testerId],
                    ...payload.patchedData,
                    requestStatus: REQUEST_STATUS.SUCCESS,
                },
            },
        };
    case TestersTypes.PATCH_TESTER_ERROR:
        return {
            ...state,
            data: {
                ...state.data,
                [payload.testerId]: {
                    ...state.data[payload.testerId],
                    requestStatus: REQUEST_STATUS.ERROR,
                    errorMsg: payload.errorMsg,
                },
            },
        };
    case TestersTypes.DELETE_TESTER_SUCCESS: {
        const data = { ...state.data };
        delete data[payload.id];
        return {
            ...state,
            data,
        };
    }
    case UserTypes.INVITE_USER_SUCCESS: {
        const userId = payload;
        // if a tester was invited, update their lastInvitedOn date
        if (state.data[userId]) {
            return {
                ...state,
                data: {
                    ...state.data,
                    [userId]: {
                        ...state.data[userId],
                        lastInvitedOn: new Date(),
                    },
                },
            };
        }
        return state;
    }

    default:
        // do nothing
    }
    return state;
};

export default TestersReducer;
