import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import Styles from './styles';

const PropertyBlockButton = ({
    classes,
    progress,
    title,
    property,
    address,
    visitDate,
    propertyImg,
    onEditClick,
    onDeleteClick,
}) => (
    <div
        className={`
            ${progress === 'inprogress' && classes.progressInProgress}
            ${progress === 'completed' && classes.progressCompleted}
            ${progress === 'reopened' && classes.progressReopened}
            ${classes.buttonWrapper}
        `}
    >
        <div className={classes.header}>
            <Typography className={classes.headerText}>
                {title}
            </Typography>
        </div>
        <div className={classes.body}>
            { propertyImg && (
                <img src={propertyImg} alt="" className={classes.propertyImg} />
            )}

            <Typography variant="h2" component="h3" className={classes.bodyHeaderText}>{property}</Typography>
            <Typography variant="body1" className={classes.bodyText}>
                {address} &#8226; Visited {visitDate}
            </Typography>

            <div className="row no-gutters pt-4 pb-4" style={{ width: '100%' }}>
                {progress !== 'completed' && (
                    <>
                        <div className="col pl-4 pr-1">
                            <Button
                                className={`${classes.outlineButton} ${classes.smallButton} ${classes.fullWidth}`}
                                classes={{
                                    label: classes.buttonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.outlineButtonRippleChildPulsate,
                                        ripplePulsate: classes.buttonRipplePulsate,
                                    },
                                }}
                                onClick={onDeleteClick}
                                aria-label={`Delete ${property} Report`}
                            >
                                <DeleteIcon className={classes.buttonIcon} />Delete
                            </Button>
                        </div>
                        <div className="col pl-1 pr-4">
                            <Button
                                className={`${classes.outlineButton} ${classes.outlineBlueButton} ${classes.smallButton} ${classes.fullWidth}`}
                                classes={{
                                    label: classes.buttonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.outlineBlueButtonRippleChildPulsate,
                                        ripplePulsate: classes.buttonRipplePulsate,
                                    },
                                }}
                                onClick={onEditClick}
                                aria-label={`Edit ${property} Report`}
                            >
                                <EditIcon className={classes.buttonIcon} />Edit
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    </div>
);

PropertyBlockButton.defaultProps = {
    propertyImg: null,
    onEditClick: null,
    onDeleteClick: null,
};

PropertyBlockButton.propTypes = {
    classes: PropTypes.object.isRequired,
    progress: PropTypes.oneOf(['inprogress', 'completed']).isRequired,
    title: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    visitDate: PropTypes.string.isRequired,
    propertyImg: PropTypes.string,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(combinedStyles)(PropertyBlockButton);
