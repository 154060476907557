import { createMuiTheme } from '@material-ui/core/styles';
import Colors from './colors';
// https://material-ui.com/customization/default-theme/?expend-path=$.palette.background
// https://fonts.adobe.com/fonts/ibm-plex-sans#fonts-section
const breakpointObject = createMuiTheme({
    breakpoints: { // using bootstrap defined breakpoints for consistency
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
});
// fontWeight: 400/700/800 = normal/bold/extrabold
const theme = {
    breakpoints: {
        values: {
            ...breakpointObject.breakpoints.values,
        },
    },
    customButtons: {
        textTransform: 'none',
        fontSize: '1rem', // defaults to 16px
        minHeight: 50,
        borderRadius: 6,
        lineHeight: 1,
        paddingLeft: 24,
        paddingRight: 24,
    },
    typography: {
        fontFamily: '"Inter", sans-serif',
        color: Colors.darkGrey,
        h1: {
            fontSize: '1.5rem', // defaults to 24px
            fontWeight: 700,
            [breakpointObject.breakpoints.down('sm')]: {
                fontSize: '1.25rem', // defaults to 20px
            },
        },
        h2: {
            fontSize: '1.125rem', // defaults to 18px
            fontWeight: 700,
            [breakpointObject.breakpoints.down('sm')]: {
                fontSize: '1.0625rem', // defaults to 17px
            },
        },
        h3: {
            fontSize: '1rem', // defaults to 16px
            fontWeight: 400,
            [breakpointObject.breakpoints.down('sm')]: {
                fontSize: '0.9375rem', // defaults to 15px
            },
        },
        body1: {
            fontSize: '0.9375rem', // defaults to 15px
            fontWeight: 400,
            color: Colors.darkGrey,
            [breakpointObject.breakpoints.down('sm')]: {
                fontSize: '0.875rem', // defaults to 14px
            },
        },
        button: {
            textTransform: 'none',
            fontWeight: 700,
            fontSize: '1rem', // defaults to 16px
        },
    },
    palette: {
        primary: {
            main: Colors.persianBlue,
            contrastText: '#fff',
        },
        secondary: {
            main: Colors.darkGrey,
        },
        background: {
            default: Colors.antiflashWhite,
        },
        text: {
            primary: Colors.darkGrey,
        },
    },
};

export default theme;
