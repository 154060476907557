export default (collection, key, order = 'asc') => (
    collection.sort((a, b) => {
        const first = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
        const second = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];
        if (first < second) {
            return order === 'desc' ? 1 : -1;
        }
        if (second < first) {
            return order === 'desc' ? -1 : 1;
        }
        return 0;
    })
);
