import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { ErrorMessage } from '@hookform/error-message';
import isEqual from 'lodash/isEqual';

import Checkbox from '../checkbox';

import InputStyles from '../../../styles/inputs';

const CheckboxGroup = ({
    classes,
    name,
    label,
    values,
    onChange,
    options,
    inputRef,
    inputProps,
    conditional,
}) => {
    const [checkedState, setCheckedState] = useState({});
    const isSingleCheckbox = options.length === 1;

    useEffect(() => {
        let previouslyChecked = values;

        // when rehydrating with data from the server we may need to convert a string list to an array
        if (values && !Array.isArray(values)) {
            previouslyChecked = values.split(',');
        }

        const checkedStateMap = {};
        options.forEach((option) => {
            checkedStateMap[option.name] = previouslyChecked ? previouslyChecked.includes(option.name) : false;
        });

        setCheckedState(checkedStateMap);
    }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const selectedInputs = Object.entries(checkedState)
            .filter(([value, isChecked]) => isChecked) // eslint-disable-line no-unused-vars
            .map(([value, isChecked]) => value); // eslint-disable-line no-unused-vars

        if (selectedInputs.length === 0 && (values === null || !Array.isArray(values))) return;

        if (isEqual(selectedInputs, values)) return;

        // send updates to the parent component
        onChange(selectedInputs);
    }, [checkedState]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = useCallback((event) => {
        const updatedState = {
            ...checkedState,
            [event.target.name]: event.target.checked,
        };
        setCheckedState(updatedState);
    }, [checkedState, setCheckedState]);

    return (
        <FormGroup aria-labelledby={label ? `${name}-label` : null}>
            <div className="container-fluid p-0 ">
                <div className="row-col-1">
                    {options.map(choice => (
                        <Checkbox
                            key={choice.id}
                            value={choice.name}
                            name={choice.name}
                            label={choice.name}
                            inputRef={inputRef}
                            inputProps={inputProps}
                            onChange={handleChange}
                            isChecked={checkedState[choice.name]}
                            showHR={!isSingleCheckbox}
                            conditional={conditional ? conditional[choice.name] : null}
                        />
                    ))}
                    { !isSingleCheckbox && <hr className={classes.greyHr} />}
                </div>
            </div>
        </FormGroup>
    );
};

CheckboxGroup.defaultProps = {
    values: [],
    label: null,
    inputRef: null,
    inputProps: null,
    conditional: null,
};

CheckboxGroup.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    inputRef: PropTypes.func,
    inputProps: PropTypes.object,
    conditional: PropTypes.object,
};


const ControlledCheckboxGroup = ({
    classes,
    name,
    label,
    errors,
    showError,
    required,
    control,
    rules,
    options,
    inputRef,
    conditional,
    inputProps,
}) => {
    const isInvalid = !!errors[name];
    const describedBy = (showError && isInvalid) ? `${name}-error` : null;

    const elementProps = {
        'aria-describedby': describedBy,
        'aria-invalid': isInvalid,
        'aria-required': required,
        required,
        ...inputProps,
    };

    return (
        <FormControl
            component="fieldset"
            error={isInvalid}
            fullWidth
            required={required}
        >
            {label && (
                <Typography variant="body1" id={`${name}-label`} className={classes.inputLabel}>{label}</Typography>
            )}

            <Controller
                name={name}
                control={control}
                rules={rules}
                render={controllerProps => (
                    <CheckboxGroup
                        classes={classes}
                        name={name}
                        label={label}
                        values={controllerProps.value}
                        onChange={controllerProps.onChange}
                        options={options}
                        inputRef={inputRef}
                        inputProps={elementProps}
                        conditional={conditional}
                    />
                )}
            />

            {showError && (
                <div id={`${name}-error`} className={classes.errorMessage} role="status" aria-live="polite">
                    <ErrorMessage name={name} errors={errors} />
                </div>
            )}
        </FormControl>
    );
};

ControlledCheckboxGroup.defaultProps = {
    label: null,
    errors: {},
    showError: true,
    required: false,
    rules: {},
    inputRef: null,
    conditional: null,
    inputProps: {},
};

ControlledCheckboxGroup.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    errors: PropTypes.object,
    showError: PropTypes.bool,
    required: PropTypes.bool,
    control: PropTypes.object.isRequired,
    rules: PropTypes.object,
    options: PropTypes.array.isRequired,
    inputRef: PropTypes.func,
    conditional: PropTypes.object,
    inputProps: PropTypes.object,
};

export default withStyles(InputStyles)(ControlledCheckboxGroup);
