import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import useReportDialog from '../../utils/use-report-dialog';
import NewReportDialog from '../create-edit-report-dialog';

import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import Styles from './styles';

const DashboardHeader = ({ classes }) => {
    const {
        handleOpenDialog,
        dialogProps,
    } = useReportDialog();

    return (
        <>
            <div className={classes.wrapper}>
                <div className="row no-gutters justify-space-between align-items-center">
                    <div className="col">
                        <Typography variant="h2">My Reports</Typography>
                    </div>
                    <div className="col text-right">
                        <Button
                            className={`${classes.primaryButton}`}
                            classes={{
                                label: classes.buttonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.primaryButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                            onClick={() => handleOpenDialog()}
                        >
                            New Report
                        </Button>
                    </div>
                </div>
            </div>

            <NewReportDialog {...dialogProps} />
        </>
    );
};

DashboardHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(combinedStyles)(DashboardHeader);
