export const USER_ROLES = {
    admin: {
        value: 'admin',
        label: 'Admin',
        pluralLabel: 'Admins',
        permissions: [
            'Manage Testers',
            'Manage Sites',
            'Manage Internal Users',
            'Request Report Changes',
            'Manage Reports',
        ],
    },
    tester: {
        value: 'tester',
        label: 'Tester',
    },
    testCoordinator: {
        value: 'testCoordinator',
        label: 'Test Coordinator',
        pluralLabel: 'Test Coordinators',
        permissions: [
            'Manage Testers',
            'Manage Sites',
            'Request Report Changes',
            'Manage Reports',
        ],
    },
    attorney: {
        value: 'attorney',
        label: 'Attorney',
        pluralLabel: 'Attorneys',
        permissions: [
            'View Testers',
            'View Sites',
            'Request Report Changes',
            'Manage Reports',
        ],
    },
};

export const REPORT_HISTORY_ACTIONS = {
    reportCreated: {
        value: 'reportCreated',
        label: 'Report Created',
    },
    reportSubmitted: {
        value: 'reportSubmitted',
        label: 'Report Submitted',
    },
    reportReopened: {
        value: 'reportReopened',
        label: 'Report Reopened',
    },
    reportAssignedOutcome: {
        value: 'reportAssignedOutcome',
        label: 'Report Assigned Outcome',
    },
};

export const ADMINISTRATIVE_ROLES = [USER_ROLES.admin.value, USER_ROLES.testCoordinator.value, USER_ROLES.attorney.value];

export default { USER_ROLES, ADMINISTRATIVE_ROLES };
