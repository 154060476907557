import React, {
    useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';

import TablePaginationActions from '../../../components/table-pagination-actions';
import { getTesters } from '../../../actions/testers';
import { getTestersList, getTotalTesters } from '../../../selectors/testers';

import TesterFilter from '../components/tester-filter';
import Pill from '../components/pill';
import TesterActionMenu from '../components/tester-action-menu';

import CombineStyles from '../../../utils/combine-styles';
import ButtonStyles from '../../../styles/buttons';
import InputStyles from '../../../styles/inputs';
import Styles from '../styles';
import DeleteTesterModal from './delete-tester-modal';
import { USER_ROLES } from '../../../utils/constants';
import { USER_STATUS } from '../../../reducers/user';
import { getRole } from '../../../selectors/auth';
import NotificationSnackbar from '../../../components/notification-snackbar';
import { getUserSuccessMessage } from '../../../selectors/user';
import { clearUserSuccessMessage } from '../../../actions/user';
import getFormattedDate from '../../../utils/get-formatted-date';

const STATUS_MAP = {
    INVITED: { type: 'italic', text: 'Invited' },
    ACTIVE: { type: 'primary', text: 'Active' },
    INACTIVE: { type: 'secondary', text: 'Inactive' },
};

const MANAGE_TESTER_ROLES = [USER_ROLES.admin.value, USER_ROLES.testCoordinator.value];

const ManageTesters = ({ classes, role }) => {
    const userRole = useSelector(getRole);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterCriteria, setFilterCriteria] = useState({
        statuses: [],
        genders: [],
        ages: [],
        disability: '',
    });
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [testerToDelete, setTesterToDelete] = useState({});
    const testerSuccessMessage = useSelector(getUserSuccessMessage);
    const [sortBy, setSortBy] = useState('fullName');
    const [sortOrder, setSortOrder] = useState('asc');
    const [fetchPayload, setFetchPaylod] = useState({
        pageNumber: page,
        pageSize: rowsPerPage,
        sortBy,
        sortOrder,
    });
    const testersTotal = useSelector(getTotalTesters);
    const testers = useSelector(getTestersList);

    // this is to support legacy code that stores reports in the redux store as a map instead of a list. eventually we should change the redux store to use a list instead
    const sortedTesters = useMemo(() => {
        if (sortBy === 'fullName') {
            return orderBy(testers, ['lastName'], [sortOrder]);
        }
        if (sortBy === 'dob') {
            return orderBy(testers, ['age'], [sortOrder]);
        }
        if (sortBy === 'gender') {
            return orderBy(testers, ['gender'], [sortOrder]);
        }
        if (sortBy === 'disability') {
            return orderBy(testers, ['hasDisability'], [sortOrder]);
        }
        if (sortBy === 'status') {
            return orderBy(testers, ['status'], [sortOrder]);
        }
        return orderBy(testers, [sortBy], [sortOrder]);
    }, [testers, sortBy, sortOrder]);


    const dispatch = useDispatch();
    const history = useHistory();

    const getStatusLabel = (tester) => {
        const { type, text } = STATUS_MAP[tester.status];
        if (tester.status === USER_STATUS.INVITED && tester.lastInvitedOn) {
            return {
                type,
                text: `${text} on ${getFormattedDate(tester.lastInvitedOn)}`,
            };
        }
        return { type, text };
    };

    useEffect(() => {
        const payload = {
            pageNumber: page,
            sortBy,
            sortOrder,
        };

        if (rowsPerPage > 1) {
            payload.pageSize = rowsPerPage;
        }

        if (filterCriteria.statuses.length) {
            payload.status = filterCriteria.statuses;
        }
        if (filterCriteria.genders.length) {
            payload.genders = filterCriteria.genders;
        }
        if (filterCriteria.ages.length) {
            payload.ages = filterCriteria.ages;
        }
        if (filterCriteria.disability) {
            payload.hasDisability = filterCriteria.disability === 'Yes';
        }

        // we sort by age in the UI, but it's DOB in the database so we need to flip the sort order
        if (sortBy === 'dob') {
            payload.sortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
        } else if (sortBy === 'disability') {
            payload.sortBy = 'hasDisability';
        }

        if (!isEqual(payload, fetchPayload)) {
            setFetchPaylod(payload);
        }
    }, [page, rowsPerPage, sortBy, sortOrder, filterCriteria, fetchPayload]);

    useEffect(() => {
        dispatch(getTesters(fetchPayload));
    }, [fetchPayload, dispatch]);

    const canManageTesters = MANAGE_TESTER_ROLES.includes(role);

    // unclear what value this deadspace was adding
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, testersTotal - page * rowsPerPage);

    const handleChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, [setPage]);

    const handleInviteNewTester = useCallback(() => {
        history.push('/dashboard/tester');
    }, [history]);

    const handleDelete = (tester) => {
        setOpenDeleteModal(true);
        setTesterToDelete(tester);
    };

    const handleChangeRowsPerPage = useCallback((event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, [setRowsPerPage, setPage]);

    const handleSort = sortProperty => () => {
        setSortBy(sortProperty);
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    };

    const handleUpdateFilters = useCallback((values) => {
        if (!isEqual(values, filterCriteria)) {
            setPage(0);
            setFilterCriteria(values);
        }
    }, [filterCriteria]);

    return (
        <div className={classes.dashboardContainer}>
            <div className="row pb-4">
                <div className="col">
                    <div className="d-flex">
                        <Typography id="testers-title" variant="h1" component="h2">Manage Testers</Typography>
                        <Typography id="testers-count" variant="body1" className="pl-3 pt-1">{testersTotal} testers</Typography>
                    </div>
                </div>
                {canManageTesters && (
                    <div className="col-auto pb-4">
                        <Button
                            className={classes.primaryButton}
                            classes={{
                                label: classes.buttonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.primaryButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                            style={{ minWidth: 'max-content' }}
                            onClick={handleInviteNewTester}
                        >
                            Invite New Tester
                        </Button>
                    </div>
                )}
            </div>

            <div className="row">
                <div className={`col ${classes.filterWrapper}`}>
                    <TesterFilter onChange={handleUpdateFilters} classes={classes} />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <Paper className={classes.tableWrapper}>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-labelledby="testers-title testers-count">
                                <TableHead className={classes.tableHeader}>
                                    <TableRow>
                                        <TableCell
                                            sortDirection={sortBy === 'fullName' ? sortOrder : false}
                                            className={classes.tableCell}
                                        >
                                            <TableSortLabel
                                                active={sortBy === 'fullName'}
                                                direction={sortBy === 'fullName' ? sortOrder : 'asc'}
                                                onClick={handleSort('fullName')}
                                            >
                                                Name
                                                {sortBy === 'fullName' ? (
                                                    <span className="screen-reader-only">
                                                        {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            sortDirection={sortBy === 'dob' ? sortOrder : false}
                                            className={classes.tableCell}
                                        >
                                            <TableSortLabel
                                                active={sortBy === 'dob'}
                                                direction={sortBy === 'dob' ? sortOrder : 'asc'}
                                                onClick={handleSort('dob')}
                                            >
                                                Age
                                                {sortBy === 'dob' ? (
                                                    <span className="screen-reader-only">
                                                        {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            sortDirection={sortBy === 'gender' ? sortOrder : false}
                                            className={classes.tableCell}
                                        >
                                            <TableSortLabel
                                                active={sortBy === 'gender'}
                                                direction={sortBy === 'gender' ? sortOrder : 'asc'}
                                                onClick={handleSort('gender')}
                                            >
                                                Gender
                                                {sortBy === 'gender' ? (
                                                    <span className="screen-reader-only">
                                                        {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            sortDirection={sortBy === 'disability' ? sortOrder : false}
                                            className={classes.tableCell}
                                        >
                                            <TableSortLabel
                                                active={sortBy === 'disability'}
                                                direction={sortBy === 'disability' ? sortOrder : 'asc'}
                                                onClick={handleSort('disability')}
                                            >
                                                Disability
                                                {sortBy === 'disability' ? (
                                                    <span className="screen-reader-only">
                                                        {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            sortDirection={sortBy === 'status' ? sortOrder : false}
                                            className={classes.tableCell}
                                        >
                                            <TableSortLabel
                                                active={sortBy === 'status'}
                                                direction={sortBy === 'status' ? sortOrder : 'asc'}
                                                onClick={handleSort('status')}
                                            >
                                                Status
                                                {sortBy === 'status' ? (
                                                    <span className="screen-reader-only">
                                                        {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>

                                    {sortedTesters.map(tester => (
                                        <TableRow key={tester.id}>
                                            <TableCell className={classes.tableCell}>
                                                <Typography variant="body1" className={classes.testerName}>{tester.fullName}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Typography variant="body1">{tester.age}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Typography variant="body1">{tester.gender}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {/* eslint-disable-next-line no-nested-ternary */}
                                                <Typography variant="body1">{tester.hasDisability === true ? 'Yes' : tester.hasDisability === false ? 'No' : ''}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Pill {...getStatusLabel(tester)} />
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {canManageTesters && (
                                                    <div className="d-flex justify-content-end pr-2">
                                                        <TesterActionMenu testerId={tester.id} tester={tester} onDelete={handleDelete} status={tester.status} role={userRole} />
                                                    </div>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {/*
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell className={classes.emptyTableCell} colSpan={6} />
                                        </TableRow>
                                    )}
                                    */}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        { testersTotal > 10 && (
                            <TablePagination
                                className={classes.tableFooter}
                                component="div"
                                count={testersTotal}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                classes={{
                                    root: classes.paginationContainer,
                                    toolbar: classes.paginationToolbar,
                                    spacer: classes.paginationSpacer,
                                }}
                            />
                        )}
                    </Paper>
                </div>
            </div>
            <NotificationSnackbar isOpen={!!testerSuccessMessage} text={testerSuccessMessage} handleClose={() => dispatch(clearUserSuccessMessage())} />
            <DeleteTesterModal tester={testerToDelete} onClose={() => setOpenDeleteModal(false)} open={openDeleteModal} />
        </div>
    );
};

ManageTesters.propTypes = {
    classes: PropTypes.object.isRequired,
    role: PropTypes.oneOf(Object.values(USER_ROLES).map(role => role.value)).isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(combinedStyles)(ManageTesters);
