import * as yup from 'yup';

const requiredFile = yup
    .mixed()
    .transform((value) => {
        if (value && value.length > 0) return value[0];
        return value;
    })
    .test(
        'fileRequired',
        'Please select an outcome document file to upload',
        value => value && value.name,
    )
    .test(
        'fileSize',
        'This file exceeds the 10 MB limit.',
        value => value.size < 10485760,
    );
const notRequiredFile = yup
    .mixed()
    .notRequired();

const schema = yup.object().shape({
    outcome: yup
        .string()
        .nullable()
        .required('Please select an outcome'),
    previousOutcomeFile: yup
        .string()
        .nullable()
        .notRequired(),
    outcomeFile: yup
        .mixed()
        .when(
            'previousOutcomeFile',
            previousOutcomeFile => (
                previousOutcomeFile && previousOutcomeFile !== '' ? notRequiredFile : requiredFile
            ),
        ),
});

export default schema;
