const questions = {
    // Select any of the topics you discussed with the Agent
    topicsDiscussedRates: {
        id: '400',
        column: 'Did you discuss Rental Rates with the agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Rental Rates' },
        ],
    },
    topicsDiscussedRatesAgentSay: {
        id: '400a',
        column: 'What did the agent say about Rental Rates?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedRates',
                min: 1,
            },
        },
    },
    topicsDiscussedRatesYouSay: {
        id: '400b',
        column: 'What did you say about Rental Rates?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedRates',
                min: 1,
            },
        },
    },
    topicsDiscussedLease: {
        id: '401',
        column: 'Did you discuss Lease (length, terms, who can be lease holder) with the agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '1', name: 'Lease (length, terms, who can be lease holder)' },
        ],
    },
    topicsDiscussedLeaseAgentSay: {
        id: '401a',
        column: 'What did the agent say about the Lease?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedLease',
                min: 1,
            },
        },
    },
    topicsDiscussedLeaseYouSay: {
        id: '401b',
        column: 'What did you say about the Lease?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedLease',
                min: 1,
            },
        },
    },
    topicsDiscussedSecurityDeposit: {
        id: '402',
        column: 'Did you discuss a Security Deposit?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '2', name: 'Security Deposit' },
        ],
    },
    topicsDiscussedSecurityDepositAgentSay: {
        id: '402a',
        column: 'What did the agent say about a Security Deposit?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedSecurityDeposit',
                min: 1,
            },
        },
    },
    topicsDiscussedSecurityDepositYouSay: {
        id: '402b',
        column: 'What did you say about a Security Deposit?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedSecurityDeposit',
                min: 1,
            },
        },
    },
    topicsDiscussedApplicationFee: {
        id: '403',
        column: 'Did you discuss an Application Fee?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '3', name: 'Application Fee' },
        ],
    },
    topicsDiscussedApplicationFeeAgentSay: {
        id: '403a',
        column: 'What did the agent say about an Application Fee?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedApplicationFee',
                min: 1,
            },
        },
    },
    topicsDiscussedApplicationFeeYouSay: {
        id: '403b',
        column: 'What did you say about an Application Fee?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedApplicationFee',
                min: 1,
            },
        },
    },
    topicsDiscussedHoldingFee: {
        id: '404',
        column: 'Did you discuss a Holding Fee?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '4', name: 'Holding Fee' },
        ],
    },
    topicsDiscussedHoldingFeeAgentSay: {
        id: '404a',
        column: 'What did the agent say about a Holding Fee?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedHoldingFee',
                min: 1,
            },
        },
    },
    topicsDiscussedHoldingFeeYouSay: {
        id: '404b',
        column: 'What did you say about a Holding Fee?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedHoldingFee',
                min: 1,
            },
        },
    },
    topicsDiscussedParking: {
        id: '405',
        column: 'Did you discuss Parking (permit, first come, first served, carport, garages, etc)?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '5', name: 'Parking (permit, first come, first served, carport, garages, etc)' },
        ],
    },
    topicsDiscussedParkingAgentSay: {
        id: '405a',
        column: 'What did the agent say about Parking?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedParking',
                min: 1,
            },
        },
    },
    topicsDiscussedParkingYouSay: {
        id: '405b',
        column: 'What did you say about Parking?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedParking',
                min: 1,
            },
        },
    },
    topicsDiscussedCreditCheck: {
        id: '406',
        column: 'Did you discuss a Credit Check?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '6', name: 'Credit Check' },
        ],
    },
    topicsDiscussedCreditCheckAgentSay: {
        id: '406a',
        column: 'What did the agent say about a Credit Check?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedCreditCheck',
                min: 1,
            },
        },
    },
    topicsDiscussedCreditCheckYouSay: {
        id: '406b',
        column: 'What did you say about a Credit Check?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedCreditCheck',
                min: 1,
            },
        },
    },
    topicsDiscussedApplication: {
        id: '407',
        column: 'Did you discuss Completing an Application?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '7', name: 'Completing an Application' },
        ],
    },
    topicsDiscussedApplicationAgentSay: {
        id: '407a',
        column: 'What did the agent say about Completing an Application?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedApplication',
                min: 1,
            },
        },
    },
    topicsDiscussedApplicationYouSay: {
        id: '407b',
        column: 'What did you say about Completing an Application?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedApplication',
                min: 1,
            },
        },
    },
    topicsDiscussedPetPolicies: {
        id: '408',
        column: 'Did you discuss Pet policies?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '8', name: 'Pet policies' },
        ],
    },
    topicsDiscussedPetPoliciesAgentSay: {
        id: '408a',
        column: 'What did the agent say about Pet policies?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedPetPolicies',
                min: 1,
            },
        },
    },
    topicsDiscussedPetPoliciesYouSay: {
        id: '408b',
        column: 'What did you say about Pet policies?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedPetPolicies',
                min: 1,
            },
        },
    },
    topicsDiscussedOther: {
        id: '409',
        column: 'Did you discuss any other topics?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '9', name: 'Other' },
        ],
    },
    topicsDiscussedOtherAgentSay: {
        id: '409a',
        column: 'What else did the agent say?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedOther',
                min: 1,
            },
        },
    },
    topicsDiscussedOtherYouSay: {
        id: '409b',
        column: 'What else did you say?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedOther',
                min: 1,
            },
        },
    },
    // Indicate who is responsible for the following utilities; were you told, or did you have to ask?
    heatWhoIsResponsible: {
        id: '410',
        column: 'Who is responsible for Heat?',
        label: 'Heat',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Tenant',
            'Landlord',
            'I don\'t know',
        ],
    },
    heatToldOrAsked: {
        id: '410a',
        column: 'Were you told or did you have to ask?',
        label: 'Were you told or did you have to ask?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'heatWhoIsResponsible',
                notValue: "I don't know",
            },
        },
        options: [
            'Told',
            'Asked',
        ],
    },
    waterWhoIsResponsible: {
        id: '411',
        column: 'Who is responsible for Water?',
        label: 'Water',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Tenant',
            'Landlord',
            'I don\'t know',
        ],
    },
    waterToldOrAsked: {
        id: '411a',
        column: 'Were you told or did you have to ask?',
        label: 'Were you told or did you have to ask?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'waterWhoIsResponsible',
                notValue: "I don't know",
            },
        },
        options: [
            'Told',
            'Asked',
        ],
    },
    trashWhoIsResponsible: {
        id: '412',
        column: 'Who is responsible for Trash?',
        label: 'Trash',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Tenant',
            'Landlord',
            'I don\'t know',
        ],
    },
    trashToldOrAsked: {
        id: '412a',
        column: 'Were you told or did you have to ask?',
        label: 'Were you told or did you have to ask?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'trashWhoIsResponsible',
                notValue: "I don't know",
            },
        },
        options: [
            'Told',
            'Asked',
        ],
    },
    sewageWhoIsResponsible: {
        id: '413',
        column: 'Who is responsible for Sewage?',
        label: 'Sewage',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Tenant',
            'Landlord',
            'I don\'t know',
        ],
    },
    sewageToldOrAsked: {
        id: '413a',
        column: 'Were you told or did you have to ask?',
        label: 'Were you told or did you have to ask?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'sewageWhoIsResponsible',
                notValue: "I don't know",
            },
        },
        options: [
            'Told',
            'Asked',
        ],
    },
    electricityWhoIsResponsible: {
        id: '414',
        column: 'Who is responsible for Electricity?',
        label: 'Electricity',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Tenant',
            'Landlord',
            'I don\'t know',
        ],
    },
    electricityToldOrAsked: {
        id: '414a',
        column: 'Were you told or did you have to ask?',
        label: 'Were you told or did you have to ask?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'electricityWhoIsResponsible',
                notValue: "I don't know",
            },
        },
        options: [
            'Told',
            'Asked',
        ],
    },
    gasWhoIsResponsible: {
        id: '415',
        column: 'Who is responsible for Gas?',
        label: 'Gas',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Tenant',
            'Landlord',
            'I don\'t know',
        ],
    },
    gasToldOrAsked: {
        id: '415a',
        column: 'Were you told or did you have to ask?',
        label: 'Were you told or did you have to ask?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'gasWhoIsResponsible',
                notValue: "I don't know",
            },
        },
        options: [
            'Told',
            'Asked',
        ],
    },
    amenitiesWhoIsResponsible: {
        id: '416',
        column: 'Who is responsible for Amenities?',
        label: 'Amenities',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Tenant',
            'Landlord',
            'I don\'t know',
        ],
    },
    amenitiesToldOrAsked: {
        id: '416a',
        column: 'Were you told or did you have to ask?',
        label: 'Were you told or did you have to ask?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'amenitiesWhoIsResponsible',
                notValue: "I don't know",
            },
        },
        options: [
            'Told',
            'Asked',
        ],
    },

    amenitiesDiscussed: {
        id: '417',
        column: 'If “Amenities” were discussed, please elaborate',
        label: 'If “Amenities” were discussed, please elaborate',
        validation: {
            type: 'text',
            required: false,
            conditional: false,
        },
    },

    didYouInspectApartment: {
        id: '418',
        column: 'Did you inspect an apartment or demo apartment?',
        label: 'Did you inspect an apartment or demo apartment?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    whatDidYouView: {
        id: '418a',
        column: 'What did you view?',
        label: 'What did you view?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'didYouInspectApartment',
                value: 'Yes',
            },
        },
        options: [
            'Open apartment/townhouse',
            'Demo apartment',
            'Open apartment but rented',
            'Other',
        ],
    },
    whatDidYouViewOther: {
        id: '418a1',
        column: 'Did you view anything else?',
        label: 'Other',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'whatDidYouView',
                value: 'Other',
            },
        },
    },
    describeAreaOutside: {
        id: '418b',
        column: 'Describe area outside apartment inspected:',
        label: 'Describe area outside apartment inspected:',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Well cut lawn' },
            { id: '1', name: 'Cigarette butts' },
            { id: '2', name: 'Trash/papers' },
            { id: '3', name: 'Potholes in parking lot' },
        ],
    },

    // Describe all units inspected (conditional questions when didYouInspectApartment is 'Yes')
    howManyUnitsInspected: {
        id: '418c',
        column: 'How many units did you inspect?',
        label: 'How many units did you inspect?',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'didYouInspectApartment',
                value: 'Yes',
            },
        },
        options: [1, 2, 3, 4, 5, 6],
    },
    // Unit 1
    unit1BuildingNo: {
        id: '418c1a',
        column: 'Unit 1: Building #',
        label: 'Building #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 0,
            },
        },
    },
    unit1ApartmentNo: {
        id: '418c1b',
        column: 'Unit 1: Apartment #',
        label: 'Apartment #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 0,
            },
        },
    },
    unit1DateAvailable: {
        id: '418c1d',
        column: 'Unit 1: Date available',
        label: 'Date available',
        validation: {
            type: 'date',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 0,
            },
        },
    },
    unit1Rent: {
        id: '418c1e',
        column: 'Unit 1: Rent',
        label: 'Rent',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 0,
            },
        },
    },
    unit1Bedrooms: {
        id: '418c1f',
        column: 'Unit 1: Number of bedrooms',
        label: 'Number of bedrooms',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 0,
            },
        },
        options: ['Studio', '1', '2', '3', '4'],
    },
    unit1UpperOrLower: {
        id: '418c1h',
        column: 'Unit 1: Upper or lower?',
        label: 'Upper or lower?',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 0,
            },
        },
        options: [
            'Upper',
            'Lower',
        ],
    },
    unit1FrontOrBack: {
        id: '418c1i',
        column: 'Unit 1: Front or back?',
        label: 'Front or back?',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 0,
            },
        },
        options: [
            'Front',
            'Back',
        ],
    },
    // Unit 2
    unit2BuildingNo: {
        id: '418c2a',
        column: 'Unit 2: Building #',
        label: 'Building #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 1,
            },
        },
    },
    unit2ApartmentNo: {
        id: '418c2b',
        column: 'Unit 2: Apartment #',
        label: 'Apartment #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 1,
            },
        },
    },
    unit2DateAvailable: {
        id: '418c2d',
        column: 'Unit 2: Date available',
        label: 'Date available',
        validation: {
            type: 'date',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 1,
            },
        },
    },
    unit2Rent: {
        id: '418c2e',
        column: 'Unit 2: Rent',
        label: 'Rent',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 1,
            },
        },
    },
    unit2Bedrooms: {
        id: '418c2f',
        column: 'Unit 2: Number of bedrooms',
        label: 'Number of bedrooms',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 1,
            },
        },
        options: ['Studio', '1', '2', '3', '4'],
    },
    unit2UpperOrLower: {
        id: '418c2h',
        column: 'Unit 2: Upper or lower?',
        label: 'Upper or lower?',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 1,
            },
        },
        options: [
            'Upper',
            'Lower',
        ],
    },
    unit2FrontOrBack: {
        id: '418c2i',
        column: 'Unit 2: Front or back?',
        label: 'Front or back?',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 1,
            },
        },
        options: [
            'Front',
            'Back',
        ],
    },
    // Unit 3
    unit3BuildingNo: {
        id: '418c3a',
        column: 'Unit 3: Buiding #',
        label: 'Building #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 2,
            },
        },
    },
    unit3ApartmentNo: {
        id: '418c3b',
        column: 'Unit 3: Apartment #',
        label: 'Apartment #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 2,
            },
        },
    },
    unit3DateAvailable: {
        id: '418c3d',
        column: 'Unit 3: Date available',
        label: 'Date available',
        validation: {
            type: 'date',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 2,
            },
        },
    },
    unit3Rent: {
        id: '418c3e',
        column: 'Unit 3: Rent',
        label: 'Rent',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 2,
            },
        },
    },
    unit3Bedrooms: {
        id: '418c3f',
        column: 'Unit 3: Number of bedrooms',
        label: 'Number of bedrooms',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 2,
            },
        },
        options: ['Studio', '1', '2', '3', '4'],
    },
    unit3UpperOrLower: {
        id: '418c3h',
        column: 'Unit 3: Upper or lower?',
        label: 'Upper or lower?',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 2,
            },
        },
        options: [
            'Upper',
            'Lower',
        ],
    },
    unit3FrontOrBack: {
        id: '418c3i',
        column: 'Unit 3: Front or back?',
        label: 'Front or back?',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 2,
            },
        },
        options: [
            'Front',
            'Back',
        ],
    },
    // Unit 4
    unit4BuildingNo: {
        id: '418c4a',
        column: 'Unit 4: Building #',
        label: 'Building #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 3,
            },
        },
    },
    unit4ApartmentNo: {
        id: '418c4b',
        column: 'Unit 4: Apartment #',
        label: 'Apartment #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 3,
            },
        },
    },
    unit4DateAvailable: {
        id: '418c4d',
        column: 'Unit 4: Date available',
        label: 'Date available',
        validation: {
            type: 'date',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 3,
            },
        },
    },
    unit4Rent: {
        id: '418c4e',
        column: 'Unit 4: Rent',
        label: 'Rent',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 3,
            },
        },
    },
    unit4Bedrooms: {
        id: '418c4f',
        column: 'Unit 4: Number of bedrooms',
        label: 'Number of bedrooms',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 3,
            },
        },
        options: ['Studio', '1', '2', '3', '4'],
    },
    unit4UpperOrLower: {
        id: '418c4h',
        column: 'Unit 4: Upper or lower?',
        label: 'Upper or lower?',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 3,
            },
        },
        options: [
            'Upper',
            'Lower',
        ],
    },
    unit4FrontOrBack: {
        id: '418c4i',
        column: 'Unit 4: Front or back?',
        label: 'Front or back?',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 3,
            },
        },
        options: [
            'Front',
            'Back',
        ],
    },
    // Unit 5
    unit5BuildingNo: {
        id: '418c5a',
        column: 'Unit 5: Building #',
        label: 'Building #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 4,
            },
        },
    },
    unit5ApartmentNo: {
        id: '418c5b',
        column: 'Unit 5: Apartment #',
        label: 'Apartment #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 4,
            },
        },
    },
    unit5DateAvailable: {
        id: '418c5d',
        column: 'Unit 5: Date available',
        label: 'Date available',
        validation: {
            type: 'date',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 4,
            },
        },
    },
    unit5Rent: {
        id: '418c5e',
        column: 'Unit 5: Rent',
        label: 'Rent',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 4,
            },
        },
    },
    unit5Bedrooms: {
        id: '418c5f',
        column: 'Unit 5: Number of bedrooms',
        label: 'Number of bedrooms',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 4,
            },
        },
        options: ['Studio', '1', '2', '3', '4'],
    },
    unit5UpperOrLower: {
        id: '418c5h',
        column: 'Unit 5: Upper or lower?',
        label: 'Upper or lower?',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 4,
            },
        },
        options: [
            'Upper',
            'Lower',
        ],
    },
    unit5FrontOrBack: {
        id: '418c5i',
        column: 'Unit 5: Front or back?',
        label: 'Front or back?',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 4,
            },
        },
        options: [
            'Front',
            'Back',
        ],
    },
    // Unit 6
    unit6BuildingNo: {
        id: '418c6a',
        column: 'Unit 6: Building #',
        label: 'Building #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 5,
            },
        },
    },
    unit6ApartmentNo: {
        id: '418c6b',
        column: 'Unit 6: Apartment #',
        label: 'Apartment #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 5,
            },
        },
    },
    unit6DateAvailable: {
        id: '418c6d',
        column: 'Unit 6: Date available',
        label: 'Date available',
        validation: {
            type: 'date',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 5,
            },
        },
    },
    unit6Rent: {
        id: '418c6e',
        column: 'Unit 6: Rent',
        label: 'Rent',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 5,
            },
        },
    },
    unit6Bedrooms: {
        id: '418c6f',
        column: 'Unit 6: Number of bedrooms',
        label: 'Number of bedrooms',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 5,
            },
        },
        options: ['Studio', '1', '2', '3', '4'],
    },
    unit6UpperOrLower: {
        id: '418c6h',
        column: 'Unit 6: Upper or lower?',
        label: 'Upper or lower?',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 5,
            },
        },
        options: [
            'Upper',
            'Lower',
        ],
    },
    unit6FrontOrBack: {
        id: '418c6i',
        column: 'Unit 6: Front or back?',
        label: 'Front or back?',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsInspected',
                greaterThan: 5,
            },
        },
        options: [
            'Front',
            'Back',
        ],
    },

    viewFromUnitsShown: {
        id: '418d',
        column: 'Select the view(s) from all units shown:',
        label: 'Select the view(s) from all units shown:',
        validation: {
            type: 'array',
            required: true,
            conditional: {
                on: 'didYouInspectApartment',
                value: 'Yes',
            },
        },
        options: [
            { id: '0', name: 'Playground' },
            { id: '1', name: 'Parking lot' },
            { id: '2', name: 'Pool' },
            { id: '3', name: 'Forest Preserves' },
            { id: '4', name: 'Garden' },
            { id: '5', name: 'Dumpsters' },
            { id: '6', name: 'Trails' },
            { id: '7', name: 'Another building' },
            { id: '8', name: 'Courtyard' },
            { id: '9', name: 'Freeway' },
            { id: '10', name: 'Golf Course' },
            { id: '11', name: 'Water fountain' },
            { id: '12', name: 'Clubhouse' },
            { id: '13', name: 'Mailbox' },
            { id: '14', name: 'Bus stop' },
            { id: '15', name: 'Other' },
        ],
    },
    viewFromUnitsShownOtherDetails: {
        id: '418d1',
        column: 'Any other view from units shown?',
        label: 'Other',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'viewFromUnitsShown',
                includes: '15',
            },
        },
    },

    wereYouToldAboutOtherUnits: {
        id: '419',
        column: 'Were you told about other units NOT available to inspect?',
        label: 'Were you told about other units NOT available to inspect?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    // Describe unavailable units - conditional questions when wereYouToldAboutOtherUnits is 'Yes'
    howManyUnitsUnavailable: {
        id: '419a',
        column: 'If yes, how many units?',
        label: 'If yes, how many units?',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'wereYouToldAboutOtherUnits',
                value: 'Yes',
            },
        },
        options: [1, 2],
    },
    // Unit 1
    unit1UnavailableBuildingNo: {
        id: '419a1a',
        column: 'Unit 1: Building #',
        label: 'Building #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsUnavailable',
                greaterThan: 0,
            },
        },
    },
    unit1UnavailableApartmentNo: {
        id: '419a1b',
        column: 'Unit 1: Apartment #',
        label: 'Apartment #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsUnavailable',
                greaterThan: 0,
            },
        },
    },
    unit1UnavailableDateAvailable: {
        id: '419a1c',
        column: 'Unit 1: Date available',
        label: 'Date available',
        validation: {
            type: 'date',
            required: false,
            conditional: {
                on: 'howManyUnitsUnavailable',
                greaterThan: 0,
            },
        },
    },
    unit1UnavailableRent: {
        id: '419a1d',
        column: 'Unit 1: Rent',
        label: 'Rent',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsUnavailable',
                greaterThan: 0,
            },
        },
    },
    unit1UnavailableBedrooms: {
        id: '419a1e',
        column: 'Unit 1: Number of bedrooms',
        label: 'Number of bedrooms',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsUnavailable',
                greaterThan: 0,
            },
        },
        options: ['Studio', '1', '2', '3', '4'],
    },
    unit1UnavailableReason: {
        id: '419a1f',
        column: 'Unit 1: Reason Given',
        label: 'Reason Given',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsUnavailable',
                greaterThan: 0,
            },
        },
    },
    // Unit 2
    unit2UnavailableBuildingNo: {
        id: '419a2a',
        column: 'Unit 2: Building #',
        label: 'Building #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsUnavailable',
                greaterThan: 1,
            },
        },
    },
    unit2UnavailableApartmentNo: {
        id: '419a2b',
        column: 'Unit 2: Apartment #',
        label: 'Apartment #',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsUnavailable',
                greaterThan: 1,
            },
        },
    },
    unit2UnavailableDateAvailable: {
        id: '419a2c',
        column: 'Unit 2: Date available',
        label: 'Date available',
        validation: {
            type: 'date',
            required: false,
            conditional: {
                on: 'howManyUnitsUnavailable',
                greaterThan: 1,
            },
        },
    },
    unit2UnavailableRent: {
        id: '419a2d',
        column: 'Unit 2: Rent',
        label: 'Rent',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsUnavailable',
                greaterThan: 1,
            },
        },
    },
    unit2UnavailableBedrooms: {
        id: '419a2e',
        column: 'Unit 2: Number of bedrooms',
        label: 'Number of bedrooms',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsUnavailable',
                greaterThan: 1,
            },
        },
        options: ['Studio', '1', '2', '3', '4'],
    },
    unit2UnavailableReason: {
        id: '419a2f',
        column: 'Unit 2: Reason Given',
        label: 'Reason Given',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'howManyUnitsUnavailable',
                greaterThan: 1,
            },
        },
    },

    didTheAgentTakePartInTour: {
        id: '420',
        column: 'Did the agent actively take part in the tour (pointing out apt features), or stand by and let you explore alone?',
        label: 'Did the agent actively take part in the tour (pointing out apt features), or stand by and let you explore alone?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Active tour',
            'Stood by',
            'No tour',
        ],
    },
    commentsAboutTheTour: {
        id: '421',
        column: 'Any comments to share about the tour?',
        label: 'Any comments to share about the tour?',
        validation: {
            type: 'text',
            required: false,
            conditional: false,
        },
    },

    wereAnyFeaturesPointedOut: {
        id: '422',
        column: 'Were any features or amenities of the apartment/complex pointed out?',
        label: 'Were any features or amenities of the apartment/complex pointed out such as:',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Laundry room' },
            { id: '1', name: 'Cable' },
            { id: '2', name: 'Dishwasher' },
            { id: '3', name: 'Air conditioner' },
            { id: '4', name: 'New carpet' },
            { id: '5', name: 'Pool' },
            { id: '6', name: 'Newly painted' },
            { id: '7', name: 'Carport' },
            { id: '8', name: 'Garage' },
            { id: '9', name: 'Clubhouse' },
            { id: '10', name: 'Fitness center' },
            { id: '11', name: 'Storage' },
            { id: '12', name: '24 hr maintenance' },
            { id: '13', name: 'Other' },
        ],
    },
    wereAnyFeaturesPointedOutOtherDetails: {
        id: '423a',
        column: 'Any other features pointed out?',
        label: 'Other',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'wereAnyFeaturesPointedOut',
                includes: '13',
            },
        },
    },

    // Did the agent say anything, good or bad, about
    unitOrComplexEnvironment: {
        id: '424',
        column: 'Did the agent say anything, good or bad about: The unit or the complex environment?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'The unit or the complex environment' },
        ],
    },
    unitOrComplexEnvironmentAgentSay: {
        id: '424a',
        column: 'What did the agent say about The unit or the complex environment?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'unitOrComplexEnvironment',
                min: 1,
            },
        },
    },
    tenants: {
        id: '425',
        column: 'Did the agent say anything, good or bad about: Tenants?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Tenants' },
        ],
    },
    tenantsAgentSay: {
        id: '425a',
        column: 'What did the agent say about Tenants?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'tenants',
                min: 1,
            },
        },
    },
    schools: {
        id: '426',
        column: 'Did the agent say anything, good or bad about: Schools?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Schools' },
        ],
    },
    schoolsAgentSay: {
        id: '426a',
        column: 'What did the agent say about Schools?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'schools',
                min: 1,
            },
        },
    },
    neighborhood: {
        id: '427',
        column: 'Did the agent say anything, good or bad about: The Neighborhood?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Neighborhood' },
        ],
    },
    neighborhoodAgentSay: {
        id: '427a',
        column: 'What did the agent say about The Neighborhood?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'neighborhood',
                min: 1,
            },
        },
    },
};

export default questions;
