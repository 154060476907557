import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-unresolved
import { ErrorMessage } from '@hookform/error-message';

import InputStyles from '../../../styles/inputs';

const TextArea = ({
    classes,
    name,
    label,
    helperText,
    errors,
    required,
    rowsMin,
    rowsMax,
    inputRef,
    inputProps,
    showError,
    hiddenLabel,
    disabled,
}) => {
    const [isFocused, setIsFocused] = useState(false);

    const isInvalid = !!errors[name];

    let describedBy = helperText ? `${name}-helper` : null;
    if (showError && isInvalid) {
        describedBy = describedBy ? `${describedBy} ${name}-error` : `${name}-error`;
    }

    const handleFocus = useCallback(() => {
        setIsFocused(true);
    }, [setIsFocused]);

    const handleBlur = useCallback(() => {
        setIsFocused(false);
    }, [setIsFocused]);

    const elementProps = {
        ...inputProps,
        name,
        'aria-label': hiddenLabel ? label : null,
        'aria-labelledby': !hiddenLabel ? `${name}-label` : null,
        'aria-describedby': describedBy,
        'aria-invalid': isInvalid,
        'aria-required': required,
        required,
        onFocus: handleFocus,
        onBlur: handleBlur,
    };

    return (
        <>
            { !hiddenLabel && (
                <Typography variant="body1" id={`${name}-label`} className={classes.inputLabel}>{label}</Typography>
            )}

            <div
                className={`
                    ${classes.textareaRoot} ${classes.textInputRoot}
                    ${isFocused && classes.textInputBackgroundFocused}
                    ${isInvalid && classes.textInputError}
                    ${disabled && classes.textAreaDisabled}
                `}
            >
                <TextareaAutosize
                    className={`${classes.textareaBackground} ${classes.textInputBackground}`}
                    ref={inputRef}
                    rowsMin={rowsMin}
                    rowsMax={rowsMax}
                    disabled={disabled}
                    {...elementProps}
                />
            </div>

            { helperText && (
                <Collapse in={isFocused}>
                    <Typography variant="body1" id={`${name}-helper`} className={classes.inputHelper}>{helperText}</Typography>
                </Collapse>
            )}

            {showError && (
                <div id={`${name}-error`} className={classes.errorMessage} role="status" aria-live="polite">
                    <ErrorMessage name={name} errors={errors} />
                </div>
            )}
        </>
    );
};

TextArea.defaultProps = {
    helperText: null,
    errors: {},
    required: false,
    rowsMin: null,
    rowsMax: null,
    inputRef: null,
    inputProps: {},
    showError: true,
    hiddenLabel: false,
    disabled: false,
};

TextArea.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    errors: PropTypes.object,
    required: PropTypes.bool,
    rowsMin: PropTypes.number,
    rowsMax: PropTypes.number,
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    inputProps: PropTypes.object,
    showError: PropTypes.bool,
    hiddenLabel: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default withStyles(InputStyles)(TextArea);
