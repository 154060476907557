import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';

import { REPORT_STATUS } from '../reducers/reports';
import sortByAttribute from '../utils/sort-by-attribute';

const getReportsState = state => state.reports;

export const getReports = createSelector(
    getReportsState,
    reports => reports.data,
);

// TODO
// There are 4 selectors in here that are all calling getReports, which is an extremely heavy
// endpoint that can take up to 10 seconds to load. This is a huge performance issue that needs
// to be addressed.  Instead of calling it 4 times, call it once and then operate on the result.
export const getReportsList = createSelector(
    getReports,
    reports => Object.values(reports) || [],
);

export const getReportById = createSelector(
    getReports,
    reports => reportId => reports[reportId],
);

const getReportsByStatus = createSelector(
    getReports,
    (reports) => {
        const reportsByStatus = {};
        Object.entries(reports).forEach(([reportId, report]) => {
            if (!reportsByStatus[report.status]) {
                reportsByStatus[report.status] = {};
            }
            reportsByStatus[report.status][reportId] = report;
        });

        return reportsByStatus;
    },
);

export const getInProgressReports = createSelector(
    getReportsByStatus,
    reports => ({
        ...reports[REPORT_STATUS.DRAFT],
    }),
);

export const getCompletedReports = createSelector(
    getReportsByStatus,
    reports => ({
        ...reports[REPORT_STATUS.SUBMITTED],
    }),
);

export const getReopenedReports = createSelector(
    getReportsByStatus,
    reports => ({
        ...reports[REPORT_STATUS.REOPENED],
    }),
);

export const getReportsFilter = createSelector(
    getReports,
    reports => (
        (filterCriteria) => {
            const {
                sites,
                testers,
                statuses,
            } = filterCriteria;
            const filteredReports = {};
            Object.entries(reports).forEach(([reportId, report]) => {
                if (statuses.length && !statuses.includes(report.outcome) && !statuses.includes(report.status)) {
                    return;
                }

                if (sites.length && !sites.includes(report.testSite.id)) {
                    return;
                }

                if (testers.length && !testers.includes(report.tester.fullName)) {
                    return;
                }

                filteredReports[reportId] = report;
            });
            return filteredReports;
        }
    ),
);

export const getGetReportAttachmentsUploadStatus = createSelector(
    getReportsState,
    state => state.attachmentUploadStatus,
);

export const getReportAnswersSelector = createSelector(
    getReports,
    reports => reportId => reports[reportId].reportAnswers,
);

export const getReportAttachmentsSelector = createSelector(
    getReports,
    reports => reportId => reports[reportId].attachments,
);

export const getDistinctTesters = createSelector(
    getCompletedReports,
    (reports) => {
        const reportDetails = Object.values(reports);
        if (reportDetails.length === 0) return [];

        const testers = reportDetails.reduce((acc, report) => {
            acc[report.tester.id] = { value: report.tester.id, label: report.tester.fullName };
            return acc;
        }, {});
        // return array sorted alphabetically
        return orderBy(Object.values(testers), ['label'], ['asc']);
    },
);

export const getMostRecentReportForSite = createSelector(
    getReports,
    reports => (siteId) => {
        const reportDetails = Object.values(reports);
        const siteReports = sortByAttribute(
            reportDetails.filter(report => report.testSite.id === siteId),
            'testDate',
            'desc',
        );
        return siteReports.length ? siteReports[0] : null;
    },
);

export const getReportsTotal = createSelector(
    getReportsState,
    state => state.total || 0,
);
