import React, { useEffect } from 'react';
import {
    Switch,
    Route,
    Redirect,
    useLocation,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { ResetAPIStatus } from '../actions/app';
import useSnackbar from '../utils/use-snackbar';
import { getAPIStatus } from '../selectors/app';
import NotificationSnackbar from '../components/notification-snackbar';

import PublicLayout from '../layouts/public';
import DashboardLayout from '../layouts/dashboard';
import { isAuthenticated as getAuthentication } from '../selectors/auth';
import withRoot from '../wiring/with-root';
import themer from '../styles/material-theme';

import ReportRouter from './report/report-router';

const App = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(getAuthentication);

    const apiStatus = useSelector(getAPIStatus);
    const { handleOpenSnackbar, snackbarProps } = useSnackbar();

    useEffect(() => {
        if (apiStatus.completed) {
            if (apiStatus.error && apiStatus.errorMsg) handleOpenSnackbar({ text: apiStatus.errorMsg, type: 'error', autoHideDisabled: apiStatus.autoHideDisabled });
            else if (!apiStatus.error && apiStatus.successMsg) handleOpenSnackbar({ text: apiStatus.successMsg, type: 'success', autoHideDisabled: apiStatus.autoHideDisabled });
            dispatch(ResetAPIStatus());
        }
    }, [apiStatus, handleOpenSnackbar, dispatch]);

    return (
        <>
            <NotificationSnackbar {...snackbarProps} />
            <Switch location={location}>
                <Route path="/report">
                    {isAuthenticated ? <ReportRouter /> : <Redirect to={{ pathname: '/login', state: { from: location } }} />}
                </Route>
                <Route path="/dashboard">
                    {isAuthenticated ? <DashboardLayout /> : <Redirect to={{ pathname: '/login', state: { from: location } }} />}
                </Route>
                <Route path="/">
                    <PublicLayout />
                </Route>
            </Switch>
        </>
    );
};

export default withRoot(App, themer);
