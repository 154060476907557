import Colors from './colors';

const styles = theme => ({
    errorMessage: {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 500,
        textAlign: 'left',
        color: Colors.carnelian,
    },
    inputWrapper: {
        paddingTop: 20,
    },
    inputLabel: {
        fontWeight: 700,
        paddingBottom: 5,
        lineHeight: 1.5,
        color: theme.typography.color,
    },
    inputLabelRadioTable: {
        fontWeight: 400,
        lineHeight: 1.5,
        color: theme.typography.color,
    },
    inputHelper: {
        fontSize: '0.875rem',
        paddingTop: 12,
        lineHeight: '17px',
        color: theme.typography.color,
    },
    // Text Inputs
    textInputError: {},
    textInputRoot: {
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        color: theme.typography.color,
        backgroundColor: `rgba(${Colors.whiteRGB}, .5)`,
        lineHeight: 1,
        width: '100%',
        padding: '2px 0',
        borderRadius: 3,
        border: `1px solid ${Colors.darkElectricBlue}`,

        '&:hover': {
            backgroundColor: Colors.white,
        },

        '&$textInputError': {
            borderColor: `${Colors.carnelian} !important`,
        },
        '&.Mui-disabled': {
            borderColor: 'rgba(0, 0, 0, 0.26)',
            '&:hover': {
                backgroundColor: `rgba(${Colors.whiteRGB}, .5)`,
            },
        },
    },
    textInputBackground: {
        padding: 10,
    },
    textInputBackgroundFocused: {
        border: `2px solid ${Colors.ultramarineBlue}`,
        padding: '1px 0',
        boxShadow: '0 5px 10px 0 rgba(25,25,25,0.3)',
        backgroundColor: Colors.white,
    },
    // Textarea
    textareaRoot: {
        cursor: 'text',
        display: 'inline-flex',
        position: 'relative',
        boxSizing: 'border-box',
        alignItems: 'center',
        fontWeight: 400,
    },
    textareaBackground: {
        font: 'inherit',
        width: '100%',
        border: 0,
        height: '1.1876em',
        margin: 0,
        display: 'block',
        minWidth: 0,
        background: 'none',
        boxSizing: 'content-box',
        animationName: 'mui-auto-fill-cancel',
        letterSpacing: 'inherit',
        animationDuration: '10ms',

        '&:focus': {
            outline: 0,
        },
    },
    textAreaBlock: {
        display: 'block',
        width: '100%',
    },
    textAreaDisabled: {
        borderColor: 'rgba(0, 0, 0, 0.26)',
        opacity: 0.3,
    },
    // red error message box
    genericErrorMessageWrapper: {
        backgroundColor: Colors.pastelRed,
        borderRadius: 8,
        padding: 15,
        marginTop: 20,
    },
    genericErrorIcon: {
        marginRight: 15,
        color: Colors.pastelRed,
    },
    // Date Picker
    datePickerRoot: {
        backgroundColor: Colors.white,
    },
    // SelectInput
    selectInputError: {},
    selectInputRoot: {
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        color: theme.typography.color,
        backgroundColor: `rgba(${Colors.whiteRGB}, .5)`,
        lineHeight: 1,
        width: '100%',
        padding: '2px 0',
        borderRadius: 3,
        border: `1px solid ${Colors.darkElectricBlue}`,

        '&:hover': {
            backgroundColor: Colors.white,
        },

        '&$selectInputError': {
            borderColor: `${Colors.carnelian} !important`,
        },
        '&.Mui-disabled': {
            borderColor: 'rgba(0, 0, 0, 0.26)',
            '&:hover': {
                backgroundColor: `rgba(${Colors.whiteRGB}, .5)`,
            },
        },
    },
    selectInputBackground: {
        padding: 10,
    },
    selectInputBackgroundFocused: {
        color: Colors.ultramarineBlue,
        fontWeight: 'bold',
        padding: '1px 0',
        border: `2px solid ${Colors.ultramarineBlue}`,
        boxShadow: '0 5px 10px 0 rgba(25,25,25,0.3)',
    },
    selectWrapper: {
        display: 'inline-flex',
        width: '100%',
        '& fieldset': {
            borderColor: Colors.darkElectricBlue,
        },
        '&:hover fieldset': {
            borderColor: `${Colors.ultramarineBlue} !important`, // this is dirty, I know.
        },
    },
    selectWrapperError: {
        display: 'inline-flex',
        width: '100%',
        '& fieldset': {
            borderColor: Colors.carnelian,
        },
        '&:hover fieldset': {
            borderColor: `${Colors.carnelian} !important`, // this is dirty, I know.
        },
    },
    selectOutlined: {
        backgroundColor: `rgba(${Colors.whiteRGB}, .5)`,
        '&:hover, &:focus': {
            backgroundColor: Colors.white,
            '&.Mui-disabled': {
                backgroundColor: `rgba(${Colors.whiteRGB}, .5)`,
            },
        },
    },
    selectIcon: {
        color: Colors.darkGrey,
    },
    selectIconOpen: {
        color: Colors.persianBlue,
    },
    menuItemRoot: {
        fontSize: theme.typography.body1.fontSize,
        marginLeft: 10,
        marginRight: 10,

        '&:hover, &:focus': {
            backgroundColor: `rgba(${Colors.ultramarineBlueRGB}, .1)`,
        },
    },
    selectedMenuItem: {
        backgroundColor: `rgba(${Colors.ultramarineBlueRGB}, .2) !important`, // this is dirty, I know.
    },
    // RadioGroupTable
    radioGroupTableRow: {
        borderTop: `1px solid ${Colors.darkElectricBlue}`,
    },
    greyHr: {
        border: 'none',
        height: 1,
        width: '100%',
        backgroundColor: Colors.lavenderGray,
    },
    // common styles for tester question wrapper
    reanswerRequestReason: {
        borderRadius: 6,
        opacity: 0.75,
        border: `1px solid ${Colors.lavenderGray}`,
        padding: 12,
        backgroundColor: Colors.white,
    },
});
export default styles;
