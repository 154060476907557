import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import {
    useThemeDispatch,
    increaseFontSize,
    decreaseFontSize,
} from '../../wiring/theme-context';

import Styles from './styles';

const DisplayOptionsControlPanel = ({ classes }) => {
    const dispatch = useThemeDispatch();

    const handleIncreaseFontSize = useCallback(() => {
        dispatch(increaseFontSize());
    }, [dispatch]);

    const handleDecreaseFontSize = useCallback(() => {
        dispatch(decreaseFontSize());
    }, [dispatch]);

    return (
        <>
            <div className={`row ${classes.fontSizeControl}`}>
                <div className="col">
                    <Typography variant="body1">Text size:</Typography>
                </div>
                <div className="col-8">
                    <ButtonGroup
                        variant="text"
                        color="primary"
                        classes={{
                            groupedTextHorizontal: classes.fontSizeButton,
                        }}
                        aria-label="increase or decrease font size"
                    >
                        <Button
                            classes={{
                                label: classes.fontSizeDecrease,
                            }}
                            onClick={handleDecreaseFontSize}
                        >
                            A
                        </Button>
                        <Button
                            classes={{
                                label: classes.fontSizeIncrease,
                            }}
                            onClick={handleIncreaseFontSize}
                        >
                            A
                        </Button>
                    </ButtonGroup>
                </div>
            </div>

            {/* TODO: add background color change buttons */}
        </>
    );
};

DisplayOptionsControlPanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(DisplayOptionsControlPanel);
