import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Styles from './styles';

const StatusBanner = ({
    type,
    text,
    classes,
}) => (
    <div className={`
            ${classes.badgeWrapper}
            ${type === 'success' && classes.badgeSuccess}
            ${type === 'warn' && classes.badgeWarn}
        `}
    >
        <div className={classes.badgeIcon}>{type === 'success' ? <CheckCircleIcon /> : <WarningIcon /> }</div>
        <Typography variant="body1" className={classes.badgeText}>{text}</Typography>
    </div>
);

StatusBanner.propTypes = {
    type: PropTypes.oneOf(['success', 'warn']).isRequired,
    text: PropTypes.string.isRequired,
    // from withStyles
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(StatusBanner);
