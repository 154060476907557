import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { ErrorMessage } from '@hookform/error-message';

import InputStyles from '../../../styles/inputs';

const RadioGroup = ({
    classes,
    name,
    label,
    errors,
    showError,
    required,
    control,
    rules,
    children,
}) => {
    const isInvalid = !!errors[name];
    const describedBy = (showError && isInvalid) ? `${name}-error` : null;

    const elementProps = {
        'aria-labelledby': `${name}-label`,
        'aria-describedby': describedBy,
        'aria-invalid': isInvalid,
        'aria-required': required,
        required,
    };

    return (
        <div className="row align-items-center">
            <div className="col">
                <Typography variant="body1" id={`${name}-label`} className={classes.inputLabelRadioTable}>{label}</Typography>
            </div>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={props => (
                    <MuiRadioGroup row {...elementProps} {...props} className="col justify-content-end">
                        {
                            React.Children.map(children, child => (
                                <div>
                                    {React.createElement(child.type, {
                                        ...{
                                            ...child.props,
                                            // eslint-disable-next-line react/prop-types
                                            isChecked: props.value === child.props.value,
                                        },
                                    })}
                                </div>
                            ))
                        }
                    </MuiRadioGroup>
                )}
            />

            {showError && (
                <div id={`${name}-error`} className={`col-12 ${classes.errorMessage}`} role="status" aria-live="polite">
                    <ErrorMessage name={name} errors={errors} />
                </div>
            )}
        </div>
    );
};

RadioGroup.defaultProps = {
    errors: {},
    showError: true,
    required: false,
    rules: {},
};

RadioGroup.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    errors: PropTypes.object,
    showError: PropTypes.bool,
    required: PropTypes.bool,
    control: PropTypes.object.isRequired,
    rules: PropTypes.object,
    children: PropTypes.node.isRequired,
};

export default withStyles(InputStyles)(RadioGroup);
