import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { withStyles } from '@material-ui/core/styles';

import TablePaginationActions from '../../../components/table-pagination-actions';

import Pill from '../components/pill';

import CombineStyles from '../../../utils/combine-styles';
import ButtonStyles from '../../../styles/buttons';
import Styles from '../styles';
import { USER_ROLES } from '../../../utils/constants';
import UserActionMenu from '../components/user-action-menu';
import { clearUserSuccessMessage, fetchInternalUsers } from '../../../actions/user';
import {
    getInternalUsers,
    getInternalUsersTotal,
    getUserId,
    getUserSuccessMessage,
} from '../../../selectors/user';
import Tooltip from '../../../components/tooltip';
import getFormattedDate from '../../../utils/get-formatted-date';
import Colors from '../../../styles/colors';
import NotificationSnackbar from '../../../components/notification-snackbar';

const STATUS_MAP = {
    INVITED: { type: 'italic', text: 'Invited' },
    ACTIVE: { type: 'primary', text: 'Active' },
    INACTIVE: { type: 'secondary', text: 'Inactive' },
};

const ManageUsers = ({ classes }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('status');
    const [sortOrder, setSortOrder] = useState('asc');
    const [selectedUser, setSelectedUser] = useState(null);
    const [tooltipAnchor, setTooltipAnchor] = useState(null);
    const userSuccessMessage = useSelector(getUserSuccessMessage);

    const handleUserHover = user => (event) => {
        setSelectedUser(user);
        setTooltipAnchor(event.currentTarget);
    };

    const handleCloseTooltip = () => {
        setSelectedUser(null);
        setTooltipAnchor(null);
    };

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const payload = {
            pageNumber: page,
            sortBy,
            sortOrder,
        };

        if (rowsPerPage > 0) {
            payload.pageSize = rowsPerPage;
        }

        dispatch(fetchInternalUsers(payload));
    }, [page, sortBy, sortOrder, rowsPerPage, dispatch]);

    const currentUserId = useSelector(getUserId);
    const users = useSelector(getInternalUsers);
    const totalUsers = useSelector(getInternalUsersTotal);

    // unclear why this was created, but ui code has been commented out
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalUsers - page * rowsPerPage);

    const handleChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, [setPage]);

    const handleChangeRowsPerPage = useCallback((event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, [setRowsPerPage, setPage]);

    const handleInviteNewUser = useCallback(() => {
        history.push('/dashboard/user');
    }, [history]);

    const handleSort = sortProperty => () => {
        setSortBy(sortProperty);
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    };

    return (
        <div className={classes.dashboardContainer}>
            <div className="row pb-4">
                <div className="col">
                    <div className="d-flex">
                        <Typography id="users-title" variant="h1" component="h2">Manage Internal Users</Typography>
                        <Typography id="users-count" variant="body1" className="pl-3 pt-1">{totalUsers} users</Typography>
                    </div>
                </div>
                <div className="col-auto pb-4">
                    <Button
                        className={classes.primaryButton}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.primaryButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        style={{ minWidth: 'max-content' }}
                        onClick={handleInviteNewUser}
                    >
                        Invite New Internal User
                    </Button>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <Paper className={classes.tableWrapper}>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-labelledby="users-title users-count">
                                <TableHead className={classes.tableHeader}>
                                    <TableRow>
                                        <TableCell
                                            sortDirection={sortBy === 'fullName' ? sortOrder : false}
                                            className={classes.tableCell}
                                        >
                                            <TableSortLabel
                                                active={sortBy === 'fullName'}
                                                direction={sortBy === 'fullName' ? sortOrder : 'asc'}
                                                onClick={handleSort('fullName')}
                                            >
                                                Name
                                                {sortBy === 'fullName' ? (
                                                    <span className="screen-reader-only">
                                                        {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            sortDirection={sortBy === 'role' ? sortOrder : false}
                                            className={classes.tableCell}
                                        >
                                            <TableSortLabel
                                                active={sortBy === 'role'}
                                                direction={sortBy === 'role' ? sortOrder : 'asc'}
                                                onClick={handleSort('role')}
                                            >
                                                Role
                                                {sortBy === 'role' ? (
                                                    <span className="screen-reader-only">
                                                        {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            sortDirection={sortBy === 'status' ? sortOrder : false}
                                            className={classes.tableCell}
                                        >
                                            <TableSortLabel
                                                active={sortBy === 'status'}
                                                direction={sortBy === 'status' ? sortOrder : 'asc'}
                                                onClick={handleSort('status')}
                                            >
                                                Status
                                                {sortBy === 'status' ? (
                                                    <span className="screen-reader-only">
                                                        {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>

                                        <TableCell
                                            sortDirection={sortBy === 'createdAt' ? sortOrder : false}
                                            className={classes.tableCell}
                                        >
                                            <TableSortLabel
                                                active={sortBy === 'createdAt'}
                                                direction={sortBy === 'createdAt' ? sortOrder : 'asc'}
                                                onClick={handleSort('createdAt')}
                                            >
                                                User Created On
                                                {sortBy === 'createdAt' ? (
                                                    <span className="screen-reader-only">
                                                        {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>

                                    {users.map(user => (
                                        <TableRow key={user.id}>
                                            <TableCell className={classes.tableCell}>
                                                <Typography variant="body1" className={classes.userName}>
                                                    {user.fullName}
                                                </Typography>
                                                <Typography variant="body1" className={classes.userEmail}>
                                                    {user.email}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Typography variant="body1" style={{ paddingBottom: 5 }} onMouseEnter={handleUserHover(user)} onMouseLeave={handleCloseTooltip}>
                                                    {USER_ROLES[user.role].label} <InfoOutlinedIcon fontSize="small" style={{ fontSize: 13, color: Colors.darkElectricBlue }} />
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Pill {...(STATUS_MAP[user.status])} />
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Typography variant="body1" style={{ paddingBottom: 5 }} onMouseEnter={handleUserHover(user)} onMouseLeave={handleCloseTooltip}>
                                                    {getFormattedDate(user.createdAt)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {user.id !== currentUserId && (
                                                    <div className="d-flex justify-content-end pr-2">
                                                        <UserActionMenu userId={user.id} status={user.status} />
                                                    </div>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                    {/* it's unclear why this was added but it creates odd ui behavior
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell className={classes.emptyTableCell} colSpan={6} />
                                        </TableRow>
                                    )}
                                     */}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        { totalUsers > 10 && (
                            <TablePagination
                                className={classes.tableFooter}
                                component="div"
                                count={totalUsers}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                classes={{
                                    root: classes.paginationContainer,
                                    toolbar: classes.paginationToolbar,
                                    spacer: classes.paginationSpacer,
                                }}
                            />
                        )}
                    </Paper>
                </div>
            </div>
            <Tooltip anchorEl={tooltipAnchor}>
                <ul>
                    {USER_ROLES[selectedUser?.role]?.permissions?.map(permission => (
                        <li key={permission}>{permission}</li>
                    ))}
                </ul>
            </Tooltip>
            <NotificationSnackbar isOpen={!!userSuccessMessage} text={userSuccessMessage} handleClose={() => dispatch(clearUserSuccessMessage())} />
        </div>
    );
};

ManageUsers.propTypes = {
    classes: PropTypes.object.isRequired,
    role: PropTypes.oneOf(Object.values(USER_ROLES).map(role => role.value)).isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(combinedStyles)(ManageUsers);
