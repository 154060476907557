const questions = {
    narrative: {
        id: '600',
        column: 'Narrative',
        label: 'Narrative',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    completionTimeHours: {
        id: '602',
        column: 'Hours',
        label: 'Hours',
        validation: {
            yup: yup => yup.number().typeError('Hours is required').required('Hours is required')
                .min(0, 'Hours must be greater than 0'),
        },
    },
    completionTimeMinutes: {
        id: '603',
        column: 'Minutes',
        label: 'Minutes',
        validation: {
            yup: yup => yup.number().typeError('Minutes is required').required('Minutes is required')
                .max(59, 'Minutes must be less than 60')
                .min(0, 'Minutes must be greater than 0'),
        },
    },
    signature: {
        id: '601',
        column: 'Signature',
        validation: {
            type: 'array',
            required: true,
            conditional: false,
        },
        options: [
            { id: '0', name: 'By selecting this checkbox as my signature, I verify that all information in this form is truthful to the best of my knowledge' },
        ],
    },
};

export default questions;
