import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MultiSelect from '../../../../components/multi-select';
import { REPORT_OUTCOME, REPORT_STATUS } from '../../../../reducers/reports';

const STATUSES = [
    { label: 'Draft', value: REPORT_STATUS.DRAFT },
    { label: 'Re-opened', value: REPORT_STATUS.REOPENED },
    { label: 'Inconclusive', value: REPORT_OUTCOME.INCONCLUSIVE },
    { label: 'Negative', value: REPORT_OUTCOME.NEGATIVE },
    { label: 'Submitted', value: REPORT_OUTCOME.PENDING },
    { label: 'Positive', value: REPORT_OUTCOME.POSITIVE },
];

const ReportFilter = ({ onChange, testerFilterOptions, sitesFilterOptions }) => {
    const [statusFilterValues, setStatusFilterValues] = useState([]);
    const [siteFilterValues, setSiteFilterValues] = useState([]);
    const [testerFilterValues, setTesterFilterValues] = useState([]);

    useEffect(() => {
        onChange({
            sites: siteFilterValues,
            testers: testerFilterValues,
            statuses: statusFilterValues,
        });
    }, [onChange, statusFilterValues, siteFilterValues, testerFilterValues]);

    return (
        <div className="d-flex">
            <div className="pr-3">
                <MultiSelect
                    label="Filter reports by status and outcome values"
                    defaultValue="All Statuses"
                    options={STATUSES}
                    onChange={setStatusFilterValues}
                />
            </div>
            <div className="pr-3">
                <MultiSelect
                    label="Filter reports by test sites"
                    defaultValue="All Sites"
                    options={sitesFilterOptions}
                    onChange={setSiteFilterValues}
                />
            </div>
            <div className="pr-3">
                <MultiSelect
                    label="Filter reports by testers"
                    defaultValue="All Testers"
                    options={testerFilterOptions}
                    onChange={setTesterFilterValues}
                />
            </div>
        </div>
    );
};

ReportFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    testerFilterOptions: PropTypes.array.isRequired,
    sitesFilterOptions: PropTypes.array.isRequired,
};

export default ReportFilter;
