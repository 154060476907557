import { createMuiTheme } from '@material-ui/core/styles';

import GeneralTheme from './material-theme';
import Colors from './colors';

const theme = createMuiTheme({
    ...GeneralTheme,
    palette: {
        ...GeneralTheme.palette,
        background: {
            default: Colors.persianBlue,
        },
    },
});

export default theme;
