import Colors from '../../../styles/colors';

const styles = () => ({
    container: {
        paddingLeft: 30,
        paddingBottom: 20,
        borderBottom: `1px solid ${Colors.lavenderGray}`,
    },
    noBorder: {
        border: 0,
    },
});

export default styles;
