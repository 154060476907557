import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

import ConditionalQuestions from '../conditional-questions';

import InputStyles from '../../../styles/inputs';

const Checkbox = ({
    classes,
    value,
    name,
    label,
    inputRef,
    inputProps,
    onChange,
    isChecked,
    showHR,
    conditional,
}) => (
    <React.Fragment>
        { showHR && <hr className={classes.greyHr} />}
        <div className="col">
            <FormControlLabel
                label={label}
                classes={{
                    root: classes.checkboxRoot,
                    label: classes.checkboxLabel,
                }}
                value={value}
                inputRef={inputRef}
                onChange={onChange}
                checked={isChecked}
                control={(
                    <MuiCheckbox
                        color="primary"
                        classes={{ root: classes.checkbox }}
                        checked={isChecked}
                        name={name}
                        inputProps={inputProps}
                        disabled={inputProps && inputProps.disabled}
                    />
                )}
            />
        </div>

        { conditional && (
            <ConditionalQuestions hideBorder condition={isChecked}>
                {conditional}
            </ConditionalQuestions>
        )}
    </React.Fragment>
);

Checkbox.defaultProps = {
    label: null,
    inputRef: null,
    inputProps: null,
    isChecked: false,
    showHR: false,
    conditional: null,
};

Checkbox.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    inputRef: PropTypes.func,
    inputProps: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool,
    showHR: PropTypes.bool,
    conditional: PropTypes.node,
};

export default withStyles(InputStyles)(Checkbox);
