import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { yupResolver } from '@hookform/resolvers';

import { uploadOutcome } from '../../../../actions/reports';
import FileUploader from '../../../../components/file-uploader';
import Select from '../../../../components/form/select';
import validationSchema from '../../../../validation/validation-schema-outcome';

import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import DialogStyles from '../../../../styles/dialog';

import Styles from './styles';

const UploadOutcomeDialog = ({
    classes,
    isDialogOpen,
    handleCloseDialog,
    reportDetails,
}) => {
    const dispatch = useDispatch();

    const {
        id,
        testSite,
        tester,
        testDate,
        previousOutcome,
        previousOutcomeFile,
    } = reportDetails;

    const {
        control,
        register,
        errors,
        handleSubmit,
        reset,
        setValue,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            outcome: null,
            outcomeFile: null,
            previousOutcomeFile: null,
        },
    });

    useEffect(() => {
        reset({
            outcome: previousOutcome || null,
            outcomeFile: null,
            previousOutcomeFile: previousOutcomeFile || null,
        });
    }, [previousOutcome, previousOutcomeFile, reset]);

    const handleFileUploadClear = useCallback(() => {
        if (previousOutcomeFile) {
            setValue('previousOutcomeFile', '');
        }
    }, [previousOutcomeFile, setValue]);

    const handleSave = useCallback((values) => {
        const onSuccess = () => handleCloseDialog();
        const onError = (error) => {
            // TODO: for now we don't have any requirements to show an error if one occurs, so just log it and close the dialog
            // eslint-disable-next-line no-console
            console.error(error);
            handleCloseDialog();
        };

        // if an outcome file has already been uploaded and was not replaced
        if (previousOutcomeFile && (!values.outcomeFile || values.outcomeFile.length === 0)) {
            // just update the outcome value don't upload a new file
            dispatch(uploadOutcome(id, { outcome: values.outcome }, onSuccess, onError));
        } else {
            dispatch(uploadOutcome(id, values, onSuccess, onError));
        }
    }, [id, dispatch, handleCloseDialog, previousOutcomeFile]);

    // There is a short period of time between when a Dialog is opened and when it gets passed reportDetails
    // so we need to handle that gap and return null until reportDetails is passed
    if (!id) {
        return null;
    }

    return (
        <Dialog
            classes={{
                paper: classes.dialogContainer,
            }}
            open={isDialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="upload-dialog-title"
            fullWidth
        >
            <form onSubmit={handleSubmit(handleSave)} noValidate>
                <DialogTitle id="upload-dialog-title" className={classes.dialogTitle} disableTypography>
                    <div className="col">
                        <div className="row justify-content-end">
                            <IconButton aria-label="cancel adding the outcome" onClick={handleCloseDialog}>
                                <CloseIcon
                                    classes={{
                                        root: classes.dialogCloseButton,
                                    }}
                                />
                            </IconButton>
                        </div>
                        <div className="row pb-4">
                            <div className="col text-center">
                                <Typography variant="h1" component="h2">{ previousOutcomeFile ? 'Replace' : 'Add' } the outcome</Typography>
                            </div>
                        </div>
                    </div>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <div className={`row ${classes.detailsWrapper}`}>
                        <div className="col pl-5">
                            <Typography variant="body1" className={classes.detailTitle}>Site</Typography>
                            <Typography variant="body1" className={classes.detailContent}>{testSite.siteName}</Typography>
                        </div>
                        <div className="col">
                            <Typography variant="body1" className={classes.detailTitle}>Tester</Typography>
                            <Typography variant="body1" className={classes.detailContent}>{tester}</Typography>
                        </div>
                        <div className="col">
                            <Typography variant="body1" className={classes.detailTitle}>Date of Visit/Phone Test</Typography>
                            <Typography variant="body1" className={classes.detailContent}>{testDate}</Typography>
                        </div>
                    </div>

                    <div className="row pt-4 pr-4 pl-4">
                        <ol className={classes.list}>
                            <li>
                                <div className="col pb-4">
                                    <Typography id="outcomeFile-label" variant="body1" className={classes.listItem}>
                                        Attach the outcome document (DOC or PDF)
                                    </Typography>
                                    <div className="pt-1">
                                        <input type="hidden" ref={register()} name="previousOutcomeFile" />
                                        <FileUploader
                                            name="outcomeFile"
                                            labelledBy="outcomeFile-label"
                                            register={register}
                                            errors={errors}
                                            required
                                            allowedFileTypes={['.pdf', '.doc', '.docx']}
                                            previousFilename={previousOutcomeFile || null}
                                            onClear={handleFileUploadClear}
                                        />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="col">
                                    <Typography variant="body1" className={classes.listItem}>Select the outcome</Typography>
                                    <div className="pt-1">
                                        <Select
                                            name="outcome"
                                            label="outcome"
                                            errors={errors}
                                            required
                                            control={control}
                                            displayEmpty
                                            hiddenLabel
                                        >
                                            <MenuItem disabled value="">Select -</MenuItem>
                                            <MenuItem value="INCONCLUSIVE">
                                                Inconclusive
                                            </MenuItem>
                                            <MenuItem value="POSITIVE">
                                                Positive
                                            </MenuItem>
                                            <MenuItem value="NEGATIVE">
                                                Negative
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </div>
                </DialogContent>

                <DialogActions className={classes.dialogActions}>
                    <Button
                        className={classes.outlineButton}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.outlineButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        onClick={handleCloseDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={`${classes.primaryButton}`}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.primaryButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        type="submit"
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

UploadOutcomeDialog.defaultProps = {
    reportDetails: {},
};

UploadOutcomeDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    reportDetails: PropTypes.object,
};

const combinedStyles = CombineStyles(ButtonStyles, DialogStyles, Styles);
export default withStyles(combinedStyles)(UploadOutcomeDialog);
