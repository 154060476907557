import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import combineStyles from '../../../../utils/combine-styles';
import buttonStyles from '../../../../styles/buttons';
import useSnackbar from '../../../../utils/use-snackbar';
import { deleteSite } from '../../../../actions/sites';
import NotificationSnackbar from '../../../../components/notification-snackbar';

export const DeleteSiteModal = ({
    site,
    open,
    onClose,
    classes,
}) => {
    const {
        siteName,
        address,
        city,
        state,
        zip,
        id,
    } = site;
    const dispatch = useDispatch();
    const { handleOpenSnackbar, snackbarProps } = useSnackbar();

    const handleDelete = () => {
        dispatch(deleteSite(
            id,
            onClose,
            () => {
                handleOpenSnackbar({ text: 'An unknown error occurred. Please try again later.', type: 'error' });
            },
        ));
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="Do you want to delete this site?"
            aria-describedby={`Confirmation modal for deleting ${siteName}.`}
        >
            <Paper className={classes.modalContent}>
                <Box className={classes.modalHeader}>
                    <Button
                        aria-label="Close Modal"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </Button>
                </Box>
                <RoomOutlinedIcon style={{ fontSize: 70 }} />
                <Typography variant="h2" className={classes.modalTitle}>
                    Are you sure you want to delete this site?
                </Typography>
                <Box className={classes.siteInfo}>
                    <Typography variant="body1" component="div">
                        <strong>{siteName}</strong>
                    </Typography>
                    <Typography variant="body1" component="div">{address}</Typography>
                    <Typography variant="body1" component="div">{city} {state} {zip}</Typography>
                </Box>
                <Box className={classes.modalFooter}>
                    <Button
                        onClick={onClose}
                        className={classes.outlineButton}
                    >
                        Cancel, keep this site
                    </Button>
                    &nbsp;
                    <Button
                        onClick={handleDelete}
                        className={classes.deleteButton}
                    >
                        Yes, delete the site
                    </Button>
                </Box>
                <NotificationSnackbar {...snackbarProps} />
            </Paper>
        </Modal>
    );
};

DeleteSiteModal.propTypes = {
    site: PropTypes.shape({
        siteName: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        id: PropTypes.number,
    }).isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

DeleteSiteModal.defaultProps = {
    open: false,
};

const combinedStyles = combineStyles(styles, buttonStyles);

export default withStyles(combinedStyles)(DeleteSiteModal);
