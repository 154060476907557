import KeyMirror from 'keymirror';

import UserTypes from '../action-types/user';
import AuthTypes from '../action-types/auth';

export const USER_STATUS = KeyMirror({
    INVITED: true,
    ACTIVE: true,
    INACTIVE: true,
});

const internals = {
    initial: () => ({
        data: {},
        internalUsers: {
            list: [],
            total: 0,
        },
        successMsg: null,
    }),
};

const UserReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case UserTypes.SETUP_USER:
        return {
            ...state,
            data: {
                ...payload,
            },
        };
    case UserTypes.FETCH_USERS_SUCCESS:
        return {
            ...state,
            internalUsers: {
                list: payload.results,
                total: payload.total,
            },
        };
    case UserTypes.UPDATE_USER_SUCCESS:
        // find and update the internal user
        return {
            ...state,
            internalUsers: {
                ...state.internalUsers,
                list: state.internalUsers.list.map((user) => {
                    if (user.id === payload.id) {
                        return {
                            ...user,
                            ...payload,
                        };
                    }
                    return user;
                }),
            },
        };
    case UserTypes.INVITE_USER_SUCCESS: {
        return {
            ...state,
            successMsg: 'Invitation sent successfully',
            internalUsers: {
                ...state.internalUsers,
                list: state.internalUsers.list.map((user) => {
                    if (user.id === payload) {
                        return {
                            ...user,
                            status: USER_STATUS.INVITED,
                            lastInvitedOn: new Date(),
                        };
                    }
                    return user;
                }),
            },
        };
    }
    case UserTypes.CLEAR_USER_SUCCESS_MESSAGE: {
        return {
            ...state,
            successMsg: null,
        };
    }
    case AuthTypes.LOGOUT:
        return internals.initial();

    default:
        // do nothing
    }
    return state;
};

export default UserReducer;
