import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/GetApp';

import CombineStyles from '../../../utils/combine-styles';
import ButtonStyles from '../../../styles/buttons';
import { getReportById } from '../../../selectors/reports';
import getFormattedDate, { getFormattedTimeStamp } from '../../../utils/get-formatted-date';
import WebClient from '../../../utils/web-client';
import { REPORT_HISTORY_ACTIONS, USER_ROLES } from '../../../utils/constants';
import OutcomePill from '../components/outcome-pill';
import { REPORT_STATUS } from '../../../reducers/reports';
import Styles from '../styles';
import transformReportHistoryData from '../../../utils/transform-report-history-data';
import { downloadReport } from '../../../actions/reports';

const ReportHistory = ({ classes }) => {
    const { reportId } = useParams();
    const dispatch = useDispatch();
    const getReport = useSelector(getReportById);
    const report = useMemo(() => getReport(reportId) || {}, [getReport, reportId]);
    const [reportHistory, setReportHistory] = useState(null);

    useEffect(() => {
        const fetchReportHistory = async () => {
            const { data } = await WebClient.get(`/reports/${reportId}/history`);
            setReportHistory(transformReportHistoryData(reportId, data));
        };
        fetchReportHistory();
    }, [reportId]);

    const renderReportAction = (action, value) => {
        if (action === REPORT_HISTORY_ACTIONS.reportSubmitted.value) {
            return <OutcomePill outcome={value || REPORT_STATUS.SUBMITTED} />;
        }

        if (action === REPORT_HISTORY_ACTIONS.reportReopened.value) {
            return <OutcomePill outcome={REPORT_STATUS.REOPENED} />;
        }

        if (action === REPORT_HISTORY_ACTIONS.reportAssignedOutcome.value) {
            return <OutcomePill outcome={value} />;
        }

        return action;
    };

    const handleDownloadReport = () => {
        const onSuccess = (url) => {
            window.location = url;
        };

        dispatch(downloadReport(reportId, onSuccess));
    };

    if (!reportHistory) {
        return <CircularProgress />;
    }

    return (
        <div className="pt-5 pl-5">
            <div className="row">
                <div className="col">
                    <div className={classes.backLink}>
                        <Link to="/dashboard/reports">Back to Reports</Link>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <Typography variant="h1">Report History</Typography>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className={classes.reportDetailsContainer}>
                        <div className="row">
                            <div className="col">
                                <Typography variant="body1" className={classes.reportDetailsTitle}>
                                    Site
                                </Typography>
                                <Typography variant="body1">
                                    {report.testSite.siteName}
                                </Typography>
                            </div>
                            <div className="col">
                                <Typography variant="body1" className={classes.reportDetailsTitle}>
                                    Tester
                                </Typography>
                                <Typography variant="body1">
                                    {report.tester.fullName}
                                </Typography>
                            </div>
                            <div className="col">
                                <Typography variant="body1" className={classes.reportDetailsTitle}>
                                    Test Date
                                </Typography>
                                <Typography variant="body1">
                                    {getFormattedDate(report.testDate)}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <Button
                        className={classes.secondaryButton}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.primaryButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        type="button"
                        onClick={handleDownloadReport}
                    >
                        <DownloadIcon className="mr-2" /> Download Report
                    </Button>
                </div>
            </div>


            <div className="row mt-4">
                <div className="col">
                    <Paper className={classes.tableWrapper}>
                        <TableContainer>
                            <Table stickyHeader aria-labelledby="report-title report-count">
                                <TableHead className={classes.tableHeader}>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell className={classes.tableCell}>Action</TableCell>
                                        <TableCell className={classes.tableCell}>Date</TableCell>
                                        <TableCell className={classes.tableCell}>User</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>
                                    <TableRow>
                                        <TableCell className={classes.timelineCell}>
                                            <div className={`${classes.circle} first`}>&nbsp;</div>
                                        </TableCell>
                                        <TableCell className={`${classes.tableCell} ${classes.noBorder}`}>
                                            Report Created
                                        </TableCell>
                                        <TableCell className={`${classes.tableCell} ${classes.noBorder}`}>
                                            {getFormattedTimeStamp(report.testDate)}
                                        </TableCell>
                                        <TableCell className={`${classes.tableCell} ${classes.noBorder}`}>
                                            <strong>{USER_ROLES.tester.label}</strong> {report.tester.fullName}
                                        </TableCell>
                                    </TableRow>
                                    {reportHistory.map(({
                                        action,
                                        date,
                                        user,
                                        value,
                                    }, index) => (
                                        <TableRow key={reportId}>
                                            <TableCell className={classes.timelineCell}>
                                                <div className={`${classes.circle} ${index === reportHistory.length - 1 && 'last'}`}>&nbsp;</div>
                                            </TableCell>
                                            <TableCell className={`${classes.tableCell} ${classes.noBorder}`}>
                                                {renderReportAction(action, value)}
                                            </TableCell>
                                            <TableCell className={`${classes.tableCell} ${classes.noBorder}`}>
                                                {getFormattedTimeStamp(date)}
                                            </TableCell>
                                            <TableCell className={`${classes.tableCell} ${classes.noBorder}`}>
                                                <strong>{USER_ROLES[user.role].label}</strong> {user.fullName}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            </div>
        </div>
    );
};

ReportHistory.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(combinedStyles)(ReportHistory);
