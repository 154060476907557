import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withStyles } from '@material-ui/core/styles';

import DisplayOptionsPopover from './display-options-popover';

import Styles from './styles';

const ReportHeader = ({ classes, backButtonLabel, onBackButtonClick }) => (
    <header className={`container-fluid ${classes.wrapper}`}>
        <div className="row justify-space-between align-items-center" style={{ height: '100%' }}>
            <div className="col">
                <Button
                    className={classes.buttonText}
                    onClick={onBackButtonClick}
                >
                    <ArrowBackIcon />{backButtonLabel}
                </Button>
            </div>
            <div className="col text-right">
                <DisplayOptionsPopover />
            </div>
        </div>
    </header>
);

ReportHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    backButtonLabel: PropTypes.string.isRequired,
    onBackButtonClick: PropTypes.func.isRequired,
};

export default withStyles(Styles)(ReportHeader);
