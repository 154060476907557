import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import TextInput from '../../components/form/textinput';
import TextArea from '../../components/form/textarea';
import RadioGroup from '../../components/form/radiogroup';
import RadioButton from '../../components/form/radiobutton';
import RadioGroupTable from '../../components/form/radiogroup-table';
import CheckboxGroup from '../../components/form/checkboxgroup';
import ConditionalQuestions from '../../components/form/conditional-questions';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import InputStyles from '../../styles/inputs';
import Styles from './styles';
import QuestionWrapper from './components/section/question-wrapper';

const ReportSection3 = ({
    classes,
    questions,
    formContext,
}) => {
    const {
        houseType,
        numberOfBedrooms,
        houseAdditionalRequirements,
        unitsAvailable,
        unitsAvailableDate,
        otherUnitsAvailableOfDifferentType,
        otherUnitsAvailableOfDifferentTypeCount,
        otherUnitsAvailableOfDifferentTypeDateAvailable,
        otherUnitsAvailableOfDifferentTypeDetails,
        waitingList,
        waitingListConversation,
        waitingListInstructions,
        waitingListInstructionsConversation,
        offerOrAskForViewing,
        numberUnitsShown,
        visitIntroduceThemself,
        visitAskYourName,
        visitShakeYourHand,
        visitInviteSitDown,
        visitOfferRefreshments,
        visitExhibitCourtesy,
        visitExhibitDiscourtesy,
        visitOtherActs,
        topicsDiscussedHousing,
        topicsDiscussedHousingAgentSay,
        topicsDiscussedHousingYouSay,
        topicsDiscussedEmployment,
        topicsDiscussedEmploymentAgentSay,
        topicsDiscussedEmploymentYouSay,
        topicsDiscussedIncome,
        topicsDiscussedIncomeAgentSay,
        topicsDiscussedIncomeYouSay,
        topicsDiscussedEmploymentLength,
        topicsDiscussedEmploymentLengthAgentSay,
        topicsDiscussedEmploymentLengthYouSay,
        topicsDiscussedAddress,
        topicsDiscussedAddressAgentSay,
        topicsDiscussedAddressYouSay,
        topicsDiscussedPhone,
        topicsDiscussedPhoneAgentSay,
        topicsDiscussedPhoneYouSay,
        topicsDiscussedAge,
        topicsDiscussedAgeAgentSay,
        topicsDiscussedAgeYouSay,
        topicsDiscussedMaritalStatus,
        topicsDiscussedMaritalStatusAgentSay,
        topicsDiscussedMaritalStatusYouSay,
        topicsDiscussedNumberChildren,
        topicsDiscussedNumberChildrenAgentSay,
        topicsDiscussedNumberChildrenYouSay,
        topicsDiscussedChildrenAge,
        topicsDiscussedChildrenAgeAgentSay,
        topicsDiscussedChildrenAgeYouSay,
        topicsDiscussedIncomeSource,
        topicsDiscussedIncomeSourceAgentSay,
        topicsDiscussedIncomeSourceYouSay,
        topicsDiscussedCredit,
        topicsDiscussedCreditAgentSay,
        topicsDiscussedCreditYouSay,
        topicsDiscussedLandlord,
        topicsDiscussedLandlordAgentSay,
        topicsDiscussedLandlordYouSay,
        topicsDiscussedMovingReason,
        topicsDiscussedMovingReasonAgentSay,
        topicsDiscussedMovingReasonYouSay,
        topicsDiscussedWhenMoving,
        topicsDiscussedWhenMovingAgentSay,
        topicsDiscussedWhenMovingYouSay,
        topicsDiscussedCriminalCheck,
        topicsDiscussedCriminalCheckAgentSay,
        topicsDiscussedCriminalCheckYouSay,
        agentsCommentOnReligion,
        agentsCommentOnReligionDiscussed,
        agentsCommentOnDisability,
        agentsCommentOnDisabilityDiscussed,
        agentsCommentOnRaceEthnicity,
        agentsCommentOnRaceEthnicityDiscussed,
        agentsCommentOnGender,
        agentsCommentOnGenderDiscussed,
        agentsCommentOnNumberOccupants,
        agentsCommentOnNumberOccupantsDiscussed,
        agentsCommentOnMaritalStatus,
        agentsCommentOnMaritalStatusDiscussed,
        agentsCommentOnAge,
        agentsCommentOnAgeDiscussed,
        agentsCommentOnOther,
        agentsCommentOnOtherDiscussed,
    } = questions;

    const {
        register,
        watch,
        errors,
        control,
    } = formContext;

    // watch all of the conditional question triggers
    const values = watch([
        otherUnitsAvailableOfDifferentType.id,
        waitingList.id,
        waitingListInstructions.id,
        topicsDiscussedHousing.id,
        topicsDiscussedEmployment.id,
        topicsDiscussedIncome.id,
        topicsDiscussedEmploymentLength.id,
        topicsDiscussedAddress.id,
        topicsDiscussedPhone.id,
        topicsDiscussedAge.id,
        topicsDiscussedMaritalStatus.id,
        topicsDiscussedNumberChildren.id,
        topicsDiscussedChildrenAge.id,
        topicsDiscussedIncomeSource.id,
        topicsDiscussedCredit.id,
        topicsDiscussedLandlord.id,
        topicsDiscussedMovingReason.id,
        topicsDiscussedWhenMoving.id,
        topicsDiscussedCriminalCheck.id,
        agentsCommentOnReligion.id,
        agentsCommentOnDisability.id,
        agentsCommentOnRaceEthnicity.id,
        agentsCommentOnGender.id,
        agentsCommentOnNumberOccupants.id,
        agentsCommentOnMaritalStatus.id,
        agentsCommentOnAge.id,
        agentsCommentOnOther.id,
    ]);

    return (
        <React.Fragment>
            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={houseType.id}

                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={houseType.id}
                                label={houseType.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={numberOfBedrooms.id}

                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={numberOfBedrooms.id}
                                label={numberOfBedrooms.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={houseAdditionalRequirements.id}

                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={houseAdditionalRequirements.id}
                                label={houseAdditionalRequirements.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={unitsAvailable.id}

                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={unitsAvailable.id}
                                label={unitsAvailable.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={unitsAvailableDate.id}

                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={unitsAvailableDate.id}
                                label={unitsAvailableDate.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>
            <QuestionWrapper
                questionId={otherUnitsAvailableOfDifferentType.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pb-3">
                            <div className={`col ${classes.inputWrapper}`}>
                                <RadioGroup
                                    name={otherUnitsAvailableOfDifferentType.id}
                                    label={otherUnitsAvailableOfDifferentType.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    {otherUnitsAvailableOfDifferentType.options.map(option => (
                                        <RadioButton
                                            key={`${otherUnitsAvailableOfDifferentType.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                        <ConditionalQuestions condition={values[otherUnitsAvailableOfDifferentType.id] === 'Yes'}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={otherUnitsAvailableOfDifferentTypeCount.id}
                                            label={otherUnitsAvailableOfDifferentTypeCount.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={otherUnitsAvailableOfDifferentTypeDateAvailable.id}
                                            label={otherUnitsAvailableOfDifferentTypeDateAvailable.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextArea
                                            name={otherUnitsAvailableOfDifferentTypeDetails.id}
                                            label={otherUnitsAvailableOfDifferentTypeDetails.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            rowsMin={3}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <QuestionWrapper
                questionId={waitingList.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pb-3">
                            <div className={`col ${classes.inputWrapper}`}>
                                <RadioGroup
                                    name={waitingList.id}
                                    label={waitingList.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    {waitingList.options.map(option => (
                                        <RadioButton
                                            key={`${waitingList.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                        <ConditionalQuestions condition={values[waitingList.id] === 'Yes'}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={waitingListConversation.id}
                                        label={waitingListConversation.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <QuestionWrapper
                questionId={waitingListInstructions.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pb-3">
                            <div className={`col ${classes.inputWrapper}`}>
                                <RadioGroup
                                    name={waitingListInstructions.id}
                                    label={waitingListInstructions.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    {waitingListInstructions.options.map(option => (
                                        <RadioButton
                                            key={`${waitingListInstructions.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                        <ConditionalQuestions condition={values[waitingListInstructions.id] === 'Yes'}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextInput
                                        name={waitingListInstructionsConversation.id}
                                        label={waitingListInstructionsConversation.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        inputProps={{
                                            autoComplete: 'off',
                                        }}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={offerOrAskForViewing.id}

                        classes={classes}
                        render={({ disabled }) => (
                            <RadioGroup
                                name={offerOrAskForViewing.id}
                                label={offerOrAskForViewing.label}
                                errors={errors}
                                required
                                row
                                control={control}
                            >
                                {offerOrAskForViewing.options.map(option => (
                                    <RadioButton
                                        key={`${offerOrAskForViewing.id}-${option}`}
                                        value={option}
                                        label={option}
                                        buttonStyle
                                        disabled={disabled}
                                    />
                                ))}
                            </RadioGroup>
                        )}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={numberUnitsShown.id}

                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={numberUnitsShown.id}
                                label={numberUnitsShown.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>

            {/* RADIO TABLE */}

            <Typography variant="h3" style={{ fontWeight: 700, paddingBottom: 15, paddingTop: 45 }}>During the visit did the representative do any of the following?</Typography>
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={visitIntroduceThemself.id}
                classes={classes}
                render={({ disabled }) => (
                    <RadioGroupTable
                        name={visitIntroduceThemself.id}
                        label={visitIntroduceThemself.label}
                        errors={errors}
                        required
                        control={control}
                    >
                        {visitIntroduceThemself.options.map(option => (
                            <RadioButton
                                key={`${visitIntroduceThemself.id}-${option}`}
                                value={option}
                                label={option}
                                buttonStyle
                                disabled={disabled}
                            />
                        ))}
                    </RadioGroupTable>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={visitAskYourName.id}
                classes={classes}
                render={({ disabled }) => (
                    <RadioGroupTable
                        name={visitAskYourName.id}
                        label={visitAskYourName.label}
                        errors={errors}
                        required
                        control={control}
                    >
                        {visitAskYourName.options.map(option => (
                            <RadioButton
                                key={`${visitAskYourName.id}-${option}`}
                                value={option}
                                label={option}
                                buttonStyle
                                disabled={disabled}
                            />
                        ))}
                    </RadioGroupTable>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={visitShakeYourHand.id}
                classes={classes}
                render={({ disabled }) => (
                    <RadioGroupTable
                        name={visitShakeYourHand.id}
                        label={visitShakeYourHand.label}
                        errors={errors}
                        required
                        control={control}
                    >
                        {visitShakeYourHand.options.map(option => (
                            <RadioButton
                                key={`${visitShakeYourHand.id}-${option}`}
                                value={option}
                                label={option}
                                buttonStyle
                                disabled={disabled}
                            />
                        ))}
                    </RadioGroupTable>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={visitInviteSitDown.id}
                classes={classes}
                render={({ disabled }) => (
                    <RadioGroupTable
                        name={visitInviteSitDown.id}
                        label={visitInviteSitDown.label}
                        errors={errors}
                        required
                        control={control}
                    >
                        {visitInviteSitDown.options.map(option => (
                            <RadioButton
                                key={`${visitInviteSitDown.id}-${option}`}
                                value={option}
                                label={option}
                                buttonStyle
                                disabled={disabled}
                            />
                        ))}
                    </RadioGroupTable>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={visitOfferRefreshments.id}
                classes={classes}
                render={({ disabled }) => (
                    <RadioGroupTable
                        name={visitOfferRefreshments.id}
                        label={visitOfferRefreshments.label}
                        errors={errors}
                        required
                        control={control}
                    >
                        {visitOfferRefreshments.options.map(option => (
                            <RadioButton
                                key={`${visitOfferRefreshments.id}-${option}`}
                                value={option}
                                label={option}
                                buttonStyle
                                disabled={disabled}
                            />
                        ))}
                    </RadioGroupTable>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={visitExhibitCourtesy.id}
                classes={classes}
                render={({ disabled }) => (
                    <RadioGroupTable
                        name={visitExhibitCourtesy.id}
                        label={visitExhibitCourtesy.label}
                        errors={errors}
                        required
                        control={control}
                    >
                        {visitExhibitCourtesy.options.map(option => (
                            <RadioButton
                                key={`${visitExhibitCourtesy.id}-${option}`}
                                value={option}
                                label={option}
                                buttonStyle
                                disabled={disabled}
                            />
                        ))}
                    </RadioGroupTable>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={visitExhibitDiscourtesy.id}
                classes={classes}
                render={({ disabled }) => (
                    <RadioGroupTable
                        name={visitExhibitDiscourtesy.id}
                        label={visitExhibitDiscourtesy.label}
                        errors={errors}
                        required
                        control={control}
                    >
                        {visitExhibitDiscourtesy.options.map(option => (
                            <RadioButton
                                key={`${visitExhibitDiscourtesy.id}-${option}`}
                                value={option}
                                label={option}
                                buttonStyle
                                disabled={disabled}
                            />
                        ))}
                    </RadioGroupTable>
                )}
            />
            <hr className={classes.greyHr} />

            {/* END RADIO TABLE */}

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={visitOtherActs.id}

                        classes={classes}
                        render={({ disabled }) => (
                            <TextArea
                                name={visitOtherActs.id}
                                label={visitOtherActs.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                rowsMin={3}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>

            {/* TOPICS DISCUSSED */}
            <div className="row">
                <div className="col">
                    <Typography variant="body1" className={classes.inputLabel}>Select any of the topics you discussed with the Agent:</Typography>
                    <Typography variant="body1" className="pt-3">Please describe exactly what the &quot;Agent&quot; said about each topic and what you, the &quot;Tester&quot; said about each topic</Typography>
                    <div className={classes.explainerBox}>
                        <Typography variant="body1" style={{ paddingBottom: 15 }}><i>Example: Housing needs</i></Typography>
                        <Typography variant="body1" style={{ paddingLeft: 45, paddingBottom: 5 }}><b>Agent:</b> asked what I was looking for</Typography>
                        <Typography variant="body1" style={{ paddingLeft: 45 }}><b>Tester:</b> I said a two bedroom</Typography>
                    </div>
                </div>
            </div>

            <QuestionWrapper
                questionId={topicsDiscussedHousing.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <hr className={classes.greyHr} />
                                <CheckboxGroup
                                    name={topicsDiscussedHousing.id}
                                    options={topicsDiscussedHousing.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedHousing.id] && !!values[topicsDiscussedHousing.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedHousingAgentSay.id}
                                            label={topicsDiscussedHousingAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedHousingYouSay.id}
                                            label={topicsDiscussedHousingYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedEmployment.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedEmployment.id}
                                    options={topicsDiscussedEmployment.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedEmployment.id] && !!values[topicsDiscussedEmployment.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedEmploymentAgentSay.id}
                                            label={topicsDiscussedEmploymentAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedEmploymentYouSay.id}
                                            label={topicsDiscussedEmploymentYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedEmploymentYouSay.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedIncome.id}
                                    options={topicsDiscussedIncome.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedIncome.id] && !!values[topicsDiscussedIncome.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedIncomeAgentSay.id}
                                            label={topicsDiscussedIncomeAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedIncomeYouSay.id}
                                            label={topicsDiscussedIncomeYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedIncomeYouSay.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedIncomeSource.id}
                                    options={topicsDiscussedIncomeSource.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedIncomeSource.id] && !!values[topicsDiscussedIncomeSource.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedIncomeSourceAgentSay.id}
                                            label={topicsDiscussedIncomeSourceAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedIncomeSourceYouSay.id}
                                            label={topicsDiscussedIncomeSourceYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedEmploymentLength.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedEmploymentLength.id}
                                    options={topicsDiscussedEmploymentLength.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedEmploymentLength.id] && !!values[topicsDiscussedEmploymentLength.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedEmploymentLengthAgentSay.id}
                                            label={topicsDiscussedEmploymentLengthAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedEmploymentLengthYouSay.id}
                                            label={topicsDiscussedEmploymentLengthYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedAddress.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedAddress.id}
                                    options={topicsDiscussedAddress.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedAddress.id] && !!values[topicsDiscussedAddress.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedAddressAgentSay.id}
                                            label={topicsDiscussedAddressAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedAddressYouSay.id}
                                            label={topicsDiscussedAddressYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedPhone.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedPhone.id}
                                    options={topicsDiscussedPhone.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedPhone.id] && !!values[topicsDiscussedPhone.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedPhoneAgentSay.id}
                                            label={topicsDiscussedPhoneAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedPhoneYouSay.id}
                                            label={topicsDiscussedPhoneYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedAge.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedAge.id}
                                    options={topicsDiscussedAge.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedAge.id] && !!values[topicsDiscussedAge.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedAgeAgentSay.id}
                                            label={topicsDiscussedAgeAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedAgeYouSay.id}
                                            label={topicsDiscussedAgeYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedMaritalStatus.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedMaritalStatus.id}
                                    options={topicsDiscussedMaritalStatus.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedMaritalStatus.id] && !!values[topicsDiscussedMaritalStatus.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedMaritalStatusAgentSay.id}
                                            label={topicsDiscussedMaritalStatusAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedMaritalStatusYouSay.id}
                                            label={topicsDiscussedMaritalStatusYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedNumberChildren.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedNumberChildren.id}
                                    options={topicsDiscussedNumberChildren.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedNumberChildren.id] && !!values[topicsDiscussedNumberChildren.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedNumberChildrenAgentSay.id}
                                            label={topicsDiscussedNumberChildrenAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedNumberChildrenYouSay.id}
                                            label={topicsDiscussedNumberChildrenYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedChildrenAge.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedChildrenAge.id}
                                    options={topicsDiscussedChildrenAge.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedChildrenAge.id] && !!values[topicsDiscussedChildrenAge.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedChildrenAgeAgentSay.id}
                                            label={topicsDiscussedChildrenAgeAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedChildrenAgeYouSay.id}
                                            label={topicsDiscussedChildrenAgeYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedCredit.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedCredit.id}
                                    options={topicsDiscussedCredit.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedCredit.id] && !!values[topicsDiscussedCredit.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedCreditAgentSay.id}
                                            label={topicsDiscussedCreditAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedCreditYouSay.id}
                                            label={topicsDiscussedCreditYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedLandlord.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedLandlord.id}
                                    options={topicsDiscussedLandlord.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedLandlord.id] && !!values[topicsDiscussedLandlord.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedLandlordAgentSay.id}
                                            label={topicsDiscussedLandlordAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedLandlordYouSay.id}
                                            label={topicsDiscussedLandlordYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedMovingReason.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedMovingReason.id}
                                    options={topicsDiscussedMovingReason.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedMovingReason.id] && !!values[topicsDiscussedMovingReason.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedMovingReasonAgentSay.id}
                                            label={topicsDiscussedMovingReasonAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedMovingReasonYouSay.id}
                                            label={topicsDiscussedMovingReasonYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedWhenMoving.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedWhenMoving.id}
                                    options={topicsDiscussedWhenMoving.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedWhenMoving.id] && !!values[topicsDiscussedWhenMoving.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedWhenMovingAgentSay.id}
                                            label={topicsDiscussedWhenMovingAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedWhenMovingYouSay.id}
                                            label={topicsDiscussedWhenMovingYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedCriminalCheck.id}

                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedCriminalCheck.id}
                                    options={topicsDiscussedCriminalCheck.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedCriminalCheck.id] && !!values[topicsDiscussedCriminalCheck.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedCriminalCheckAgentSay.id}
                                            label={topicsDiscussedCriminalCheckAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedCriminalCheckYouSay.id}
                                            label={topicsDiscussedCriminalCheckYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            {/* END TOPICS DISCUSSED */}

            {/* AGENT MADE STATEMENTS */}
            <Typography variant="h2" style={{ fontWeight: 700, paddingBottom: 15, paddingTop: 45 }}>
                Did the agent make any direct or indirect statements about any of the following personal characteristics, including but not limited to about your family?
            </Typography>
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={agentsCommentOnReligion.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <RadioGroupTable
                            name={agentsCommentOnReligion.id}
                            label={agentsCommentOnReligion.label}
                            errors={errors}
                            required
                            control={control}
                        >
                            {agentsCommentOnReligion.options.map(option => (
                                <RadioButton
                                    key={`${agentsCommentOnReligion.id}-${option}`}
                                    value={option}
                                    label={option}
                                    buttonStyle
                                    disabled={disabled}
                                />
                            ))}
                        </RadioGroupTable>
                        <ConditionalQuestions hideBorder condition={values[agentsCommentOnReligion.id] === 'Yes'}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={agentsCommentOnReligionDiscussed.id}
                                        label={agentsCommentOnReligionDiscussed.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={agentsCommentOnDisability.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <RadioGroupTable
                            name={agentsCommentOnDisability.id}
                            label={agentsCommentOnDisability.label}
                            errors={errors}
                            required
                            control={control}
                        >
                            {agentsCommentOnDisability.options.map(option => (
                                <RadioButton
                                    key={`${agentsCommentOnDisability.id}-${option}`}
                                    value={option}
                                    label={option}
                                    buttonStyle
                                    disabled={disabled}
                                />
                            ))}
                        </RadioGroupTable>
                        <ConditionalQuestions hideBorder condition={values[agentsCommentOnDisability.id] === 'Yes'}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={agentsCommentOnDisabilityDiscussed.id}
                                        label={agentsCommentOnDisabilityDiscussed.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={agentsCommentOnRaceEthnicity.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <RadioGroupTable
                            name={agentsCommentOnRaceEthnicity.id}
                            label={agentsCommentOnRaceEthnicity.label}
                            errors={errors}
                            required
                            control={control}
                        >
                            {agentsCommentOnRaceEthnicity.options.map(option => (
                                <RadioButton
                                    key={`${agentsCommentOnRaceEthnicity.id}-${option}`}
                                    value={option}
                                    label={option}
                                    buttonStyle
                                    disabled={disabled}
                                />
                            ))}
                        </RadioGroupTable>
                        <ConditionalQuestions hideBorder condition={values[agentsCommentOnRaceEthnicity.id] === 'Yes'}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={agentsCommentOnRaceEthnicityDiscussed.id}
                                        label={agentsCommentOnRaceEthnicityDiscussed.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={agentsCommentOnGender.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <RadioGroupTable
                            name={agentsCommentOnGender.id}
                            label={agentsCommentOnGender.label}
                            errors={errors}
                            required
                            control={control}
                        >
                            {agentsCommentOnGender.options.map(option => (
                                <RadioButton
                                    key={`${agentsCommentOnGender.id}-${option}`}
                                    value={option}
                                    label={option}
                                    buttonStyle
                                    disabled={disabled}
                                />
                            ))}
                        </RadioGroupTable>
                        <ConditionalQuestions hideBorder condition={values[agentsCommentOnGender.id] === 'Yes'}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={agentsCommentOnGenderDiscussed.id}
                                        label={agentsCommentOnGenderDiscussed.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={agentsCommentOnNumberOccupants.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <RadioGroupTable
                            name={agentsCommentOnNumberOccupants.id}
                            label={agentsCommentOnNumberOccupants.label}
                            errors={errors}
                            required
                            control={control}
                        >
                            {agentsCommentOnNumberOccupants.options.map(option => (
                                <RadioButton
                                    key={`${agentsCommentOnNumberOccupants.id}-${option}`}
                                    value={option}
                                    label={option}
                                    buttonStyle
                                    disabled={disabled}
                                />
                            ))}
                        </RadioGroupTable>
                        <ConditionalQuestions hideBorder condition={values[agentsCommentOnNumberOccupants.id] === 'Yes'}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={agentsCommentOnNumberOccupantsDiscussed.id}
                                        label={agentsCommentOnNumberOccupantsDiscussed.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={agentsCommentOnMaritalStatus.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <RadioGroupTable
                            name={agentsCommentOnMaritalStatus.id}
                            label={agentsCommentOnMaritalStatus.label}
                            errors={errors}
                            required
                            control={control}
                        >
                            {agentsCommentOnMaritalStatus.options.map(option => (
                                <RadioButton
                                    key={`${agentsCommentOnMaritalStatus.id}-${option}`}
                                    value={option}
                                    label={option}
                                    buttonStyle
                                    disabled={disabled}
                                />
                            ))}
                        </RadioGroupTable>
                        <ConditionalQuestions hideBorder condition={values[agentsCommentOnMaritalStatus.id] === 'Yes'}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={agentsCommentOnMaritalStatusDiscussed.id}
                                        label={agentsCommentOnMaritalStatusDiscussed.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={agentsCommentOnAge.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <RadioGroupTable
                            name={agentsCommentOnAge.id}
                            label={agentsCommentOnAge.label}
                            errors={errors}
                            required
                            control={control}
                        >
                            {agentsCommentOnAge.options.map(option => (
                                <RadioButton
                                    key={`${agentsCommentOnAge.id}-${option}`}
                                    value={option}
                                    label={option}
                                    buttonStyle
                                    disabled={disabled}
                                />
                            ))}
                        </RadioGroupTable>
                        <ConditionalQuestions hideBorder condition={values[agentsCommentOnAge.id] === 'Yes'}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={agentsCommentOnAgeDiscussed.id}
                                        label={agentsCommentOnAgeDiscussed.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={agentsCommentOnOther.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <RadioGroupTable
                            name={agentsCommentOnOther.id}
                            label={agentsCommentOnOther.label}
                            errors={errors}
                            required
                            control={control}
                        >
                            {agentsCommentOnOther.options.map(option => (
                                <RadioButton
                                    key={`${agentsCommentOnOther.id}-${option}`}
                                    value={option}
                                    label={option}
                                    buttonStyle
                                    disabled={disabled}
                                />
                            ))}
                        </RadioGroupTable>
                        <ConditionalQuestions hideBorder condition={values[agentsCommentOnOther.id] === 'Yes'}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={agentsCommentOnOtherDiscussed.id}
                                        label={agentsCommentOnOtherDiscussed.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />
        </React.Fragment>
    );
};

ReportSection3.propTypes = {
    classes: PropTypes.object.isRequired,
    questions: PropTypes.object.isRequired,
    formContext: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(combinedStyles)(ReportSection3);
