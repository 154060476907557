import React, {
    useState,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { yupResolver } from '@hookform/resolvers';

import Validation from '../../validation/validation-schema-login';
import ScrollToTop from '../../utils/scroll-to-top';
import TextInput from '../../components/form/textinput';
import PasswordTextInput from '../../components/form/password-textinput';

import { ResetPass } from '../../actions/auth';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import InputStyles from '../../styles/inputs';
import Styles from './styles';

import StatusBanner from '../../components/feedback-banner';

import Logo from '../../images/logo_on_blue_bg.png';
import LSCLogo from '../../images/LSC Logo-White-Horizontal.png';

const ResetPassword = ({ classes, initialSetup }) => {
    const [errorMsg, setErrorMsg] = useState(null);

    const dispatch = useDispatch();
    const history = useHistory();

    const { resetToken } = useParams();
    const resetpassError = useSelector(state => state.auth.resetPass.error);
    const resetpassErrorMsg = useSelector(state => state.auth.resetPass.errorMsg);
    const resetpassCompleted = useSelector(state => state.auth.resetPass.completed);

    const {
        register,
        handleSubmit,
        setValue,
        errors,
    } = useForm({
        resolver: yupResolver(Validation.reset),
    });

    document.title = `${initialSetup ? 'Set' : 'Reset'} Password - Legal Services of Eastern Michigan - Fair Housing Center`;

    useEffect(() => {
        setValue('resetToken', resetToken);
    }, [resetToken, setValue]);

    useEffect(() => {
        let timer = null;
        if (resetpassCompleted && !resetpassError) {
            timer = setTimeout(() => {
                history.push('/login');
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [resetpassCompleted, resetpassError, history]);

    useEffect(() => {
        // Only show the first error message
        if (Object.keys(errors).length) {
            // Relies on Object.keys insertion order property ordering, not ideal
            setErrorMsg(errors[Object.keys(errors)[0]].message);
            ScrollToTop();
        }
    }, [errors]);

    const onSubmit = (data) => {
        setErrorMsg(null);
        dispatch(ResetPass(data));
    };

    return (
        <div className={classes.wrapper}>
            <div role="status" aria-live="polite" style={{ width: '100%', textAlign: 'center' }}>
                {errorMsg && <StatusBanner type="warn" text={errorMsg} />}
                {resetpassError && <StatusBanner type="warn" text={resetpassErrorMsg} />}
                {(resetpassCompleted && !resetpassError) && (<StatusBanner type="success" text={`Success: Your password has successfully been ${initialSetup ? 'Set' : 'Updated'}.`} />)}
            </div>

            <img src={Logo} alt="" className={classes.logo} />
            <img src={LSCLogo} alt="" className={classes.lscLogo} />

            <div className={classes.formWrapper}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.inputWrapper}>
                        <TextInput
                            name="email"
                            label="Email"
                            errors={errors}
                            required
                            inputRef={register()}
                            inputProps={{
                                autoComplete: 'on',
                                autoFocus: true,
                            }}
                            showError={false}
                        />
                    </div>
                    <div className={classes.inputWrapper}>
                        <PasswordTextInput
                            name="password"
                            label="Password"
                            errors={errors}
                            required
                            inputRef={register()}
                            showError={false}
                        />
                    </div>
                    <div className={classes.inputWrapper}>
                        <PasswordTextInput
                            name="passwordConfirm"
                            label="Confirm Password"
                            errors={errors}
                            required
                            inputRef={register()}
                            showError={false}
                        />
                        <div className={classes.inputWrapper}>
                            <Button
                                variant="contained"
                                className={classes.primaryButton}
                                classes={{
                                    label: classes.buttonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.primaryButtonRippleChildPulsate,
                                        ripplePulsate: classes.buttonRipplePulsate,
                                    },
                                }}
                                type="submit"
                                fullWidth
                            >
                                { initialSetup ? 'Set' : 'Reset'} Password
                            </Button>
                        </div>
                    </div>
                    <input name="resetToken" ref={register} type="hidden" />
                </form>
            </div>
        </div>
    );
};

ResetPassword.defaultProps = {
    initialSetup: false,
};

ResetPassword.propTypes = {
    classes: PropTypes.object.isRequired,
    initialSetup: PropTypes.bool,
};

const combinedStyles = CombineStyles(InputStyles, ButtonStyles, Styles);
export default withStyles(combinedStyles)(ResetPassword);
