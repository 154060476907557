import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ActionMenu from '../../../../components/action-menu';
import { activateTester, deactivateTester } from '../../../../actions/testers';
import { USER_ROLES } from '../../../../utils/constants';
import { UserRolesPropType } from '../../../../utils/prop-types';
import { USER_STATUS } from '../../../../reducers/user';
import { inviteUser } from '../../../../actions/user';

const TesterActionMenu = ({
    testerId,
    tester,
    status,
    onDelete,
    role,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleResendInvite = useCallback(() => {
        dispatch(inviteUser(testerId));
    }, [dispatch, testerId]);

    const handleEditTester = useCallback(() => {
        history.push(`/dashboard/tester/${testerId}`);
    }, [history, testerId]);

    const handleActivateTester = useCallback(() => {
        dispatch(activateTester(testerId));
    }, [dispatch, testerId]);

    const handleDeactivateTester = useCallback(() => {
        dispatch(deactivateTester(testerId));
    }, [dispatch, testerId]);

    const handleDelete = useCallback(() => {
        onDelete({ ...tester, id: testerId });
    }, [tester, onDelete, testerId]);

    const actions = [
        { label: 'Edit', onClick: handleEditTester },
    ];

    if (status === USER_STATUS.INVITED) {
        actions.push({ label: 'Resend Invitation', onClick: handleResendInvite });
    }

    if (role === USER_ROLES.admin.value) {
        actions.push({ label: 'Delete', onClick: handleDelete });
    }

    if (status === 'INACTIVE') {
        actions.push({ label: 'Make Active', onClick: handleActivateTester });
    } else {
        actions.push({ label: 'Make Inactive', onClick: handleDeactivateTester });
    }

    const defaultAction = status === USER_STATUS.INVITED ? {
        label: 'Resend Invitation',
        onClick: handleResendInvite,
    } : {
        label: 'Edit',
        onClick: handleEditTester,
    };

    return (
        <ActionMenu
            id={`${testerId}-actions`}
            defaultAction={defaultAction}
            actions={actions}
        />
    );
};

TesterActionMenu.propTypes = {
    testerId: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    tester: PropTypes.object.isRequired,
    role: UserRolesPropType.isRequired,
};

export default TesterActionMenu;
