import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ActionMenu from '../../../../components/action-menu';
import { USER_STATUS } from '../../../../reducers/user';
import { inviteUser, updateInternalUser } from '../../../../actions/user';

const UserActionMenu = ({
    userId,
    status,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleInviteUser = useCallback(() => {
        dispatch(inviteUser(userId));
    }, [dispatch, userId]);

    const handleEditUser = useCallback(() => {
        history.push(`/dashboard/user/${userId}`);
    }, [history, userId]);

    const handleActivateUser = useCallback(() => {
        dispatch(updateInternalUser(userId, { status: USER_STATUS.ACTIVE }));
    }, [dispatch, userId]);

    const handleDeactivateUser = useCallback(() => {
        dispatch(updateInternalUser(userId, { status: USER_STATUS.INACTIVE }));
    }, [dispatch, userId]);

    const actions = [
        { label: 'Edit', onClick: handleEditUser },
    ];

    if (status === USER_STATUS.INVITED) {
        actions.push({ label: 'Resend Invitation', onClick: handleInviteUser });
    }

    if (status === USER_STATUS.INACTIVE) {
        actions.push({ label: 'Make Active', onClick: handleActivateUser });
    } else {
        actions.push({ label: 'Make Inactive', onClick: handleDeactivateUser });
    }

    const defaultAction = status === USER_STATUS.INVITED ? {
        label: 'Resend Invitation',
        onClick: handleInviteUser,
    } : {
        label: 'Edit',
        onClick: handleEditUser,
    };

    return (
        <ActionMenu
            id={`${userId}-actions`}
            defaultAction={defaultAction}
            actions={actions}
        />
    );
};

UserActionMenu.propTypes = {
    userId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
};

export default UserActionMenu;
