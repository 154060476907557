import KeyMirror from 'keymirror';

import SitesTypes from '../action-types/sites';

const REQUEST_STATUS = KeyMirror({
    ACTIVE: true,
    SUCCESS: true,
    ERROR: true,
});

const internals = {
    initial: () => ({
        data: [],
        requestStatus: null,
        errorMsg: null,
        total: 0,
    }),
};

const SitesReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { type, payload } = action;

    switch (type) {
    case SitesTypes.GET_SITES_BEGIN:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ACTIVE,
        };
    case SitesTypes.GET_SITES_SUCCESS:
        return {
            ...state,
            data: payload.sites,
            total: payload.total,
            requestStatus: REQUEST_STATUS.SUCCESS,
        };
    case SitesTypes.GET_SITES_ERROR:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ERROR,
            errorMsg: payload.errorMsg,
        };
    case SitesTypes.DELETE_SITES_SUCCESS:
        return {
            ...state,
            data: state.data.filter(site => site.id !== payload.siteId),
        };
    case SitesTypes.CREATE_SITES_SUCCESS:
        return {
            ...state,
            data: [...state.data, payload.site],
        };
    case SitesTypes.UPDATE_SITES_SUCCESS:
        return {
            ...state,
            data: state.data.map((site) => {
                if (site.id === payload.site.id) {
                    return payload.site;
                }
                return site;
            }),
        };
    default:
        // do nothing
    }
    return state;
};

export default SitesReducer;
