import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CreateEditSiteForm from './form';
import Styles from '../invite-edit-tester/styles';
import { getSiteSelector } from '../../../selectors/sites';

const CreateEditSite = ({ classes }) => {
    const { siteId } = useParams();
    const getSite = useSelector(getSiteSelector);
    const site = getSite(siteId);


    return (
        <div className="pt-5 pl-5">
            <div className="row">
                <div className="col">
                    <div className={classes.backLink}>
                        <Link to="/dashboard/sites">Back to Manage Sites</Link>
                    </div>
                    <div>
                        <Typography variant="h1">{site ? 'Edit ' : 'Add New '} Site</Typography>
                    </div>
                    <div className={classes.description}>
                        <Typography variant="body1">
                            All fields marked with an asterisk (*) are required.
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="row align-items-top justify-content-start">
                <div className="col col-md-7 pt-4 pr-5">
                    <CreateEditSiteForm site={site} />
                </div>
            </div>
        </div>
    );
};

CreateEditSite.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(CreateEditSite);
