import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Typography from '@material-ui/core/Typography';

import { getInProgressReports, getCompletedReports, getReopenedReports } from '../../selectors/reports';
import { setCurrentReportData } from '../../actions/current-report';
import getFormattedDate from '../../utils/get-formatted-date';

import PropertyBlock from './components/property-block';
import DashboardHeader from './components/header';
import DeleteReportDialog from './components/delete-report-dialog';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import Styles from './styles';
import useReportDialog from './utils/use-report-dialog';
import { getUserName } from '../../selectors/user';

const TesterDashboard = ({ classes }) => {
    const [selectedTab, setSelectedTab] = useState('inprogress');

    const dispatch = useDispatch();
    const history = useHistory();

    const {
        handleOpenDialog,
        dialogProps,
    } = useReportDialog();

    const inProgressReports = useSelector(getInProgressReports);
    const inProgressCount = Object.keys(inProgressReports).length;
    const completedReports = useSelector(getCompletedReports);
    const completedCount = Object.keys(completedReports).length;
    const reopenedReports = useSelector(getReopenedReports);
    const reopenedCount = Object.keys(reopenedReports).length;
    const testerName = useSelector(getUserName);

    const handleEditReport = useCallback((reportId) => {
        dispatch(setCurrentReportData(reportId));
        history.push('/report');
    }, [dispatch, history]);

    const handleChange = useCallback((event, newValue) => {
        setSelectedTab(newValue);
    }, [setSelectedTab]);

    return (
        <div className={classes.wrapper}>
            <DashboardHeader />
            <DeleteReportDialog {...dialogProps} />

            <TabContext value={selectedTab}>
                <TabList
                    value={selectedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="My Reports Selector"
                    classes={{
                        indicator: classes.tabsIndicator,
                    }}
                >
                    <Tab
                        label={`In Progress ${inProgressCount}`}
                        disabled={inProgressCount === 0}
                        classes={{
                            root: classes.tabRoot,
                            selected: classes.tabSelected,
                        }}
                        value="inprogress"
                    />
                    <Tab
                        label={`Reopened ${reopenedCount}`}
                        disabled={reopenedCount === 0}
                        classes={{
                            root: classes.tabRoot,
                            selected: classes.tabSelected,
                        }}
                        value="reopened"
                    />
                    <Tab
                        label={`Completed ${completedCount}`}
                        disabled={completedCount === 0}
                        classes={{
                            root: classes.tabRoot,
                            selected: classes.tabSelected,
                        }}
                        value="completed"
                    />
                </TabList>
                <hr className={classes.greyHr} />

                <TabPanel
                    value="inprogress"
                >
                    <div className="row" style={{ margin: '0 auto' }}>
                        { Object.entries(inProgressReports).map(([reportId, report]) => (
                            <div key={reportId} className="col-12 col-sm-6 col-lg-4">
                                <PropertyBlock
                                    onEditClick={() => handleEditReport(reportId)}
                                    progress="inprogress"
                                    title={`${report.percentComplete}% complete`}
                                    property={report.testSite.siteName}
                                    address={report.testSite.address}
                                    visitDate={getFormattedDate(report.testDate)}
                                    onDeleteClick={() => handleOpenDialog({
                                        id: reportId,
                                        siteName: report.testSite.siteName,
                                        tester: testerName,
                                        testDate: getFormattedDate(report.testDate),
                                    })}
                                />
                            </div>
                        ))}
                    </div>
                </TabPanel>
                <TabPanel
                    value="reopened"
                >
                    <div className="row" style={{ margin: '0 auto' }}>
                        { Object.entries(reopenedReports).map(([reportId, report]) => (
                            <div key={reportId} className="col-12 col-sm-6 col-lg-4">
                                <PropertyBlock
                                    onEditClick={() => handleEditReport(reportId)}
                                    progress="reopened"
                                    title="Reopened"
                                    property={report.testSite.siteName}
                                    address={report.testSite.address}
                                    visitDate={getFormattedDate(report.testDate)}
                                />
                            </div>
                        ))}
                    </div>
                </TabPanel>
                <TabPanel
                    value="completed"
                >
                    <div className="row" style={{ margin: '0 auto' }}>
                        <div className="col-12 pt-3">
                            <Typography variant="body1">To edit a completed report, please email <a href="mailto:pbaird@lsem-mi.org">pbaird@lsem-mi.org</a>.</Typography>
                        </div>
                        { Object.entries(completedReports).map(([reportId, report]) => (
                            <div key={reportId} className="col-12 col-sm-6 col-lg-4">
                                <PropertyBlock
                                    progress="completed"
                                    title={`Completed on ${getFormattedDate(report.submittedDate)}`}
                                    property={report.testSite.siteName}
                                    address={report.testSite.address}
                                    visitDate={getFormattedDate(report.testDate)}
                                />
                            </div>
                        ))}
                    </div>
                </TabPanel>
            </TabContext>
        </div>
    );
};
TesterDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(combinedStyles)(TesterDashboard);
