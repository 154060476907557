import Colors from './colors';

const styles = theme => ({
    primaryButton: {
        ...theme.customButtons,
        backgroundColor: Colors.persianBlue,
        color: Colors.white,
        '&:hover, &:focus:hover': {
            backgroundColor: Colors.ultramarineBlue,
            boxShadow: '0 5px 15px 0 rgba(0,0,0,0.2)',
        },
        '&:active': {
            backgroundColor: Colors.ultramarineBlue,
        },
        '&:focus': {
            backgroundColor: Colors.persianBlue,
        },
        '&:disabled': {
            backgroundColor: Colors.ultramarineBlue,
            color: Colors.white,
        },
    },
    primaryButtonRippleChildPulsate: {
        backgroundColor: '#2e68b7', // https://v3.material-ui.com/api/touch-ripple/
    },
    primaryButtonDisabled: {
        // used to fake disable, for accessability
        color: 'white',
        cursor: 'default',
        backgroundColor: '#9aaad6 !important',
        boxShadow: 'none',
        '&:hover, &:focus:hover': {
            boxShadow: 'none',
        },
    },
    secondaryButton: {
        ...theme.customButtons,
        backgroundColor: Colors.white,
        border: `1px solid ${Colors.persianBlue}`,
        color: Colors.persianBlue,
        '&:hover, &:focus:hover': {
            backgroundColor: '#e0e8fe',
        },
        '&:active': {
            backgroundColor: '#bacbff',
        },
        '&:focus': {
            backgroundColor: Colors.white,
        },
        '&:disabled': {
            backgroundColor: Colors.white,
            color: `rgba(${Colors.persianBlueRGB}, .3)`,
            border: `1px solid rgba(${Colors.persianBlueRGB}, .3)`,
        },
    },
    secondaryButtonRippleChildPulsate: {
        backgroundColor: '#bacbff', // https://v3.material-ui.com/api/touch-ripple/
    },
    outlineButton: {
        ...theme.customButtons,
        color: theme.typography.color,
        border: `1px solid ${Colors.lavenderGray}`,
        '&:hover, &:focus:hover': {
            borderColor: Colors.darkGrey,
            boxShadow: '0 5px 15px 0 rgba(0,0,0,0.2)',
        },
        '&:active': {
            borderColor: Colors.darkGrey,
            backgroundColor: Colors.lavenderGray,
        },
        '&:focus': {
            borderColor: Colors.darkGrey,
        },
        '&:disabled': {
            backgroundColor: Colors.ultramarineBlue,
            color: `rgba(${theme.typography.color}, .3)`,
            border: `1px solid rgba(${Colors.lavenderGray}, .3)`,
        },
    },
    outlineButtonRippleChildPulsate: {
        backgroundColor: Colors.lavenderGray, // https://v3.material-ui.com/api/touch-ripple/
    },
    buttonRipplePulsate: {
        opacity: 1,
    },
    outlineBlueButton: {
        color: Colors.persianBlue,
        border: `1px solid ${Colors.persianBlue}`,
        '&:hover, &:focus:hover': {
            borderColor: Colors.ultramarineBlue,
            boxShadow: '0 5px 15px 0 rgba(0,0,0,0.2)',
        },
        '&:active': {
            borderColor: Colors.ultramarineBlue,
            backgroundColor: Colors.lavenderGray,
        },
        '&:focus': {
            borderColor: Colors.ultramarineBlue,
        },
        '&:disabled': {},
    },
    outlineBlueButtonRippleChildPulsate: {
        backgroundColor: `rgba(${Colors.persianBlueRGB}, .3)`,
    },
    // we have to lift the label above the ripple for accessability
    buttonLabel: {
        zIndex: 1,
    },
    fullWidth: {
        width: '100%',
    },
    smallButton: {
        minHeight: 30,
        fontSize: '0.9375rem',
        padding: '2px 0',
        borderRadius: 3,
        fontWeight: 500,
    },
    textButton: {
        ...theme.customButtons,
        color: Colors.persianBlue,
        fontWeight: 600,
    },
    navButton: {
        color: Colors.white,
        fontSize: '1.0625rem',
        height: 60,
        justifyContent: 'start',
        paddingLeft: 30,
        borderRadius: 0,
        fontWeight: 500,
    },
    navButtonActive: {
        backgroundColor: Colors.ultramarineBlue,
        fontWeight: 700,
    },
    // TABS
    tabsIndicator: {
        height: 5,
    },
    tabRoot: {
        fontWeight: 400,
        color: Colors.darkGrey,
        [theme.breakpoints.down('sm')]: {
            width: '50%',
        },
    },
    tabSelected: {
        fontWeight: 700,
    },
    deleteButton: {
        ...theme.customButtons,
        backgroundColor: Colors.carnelian,
        color: Colors.white,
        '&:hover, &:focus:hover': {
            backgroundColor: Colors.carnelian,
            boxShadow: '0 5px 15px 0 rgba(0,0,0,0.2)',
        },
    },
});

export default styles;
