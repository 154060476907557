import React, {
    useState,
    useRef,
    useEffect,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import Styles from './styles';

const ActionMenu = ({
    classes,
    id,
    defaultAction,
    actions,
}) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = useCallback((event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    }, [anchorRef, setOpen]);

    const handleListKeyDown = useCallback((event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }, [setOpen]);

    const handleActionClick = useCallback((event, actionOnClick) => {
        actionOnClick();
        handleClose(event);
    }, [handleClose]);

    return (
        <div className={classes.container}>
            { defaultAction && (
                <div className={actions.length > 1 && classes.defaultActionContainer}>
                    <Button
                        className={classes.defaultActionButton}
                        onClick={defaultAction.onClick}
                    >
                        {defaultAction.label}
                    </Button>
                </div>
            )}

            {(!defaultAction || actions.length > 1) && (
                <div>
                    <IconButton
                        className={classes.expandButton}
                        aria-label={open ? 'collapse actions menu' : 'expand actions menu'}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                    <Popper
                        className={classes.popper}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper className={classes.paper}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                            { actions.map(action => (
                                                <MenuItem
                                                    key={`${id}-${action.label}`}
                                                    onClick={event => handleActionClick(event, action.onClick)}
                                                >
                                                    {action.label}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            )
            }
        </div>
    );
};

ActionMenu.defaultProps = {
    defaultAction: null,
};

ActionMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    defaultAction: PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    }),
    actions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        default: PropTypes.bool,
    })).isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(combinedStyles)(ActionMenu);
