import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { REQUEST_STATUS } from '../../../reducers/reports';


const ReportError = ({ currentReport }) => {
    const { requestStatus, errorMsg = {} } = currentReport;

    if (requestStatus === REQUEST_STATUS.SESSION_EXPIRED) {
        return (
            <Alert severity="error" style={{ marginTop: '12px' }}>
                {errorMsg.message}
            </Alert>
        );
    }

    return null;
};

ReportError.propTypes = {
    currentReport: PropTypes.shape({
        requestStatus: PropTypes.string,
        errorMsg: PropTypes.shape({
            message: PropTypes.string,
        }),
    }).isRequired,
};

export default ReportError;
