import Colors from '../../styles/colors';

const styles = () => ({
    wrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formWrapper: {
        padding: '30px 60px',
        backgroundColor: Colors.antiflashWhite,
        borderRadius: 5,
        width: 400,
        height: 'auto',
    },
    fullWidth: {
        minWidth: 280,
    },
    logo: {
        paddingTop: 30,
        paddingBottom: 10,
        width: 200,
    },
    lscLogo: {
        paddingBottom: 30,
        width: 180,
    },
});
export default styles;
