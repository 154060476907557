import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Auth from './auth';
import User from './user';
import Reports from './reports';
import CurrentReport from './current-report';
import Testers from './testers';
import Videos from './videos';
import Sites from './sites';
import App from './app';

const Reducers = history => combineReducers({
    auth: Auth,
    user: User,
    reports: Reports,
    currentReport: CurrentReport,
    testers: Testers,
    videos: Videos,
    sites: Sites,
    app: App,
    router: connectRouter(history),
});

export default Reducers;
