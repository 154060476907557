import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

import Styles from './styles';

const NotificationSnackbar = ({
    classes,
    isOpen,
    handleClose,
    text,
    type,
}) => {
    let Icon = null;
    if (type === 'success') {
        Icon = CheckCircleIcon;
    } else if (type === 'error') {
        Icon = WarningIcon;
    } else if (type === 'info') {
        Icon = InfoIcon;
    }

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert
                onClose={handleClose}
                severity={type}
                icon={<Icon fontSize="inherit" />}
                variant="filled"
                classes={{
                    root: classes.root,
                    filledSuccess: classes.filledSuccess,
                    filledError: classes.filledError,
                    filledInfo: classes.filledInfo,
                }}
            >
                {text}
            </Alert>
        </Snackbar>
    );
};

NotificationSnackbar.defaultProps = {
    text: '',
    type: 'success',
};

NotificationSnackbar.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    text: PropTypes.string,
    type: PropTypes.oneOf(['success', 'error', 'info']),
};

export default withStyles(Styles)(NotificationSnackbar);
