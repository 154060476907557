import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { withStyles } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-unresolved
import { ErrorMessage } from '@hookform/error-message';
import FormHelperText from '@material-ui/core/FormHelperText';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import InputStyles from '../../styles/inputs';
import GetFileExtension from '../../utils/get-file-extension';
import Styles from './styles';
import { APIError } from '../../actions/app';

const FileUploader = ({
    classes,
    name,
    label,
    labelledBy,
    describedBy,
    required,
    onChange,
    onClear,
    register,
    errors,
    allowedFileTypes,
    previousFilename,
}) => {
    const dispatch = useDispatch();
    const [uploadedFile, setUploadedFile] = useState(null);
    const [localError, setLocalError] = useState(null);

    // on mount, if a file has been uploaded previously (when offering the ability to replace the file),
    // set the uploadedFile and default uploadStatus to 'success'
    useEffect(() => {
        if (previousFilename) {
            setUploadedFile({ name: previousFilename });
        }
    }, [setUploadedFile, name, previousFilename]);

    const isInvalid = !!errors[name];
    const describedByError = isInvalid ? `${name}-error` : null;

    const elementProps = {
        'aria-labelledby': labelledBy,
        'aria-label': label,
        'aria-describedby': `${describedBy} ${describedByError}`,
        'aria-invalid': isInvalid,
        'aria-required': required,
        required,
    };

    const selectFile = useCallback(() => {
        document.getElementById(`file-input-${name}`).click();
    }, [name]);

    const handleChange = useCallback(() => {
        const fileToUpload = document.getElementById(`file-input-${name}`).files[0];
        let uploadErrMsg = null;
        // only proceed if we have a file selected
        if (fileToUpload) {
            if (!allowedFileTypes.includes(GetFileExtension(fileToUpload.name, true).toLowerCase())) {
                uploadErrMsg = 'File type not supported';
                dispatch(APIError(uploadErrMsg));
            } else if (fileToUpload.size > 10485760) { // 10mb
                uploadErrMsg = 'File size exceeds 10MB limit';
                dispatch(APIError(uploadErrMsg));
            } else {
                setUploadedFile(fileToUpload);

                if (onChange) {
                    onChange(fileToUpload);
                }
            }
            setLocalError(uploadErrMsg);
        }
    }, [dispatch, allowedFileTypes, setUploadedFile, name, onChange]);

    const clearSelectedFile = useCallback(() => {
        setUploadedFile(null);
        // clear file uploader component, so onChange event is registered
        document.getElementById(`file-input-${name}`).value = '';

        if (onClear) onClear();
    }, [setUploadedFile, name, onClear]);

    const replaceFile = useCallback(() => {
        clearSelectedFile();
        selectFile();
    }, [clearSelectedFile, selectFile]);

    return (
        <>
            <div className="row align-items-center">
                {(uploadedFile && uploadedFile.name) && (
                    <div className="col-auto d-flex align-items-center">
                        <InsertDriveFileIcon className={classes.documentIcon} />
                        <Typography variant="body1">{uploadedFile.name}</Typography>
                    </div>
                )}
                <div className="col-auto">
                    <input
                        id={`file-input-${name}`}
                        type="file"
                        name={name}
                        accept={allowedFileTypes ? allowedFileTypes.join(',') : null}
                        style={{ display: 'none' }}
                        ref={register ? register() : null}
                        {...elementProps}
                        onChange={handleChange}
                    />
                    <Button
                        className={classes.outlineBlueButton}
                        classes={{
                            label: classes.uploadButtonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.outlineBlueButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        aria-label={(!uploadedFile || !uploadedFile.name) ? 'upload outcome file' : 'replace uploaded outcome file'}
                        onClick={(!uploadedFile || !uploadedFile.name) ? selectFile : replaceFile}
                    >
                        {(!uploadedFile || !uploadedFile.name) ? 'Upload File' : 'Replace'}
                    </Button>
                </div>
                <div className="col">
                    <div className="d-inline" style={{ whiteSpace: 'nowrap' }} role="alert" aria-live="polite">
                        <button
                            type="button"
                            onClick={clearSelectedFile}
                            aria-label="delete uploaded outcome file"
                            className={classes.deleteButton}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
            <div className="row pt-3">
                <div className="col">
                    <div id={`${name}-error`} className={classes.errorMessage} role="alert" aria-live="polite">
                        <ErrorMessage name={name} errors={errors} />
                        {localError && (
                            <FormHelperText error>
                                <b>Error with selected file:</b><br />
                                <span style={{ fontStyle: 'italic', fontSize: '13px' }}>
                                    {localError}
                                </span>
                            </FormHelperText>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

FileUploader.defaultProps = {
    label: null,
    labelledBy: null,
    describedBy: null,
    errors: {},
    required: false,
    onChange: null,
    onClear: null,
    register: null,
    allowedFileTypes: null,
    previousFilename: null,
};

FileUploader.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelledBy: (props, propName, componentName) => {
        if ((!props.label && (!props[propName] || typeof props[propName] !== 'string'))) {
            return new Error(`One of props: label or labelledBy supplied to ${componentName} is required.`);
        }
        return undefined;
    },
    describedBy: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    register: PropTypes.func,
    errors: PropTypes.object,
    allowedFileTypes: PropTypes.array,
    previousFilename: PropTypes.string,
};

const combinedStyles = CombineStyles(InputStyles, ButtonStyles, Styles);
export default withStyles(combinedStyles)(FileUploader);
