import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import MenuItem from '@material-ui/core/MenuItem';

import Select from '../../components/form/select';
import TextInput from '../../components/form/textinput';
import TextArea from '../../components/form/textarea';
import RadioGroup from '../../components/form/radiogroup';
import RadioButton from '../../components/form/radiobutton';
import ConditionalQuestions from '../../components/form/conditional-questions';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import InputStyles from '../../styles/inputs';
import Styles from './styles';
import QuestionWrapper from './components/section/question-wrapper';

const ReportSection2 = ({
    classes,
    questions,
    formContext,
}) => {
    const {
        agentFirstName,
        agentLastName,
        agentPosition,
        agentRace,
        agentRaceOther,
        agentGender,
        agentGenderOther,
        agentAge,
        agentHairColor,
        agentHeight,
        agentWeight,
        agentGlasses,
        agentClothing,
        agentAdditionalDetails,
        employeeConversation,
        employeeFirstName,
        employeeLastName,
        employeePosition,
        employeeRace,
        employeeRaceOther,
        employeeGender,
        employeeGenderOther,
        employeeAge,
        employeeHairColor,
        employeeHeight,
        employeeWeight,
        employeeGlasses,
        employeeClothing,
        employeeAdditionalDetails,
    } = questions;

    const {
        register,
        watch,
        errors,
        control,
    } = formContext;

    const watchAgentRace = watch(agentRace.id);
    const watchAgentGender = watch(agentGender.id);
    const watchEmployeeRace = watch(employeeRace.id);
    const watchEmployeeGender = watch(employeeGender.id);
    const watchEmployeeConversation = watch(employeeConversation.id);

    return (
        <React.Fragment>
            <Typography variant="h3" component="p" style={{ fontWeight: 700, paddingBottom: 15, paddingTop: 45 }}>Describe the person who interviewed you:</Typography>
            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={agentFirstName.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={agentFirstName.id}
                                label={agentFirstName.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={agentLastName.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={agentLastName.id}
                                label={agentLastName.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={agentPosition.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={agentPosition.id}
                                label={agentPosition.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={agentRace.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <Select
                                name={agentRace.id}
                                label={agentRace.label}
                                errors={errors}
                                required
                                control={control}
                                displayEmpty
                                disabled={disabled}
                            >
                                <MenuItem disabled value="">Select</MenuItem>
                                {agentRace.options.map(option => (
                                    <MenuItem
                                        key={`${agentRace.id}-${option}`}
                                        value={option}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </div>
            </div>
            <ConditionalQuestions condition={watchAgentRace === 'Other'}>
                <div className="row pb-3">
                    <div className={`col ${classes.inputWrapper}`}>
                        <QuestionWrapper
                            questionId={agentRaceOther.id}
                            classes={classes}
                            render={({ disabled }) => (
                                <TextInput
                                    name={agentRaceOther.id}
                                    label={agentRaceOther.label}
                                    errors={errors}
                                    required
                                    inputRef={register()}
                                    inputProps={{
                                        autoComplete: 'off',
                                    }}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </div>
                </div>
            </ConditionalQuestions>

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={agentGender.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <RadioGroup
                                name={agentGender.id}
                                label={agentGender.label}
                                errors={errors}
                                required
                                row
                                control={control}
                            >
                                {agentGender.options.map(option => (
                                    <RadioButton
                                        key={`${agentGender.id}-${option}`}
                                        value={option}
                                        label={option}
                                        buttonStyle
                                        disabled={disabled}
                                    />
                                ))}
                            </RadioGroup>
                        )}
                    />
                </div>
            </div>
            <ConditionalQuestions condition={watchAgentGender === 'Other'}>
                <div className="row pb-3">
                    <div className={`col ${classes.inputWrapper}`}>
                        <QuestionWrapper
                            questionId={agentGenderOther.id}
                            classes={classes}
                            render={({ disabled }) => (
                                <TextInput
                                    name={agentGenderOther.id}
                                    label={agentGenderOther.label}
                                    errors={errors}
                                    required
                                    inputRef={register()}
                                    inputProps={{
                                        autoComplete: 'off',
                                    }}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </div>
                </div>
            </ConditionalQuestions>

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={agentAge.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={agentAge.id}
                                label={agentAge.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={agentHairColor.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={agentHairColor.id}
                                label={agentHairColor.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={agentHeight.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={agentHeight.id}
                                label={agentHeight.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={agentWeight.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={agentWeight.id}
                                label={agentWeight.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={agentGlasses.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <RadioGroup
                                name={agentGlasses.id}
                                label={agentGlasses.label}
                                errors={errors}
                                required
                                row
                                control={control}
                            >
                                {agentGlasses.options.map(option => (
                                    <RadioButton
                                        key={`${agentGlasses.id}-${option}`}
                                        value={option}
                                        label={option}
                                        buttonStyle
                                        disabled={disabled}
                                    />
                                ))}
                            </RadioGroup>
                        )}
                    />
                </div>
            </div>

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={agentClothing.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TextArea
                                name={agentClothing.id}
                                label={agentClothing.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                rowsMin={3}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={agentAdditionalDetails.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TextArea
                                name={agentAdditionalDetails.id}
                                label={agentAdditionalDetails.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                rowsMin={3}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>
            <QuestionWrapper
                questionId={employeeConversation.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pb-3">
                            <div className={`col ${classes.inputWrapper}`}>
                                <RadioGroup
                                    name={employeeConversation.id}
                                    label={employeeConversation.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    {employeeConversation.options.map(option => (
                                        <RadioButton
                                            key={`${employeeConversation.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                        <ConditionalQuestions condition={watchEmployeeConversation === 'Yes'}>
                            <Typography variant="h3" component="p" style={{ fontWeight: 700, paddingBottom: 15, paddingTop: 20 }}>Describe the Employee:</Typography>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextInput
                                        name={employeeFirstName.id}
                                        label={employeeFirstName.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        inputProps={{
                                            autoComplete: 'off',
                                        }}
                                        disabled={disabled}
                                    />
                                </div>
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextInput
                                        name={employeeLastName.id}
                                        label={employeeLastName.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        inputProps={{
                                            autoComplete: 'off',
                                        }}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextInput
                                        name={employeePosition.id}
                                        label={employeePosition.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        inputProps={{
                                            autoComplete: 'off',
                                        }}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <Select
                                        name={employeeRace.id}
                                        label={employeeRace.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        control={control}
                                        inputProps={{
                                            autoComplete: 'off',
                                        }}
                                        displayEmpty
                                        disabled={disabled}
                                    >
                                        <MenuItem disabled value="">Select</MenuItem>
                                        {employeeRace.options.map(option => (
                                            <MenuItem
                                                key={`${employeeRace.id}-${option}`}
                                                value={option}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <ConditionalQuestions condition={watchEmployeeRace === 'Other'}>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={employeeRaceOther.id}
                                            label={employeeRaceOther.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </ConditionalQuestions>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <RadioGroup
                                        name={employeeGender.id}
                                        label={employeeGender.label}
                                        errors={errors}
                                        required
                                        control={control}
                                    >
                                        {employeeGender.options.map(option => (
                                            <RadioButton
                                                key={`${employeeGender.id}-${option}`}
                                                value={option}
                                                label={option}
                                                disabled={disabled}
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                            </div>
                            <ConditionalQuestions condition={watchEmployeeGender === 'Other'}>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={employeeGenderOther.id}
                                            label={employeeGenderOther.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </ConditionalQuestions>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextInput
                                        name={employeeAge.id}
                                        label={employeeAge.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        inputProps={{
                                            autoComplete: 'off',
                                        }}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextInput
                                        name={employeeHairColor.id}
                                        label={employeeHairColor.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        inputProps={{
                                            autoComplete: 'off',
                                        }}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextInput
                                        name={employeeHeight.id}
                                        label={employeeHeight.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        inputProps={{
                                            autoComplete: 'off',
                                        }}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextInput
                                        name={employeeWeight.id}
                                        label={employeeWeight.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        inputProps={{
                                            autoComplete: 'off',
                                        }}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <RadioGroup
                                        name={employeeGlasses.id}
                                        label={employeeGlasses.label}
                                        errors={errors}
                                        required
                                        control={control}
                                    >
                                        {employeeGlasses.options.map(option => (
                                            <RadioButton
                                                key={`${employeeGlasses.id}-${option}`}
                                                value={option}
                                                label={option}
                                                disabled={disabled}
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                            </div>

                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={employeeClothing.id}
                                        label={employeeClothing.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>

                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={employeeAdditionalDetails.id}
                                        label={employeeAdditionalDetails.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
        </React.Fragment>
    );
};

ReportSection2.propTypes = {
    classes: PropTypes.object.isRequired,
    questions: PropTypes.object.isRequired,
    formContext: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(combinedStyles)(ReportSection2);
