import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import CombineStyles from '../../../../../utils/combine-styles';
import ButtonStyles from '../../../../../styles/buttons';
import Styles from './styles';
import { USER_ROLES } from '../../../../../utils/constants';

const FormButtons = ({
    classes,
    onSaveDraft,
    onSubmit,
    onSaveFeedback,
    onCancelFeedback,
    role,
}) => (role === USER_ROLES.tester.value ? (
    <div className="row">
        <div className={`col ${classes.buttonsContainer}`}>
            <div className="pr-3">
                <Button
                    className={classes.secondaryButton}
                    classes={{
                        label: classes.buttonLabel,
                    }}
                    TouchRippleProps={{
                        classes: {
                            childPulsate: classes.secondaryButtonRippleChildPulsate,
                            ripplePulsate: classes.buttonRipplePulsate,
                        },
                    }}
                    onClick={onSaveDraft}
                >
                    Save as Draft
                </Button>
            </div>
            <div>
                <Button
                    className={classes.primaryButton}
                    classes={{
                        label: classes.buttonLabel,
                    }}
                    TouchRippleProps={{
                        classes: {
                            childPulsate: classes.primaryButtonRippleChildPulsate,
                            ripplePulsate: classes.buttonRipplePulsate,
                        },
                    }}
                    type="submit"
                    onClick={onSubmit}
                >
                    Complete Section
                </Button>
            </div>
        </div>
    </div>
) : (
    <div className="row">
        <div className={`col ${classes.buttonsContainer}`}>
            <div className="pr-3">
                <Button
                    className={classes.secondaryButton}
                    classes={{
                        label: classes.buttonLabel,
                    }}
                    TouchRippleProps={{
                        classes: {
                            childPulsate: classes.secondaryButtonRippleChildPulsate,
                            ripplePulsate: classes.buttonRipplePulsate,
                        },
                    }}
                    onClick={onCancelFeedback}
                >
                    Cancel
                </Button>
            </div>
            <div>
                <Button
                    className={classes.primaryButton}
                    classes={{
                        label: classes.buttonLabel,
                    }}
                    TouchRippleProps={{
                        classes: {
                            childPulsate: classes.primaryButtonRippleChildPulsate,
                            ripplePulsate: classes.buttonRipplePulsate,
                        },
                    }}
                    onClick={onSaveFeedback}
                >
                    Save Comments
                </Button>
            </div>
        </div>
    </div>
));

FormButtons.propTypes = {
    classes: PropTypes.object.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSaveFeedback: PropTypes.func.isRequired,
    onCancelFeedback: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(combinedStyles)(FormButtons);
