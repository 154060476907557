import { createSelector } from 'reselect';

const getTestersState = state => state.testers;

export const getTesters = createSelector(
    getTestersState,
    testers => testers.data,
);

export const getTestersList = createSelector(
    getTesters,
    testers => Object.values(testers) || [],
);

export const getTestersFilter = createSelector(
    getTesters,
    testers => (
        (filterCriteria) => {
            const {
                statuses,
                genders,
                ages,
                disability,
            } = filterCriteria;

            const filteredTesters = {};
            Object.entries(testers).forEach(([testerId, tester]) => {
                if (statuses && statuses.length && !statuses.includes(tester.status)) {
                    return;
                }

                if (genders && genders.length && !genders.includes(tester.gender)) {
                    return;
                }

                if (ages && ages.length) {
                    let isIncluded = false;

                    ages.forEach((ageRange) => {
                        if (!isIncluded) {
                            const [startAge, endAge] = ageRange.split(' - ');
                            if (tester.age >= startAge && tester.age <= endAge) {
                                isIncluded = true;
                            }
                        }
                    });

                    if (!isIncluded) return;
                }

                if (disability && disability.length && !disability.includes(tester.hasDisability ? 'Yes' : 'No')) {
                    return;
                }

                filteredTesters[testerId] = tester;
            });

            return filteredTesters;
        }
    ),
);

export const getTesterSelector = createSelector(
    getTesters,
    testers => testerId => testers[testerId],
);

export const getTotalTesters = createSelector(
    getTestersState,
    state => state.total,
);
