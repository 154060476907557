import React from 'react';
import {
    useLocation,
    Switch,
    Route,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getCurrentReport } from '../../selectors/current-report';
import ReportLayout from '../../layouts/report';
import ReportSectionLayout from '../../layouts/report/section';
import { getFlaggedVersion } from '../../reducers/reports';

const ReportRouter = () => {
    const location = useLocation();
    const currentReport = useSelector(getCurrentReport);
    const flaggedVersion = getFlaggedVersion(currentReport);

    return (
        <Switch location={location}>
            <Route exact path="/report/section/:sectionId">
                <ReportSectionLayout currentReport={currentReport} flaggedVersion={flaggedVersion} />
            </Route>
            <Route exact path="/report">
                <ReportLayout currentReport={currentReport} flaggedVersion={flaggedVersion} />
            </Route>
        </Switch>
    );
};

export default ReportRouter;
