import * as yup from 'yup';

const schema = yup.object().shape({
    siteName: yup.string()
        .required('Please provide a Site Name'),
    address: yup.string()
        .required('Please provide Address'),
    city: yup.string()
        .required('Please provide City'),
    state: yup.string()
        .required('Please select State'),
});

export default schema;
