import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ActionMenu from '../../../../components/action-menu';
import getFormattedDate from '../../../../utils/get-formatted-date';
import WebClient from '../../../../utils/web-client';
import { REPORT_OUTCOME, REPORT_STATUS } from '../../../../reducers/reports';
import { USER_ROLES } from '../../../../utils/constants';
import { UserRolesPropType } from '../../../../utils/prop-types';
import { downloadReport } from '../../../../actions/reports';

const ReportActionMenu = ({
    reportId,
    report,
    handleActions,
    role,
}) => {
    const {
        testSite,
        tester,
        testDate,
        outcome,
        outcomeFile,
    } = report;
    const {
        handleOpenOutcomeDialog,
        handleSendBackToTester,
        handleOpenDeleteReportDialog,
    } = handleActions;
    const history = useHistory();
    const dispatch = useDispatch();

    const handleReportDownload = useCallback(async () => {
        const onSuccess = (url) => {
            window.location = url;
        };

        dispatch(downloadReport(reportId, onSuccess));
    }, [reportId, dispatch]);

    const handleCsvReportDownload = useCallback(async () => {
        const response = await WebClient.get(`reports/${reportId}/csv`);
        const { url } = response.data;
        window.location = url;
    }, [reportId]);

    const handleDeleteReport = useCallback(
        () => handleOpenDeleteReportDialog({
            id: reportId,
            siteName: testSite.siteName,
            tester: tester.fullName,
            testDate: getFormattedDate(testDate),
        }),
        [handleOpenDeleteReportDialog, reportId, testSite, tester.fullName, testDate],
    );

    const handleUploadOutcome = useCallback(() => handleOpenOutcomeDialog({
        id: reportId,
        testSite,
        tester: tester.fullName,
        testDate: getFormattedDate(testDate),
    }), [handleOpenOutcomeDialog, reportId, testSite, tester.fullName, testDate]);

    const handleDownloadOutcome = useCallback(async () => {
        const response = await WebClient.get(`reports/${reportId}/outcome`);
        const { url } = response.data;
        window.location = url;
    }, [reportId]);

    const handleReplaceOutcome = useCallback(() => handleOpenOutcomeDialog({
        id: reportId,
        testSite,
        tester: tester.fullName,
        testDate: getFormattedDate(testDate),
        previousOutcome: outcome,
        previousOutcomeFile: outcomeFile,
    }), [handleOpenOutcomeDialog, reportId, testSite, tester.fullName, testDate, outcome, outcomeFile]);

    const handleViewHistory = useCallback(() => {
        history.push(`/dashboard/reports/${reportId}`);
    }, [history, reportId]);

    const actions = [
        { label: 'Download report', onClick: handleReportDownload },
        { label: 'Download .csv report', onClick: handleCsvReportDownload },
        { label: 'View History', onClick: handleViewHistory },
    ];

    if (role === USER_ROLES.admin.value) {
        actions.push({ label: 'Delete report', onClick: handleDeleteReport });
    }

    if ((outcome === REPORT_OUTCOME.POSITIVE || outcome === REPORT_OUTCOME.NEGATIVE || outcome === REPORT_OUTCOME.INCONCLUSIVE) && report.status === REPORT_STATUS.SUBMITTED) {
        actions.push({ label: 'Download outcome', onClick: handleDownloadOutcome });
        actions.push({ label: 'Replace outcome', onClick: handleReplaceOutcome });
    } else if (outcome === REPORT_OUTCOME.PENDING && report.status === REPORT_STATUS.SUBMITTED) {
        actions.push({ label: 'Send back to tester', onClick: () => handleSendBackToTester(reportId) });
        actions.push({ label: 'Upload outcome', onClick: handleUploadOutcome });
    }

    return (
        <ActionMenu
            id={`${reportId}-actions`}
            defaultAction={{
                label: 'Download report',
                onClick: handleReportDownload,
            }}
            actions={actions}
        />
    );
};

ReportActionMenu.propTypes = {
    reportId: PropTypes.number.isRequired,
    report: PropTypes.object.isRequired,
    handleActions: PropTypes.shape({
        handleOpenOutcomeDialog: PropTypes.func.isRequired,
        handleOpenDeleteReportDialog: PropTypes.func.isRequired,
        handleSendBackToTester: PropTypes.func.isRequired,
    }).isRequired,
    role: UserRolesPropType.isRequired,
};

export default ReportActionMenu;
