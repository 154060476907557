import React from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Styles from './styles';

const VideoBlockButton = ({
    classes,
    video,
}) => {
    const { title, description } = video.snippet;
    const { videoId } = video.contentDetails;
    const videoOptions = {
        height: '160',
        width: '320',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            modestbranding: 1,
            rel: 0,
        },
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.header} />

            <YouTube
                videoId={videoId}
                opts={videoOptions}
                containerClassName={classes.videoContainer}
            />

            <div className={classes.body}>
                <Typography variant="h2" className={classes.titleText}>{title}</Typography>
                <Typography variant="body1" className={classes.descriptionText}>
                    {description}
                </Typography>
            </div>
        </div>
    );
};

VideoBlockButton.propTypes = {
    classes: PropTypes.object.isRequired,
    video: PropTypes.object.isRequired,
};

export default withStyles(Styles)(VideoBlockButton);
