import React, {
    createContext,
    useReducer,
    useContext,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import KeyMirror from 'keymirror';

const HTML_ELEMENT = document.documentElement;
const getHtmlFontSize = () => {
    const fontSize = window
        .getComputedStyle(HTML_ELEMENT).fontSize
        .replace(/\D/g, '');
    return parseInt(fontSize, 10);
};

// this reducer is only intended to store the state of the styles that
// are able to be overridden by the user with the DisplayOptionsControlPanel
// it is not meant to store the entire Material theme
const initialTheme = {
    background: null,
    // default to the browser default font-size in case the user has set a custom size for accessibility
    fontSize: getHtmlFontSize(),
};

const ThemeTypes = KeyMirror({
    UPDATE_BACKGROUND_COLOR: true,
    INCREASE_FONT_SIZE: true,
    DECREASE_FONT_SIZE: true,
});

const themeReducer = (state, action) => {
    switch (action.type) {
    case ThemeTypes.UPDATE_BACKGROUND_COLOR:
        return {
            ...state,
            background: action.background,
        };
    case ThemeTypes.INCREASE_FONT_SIZE:
        return {
            ...state,
            fontSize: state.fontSize + 1,
            htmlFontSize: state.fontSize + 1,
        };
    case ThemeTypes.DECREASE_FONT_SIZE:
        return {
            ...state,
            fontSize: state.fontSize - 1,
            htmlFontSize: state.fontSize - 1,
        };
    default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const increaseFontSize = () => ({
    type: ThemeTypes.INCREASE_FONT_SIZE,
});

export const decreaseFontSize = () => ({
    type: ThemeTypes.DECREASE_FONT_SIZE,
});

export const updateBackground = color => ({
    type: ThemeTypes.UPDATE_BACKGROUND_COLOR,
    background: color,
});

const ThemeStateContext = createContext();
const ThemeDispatchContext = createContext();

export const ThemeContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(themeReducer, initialTheme);

    useEffect(() => {
        // update the <html> font-size so that all styles using rem's will size appropriately
        HTML_ELEMENT.style.fontSize = `${state.fontSize}px`;
    }, [state.fontSize]);

    return (
        <ThemeStateContext.Provider value={state}>
            <ThemeDispatchContext.Provider value={dispatch}>
                {children}
            </ThemeDispatchContext.Provider>
        </ThemeStateContext.Provider>
    );
};

ThemeContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useThemeState = () => {
    const context = useContext(ThemeStateContext);
    if (context === undefined) {
        throw new Error('useThemeState must be used within a ThemeContextProvider');
    }
    return context;
};

export const useThemeDispatch = () => {
    const context = useContext(ThemeDispatchContext);
    if (context === undefined) {
        throw new Error(
            'useThemeDispatch must be used within a ThemeContextProvider',
        );
    }
    return context;
};
