import * as yup from 'yup';
import { isValid as isDateTimeValid } from 'date-fns';

const REQUIRED_MESSAGE = 'Please answer this question';
const INVALID_DATE_MESSAGE = 'Please enter a valid date';
const INVALID_TIME_MESSAGE = 'Please enter a valid time';
const ARRAY_TRANSFORM = (value, originalValue) => {
    if (Array.isArray(value)) {
        return !!value && value.length === 0 ? null : value;
    }

    if (typeof originalValue === 'string') {
        return originalValue.split(',');
    }

    // if not array or string then probably not valid
    return null;
};

const optionalText = yup
    .string()
    .transform(value => (value === '' ? null : value))
    .nullable()
    .notRequired();

const requiredText = yup
    .string()
    .nullable()
    .required(REQUIRED_MESSAGE);

const optionalDate = yup
    .date()
    .nullable()
    .typeError(INVALID_DATE_MESSAGE)
    .notRequired();

const requiredDate = yup
    .date()
    .nullable()
    .typeError(INVALID_DATE_MESSAGE)
    .required(REQUIRED_MESSAGE)
    .test(
        'isDateValid',
        INVALID_DATE_MESSAGE,
        value => isDateTimeValid(value),
    );

const optionalTime = yup
    .date()
    .nullable()
    .typeError(INVALID_TIME_MESSAGE)
    .notRequired();

const requiredTime = yup
    .date()
    .nullable()
    .typeError(INVALID_TIME_MESSAGE)
    .required(REQUIRED_MESSAGE)
    .test(
        'isTimeValid',
        INVALID_TIME_MESSAGE,
        value => isDateTimeValid(value),
    );

const optionalArray = yup
    .array()
    .transform(ARRAY_TRANSFORM)
    .nullable()
    .notRequired();

const requiredArray = yup
    .array()
    .transform(ARRAY_TRANSFORM)
    .nullable()
    .required(REQUIRED_MESSAGE);


const ruleTypes = {
    text: {
        optional: optionalText,
        required: requiredText,
    },
    date: {
        optional: optionalDate,
        required: requiredDate,
    },
    time: {
        optional: optionalTime,
        required: requiredTime,
    },
    array: {
        optional: optionalArray,
        required: requiredArray,
    },
};

const getValidation = (questions) => {
    const rules = {};

    Object.values(questions).forEach((questionDetails) => {
        const { validation } = questionDetails;
        const {
            type,
            conditional,
            required,
            yup: yupSchema,
        } = validation;

        if (yupSchema) {
            // short circuit if yup schema is provided
            rules[questionDetails.id] = yupSchema(yup);
            return;
        }

        const rulesForType = ruleTypes[type];

        if (!conditional) {
            rules[questionDetails.id] = required ? rulesForType.required : rulesForType.optional;
            return;
        }

        const conditionalQuestion = questions[conditional.on].id;
        let conditionFunction = () => false;

        if (typeof conditional.value !== 'undefined') {
            conditionFunction = val => (val === conditional.value);
        } else if (typeof conditional.notValue !== 'undefined') {
            conditionFunction = val => (!!val && val !== conditional.notValue);
        } else if (typeof conditional.min !== 'undefined') {
            conditionFunction = val => (!!val && val.length >= conditional.min);
        } else if (typeof conditional.max !== 'undefined') {
            conditionFunction = val => (!!val && val.length <= conditional.max);
        } else if (typeof conditional.greaterThan !== 'undefined') {
            conditionFunction = val => (val > conditional.greaterThan);
        } else if (typeof conditional.includes !== 'undefined') {
            conditionFunction = val => (!!val && val.includes(conditional.includes));
        }

        rules[questionDetails.id] = rulesForType.optional
            .when(conditionalQuestion, { is: conditionFunction, then: rulesForType.required });
    });

    return yup.object().shape(rules);
};

export default getValidation;
