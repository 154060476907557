import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { getTesterSelector } from '../../../selectors/testers';

import InviteEditTesterForm from './form';
import Icon from './images/tester-phone.png';
import Styles from './styles';

const InviteEditTester = ({ classes }) => {
    const { testerId } = useParams();
    const getTester = useSelector(getTesterSelector);
    const tester = getTester(testerId);
    const isEditing = !!tester;

    return (
        <div className="pt-5 pl-5">
            <div className="row">
                <div className="col">
                    <div className={classes.backLink}>
                        <Link to="/dashboard/testers">Back to Manage Testers</Link>
                    </div>
                    <div>
                        <Typography variant="h1">{isEditing ? 'Edit ' : 'Invite New '} Tester</Typography>
                    </div>
                    <div className={classes.description}>
                        <Typography variant="body1">
                            All questions are required unless marked optional
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="row align-items-top justify-content-start">
                <div className="col pt-4 pr-5">
                    <InviteEditTesterForm testerId={testerId} tester={tester} />
                </div>

                <div className="col pl-5 pr-5 flex-shrink-1">
                    <div className={classes.icon}>
                        <img src={Icon} alt="" />
                    </div>
                    <div className={classes.note}>
                        <Typography variant="body1">
                            <b>Note:</b> Call the tester to make sure you get all of their
                            information before sending the invitation.  This will be
                            submitted each time with their report.
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

InviteEditTester.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(InviteEditTester);
