import React, {
    useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';

import TablePaginationActions from '../../components/table-pagination-actions';
import { getReports as fetchReports } from '../../actions/reports';
import { getReportsList, getReportsTotal } from '../../selectors/reports';
import { getTestersForReportsFilters } from '../../actions/testers';
import { getSitesForReportsFilters } from '../../actions/sites';
import getFormattedDate from '../../utils/get-formatted-date';

import ReportFilter from './components/report-filter';
import OutcomePill from './components/outcome-pill';
import ReportActionMenu from './components/report-action-menu';
import UploadOutcomeDialog from './components/upload-outcome-dialog';
import useReportDialog from './utils/use-report-dialog';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import Styles from './styles';
import DeleteReportDialog from './components/delete-report-dialog';
import { REPORT_STATUS } from '../../reducers/reports';
import { getRole } from '../../selectors/auth';
import { setCurrentReportData } from '../../actions/current-report';

const AdminDashboard = ({ classes }) => {
    const userRole = useSelector(getRole);
    const reports = useSelector(getReportsList);
    const totalReports = useSelector(getReportsTotal);
    const dispatch = useDispatch();
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [reportToDelete, setReportToDelete] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [testerFilterOptions, setTesterFilterOptions] = useState([]);
    const [sitesFilterOptions, setSitesFilterOptions] = useState([]);
    const [filterCriteria, setFilterCriteria] = useState({
        statuses: [],
        sites: [],
        testers: [],
    });
    const [sortBy, setSortBy] = useState('testDate');
    const [sortOrder, setSortOrder] = useState('asc');
    const [reportQueryParams, setReportQueryParams] = useState({
        pageNumber: page,
        pageSize: rowsPerPage,
        sortBy: 'testDate',
    });

    // this is to support legacy code that stores reports in the redux store as a map instead of a list.
    // eventually we should change the redux store to use a list instead, but it's unclear why it was a map in the first place.
    const sortedReports = useMemo(() => {
        if (sortBy === 'testDate') {
            return orderBy(reports, [report => report.testDate], [sortOrder]);
        }
        if (sortBy === 'tester') {
            return orderBy(reports, [report => report.tester.fullName], [sortOrder]);
        }
        if (sortBy === 'site') {
            return orderBy(reports, [report => report.testSite.siteName], [sortOrder]);
        }
        if (sortBy === 'status') {
            // order by status, then by add another layer of order by outcome
            const orderedArray = orderBy(reports, [report => report.status], [sortOrder]);
            if (sortOrder === 'desc') {
                return orderBy(orderedArray, [report => report.outcome], [sortOrder]);
            }

            return orderedArray;
        }
        return orderBy(reports, [sortBy], [sortOrder]);
    }, [reports, sortBy, sortOrder]);

    const {
        handleOpenDialog: handleOpenOutcomeDialog,
        dialogProps: uploadOutcomeDialogProps,
    } = useReportDialog();

    const handleSendBackToTester = (reportId) => {
        dispatch(setCurrentReportData(reportId));
        history.push('/report');
    };

    const handleSort = sortProperty => () => {
        setSortBy(sortProperty);
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    };

    useEffect(() => {
        const onTesterSuccess = (testers) => {
            setTesterFilterOptions(testers);
        };
        dispatch(getTestersForReportsFilters(onTesterSuccess));

        const onSiteSuccess = (sites) => {
            setSitesFilterOptions(sites);
        };
        dispatch(getSitesForReportsFilters(onSiteSuccess));
    }, [dispatch]);
    useEffect(() => {
        const params = {
            pageNumber: page,
            sortBy,
            sortOrder,
        };

        if (rowsPerPage > 0) {
            params.pageSize = rowsPerPage;
        }

        Object.entries(filterCriteria).forEach(([key, value]) => {
            if (value.length > 0) {
                params[key] = value;
            }
        });
        if (!isEqual(params, reportQueryParams)) {
            setReportQueryParams(params);
        }
    }, [filterCriteria, page, rowsPerPage, sortBy, sortOrder]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        dispatch(fetchReports(reportQueryParams));
    }, [reportQueryParams, dispatch]);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalReports - page * rowsPerPage);

    const handleChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, [setPage]);

    const handleChangeRowsPerPage = useCallback((event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, [setRowsPerPage, setPage]);

    const handleOpenDeleteReportDialog = (report) => {
        setReportToDelete(report);
        setOpenDeleteModal(true);
    };

    const getStatusOrOutcome = (report) => {
        if (report.status === REPORT_STATUS.SUBMITTED) {
            return report.outcome || REPORT_STATUS.SUBMITTED;
        }
        return report.status;
    };

    const handleUpdateFilters = useCallback((values) => {
        if (!isEqual(values, filterCriteria)) {
            setPage(0);
            setFilterCriteria(values);
        }
    }, [filterCriteria]);

    return (
        <div className={classes.dashboardContainer}>
            <div className="row pb-4">
                <div className="col">
                    <div className="d-flex">
                        <Typography id="report-title" variant="h1" component="h2">All Reports</Typography>
                        <Typography id="report-count" variant="body1" className="pl-3 pt-1">{totalReports} reports</Typography>
                    </div>
                </div>
            </div>

            <div className="row pb-4">
                <div className="col-6">
                    <ReportFilter onChange={handleUpdateFilters} testerFilterOptions={testerFilterOptions} sitesFilterOptions={sitesFilterOptions} />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <Paper className={classes.tableWrapper}>
                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader aria-labelledby="report-title report-count">
                                <TableHead className={classes.tableHeader}>
                                    <TableRow>
                                        <TableCell
                                            sortDirection={sortBy === 'testDate' ? sortOrder : false}
                                            className={classes.tableCell}
                                        >
                                            <TableSortLabel
                                                active={sortBy === 'testDate'}
                                                direction={sortBy === 'testDate' ? sortOrder : 'asc'}
                                                onClick={handleSort('testDate')}
                                            >
                                                Test Date
                                                {sortBy === 'testDate' ? (
                                                    <span className="screen-reader-only">
                                                        {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            sortDirection={sortBy === 'site' ? sortOrder : false}
                                            className={classes.tableCell}
                                        >
                                            <TableSortLabel
                                                active={sortBy === 'site'}
                                                direction={sortBy === 'site' ? sortOrder : 'asc'}
                                                onClick={handleSort('site')}
                                            >
                                                Site
                                                {sortBy === 'site' ? (
                                                    <span className="screen-reader-only">
                                                        {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            sortDirection={sortBy === 'tester' ? sortOrder : false}
                                            className={classes.tableCell}
                                        >
                                            <TableSortLabel
                                                active={sortBy === 'tester'}
                                                direction={sortBy === 'tester' ? sortOrder : 'asc'}
                                                onClick={handleSort('tester')}
                                            >
                                                Tester
                                                {sortBy === 'tester' ? (
                                                    <span className="screen-reader-only">
                                                        {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            sortDirection={sortBy === 'status' ? sortOrder : false}
                                            className={classes.tableCell}
                                        >
                                            <TableSortLabel
                                                active={sortBy === 'status'}
                                                direction={sortBy === 'status' ? sortOrder : 'asc'}
                                                onClick={handleSort('status')}
                                            >
                                                Status
                                                {sortBy === 'status' ? (
                                                    <span className="screen-reader-only">
                                                        {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBody}>
                                    {sortedReports.map(report => (
                                        <TableRow key={report.id}>
                                            <TableCell className={`${classes.tableCell} ${classes.noWrapCell}`}>
                                                {getFormattedDate(report.testDate)}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Typography variant="body1" className={classes.siteName}>{report.testSite.siteName}</Typography>
                                                { report.testSite.owner && <Typography variant="body1" className={classes.siteOwner}>Owner: {report.testSite.owner}</Typography> }
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {report.tester.fullName}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <OutcomePill outcome={getStatusOrOutcome(report)} />
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <div className="d-flex justify-content-end pr-2">
                                                    <ReportActionMenu
                                                        reportId={report.id}
                                                        report={report}
                                                        handleActions={{
                                                            handleOpenOutcomeDialog,
                                                            handleSendBackToTester,
                                                            handleOpenDeleteReportDialog,
                                                        }}
                                                        role={userRole}
                                                    />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell className={classes.emptyTableCell} colSpan={5} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        { totalReports > 10 && (
                            <TablePagination
                                className={classes.tableFooter}
                                component="div"
                                count={totalReports}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                classes={{
                                    root: classes.paginationContainer,
                                    toolbar: classes.paginationToolbar,
                                    spacer: classes.paginationSpacer,
                                }}
                            />
                        )}
                    </Paper>
                </div>
            </div>

            { totalReports > 0 && (
                <>
                    <UploadOutcomeDialog {...uploadOutcomeDialogProps} />
                    <DeleteReportDialog
                        isDialogOpen={openDeleteModal}
                        reportDetails={reportToDelete}
                        handleCloseDialog={() => setOpenDeleteModal(false)}
                    />
                </>
            )}
        </div>
    );
};

AdminDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(combinedStyles)(AdminDashboard);
