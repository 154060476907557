const questions = {
    agentFirstName: {
        id: '9',
        column: 'Agent Full First Name',
        label: 'Full First Name',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    agentLastName: {
        id: '10',
        column: 'Agent Full Last Name',
        label: 'Full Last Name',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    agentPosition: {
        id: '11',
        column: 'Agent Position',
        label: 'Position',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    agentRace: {
        id: '12',
        column: 'Agent Race/Ethnicity',
        label: 'Race/Ethnicity',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'White',
            'Black or African American',
            'Hispanic',
            'American Indian or Alaska Native',
            'Asian',
            'Native Hawaiian or Other Pacific Islander',
            'Other',
        ],
    },
    agentRaceOther: {
        id: '12a',
        column: 'Agent Race/Ethnicity Other',
        label: 'Agent Race/Ethnicity Other',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'agentRace',
                value: 'Other',
            },
        },
    },
    agentGender: {
        id: '13',
        column: 'Agent Gender',
        label: 'Gender',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Male',
            'Female',
            'Other',
        ],
    },
    agentGenderOther: {
        id: '13a',
        column: 'Agent Gender Other',
        label: 'Agent Gender Other',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'agentGender',
                value: 'Other',
            },
        },
    },
    agentAge: {
        id: '14',
        column: 'Agent Approximate Age',
        label: 'Age (This can be approximate)',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    agentHairColor: {
        id: '15',
        column: 'Agent Hair Color',
        label: 'Hair Color (This can be approximate)',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    agentHeight: {
        id: '16',
        column: 'Agent Approximate Height',
        label: 'Height (This can be approximate)',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    agentWeight: {
        id: '17',
        column: 'Agent Approximate Weight in Pounds',
        label: 'Weight in Pounds (This can be approximate)',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    agentGlasses: {
        id: '18',
        column: 'Agent Glasses',
        label: 'Glasses',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    agentClothing: {
        id: '19',
        column: 'Agent Clothing',
        label: 'Clothing',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    agentAdditionalDetails: {
        id: '20',
        column: 'Agent Additional Details',
        label: 'Additional Details',
        validation: {
            type: 'text',
            required: false,
            conditional: false,
        },
    },
    employeeConversation: {
        id: '21',
        column: 'Did you speak with any other Employees',
        label: 'Did you speak with any other Employees',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    // Employee
    employeeFirstName: {
        id: '22',
        column: 'Employee Full First Name',
        label: 'Full First Name',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'employeeConversation',
                value: 'Yes',
            },
        },
    },
    employeeLastName: {
        id: '23',
        column: 'Employee Full Last Name',
        label: 'Full Last Name',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'employeeConversation',
                value: 'Yes',
            },
        },
    },
    employeePosition: {
        id: '24',
        column: 'Employee Position',
        label: 'Position',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'employeeConversation',
                value: 'Yes',
            },
        },
    },
    employeeRace: {
        id: '25',
        column: 'Employee Race/Ethnicity',
        label: 'Race/Ethnicity',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'employeeConversation',
                value: 'Yes',
            },
        },
        options: [
            'White',
            'Black or African American',
            'Hispanic',
            'American Indian or Alaska Native',
            'Asian',
            'Native Hawaiian or Other Pacific Islander',
            'Other',
        ],
    },
    employeeRaceOther: {
        id: '25a',
        column: 'Employee Race/Ethnicity Other',
        label: 'Employee Race/Ethnicity Other',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'employeeRace',
                value: 'Other',
            },
        },
    },
    employeeGender: {
        id: '26',
        column: 'Employee Gender',
        label: 'Gender',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'employeeConversation',
                value: 'Yes',
            },
        },
        options: [
            'Male',
            'Female',
            'Other',
        ],
    },
    employeeGenderOther: {
        id: '26a',
        column: 'Employee Gender Other',
        label: 'Employee Gender Other',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'employeeGender',
                value: 'Other',
            },
        },
    },
    employeeAge: {
        id: '27',
        column: 'Employee Approximate Age',
        label: 'Age (This can be approximate)',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'employeeConversation',
                value: 'Yes',
            },
        },
    },
    employeeHairColor: {
        id: '28',
        column: 'Employee Hair Color',
        label: 'Hair Color (This can be approximate)',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'employeeConversation',
                value: 'Yes',
            },
        },
    },
    employeeHeight: {
        id: '29',
        column: 'Employee Approximate Height',
        label: 'Height (This can be approximate)',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'employeeConversation',
                value: 'Yes',
            },
        },
    },
    employeeWeight: {
        id: '30',
        column: 'Employee Approximate Weight in Pounds',
        label: 'Weight in Pounds (This can be approximate)',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'employeeConversation',
                value: 'Yes',
            },
        },
    },
    employeeGlasses: {
        id: '31',
        column: 'Employee Glasses',
        label: 'Glasses',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'employeeConversation',
                value: 'Yes',
            },
        },
        options: [
            'Yes',
            'No',
        ],
    },
    employeeClothing: {
        id: '32',
        column: 'Employee Clothing',
        label: 'Clothing',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'employeeConversation',
                value: 'Yes',
            },
        },
    },
    employeeAdditionalDetails: {
        id: '33',
        column: 'Employee Additional Details',
        label: 'Additional Details',
        validation: {
            type: 'text',
            required: false,
        },
    },
};

export default questions;
