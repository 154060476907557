import Axios from 'axios';

import VideosTypes from '../action-types/videos';
import { APIError } from './app';

const GOOGLE_API = 'https://www.googleapis.com/youtube/v3';
const LSEM_YOUTUBE_CHANNEL_ID = 'UCdtOCup-utY_N164TIfRj8Q';

const getVideosBegin = () => ({
    type: VideosTypes.GET_VIDEOS_BEGIN,
});

const getVideosSuccess = videos => ({
    type: VideosTypes.GET_VIDEOS_SUCCESS,
    payload: { videos },
});

const getVideosError = errorMsg => ({
    type: VideosTypes.GET_VIDEOS_ERROR,
    payload: { errorMsg },
});

// eslint-disable-next-line import/prefer-default-export
export const fetchVideos = () => (
    async (dispatch) => {
        dispatch(getVideosBegin());

        try {
            // first fetch details about the LSEM YouTube channel
            // so we can get the playlistId of the 'uploads' playlist
            const channelDataURL = `${GOOGLE_API}/channels?part=contentDetails&id=${LSEM_YOUTUBE_CHANNEL_ID}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`;
            const channelData = (await Axios.get(channelDataURL)).data;
            const uploadsPlaylistId = channelData.items[0].contentDetails.relatedPlaylists.uploads;

            // then fetch details about all of the videos in the 'uploads' playlist
            const playlistDataURL = `${GOOGLE_API}/playlistItems?part=snippet%2CcontentDetails&playlistId=${uploadsPlaylistId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`;
            const playlistData = (await Axios.get(playlistDataURL)).data;

            let videos = playlistData.items;

            const totalPages = playlistData.pageInfo.totalResults / playlistData.pageInfo.resultsPerPage;

            // if there is more than one page of results continue to fetch the remaining pages of data
            if (totalPages > 1) {
                let { nextPageToken } = playlistData;
                while (nextPageToken) {
                    const nextPagePlaylistDataURL = `${playlistDataURL}&pageToken=${nextPageToken}`;
                    // eslint-disable-next-line no-await-in-loop
                    const nextPagePlaylistData = (await Axios.get(nextPagePlaylistDataURL)).data;

                    videos = videos.concat(nextPagePlaylistData.items);

                    // eslint-disable-next-line prefer-destructuring
                    nextPageToken = nextPagePlaylistDataURL.nextPageToken;
                }
            }

            dispatch(getVideosSuccess(videos));
        } catch (error) {
            dispatch(APIError('Error fetching videos.'));
            dispatch(getVideosError(error));
        }
    }
);
