const questions = {
    houseType: {
        id: '300',
        column: 'What type of housing did you request?',
        label: 'What type of housing did you request?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    numberOfBedrooms: {
        id: '301',
        column: 'Number of bedrooms',
        label: 'Number of bedrooms',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    houseAdditionalRequirements: {
        id: '302',
        column: 'Additional requirements',
        label: 'Additional requirements',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    unitsAvailable: {
        id: '303',
        column: 'How many units of the type you requested did the agent say were available?',
        label: 'How many units of the type you requested did the agent say were available?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    unitsAvailableDate: {
        id: '304',
        column: 'When would they be available?',
        label: 'When would they be available?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    otherUnitsAvailableOfDifferentType: {
        id: '305',
        column: 'Did the agent mention if other units were available, but that are different from what you requested?',
        label: 'Did the agent mention if other units were available, but that are different from what you requested?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    otherUnitsAvailableOfDifferentTypeCount: {
        id: '305a',
        column: 'If yes, how many',
        label: 'If yes, how many',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'otherUnitsAvailableOfDifferentType',
                value: 'Yes',
            },
        },
    },
    otherUnitsAvailableOfDifferentTypeDateAvailable: {
        id: '305b',
        column: 'When would they be available?',
        label: 'When would they be available?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'otherUnitsAvailableOfDifferentType',
                value: 'Yes',
            },
        },
    },
    otherUnitsAvailableOfDifferentTypeDetails: {
        id: '305c',
        column: 'What is different about the unit(s)?',
        label: 'What is different about the unit(s)?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'otherUnitsAvailableOfDifferentType',
                value: 'Yes',
            },
        },
    },
    waitingList: {
        id: '306',
        column: 'Did the agent mention a waiting list?',
        label: 'Did the agent mention a waiting list?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    waitingListConversation: {
        id: '306a',
        column: 'What did the agent say about the waiting list?',
        label: 'What did the agent say about the waiting list?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'waitingList',
                value: 'Yes',
            },
        },
    },
    waitingListInstructions: {
        id: '307',
        column: 'Were you told how to get added to the waiting list?',
        label: 'Were you told how to get added to the waiting list?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    waitingListInstructionsConversation: {
        id: '307a',
        column: 'If so, how can you be added to the list?',
        label: 'If so, how can you be added to the list?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'waitingListInstructions',
                value: 'Yes',
            },
        },
    },
    offerOrAskForViewing: {
        id: '308',
        column: 'Did the agent offer, or did you have to ask to see an apartment?',
        label: 'Did the agent offer, or did you have to ask to see an apartment?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Offer',
            'Ask',
        ],
    },
    numberUnitsShown: {
        id: '309',
        column: 'How many apartments were you shown?',
        label: 'How many apartments were you shown?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    // During the visit did the representative do any of the following?
    visitIntroduceThemself: {
        id: '310',
        column: 'During the visit did the representative: Introduce themselves to you?',
        label: 'Introduce themselves to you',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    visitAskYourName: {
        id: '311',
        column: 'During the visit did the representative: Ask your name?',
        label: 'Ask your name',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    visitShakeYourHand: {
        id: '312',
        column: 'During the visit did the representative: Shake your hand?',
        label: 'Shake your hand',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    visitInviteSitDown: {
        id: '313',
        column: 'During the visit did the representative: Invite you to sit down?',
        label: 'Invite you to sit down',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    visitOfferRefreshments: {
        id: '314',
        column: 'During the visit did the representative: Offer you refreshments?',
        label: 'Offer you refreshments',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    visitExhibitCourtesy: {
        id: '315',
        column: 'During the visit did the representative: Exhibit acts of courtesy?',
        label: 'Exhibit acts of courtesy',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    visitExhibitDiscourtesy: {
        id: '316',
        column: 'During the visit did the representative: Exhibit acts of discourtesy?',
        label: 'Exhibit acts of discourtesy',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    visitOtherActs: {
        id: '317',
        column: 'During the visit did the representative: Exhibit any other acts?',
        label: 'Other acts',
        validation: {
            type: 'text',
            required: false,
            conditional: false,
        },
    },
    // Select any of the topics you discussed with the Agent
    topicsDiscussedHousing: {
        id: '318a',
        column: 'Did you discuss Housing Needs with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Housing Needs' },
        ],
    },
    topicsDiscussedHousingAgentSay: {
        id: '318a-a',
        column: 'What did the agent say about Housing Needs?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedHousing',
                min: 1,
            },
        },
    },
    topicsDiscussedHousingYouSay: {
        id: '318a-b',
        column: 'What did you say about Housing Needs?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedHousing',
                min: 1,
            },
        },
    },
    topicsDiscussedEmployment: {
        id: '318b',
        column: 'Did you discuss Employment with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '1', name: 'Employment' },
        ],
    },
    topicsDiscussedEmploymentAgentSay: {
        id: '318b-a',
        column: 'What did the agent say about Employment?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedEmployment',
                min: 1,
            },
        },
    },
    topicsDiscussedEmploymentYouSay: {
        id: '318b-b',
        column: 'What did you say about Employment?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedEmployment',
                min: 1,
            },
        },
    },
    topicsDiscussedIncome: {
        id: '318c',
        column: 'Did you discuss Income with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '2', name: 'Income' },
        ],
    },
    topicsDiscussedIncomeAgentSay: {
        id: '318c-a',
        column: 'What did the agent say about Income?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedIncome',
                min: 1,
            },
        },
    },
    topicsDiscussedIncomeYouSay: {
        id: '318c-b',
        column: 'What did you say about Income?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedIncome',
                min: 1,
            },
        },
    },
    topicsDiscussedEmploymentLength: {
        id: '318d',
        column: 'Did you discuss Length of Employment with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '3', name: 'Length of Employment' },
        ],
    },
    topicsDiscussedEmploymentLengthAgentSay: {
        id: '318d-a',
        column: 'What did the agent say about Length of Employment?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedEmploymentLength',
                min: 1,
            },
        },
    },
    topicsDiscussedEmploymentLengthYouSay: {
        id: '318d-b',
        column: 'What did you say about Length of Employment?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedEmploymentLength',
                min: 1,
            },
        },
    },
    topicsDiscussedAddress: {
        id: '318e',
        column: 'Did you discuss your Current Address with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '4', name: 'Current Address' },
        ],
    },
    topicsDiscussedAddressAgentSay: {
        id: '318e-a',
        column: 'What did the agent say about your Current Address?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedAddress',
                min: 1,
            },
        },
    },
    topicsDiscussedAddressYouSay: {
        id: '318e-b',
        column: 'What did you say about your Current Address?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedAddress',
                min: 1,
            },
        },
    },
    topicsDiscussedPhone: {
        id: '318f',
        column: 'Did you discuss your Phone with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '5', name: 'Phone Number' },
        ],
    },
    topicsDiscussedPhoneAgentSay: {
        id: '318f-a',
        column: 'What did the agent say about your Phone Number?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedPhone',
                min: 1,
            },
        },
    },
    topicsDiscussedPhoneYouSay: {
        id: '318f-b',
        column: 'What did you say about your Phone Number?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedPhone',
                min: 1,
            },
        },
    },
    topicsDiscussedAge: {
        id: '318g',
        column: 'Did you discuss your Age with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '6', name: 'Your Age' },
        ],
    },
    topicsDiscussedAgeAgentSay: {
        id: '318g-a',
        column: 'What did the agent say about your Age?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedAge',
                min: 1,
            },
        },
    },
    topicsDiscussedAgeYouSay: {
        id: '318g-b',
        column: 'What did you say about your Age?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedAge',
                min: 1,
            },
        },
    },
    topicsDiscussedMaritalStatus: {
        id: '318h',
        column: 'Did you discuss your Marital Status with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '7', name: 'Marital Status' },
        ],
    },
    topicsDiscussedMaritalStatusAgentSay: {
        id: '318h-a',
        column: 'What did the agent say about your Marital Status?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedMaritalStatus',
                min: 1,
            },
        },
    },
    topicsDiscussedMaritalStatusYouSay: {
        id: '318h-b',
        column: 'What did you say about your Marital Status?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedMaritalStatus',
                min: 1,
            },
        },
    },
    topicsDiscussedNumberChildren: {
        id: '318i',
        column: 'Did you discuss Number of Children with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '8', name: 'Number of Children' },
        ],
    },
    topicsDiscussedNumberChildrenAgentSay: {
        id: '318i-a',
        column: 'What did the agent say about Number of Children?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedNumberChildren',
                min: 1,
            },
        },
    },
    topicsDiscussedNumberChildrenYouSay: {
        id: '318i-b',
        column: 'What did you say about Number of Children?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedNumberChildren',
                min: 1,
            },
        },
    },
    topicsDiscussedChildrenAge: {
        id: '318j',
        column: 'Did you discuss Age or Sex of Children with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '9', name: 'Age or Sex of Children' },
        ],
    },
    topicsDiscussedChildrenAgeAgentSay: {
        id: '318j-a',
        column: 'What did the agent say about Age or Sex of Children?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedChildrenAge',
                min: 1,
            },
        },
    },
    topicsDiscussedChildrenAgeYouSay: {
        id: '318j-b',
        column: 'What did you say about Age or Sex of Children?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedChildrenAge',
                min: 1,
            },
        },
    },
    topicsDiscussedIncomeSource: {
        id: '318k',
        column: 'Did you discuss Source of Income with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '10', name: 'Source of Income' },
        ],
    },
    topicsDiscussedIncomeSourceAgentSay: {
        id: '318k-a',
        column: 'What did the agent say about Source of Income?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedIncomeSource',
                min: 1,
            },
        },
    },
    topicsDiscussedIncomeSourceYouSay: {
        id: '318k-b',
        column: 'What did you say about Source of Income?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedIncomeSource',
                min: 1,
            },
        },
    },
    topicsDiscussedCredit: {
        id: '318l',
        column: 'Did you discuss Credit References with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '11', name: 'Credit References' },
        ],
    },
    topicsDiscussedCreditAgentSay: {
        id: '318l-a',
        column: 'What did the agent say about Credit References?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedCredit',
                min: 1,
            },
        },
    },
    topicsDiscussedCreditYouSay: {
        id: '318l-b',
        column: 'What did you say about Credit References?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedCredit',
                min: 1,
            },
        },
    },
    topicsDiscussedLandlord: {
        id: '318m',
        column: 'Did you discuss your Current Landlord with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '12', name: 'Current Landlord' },
        ],
    },
    topicsDiscussedLandlordAgentSay: {
        id: '318m-a',
        column: 'What did the agent say about your Current Landlord?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedLandlord',
                min: 1,
            },
        },
    },
    topicsDiscussedLandlordYouSay: {
        id: '318m-b',
        column: 'What did you say about your Current Landlord?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedLandlord',
                min: 1,
            },
        },
    },
    topicsDiscussedMovingReason: {
        id: '318n',
        column: 'Did you discuss your Reason for Moving with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '13', name: 'Reason for Moving' },
        ],
    },
    topicsDiscussedMovingReasonAgentSay: {
        id: '318n-a',
        column: 'What did the agent say about your Reason for Moving?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedMovingReason',
                min: 1,
            },
        },
    },
    topicsDiscussedMovingReasonYouSay: {
        id: '318n-b',
        column: 'What did you say about your Reason for Moving?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedMovingReason',
                min: 1,
            },
        },
    },
    topicsDiscussedWhenMoving: {
        id: '318o',
        column: 'Did you discuss When You Wish to Move with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '14', name: 'When You Wish to Move' },
        ],
    },
    topicsDiscussedWhenMovingAgentSay: {
        id: '318o-a',
        column: 'What did the agent say about When You Wish to Move?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedWhenMoving',
                min: 1,
            },
        },
    },
    topicsDiscussedWhenMovingYouSay: {
        id: '318o-b',
        column: 'What did you say about When You Wish to Move?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedWhenMoving',
                min: 1,
            },
        },
    },
    topicsDiscussedCriminalCheck: {
        id: '318p',
        column: 'Did you discuss a Criminal Check with the Agent?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '14', name: 'Criminal Check' },
        ],
    },
    topicsDiscussedCriminalCheckAgentSay: {
        id: '318p-a',
        column: 'What did the agent say about a Criminal Check?',
        label: 'What did the agent say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedCriminalCheck',
                min: 1,
            },
        },
    },
    topicsDiscussedCriminalCheckYouSay: {
        id: '318p-b',
        column: 'What did you say about a Criminal Check?',
        label: 'What did you say?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'topicsDiscussedCriminalCheck',
                min: 1,
            },
        },
    },
    // agent comments
    agentsCommentOnReligion: {
        id: '320',
        column: 'Did the agent comment on Religion?',
        label: 'Religion',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    agentsCommentOnReligionDiscussed: {
        id: '320a',
        column: 'If yes, what was said?',
        label: 'If yes, what was said?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'agentsCommentOnReligion',
                value: 'Yes',
            },
        },
    },
    agentsCommentOnDisability: {
        id: '321',
        column: 'Did the agent comment on Disability?',
        label: 'Disability',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    agentsCommentOnDisabilityDiscussed: {
        id: '321a',
        column: 'If yes, what was said?',
        label: 'If yes, what was said?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'agentsCommentOnDisability',
                value: 'Yes',
            },
        },
    },
    agentsCommentOnRaceEthnicity: {
        id: '322',
        column: 'Did the agent comment on Race/Ethnicity?',
        label: 'Race/Ethnicity',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    agentsCommentOnRaceEthnicityDiscussed: {
        id: '322a',
        column: 'If yes, what was said?',
        label: 'If yes, what was said?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'agentsCommentOnRaceEthnicity',
                value: 'Yes',
            },
        },
    },
    agentsCommentOnGender: {
        id: '323',
        column: 'Did the agent comment on Gender?',
        label: 'Gender',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    agentsCommentOnGenderDiscussed: {
        id: '323a',
        column: 'If yes, what was said?',
        label: 'If yes, what was said?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'agentsCommentOnGender',
                value: 'Yes',
            },
        },
    },
    agentsCommentOnNumberOccupants: {
        id: '324',
        column: 'Did the agent comment on Number of occupants/children?',
        label: 'Number of occupants/children',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    agentsCommentOnNumberOccupantsDiscussed: {
        id: '324a',
        column: 'If yes, what was said?',
        label: 'If yes, what was said?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'agentsCommentOnNumberOccupants',
                value: 'Yes',
            },
        },
    },
    agentsCommentOnMaritalStatus: {
        id: '325',
        column: 'Did the agent comment on Marital status?',
        label: 'Marital status',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    agentsCommentOnMaritalStatusDiscussed: {
        id: '325a',
        column: 'If yes, what was said?',
        label: 'If yes, what was said?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'agentsCommentOnMaritalStatus',
                value: 'Yes',
            },
        },
    },
    agentsCommentOnAge: {
        id: '326',
        column: 'Did the agent comment on Age?',
        label: 'Age',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    agentsCommentOnAgeDiscussed: {
        id: '326a',
        column: 'If yes, what was said?',
        label: 'If yes, what was said?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'agentsCommentOnAge',
                value: 'Yes',
            },
        },
    },
    agentsCommentOnOther: {
        id: '327',
        column: 'Did the agent comment on anything else?',
        label: 'Other',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    agentsCommentOnOtherDiscussed: {
        id: '327a',
        column: 'If yes, what was said?',
        label: 'If yes, what was said?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'agentsCommentOnOther',
                value: 'Yes',
            },
        },
    },
};

export default questions;
