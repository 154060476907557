import React, {
    useState,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';

import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { yupResolver } from '@hookform/resolvers';

import Validation from '../../validation/validation-schema-login';
import ScrollToTop from '../../utils/scroll-to-top';
import TextInput from '../../components/form/textinput';
import PasswordTextInput from '../../components/form/password-textinput';

import useQuery from '../../utils/use-query';

import { Login, ResetPassBegin, LoginBegin } from '../../actions/auth';
import {
    isAuthenticated as getAuthentication,
    getAuthError,
    getAuthErrorMessage,
    isResetPasswordCompleted,
    getResetPasswordError,
} from '../../selectors/auth';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import InputStyles from '../../styles/inputs';
import Styles from './styles';

import StatusBanner from '../../components/feedback-banner';

import Logo from '../../images/logo_on_blue_bg.png';
import LSCLogo from '../../images/LSC Logo-White-Horizontal.png';

const SignInForm = ({ classes }) => {
    const isAuthenticated = useSelector(getAuthentication);

    const loginError = useSelector(getAuthError);
    const loginErrorMsg = useSelector(getAuthErrorMessage);

    const resetpassCompleted = useSelector(isResetPasswordCompleted);
    const resetpassError = useSelector(getResetPasswordError);

    const dispatch = useDispatch();

    const history = useHistory();
    const query = useQuery();

    const [errorMsg, setErrorMsg] = useState(null);
    const [errorAPI, setErrorAPI] = useState(null);
    const [errorMsgAPI, setErrorMsgAPI] = useState(null);

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        errors,
    } = useForm({
        resolver: yupResolver(Validation.login),
    });

    const encodeQueryParam = x => (
        x.replace(/\s/g, '+')
    );

    useEffect(() => {
        document.title = 'Log in - Legal Services of Eastern Michigan - Fair Housing Center';
        // clear errors on mount/dismount
        dispatch(LoginBegin({ error: false, errorMsg: '' }));
        // hydrate email address from URL
        if (query.get('email')) {
            if (getValues('email') !== encodeQueryParam(query.get('email'))) {
                // populate email and must set password as controlled input
                setValue('email', encodeQueryParam(query.get('email')));
                setValue('password', '');
                // clear query string
                history.push({ search: '' });
            }
        }
        dispatch(LoginBegin({ error: false, errorMsg: '' }));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            document.title = 'Legal Services of Eastern Michigan - Fair Housing Center';
            history.push('/dashboard/reports');
        }
    }, [isAuthenticated, history]);

    useEffect(() => {
        setErrorAPI(loginError);
        if (loginError) {
            setErrorMsgAPI(loginErrorMsg);
        }
    }, [loginError, loginErrorMsg]);

    useEffect(() => {
        // Only show the first error message
        if (Object.keys(errors).length) {
            // Relies on Object.keys insertion order property ordering, not ideal
            setErrorMsg(errors[Object.keys(errors)[0]].message);
            setErrorAPI(null);
            ScrollToTop();
        }
    }, [errors]);

    useEffect(() => {
        let timer = null;
        if (resetpassCompleted) {
            timer = setTimeout(() => {
                dispatch(ResetPassBegin());
            }, 5000);
        }
        return () => clearTimeout(timer);
    }, [resetpassCompleted, dispatch]);

    const onSubmit = (data) => {
        setErrorMsg(null);
        dispatch(Login(data));
    };

    return (
        <div className={classes.wrapper}>
            <div role="status" aria-live="polite" style={{ width: '100%', textAlign: 'center' }}>
                {errorMsg && <StatusBanner type="warn" text={errorMsg} />}
                {errorAPI && <StatusBanner type="warn" text={errorMsgAPI} />}
                {(resetpassCompleted && !resetpassError) && (<StatusBanner type="success" text="Success: Your password has successfully been updated." />)}
            </div>

            <img src={Logo} alt="" className={classes.logo} />
            <img src={LSCLogo} alt="" className={classes.lscLogo} />

            <div className={classes.formWrapper}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.inputWrapper}>
                        <TextInput
                            name="email"
                            label="Email"
                            errors={errors}
                            required
                            inputRef={register()}
                            inputProps={{
                                autoComplete: 'on',
                                autoFocus: !getValues('email'),
                            }}
                            showError={false}
                        />
                    </div>
                    <div className={classes.inputWrapper}>
                        <PasswordTextInput
                            name="password"
                            label="Password"
                            errors={errors}
                            required
                            inputRef={register()}
                            inputProps={{
                                autoComplete: 'current-password',
                                autoFocus: !getValues('email'),
                            }}
                            showError={false}
                        />
                    </div>
                    <div className={classes.inputWrapper}>
                        <Button
                            variant="contained"
                            className={classes.primaryButton}
                            classes={{
                                label: classes.buttonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.primaryButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                            type="submit"
                            fullWidth
                        >
                            Log In
                        </Button>
                        <Button
                            style={{ marginTop: 15 }}
                            className={classes.outlineButton}
                            fullWidth
                            onClick={() => {
                                history.push(getValues('email') ? `/login/forgot-password?email=${getValues('email')}` : '/login/forgot-password');
                            }}
                        >
                            Forgot Password?
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

SignInForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(InputStyles, ButtonStyles, Styles);
export default withStyles(combinedStyles)(SignInForm);
