import { format } from 'date-fns';
import noop from 'lodash/noop';

import WebClient from '../utils/web-client';
import TestersTypes from '../action-types/testers';
import { USER_STATUS } from '../reducers/user';
import { APIError } from './app';

const getTestersForReportsFiltersBegin = () => ({
    type: TestersTypes.GET_TESTERS_FOR_FILTERS_BEGIN,
});

const getTestersForReportsFiltersSuccess = () => ({
    type: TestersTypes.GET_TESTERS_FOR_FILTERS_SUCCESS,
});

const getTestersForReportsFiltersError = errorMsg => ({
    type: TestersTypes.GET_TESTERS_FOR_FILTERS_ERROR,
    payload: { errorMsg },
});

export const getTestersForReportsFilters = onSuccess => (
    async (dispatch) => {
        dispatch(getTestersForReportsFiltersBegin);

        try {
            const response = await WebClient.get('/testers');

            dispatch(getTestersForReportsFiltersSuccess());
            const preparedData = response.data.map(tester => ({
                label: tester.fullName,
                value: tester.id,
            }));
            const sortedByName = preparedData.sort((a, b) => a.label.localeCompare(b.label));

            if (onSuccess) onSuccess(sortedByName);
        } catch (error) {
            dispatch(getTestersForReportsFiltersError(error));
        }
    }
);

const getTestersBegin = () => ({
    type: TestersTypes.GET_TESTERS_BEGIN,
});

const getTestersSuccess = ({ results, total }) => ({
    type: TestersTypes.GET_TESTERS_SUCCESS,
    payload: { testers: results, total },
});

const getTestersError = errorMsg => ({
    type: TestersTypes.GET_TESTERS_ERROR,
    payload: { errorMsg },
});

export const getTesters = (payload = {}) => (
    async (dispatch) => {
        dispatch(getTestersBegin);

        try {
            const response = await WebClient.post('/testers/list', payload);

            dispatch(getTestersSuccess(response.data));
        } catch (error) {
            dispatch(getTestersError(error));
        }
    }
);

const postCreateTesterSuccess = (testerId, data) => ({
    type: TestersTypes.POST_CREATE_TESTER_SUCCESS,
    payload: {
        testerId,
        data,
    },
});

export const createNewTester = (tester, onSuccess, onError) => (
    async (dispatch) => {
        try {
            const payload = {
                ...tester,
                dob: format(tester.dob, 'MM-dd-yyyy'),
            };
            const response = await WebClient.post('/users/testers', payload);
            dispatch(postCreateTesterSuccess(response.data.id, tester));

            onSuccess();
        } catch (error) {
            onError(error);
            dispatch(APIError('Error creating tester.'));
        }
    }
);

const patchTesterBegin = testerId => ({
    type: TestersTypes.PATCH_TESTER_BEGIN,
    payload: { testerId },
});

const patchTesterSuccess = (testerId, patchedData) => ({
    type: TestersTypes.PATCH_TESTER_SUCCESS,
    payload: {
        testerId,
        patchedData,
    },
});

const patchTesterError = (testerId, errorMsg) => ({
    type: TestersTypes.PATCH_TESTER_ERROR,
    payload: {
        testerId,
        errorMsg,
    },
});

export const patchTester = (testerId, patchData, onSuccess, onError) => (
    async (dispatch) => {
        dispatch(patchTesterBegin(testerId));

        try {
            const payload = patchData;

            if (payload.dob) {
                payload.dob = format(payload.dob, 'MM-dd-yyyy');
            }

            await WebClient.patch(`/users/${testerId}`, payload);
            dispatch(patchTesterSuccess(testerId, patchData));

            if (onSuccess) onSuccess();
        } catch (error) {
            dispatch(patchTesterError(testerId, error));
            dispatch(APIError('Error processing update.'));
            if (onError) onError();
        }
    }
);

export const activateTester = (testerId, onSuccess, onError) => (
    async (dispatch) => {
        const patchData = {
            status: USER_STATUS.ACTIVE,
        };
        await patchTester(testerId, patchData, onSuccess, onError)(dispatch);
    }
);

export const deactivateTester = (testerId, onSuccess, onError) => (
    async (dispatch) => {
        const patchData = {
            status: USER_STATUS.INACTIVE,
        };
        await patchTester(testerId, patchData, onSuccess, onError)(dispatch);
    }
);

export const deleteTester = (testerId, onSuccess = noop, onError = noop) => (
    async (dispatch) => {
        try {
            await WebClient.delete(`/users/${testerId}`);
            dispatch({
                type: TestersTypes.DELETE_TESTER_SUCCESS,
                payload: {
                    id: testerId,
                },
            });
            onSuccess();
        } catch (error) {
            dispatch(APIError('Error deleting tester.'));
            onError();
        }
    }
);
