// https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript/1203361#1203361
const getExtension = (path, includePeriod) => {
    // extract file name from full path ...
    // (supports `\\` and `/` separators)
    const basename = path.split(/[\\/]/).pop();
    // get last position of `.`
    const pos = basename.lastIndexOf('.');

    // if file name is empty or
    // `.` not found (-1) or comes first (0)
    if (basename === '' || pos < 1) return '';

    // extract extension and include the period
    if (includePeriod) return basename.slice(pos).toLowerCase();
    // extract extension ignoring `.`
    return basename.slice(pos + 1).toLowerCase();
};

export default getExtension;
