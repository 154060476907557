import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

import { Logout } from '../../../../actions/auth';
import LogoImg from '../../../../images/logo_on_blue_bg.png';
import LSCLogo from '../../../../images/LSC Logo-White-Horizontal.png';

import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import Styles from './styles';
import { ADMINISTRATIVE_ROLES, USER_ROLES } from '../../../../utils/constants';
import { UserRolesPropType } from '../../../../utils/prop-types';

const DesktopNav = ({ classes, role }) => {
    const dispatch = useDispatch();
    const showAdmin = ADMINISTRATIVE_ROLES.includes(role);
    const canEdit = role === USER_ROLES.admin.value || role === USER_ROLES.testCoordinator.value;

    const getLinkComponent = route => (
        forwardRef((props, ref) => <NavLink activeClassName={classes.navButtonActive} exact to={`/dashboard/${route}`} {...ref} {...props} />)
    );

    return (
        <div className={classes.wrapper}>
            <div className="row no-gutters">
                <div className="col d-flex flex-column align-items-center">
                    <h1 className="offscreen">Legal Services of Eastern Michigan</h1>

                    <img className={classes.logo} src={LogoImg} alt="" />
                    <img className={classes.lscLogo} src={LSCLogo} alt="" />

                    { showAdmin ? (
                        <>
                            <Button
                                component={getLinkComponent('reports')}
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                Reports
                            </Button>
                            <Button
                                component={getLinkComponent('testers')}
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                {canEdit ? 'Manage Testers' : 'Testers'}
                            </Button>
                            {role === USER_ROLES.admin.value && (
                                <Button
                                    component={getLinkComponent('users')}
                                    className={classes.navButton}
                                    fullWidth
                                    role="link"
                                >
                                    Manage Internal Users
                                </Button>
                            )}
                            <Button
                                component={getLinkComponent('sites')}
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                {canEdit ? 'Manage Sites' : 'Sites'}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                component={getLinkComponent('reports')}
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                My Reports
                            </Button>
                            <Button
                                component={getLinkComponent('training')}
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                Training Videos
                            </Button>
                        </>
                    )}

                    <Button
                        onClick={() => dispatch(Logout())}
                        className={classes.navButton}
                        fullWidth
                        role="link"
                    >
                        Log Out
                    </Button>
                </div>
            </div>
        </div>
    );
};

DesktopNav.propTypes = {
    classes: PropTypes.object.isRequired,
    role: UserRolesPropType.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(combinedStyles)(DesktopNav);
