import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-unresolved
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';

import Colors from '../../../styles/colors';
import InputStyles from '../../../styles/inputs';

const PickerTheme = theme => createMuiTheme({
    palette: {
        primary: {
            main: Colors.ultramarineBlue,
            contrastText: Colors.white,
        },
    },
    overrides: {
        MuiTextField: {
            root: {
                backgroundColor: Colors.white,
            },
        },

        MuiInputBase: {
            root: {
                fontFamily: theme.typography.body1.fontFamily,
                fontSize: theme.typography.body1.fontSize,
                color: theme.typography.color,
                lineHeight: 1,
                width: '100%',
                padding: '2px 0',
            },
        },

        MuiOutlinedInput: {
            root: {
                borderRadius: 3,

                '&:focus-within': {
                    boxShadow: '0 5px 10px 0 rgba(25,25,25,0.3)',
                },
            },

            notchedOutline: {
                borderColor: Colors.darkElectricBlue,
            },

            input: {
                padding: 10,
            },
        },

        MuiButtonBase: {
            root: {
                '&$focusVisible': {
                    backgroundColor: `rgba(${Colors.ultramarineBlueRGB}, 0.25)`,
                },
            },
        },

        MuiSvgIcon: {
            root: {
                color: theme.typography.color,
            },
        },

        MuiPickersToolbarText: {
            toolbarTxt: {
                color: Colors.antiflashWhite,
            },
        },

        MuiPickersClockNumber: {
            clockNumber: {
                '&:hover, &:focus &:focus-visible': {
                    backgroundColor: `rgba(${Colors.ultramarineBlueRGB}, 0.25)`,
                },
            },
        },

        MuiButton: {
            root: {
                '&$focusVisible': {
                    backgroundColor: Colors.persianBlue,
                },
            },
        },
    },
});

const TimePicker = ({
    classes,
    name,
    label,
    value,
    format,
    placeholder,
    onChange,
    helperText,
    errors,
    showError,
    required,
    inputRef,
    inputProps,
    disabled,
}) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = useCallback(() => {
        setIsFocused(true);
    }, [setIsFocused]);

    const handleBlur = useCallback(() => {
        setIsFocused(false);
    }, [setIsFocused]);

    const isInvalid = !!errors[name];
    let describedBy = helperText ? `${name}-helper` : null;
    if (showError && isInvalid) {
        describedBy = describedBy ? `${describedBy} ${name}-error` : `${name}-error`;
    }

    const elementProps = {
        ...inputProps,
        'aria-labelledby': `${name}-label`,
        'aria-describedby': describedBy,
        'aria-invalid': isInvalid,
        'aria-required': required,
        onFocus: handleFocus,
        onBlur: handleBlur,
    };

    return (
        <>
            <Typography variant="body1" id={`${name}-label`} className={classes.inputLabel}>{label}</Typography>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={PickerTheme}>
                    <KeyboardTimePicker
                        name={name}
                        value={value}
                        placeholder={placeholder}
                        maskChar="-"
                        mask="--:-- -M"
                        format={format}
                        autoOk
                        clearable="true"
                        variant="inline"
                        inputVariant="outlined"
                        keyboardIcon={<ScheduleOutlinedIcon />}
                        helperText={null} // override the built-in helperText
                        onChange={onChange}
                        inputRef={inputRef}
                        required={required}
                        error={isInvalid}
                        disabled={disabled}
                        inputProps={elementProps}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        rightArrowButtonProps={{
                            'aria-label': 'next month',
                        }}
                        leftArrowButtonProps={{
                            'aria-label': 'previous month',
                        }}
                    />
                </ThemeProvider>
            </MuiPickersUtilsProvider>

            { helperText && (
                <Collapse in={isFocused}>
                    <Typography variant="body1" id={`${name}-helper`} className={classes.inputHelper}>{helperText}</Typography>
                </Collapse>
            )}

            {showError && (
                <div id={`${name}-error`} className={classes.errorMessage} role="status" aria-live="polite">
                    <ErrorMessage name={name} errors={errors} />
                </div>
            )}
        </>
    );
};

TimePicker.defaultProps = {
    value: null,
    format: 'hh:mm aa',
    placeholder: null,
    errors: {},
    showError: true,
    helperText: null,
    required: false,
    inputRef: null,
    inputProps: {},
    disabled: false,
};

TimePicker.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    format: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    helperText: PropTypes.string,
    errors: PropTypes.object,
    showError: PropTypes.bool,
    required: PropTypes.bool,
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    inputProps: PropTypes.object,
    disabled: PropTypes.bool,
};

const TimePickerWrapper = ({ control, rules, ...pickerProps }) => {
    if (control) {
        return (
            <Controller
                control={control}
                rules={rules}
                as={TimePicker}
                {...pickerProps}
            />
        );
    }

    return (
        <TimePicker {...pickerProps} />
    );
};

TimePickerWrapper.defaultProps = {
    value: null,
    format: 'hh:mm aa',
    placeholder: null,
    onChange: null,
    helperText: null,
    errors: {},
    showError: true,
    required: false,
    inputRef: null,
    inputProps: {},
    control: null,
    rules: null,
    disabled: false,
};

TimePickerWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    format: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func, // required if control not provided
    helperText: PropTypes.string,
    errors: PropTypes.object,
    showError: PropTypes.bool,
    required: PropTypes.bool,
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    inputProps: PropTypes.object,
    control: PropTypes.object,
    rules: PropTypes.object,
    disabled: PropTypes.bool,
};

export default withStyles(InputStyles)(TimePickerWrapper);
