import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import TextInput from '../../../../components/form/textinput';
import RadioGroupTable from '../../../../components/form/radiogroup-table';
import RadioButton from '../../../../components/form/radiobutton';
import DatePicker from '../../../../components/form/datepicker';
import Select from '../../../../components/form/select';

import CombineStyles from '../../../../utils/combine-styles';
import InputStyles from '../../../../styles/inputs';

const UnitDescription = ({
    classes,
    unitNumber,
    questions,
    errors,
    register,
    control,
    unavailable,
}) => {
    const buildingNo = questions[`unit${unitNumber}${unavailable ? 'Unavailable' : ''}BuildingNo`];
    const apartmentNo = questions[`unit${unitNumber}${unavailable ? 'Unavailable' : ''}ApartmentNo`];
    const dateAvailable = questions[`unit${unitNumber}${unavailable ? 'Unavailable' : ''}DateAvailable`];
    const rent = questions[`unit${unitNumber}${unavailable ? 'Unavailable' : ''}Rent`];
    const bedrooms = questions[`unit${unitNumber}${unavailable ? 'Unavailable' : ''}Bedrooms`];
    const upperOrLower = questions[`unit${unitNumber}UpperOrLower`];
    const frontOrBack = questions[`unit${unitNumber}FrontOrBack`];
    const unavailableReason = questions[`unit${unitNumber}UnavailableReason`];

    return (
        <div className="pb-3">
            { unavailable && <hr className={classes.greyHr} />}

            <Typography variant="h2" component="p" className="pt-3">
                {`Unit ${unitNumber}`}
            </Typography>

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <TextInput
                        name={buildingNo.id}
                        label={buildingNo.label}
                        errors={errors}
                        required
                        inputRef={register()}
                        inputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </div>
                <div className={`col ${classes.inputWrapper}`}>
                    <TextInput
                        name={apartmentNo.id}
                        label={apartmentNo.label}
                        errors={errors}
                        required
                        inputRef={register()}
                        inputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </div>
            </div>

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <DatePicker
                        name={dateAvailable.id}
                        label={dateAvailable.label}
                        helperText={dateAvailable.helperText}
                        errors={errors}
                        required
                        initialFocusedDate={format(new Date(), 'MM/dd/yyyy')}
                        control={control}
                    />
                </div>
                <div className={`col ${classes.inputWrapper}`}>
                    <TextInput
                        name={rent.id}
                        label={rent.label}
                        errors={errors}
                        required
                        inputRef={register()}
                        inputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </div>
            </div>

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <Select
                        name={bedrooms.id}
                        label={bedrooms.label}
                        errors={errors}
                        required
                        control={control}
                        displayEmpty
                    >
                        <MenuItem disabled value="">Select</MenuItem>
                        {bedrooms.options.map(option => (
                            <MenuItem
                                key={`${bedrooms.id}-${option}`}
                                value={option}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>

            { unavailable
                ? (
                    <div className="row pb-3">
                        <div className={`col ${classes.inputWrapper}`}>
                            <TextInput
                                name={unavailableReason.id}
                                label={unavailableReason.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                            />
                        </div>
                    </div>
                )
                : (
                    <>
                        <Typography variant="h3" component="p" className={classes.inputLabel}>During the visit was the apartment:</Typography>
                        <hr className={classes.greyHr} />
                        <RadioGroupTable
                            name={upperOrLower.id}
                            label={upperOrLower.label}
                            errors={errors}
                            required
                            control={control}
                        >
                            {upperOrLower.options.map(option => (
                                <RadioButton
                                    key={`${upperOrLower.id}-${option}`}
                                    value={option}
                                    label={option}
                                    buttonStyle
                                />
                            ))}
                        </RadioGroupTable>
                        <hr className={classes.greyHr} />
                        <RadioGroupTable
                            name={frontOrBack.id}
                            label={frontOrBack.label}
                            errors={errors}
                            required
                            control={control}
                        >
                            {frontOrBack.options.map(option => (
                                <RadioButton
                                    key={`${frontOrBack.id}-${option}`}
                                    value={option}
                                    label={option}
                                    buttonStyle
                                />
                            ))}
                        </RadioGroupTable>

                        <hr className={classes.greyHr} />
                    </>
                )
            }
        </div>
    );
};

UnitDescription.defaultProps = {
    errors: null,
    register: null,
    control: null,
    unavailable: false,
};

UnitDescription.propTypes = {
    classes: PropTypes.object.isRequired,
    unitNumber: PropTypes.number.isRequired,
    questions: PropTypes.object.isRequired,
    errors: PropTypes.object,
    register: PropTypes.func,
    control: PropTypes.object,
    unavailable: PropTypes.bool,
};

const combinedStyles = CombineStyles(InputStyles);
export default withStyles(combinedStyles)(UnitDescription);
