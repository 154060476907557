import CurrentReportTypes from '../../action-types/current-report';
import AuthTypes from '../../action-types/auth';

const internals = {
    initial: () => ({
        id: '',
        sections: [
            {
                id: '1',
                title: 'Setup & Arrival',
                Icon: 'Icon1',
            },
            {
                id: '2',
                title: 'Agent and Employees',
                Icon: 'Icon2',
            },
            {
                id: '3',
                title: 'Tour/Phone Test',
                Icon: 'Icon3',
            },
            {
                id: '4',
                title: 'Rental Details',
                Icon: 'Icon4',
            },
            {
                id: '5',
                title: 'Service and Treatment',
                Icon: 'Icon5',
            },
            {
                id: '6',
                title: 'Narrative',
                Icon: 'Icon6',
            },
        ],
    }),
};

const CurrentReportReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { type, payload } = action;

    switch (type) {
    case CurrentReportTypes.SET_CURRENT_REPORT_DATA:
        return {
            ...state,
            id: payload.reportId,
        };
    case AuthTypes.LOGOUT:
        return internals.initial();

    default:
        // do nothing
    }
    return state;
};

export default CurrentReportReducer;
