import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';

import ConditionalQuestions from '../conditional-questions';

import CombineStyles from '../../../utils/combine-styles';
import InputStyles from '../../../styles/inputs';
import Styles from './styles';

const RadioButton = ({
    classes,
    value,
    label,
    required,
    disabled,
    isChecked,
    inputRef,
    inputProps,
    buttonStyle,
    conditional,
}) => {
    const elementProps = {
        ...inputProps,
        'aria-required': required,
        required,
    };

    let buttonStyleRootClass = classes['radioButton-buttonStyle'];
    let buttonStyleLabelClass = classes['radioButtonLabel-buttonStyle'];
    if (isChecked && disabled) {
        buttonStyleRootClass = classes['radioButtonCheckedDisabled-buttonStyle'];
        buttonStyleLabelClass = classes['radioButtonLabelCheckedDisabled-buttonStyle'];
    } else if (disabled) {
        buttonStyleRootClass = classes['radioButtonDisabled-buttonStyle'];
        buttonStyleLabelClass = classes['radioButtonLabelDisabled-buttonStyle'];
    } else if (isChecked) {
        buttonStyleRootClass = classes['radioButtonChecked-buttonStyle'];
        buttonStyleLabelClass = classes['radioButtonLabelChecked-buttonStyle'];
    }

    return (
        <>
            <FormControlLabel
                classes={{
                    root: buttonStyle && buttonStyleRootClass,
                    label: buttonStyle && buttonStyleLabelClass,
                }}
                value={value}
                label={label}
                checked={isChecked}
                inputRef={inputRef}
                control={(
                    <Radio
                        classes={{
                            root: buttonStyle ? classes.hideRadioIcon : classes.radioButton,
                            checked: !buttonStyle && classes.radioButtonChecked,
                        }}
                        color="default"
                        disableRipple
                        disabled={disabled}
                        inputProps={elementProps}
                    />
                )}
            />

            { conditional && (
                <ConditionalQuestions hideBorder condition={isChecked}>
                    {conditional}
                </ConditionalQuestions>
            )}

            { !buttonStyle && (
                <hr className={classes.greyHr} />
            )}
        </>
    );
};

RadioButton.defaultProps = {
    required: false,
    disabled: false,
    isChecked: false,
    inputRef: null,
    inputProps: null,
    buttonStyle: false,
    conditional: null,
};

RadioButton.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    isChecked: PropTypes.bool,
    inputRef: PropTypes.func,
    inputProps: PropTypes.object,
    buttonStyle: PropTypes.bool,
    conditional: PropTypes.node,
};

const combinedStyles = CombineStyles(InputStyles, Styles);
export default withStyles(combinedStyles)(RadioButton);
