import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { yupResolver } from '@hookform/resolvers';
import TextInput from '../../../components/form/textinput';
import Select from '../../../components/form/select';
import TextArea from '../../../components/form/textarea';
import states from '../invite-edit-tester/states';
import ButtonStyles from '../../../styles/buttons';
import Checkbox from '../../../components/form/checkbox';
import validationSchema from '../../../validation/validation-schema-sites';
import { createSite, updateSite } from '../../../actions/sites';
import useSnackbar from '../../../utils/use-snackbar';
import NotificationSnackbar from '../../../components/notification-snackbar';
import makeEmptyEntriesNull from '../../../utils/make-empty-entries-null';

const initialValues = {
    siteName: null,
    address: null,
    city: null,
    state: 'MI',
    zipcode: null,
    censusNo: null,
    municipality: null,
    phone: null,
    blockGroup: null,
    notes: null,
    isInLitigation: false,
};

const CreateEditSiteForm = ({ site, classes }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { handleOpenSnackbar, snackbarProps } = useSnackbar();
    const [litigationIsChecked, setLitigationIsChecked] = useState(!!(site && site.isInLitigation));
    const isEditing = !!site;

    const onSubmitError = () => {
        handleOpenSnackbar({ text: 'An unknown error occurred. Please try again later.', type: 'error' });
    };

    const onSubmitSuccess = (result) => {
        history.push(`/dashboard/sites/${result.id}`);
        handleOpenSnackbar({ text: 'Saved successfully', type: 'success' });
    };

    const onSubmitNew = (values) => {
        const newSiteValues = { ...makeEmptyEntriesNull(values), isInLitigation: litigationIsChecked };
        dispatch(createSite(newSiteValues, onSubmitSuccess, onSubmitError));
    };

    const onSubmitEdit = (values) => {
        const updateValues = { ...makeEmptyEntriesNull(values), isInLitigation: litigationIsChecked };
        dispatch(updateSite(site.id, updateValues, onSubmitSuccess, onSubmitError));
    };

    const handleCancel = () => {
        history.push('/dashboard/sites');
    };

    const {
        register,
        errors,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: isEditing ? site : initialValues,
    });

    return (
        <form onSubmit={handleSubmit(isEditing ? onSubmitEdit : onSubmitNew)} noValidate>
            <div className="row pb-3">
                <div className="col">
                    <TextInput
                        name="siteName"
                        label="Name *"
                        errors={errors}
                        required
                        inputRef={register()}
                        inputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <TextInput
                        name="address"
                        label="Address *"
                        errors={errors}
                        required
                        inputRef={register()}
                        inputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className="col-8 col-lg-5 pt-3">
                    <TextInput
                        name="city"
                        label="City *"
                        errors={errors}
                        required
                        inputRef={register()}
                        inputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </div>
                <div className="col-4 col-lg-3 pt-3">
                    <Select
                        name="state"
                        label="State *"
                        errors={errors}
                        required
                        control={control}
                        displayEmpty
                    >
                        {states.map(state => (
                            <MenuItem
                                key={state}
                                value={state}
                            >
                                {state}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div className="col-8 col-lg-4 pt-3">
                    <TextInput
                        name="zipcode"
                        label="Zip Code"
                        inputRef={register()}
                        inputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className="col">
                    <TextInput
                        name="phone"
                        label="Phone Number"
                        inputRef={register()}
                        inputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </div>
                <div className="col">
                    <TextInput
                        name="municipality"
                        label="Municipality"
                        inputRef={register()}
                        inputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className="col">
                    <TextInput
                        name="censusNo"
                        label="Census Number"
                        inputRef={register()}
                        inputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </div>
                <div className="col">
                    <TextInput
                        name="blockGroup"
                        label="Block Group"
                        inputRef={register()}
                        inputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className="col">
                    <TextArea
                        name="notes"
                        label="Notes"
                        errors={errors}
                        inputRef={register()}
                        rowsMin={3}
                    />
                </div>
            </div>
            <div className="row pb-3">
                <div className="col">
                    <Checkbox
                        name="isInLitigation"
                        label="Site currently in litigation"
                        onChange={event => setLitigationIsChecked(!!event.target.checked)}
                        isChecked={litigationIsChecked}
                        value={`${litigationIsChecked}`}
                        inputRef={register()}
                    />
                </div>
            </div>
            <div className="row pt-4 pb-5">
                <div className="col d-flex justify-content-start">
                    <Button
                        className={`${classes.primaryButton}`}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.primaryButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        aria-label={isEditing ? 'Save updates to site' : null}
                        type="submit"
                    >
                        {isEditing ? 'Save' : 'Create Site'}
                    </Button>
                    &nbsp;
                    { isEditing && (
                        <Button
                            className={`${classes.secondaryButton}`}
                            classes={{
                                label: classes.buttonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.primaryButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                            aria-label="Return to sites"
                            type="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    )}
                </div>
            </div>

            <NotificationSnackbar {...snackbarProps} />
        </form>
    );
};

CreateEditSiteForm.propTypes = {
    site: PropTypes.shape({
        id: PropTypes.number,
        siteName: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipcode: PropTypes.string,
        censusNo: PropTypes.string,
        municipality: PropTypes.string,
        phone: PropTypes.string,
        blockGroup: PropTypes.string,
        notes: PropTypes.string,
        isInLitigation: PropTypes.bool,
        length: PropTypes.number,
    }),
    classes: PropTypes.object.isRequired,
};

CreateEditSiteForm.defaultProps = {
    site: null,
};

export default withStyles(ButtonStyles)(CreateEditSiteForm);
