import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LoopIcon from '@material-ui/icons/Loop';

import Styles from './styles';
import { REPORT_STATUS } from '../../../../reducers/reports';
import SubmitIcon from '../../../../components/icons/submit-icon';

const OUTCOME_MAP = {
    PENDING: {
        text: 'Submitted',
        icon: <SubmitIcon />,
    },
    POSITIVE: {
        text: 'Positive',
        icon: (<AddCircleIcon />),
    },
    NEGATIVE: {
        text: 'Negative',
        icon: (<RemoveCircleIcon />),
    },
    INCONCLUSIVE: {
        text: 'Inconclusive',
        icon: (<NotInterestedIcon />),
    },
    [REPORT_STATUS.DRAFT]: {
        text: 'Draft',
        icon: null,
    },
    [REPORT_STATUS.SUBMITTED]: {
        text: 'Submitted',
        icon: <SubmitIcon />,
    },
    [REPORT_STATUS.REOPENED]: {
        text: 'Re-opened',
        icon: <LoopIcon />,
    },
};

const OutcomePill = ({
    outcome,
    classes,
}) => {
    const { text, icon } = OUTCOME_MAP[outcome];

    return (
        <div className={`
                ${classes.pill}
                ${classes[outcome.toLowerCase()]}
            `}
        >
            { icon && <div className={classes.pillIcon}>{icon}</div> }
            <Typography variant="body2" component="div" className={classes.pillText}>{text}</Typography>
        </div>
    );
};

OutcomePill.propTypes = {
    outcome: PropTypes.oneOf(['PENDING', 'POSITIVE', 'NEGATIVE', 'INCONCLUSIVE', 'REOPENED', 'DRAFT']).isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(OutcomePill);
