import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import 'bootstrap-css-only/css/bootstrap-grid.min.css';

// REDUX
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';

import ScrollToTop from './utils/scroll-to-top';
import ErrorFallback from './components/ErrorFallback';
import * as serviceWorker from './service-worker';

import { ClearErrors, Logout } from './actions/auth';
import { isAuthenticated } from './selectors/auth';
import reducers from './reducers';

import browserHistory from './wiring/history';
import App from './routes';

import Initializers from './initializers';
import InitializerUser from './initializers/user';

import 'sanitize.css/sanitize.css';
import './index.css';

const initialState = {};
const enhancers = [];
const middleware = [thunk, routerMiddleware(browserHistory)];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line global-require
    const axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_SENTRY_ENV,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(browserHistory),
            }),
            new CaptureConsoleIntegration({
                // array of methods that should be captured
                // options include: ['log', 'info', 'warn', 'error', 'debug', 'assert']
                levels: ['info', 'warn', 'error'],
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,
    });
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'user', 'currentReport', 'reports', 'testers', 'videos', 'sites'],
};
const persistedReducer = persistReducer(persistConfig, reducers(browserHistory));
const store = createStore(
    persistedReducer,
    initialState,
    composedEnhancers,
);
const persistedStore = persistStore(store);

browserHistory.listen(() => {
    ScrollToTop();
});

const onBeforeLift = () => {
    // Run initializers... anything that will need to use or subscribe to the store
    Initializers(store);

    // clear login/logout errors that may be in local storage
    store.dispatch(ClearErrors());

    // if a new version of the app has been deployed, log the user out
    if (process.env.REACT_APP_DEPLOYMENT_ID !== localStorage.getItem('deploymentId')) {
        store.dispatch(Logout());
        localStorage.setItem('deploymentId', process.env.REACT_APP_DEPLOYMENT_ID);
    }

    if (isAuthenticated(store.getState())) {
        InitializerUser(store);
        // load role specific content
        // if (isUserAdmin(store.getState())) {}
    }
};

ReactDOM.render(
    <Provider store={store}>
        <PersistGate
            loading={null}
            persistor={persistedStore}
            onBeforeLift={onBeforeLift}
        >
            <ConnectedRouter history={browserHistory}>
                <Sentry.ErrorBoundary fallback={ErrorFallback}>
                    <App />
                </Sentry.ErrorBoundary>
            </ConnectedRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
