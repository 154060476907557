import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import TablePaginationActions from '../../../components/table-pagination-actions';
import VideoBlock from '../../../components/video-block';
import { fetchVideos } from '../../../actions/videos';
import { getVideos } from '../../../selectors/videos';

import Styles from '../styles';

const TrainingVideos = ({ classes }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchVideos());
    }, [dispatch]);

    const videos = useSelector(getVideos);
    const totalVideos = videos.length;

    const handleChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, [setPage]);

    const handleChangeRowsPerPage = useCallback((event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, [setRowsPerPage, setPage]);

    const getVideosForCurrentPage = useCallback(() => {
        if (videos.length && rowsPerPage > 0) {
            return videos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        }
        return videos;
    }, [rowsPerPage, page, videos]);

    return (
        <div className={classes.trainingVideosContainer}>
            <div className="row pb-md-4 pb-3">
                <div className="col">
                    <div className={classes.trainingVideosHeader}>
                        <Typography id="videos-title" variant="h1" component="h2">Training Videos</Typography>
                        <Typography id="videos-count" variant="body1" className="pl-3 pt-1">{totalVideos} videos</Typography>
                    </div>
                </div>
            </div>

            <div className={`row no-gutters ${classes.trainingVideosBody}`}>

                { getVideosForCurrentPage().map(video => (
                    <div key={video.id} className="col-auto pr-sm-3">
                        <VideoBlock video={video} />
                    </div>
                ))}

            </div>
            <div className="row pb-5">
                <div className="col">
                    { totalVideos > 10 && (
                        <TablePagination
                            className={classes.tableFooter}
                            component="div"
                            count={totalVideos}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            classes={{
                                root: classes.paginationContainer,
                                toolbar: classes.paginationToolbar,
                                spacer: classes.paginationSpacer,
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
TrainingVideos.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(TrainingVideos);
