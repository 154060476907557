import Colors from '../../../../styles/colors';

const styles = theme => ({
    wrapper: {
        paddingTop: 15,
        paddingBottom: 45,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
    buttonText: {
        color: Colors.white,
    },
});

export default styles;
