import * as yup from 'yup';
import { isValid as isDateTimeValid } from 'date-fns';

const schema = yup.object().shape({
    firstName: yup.string()
        .required('Please provide First Name'),
    lastName: yup.string()
        .required('Please provide Last Name'),
    email: yup.string()
        .email('Email invalid')
        .required('Please provide Email'),
    phone: yup.string()
        .required('Please provide Phone number'),
    address: yup.string()
        .required('Please provide Address'),
    city: yup.string()
        .required('Please provide City'),
    state: yup.string()
        .nullable()
        .required('Please select State'),
    zip: yup.string()
        .required('Please provide Zip Code'),
    race: yup.string()
        .nullable()
        .required('Please select /Ethnicity'),
    raceOther: yup.string()
        .nullable()
        .when('race', {
            is: 'Other',
            then: yup
                .string()
                .nullable()
                .required('Please enter Race/Ethnicity'),
        }),
    gender: yup.string()
        .nullable()
        .required('Please select Gender'),
    hasDisability: yup.string()
        .nullable()
        .required('Please select Disability Status'),
    disability: yup.string()
        .nullable()
        .notRequired(),
    dob: yup.date()
        .nullable()
        .typeError('Please provide a valid Date of Birth')
        .required('Please provide Date of Birth')
        .test(
            'isDateValid',
            'Please provide a valid Date of Birth',
            value => isDateTimeValid(value),
        ),
    comments: yup.string()
        .nullable()
        .notRequired()
        .max(100),
});

export default schema;
