import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Switch,
    Route,
    useLocation,
    useHistory,
    useParams,
} from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-unresolved
import { yupResolver } from '@hookform/resolvers';

import {
    uploadReportAttachments,
    saveReportSectionDraft,
    completeReportSection,
    patchReportVersion,
    setSectionToFlagged,
    setSectionToCompleted,
    patchReportVersionSuccess,
} from '../../../actions/reports';
import { getSectionByIdSelector } from '../../../selectors/current-report';
import { getGetReportAttachmentsUploadStatus } from '../../../selectors/reports';
import getValidation from '../../../validation/validation-schema-report';

import ReportSectionHeader from '../../../routes/report/components/section/header';
import FormButtons from '../../../routes/report/components/section/form-buttons';
import ReportSection1 from '../../../routes/report/section1';
import ReportSection2 from '../../../routes/report/section2';
import ReportSection3 from '../../../routes/report/section3';
import ReportSection4 from '../../../routes/report/section4';
import ReportSection5 from '../../../routes/report/section5';
import ReportSection6 from '../../../routes/report/section6';

import ReportHeader from '../components/header';
import ReportError from '../components/report-error';
import { getRole } from '../../../selectors/auth';
import Colors from '../../../styles/colors';

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: Colors.ultramarineBlue,
        backgroundColors: 'rgba(0,0,0,0.7)',
        display: 'flex',
        flexDirection: 'column',
    },
}));

const ReportSectionLayout = ({ currentReport, flaggedVersion }) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const { id: reportId } = currentReport;
    const { siteName } = currentReport.testSite;
    const { sectionId } = useParams();
    const getSectionById = useSelector(getSectionByIdSelector);
    const currentSection = getSectionById(sectionId);
    const userRole = useSelector(getRole);
    const reportAttachmentsUploadStatus = useSelector(getGetReportAttachmentsUploadStatus);
    const [uploadedAttachments, setUploadedAttachments] = useState([null]);


    const defaultValues = {};
    Object.values(currentSection.questions).forEach((question) => {
        defaultValues[question.id] = currentReport.reportAnswers[question.id] || null;
    });

    const formContext = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(getValidation(currentSection.questions)),
        defaultValues,
    });
    const { handleSubmit, getValues } = formContext;

    const onComplete = () => {
        history.push('/report');
    };

    const SaveAttachments = async () => {
        // function gets called anytime a section is saved
        // only the 6th section has attachments
        if (currentSection.id === '6') {
            const validAttachments = uploadedAttachments
                .filter(attachment => attachment !== null);
            await dispatch(uploadReportAttachments(reportId, validAttachments));
        }
    };

    const onFormSubmit = async (validatedFormData) => {
        await SaveAttachments();

        const answers = validatedFormData;
        const { questions } = currentSection;

        dispatch(completeReportSection(reportId, sectionId, questions, answers, onComplete));
    };

    const onSaveDraft = async () => {
        await SaveAttachments();

        dispatch(saveReportSectionDraft(reportId, sectionId, currentSection.questions, getValues(), onComplete));
    };

    const onCancelFeedback = () => {
        // feedback is only stored in the redux store, so if we navigate back it will reset
        history.push('/report');
    };

    const onSaveFeedback = () => {
        // add error messages if any flaggedAnswers are missing a reason
        const flaggedAnswers = flaggedVersion.flaggedAnswers || {};
        let hasErrors = false;
        const validatedAnswers = Object.entries(flaggedAnswers).reduce((acc, [questionId, data]) => {
            if (!data.reason) {
                hasErrors = true;
                acc[questionId] = { ...data, error: 'Reason is required' };
            } else {
                const { error, ...validatedData } = data;
                acc[questionId] = validatedData;
            }
            return acc;
        }, {});

        const { id, ...payload } = flaggedVersion;
        if (hasErrors) {
            dispatch(patchReportVersionSuccess(reportId, id, { ...flaggedVersion, flaggedAnswers: validatedAnswers }));
        } else {
            dispatch(patchReportVersion(reportId, id, { ...payload, flaggedAnswers: validatedAnswers, sectionId }, () => {
                let sectionContainsFlaggedAnswers = false;
                Object.values(currentSection.questions).forEach((question) => {
                    if (validatedAnswers[question.id]) {
                        sectionContainsFlaggedAnswers = true;
                    }
                });
                if (sectionContainsFlaggedAnswers) {
                    dispatch(setSectionToFlagged(currentReport.id, currentSection.id));
                } else {
                    dispatch(setSectionToCompleted(currentReport.id, currentSection.id));
                }
                onComplete();
            }));
        }
    };

    const onBackButtonClick = () => {
        onSaveDraft();
    };

    const sectionProps = {
        questions: currentSection.questions,
        onSaveDraft,
        formContext,
        flaggedAnswers: flaggedVersion.flaggedAnswers || {},
        setUploadedAttachments,
    };

    const [progressBlockerOpen, setProgressBlockerOpen] = useState(false);
    const handleCloseProgressBlocker = () => {
        setProgressBlockerOpen(false);
    };

    useEffect(() => {
        if (reportAttachmentsUploadStatus.inProgress) {
            setProgressBlockerOpen(true);
        } else if (!reportAttachmentsUploadStatus.inProgress) {
            handleCloseProgressBlocker();
        }
    }, [reportAttachmentsUploadStatus]);

    return (
        <React.Fragment>
            <ReportHeader
                backButtonLabel={`Report: ${siteName}`}
                onBackButtonClick={onBackButtonClick}
            />
            <main>
                <div className="container">
                    <div className="row align-items-top pt-md-5 pt-3 pb-5">
                        <div className="col-md-4">
                            <ReportSectionHeader section={currentSection} />
                        </div>
                        <div className="col-md">
                            <form noValidate>
                                <Backdrop
                                    className={classes.backdrop}
                                    open={progressBlockerOpen}
                                    onClick={handleCloseProgressBlocker}
                                >
                                    <CircularProgress color="inherit" style={{ marginBottom: 30 }} />
                                    <Typography variant="h6" style={{ color: 'white' }}>Uploading attachments...</Typography>
                                </Backdrop>
                                <Switch location={location}>
                                    <Route exact path="/report/section/1">
                                        <ReportSection1 {...sectionProps} />
                                    </Route>
                                    <Route exact path="/report/section/2">
                                        <ReportSection2 {...sectionProps} />
                                    </Route>
                                    <Route exact path="/report/section/3">
                                        <ReportSection3 {...sectionProps} />
                                    </Route>
                                    <Route exact path="/report/section/4">
                                        <ReportSection4 {...sectionProps} />
                                    </Route>
                                    <Route exact path="/report/section/5">
                                        <ReportSection5 {...sectionProps} />
                                    </Route>
                                    <Route exact path="/report/section/6">
                                        <ReportSection6 {...sectionProps} />
                                    </Route>
                                </Switch>

                                <FormButtons
                                    onSaveDraft={onSaveDraft}
                                    onSubmit={handleSubmit(onFormSubmit)}
                                    onSaveFeedback={onSaveFeedback}
                                    onCancelFeedback={onCancelFeedback}
                                    role={userRole}
                                />

                                <ReportError currentReport={currentReport} />
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
};

ReportSectionLayout.propTypes = {
    currentReport: PropTypes.object.isRequired,
    flaggedVersion: PropTypes.object.isRequired,
};

export default ReportSectionLayout;
