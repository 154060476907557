import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

const ALPHANUMERIC_REGEX = /[A-Za-z0-9]/;

const TextMaskAlphanumeric = (props) => {
    const { inputRef, maxLength, ...other } = props;

    const mask = [];
    for (let i = 0; i < maxLength; i += 1) {
        mask.push(ALPHANUMERIC_REGEX);
    }

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            guide={false}
            showMask
            keepCharPositions
        />
    );
};

TextMaskAlphanumeric.propTypes = {
    inputRef: PropTypes.func.isRequired,
    maxLength: PropTypes.number.isRequired,
};

export default TextMaskAlphanumeric;
