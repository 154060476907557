import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Styles from './styles';

const ReportSectionHeader = ({ classes, section }) => {
    const { title, Icon } = section;

    return (
        <>
            <div className={classes.sectionIcon}>
                <Icon />
            </div>
            <div className={classes.sectionTitle}>
                <Typography variant="h1">{title}</Typography>
            </div>
            <div className={classes.sectionDescription}>
                <Typography variant="body1">
                    All questions are required unless marked optional
                </Typography>
            </div>
        </>
    );
};

ReportSectionHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    section: PropTypes.object.isRequired,
};

export default withStyles(Styles)(ReportSectionHeader);
