import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { format, isValid } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';

import { getActiveSites } from '../../../../selectors/sites';
import { createNewReport, patchReport } from '../../../../actions/reports';
import TextInput from '../../../../components/form/textinput';
import Select from '../../../../components/form/select';
import RadioGroup from '../../../../components/form/radiogroup';
import RadioButton from '../../../../components/form/radiobutton';
import DatePicker from '../../../../components/form/datepicker';
import TextMaskAlphanumeric from '../../../../components/form/text-mask-alphanumeric';
import getFormattedDate from '../../../../utils/get-formatted-date';

import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import InputStyles from '../../../../styles/inputs';
import DialogStyles from '../../../../styles/dialog';
import sortByAttribute from '../../../../utils/sort-by-attribute';

const CreateEditReportDialog = ({
    classes,
    isDialogOpen,
    handleCloseDialog,
    reportDetails,
}) => {
    const [beginButtonEnabled, setBeginButtonEnabled] = useState(false);
    const [testDate, setTestDate] = useState(null);

    const dispatch = useDispatch();
    const history = useHistory();

    const testSites = sortByAttribute(useSelector(getActiveSites), 'siteName');

    const isEditing = !!(reportDetails && reportDetails.id);

    const {
        handleSubmit,
        watch,
        errors,
        control,
        register,
        reset,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            testSite: null,
            testNumber: null,
            testCoordinator: null,
            dateOfVisitType: null,
        },
    });
    const testSite = watch('testSite');
    const testCoordinator = watch('testCoordinator');
    const dateOfVisitType = watch('dateOfVisitType');
    const testNumber = watch('testNumber');

    useEffect(() => {
        reset({
            testSite: isEditing ? reportDetails.testSite.id : null,
            testNumber: isEditing ? reportDetails.testNumber : null,
            testCoordinator: isEditing ? reportDetails.testCoordinator : null,
            dateOfVisitType: isEditing ? 'selectDate' : null,
        });
    }, [isEditing, reportDetails, reset]);

    useEffect(() => {
        if (dateOfVisitType === 'today') {
            setTestDate(format(new Date(), 'MM/dd/yyyy'));
        } else if (isEditing) {
            setTestDate(getFormattedDate(reportDetails.testDate));
        } else {
            setTestDate(null);
        }
    }, [dateOfVisitType, setTestDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (testSite && testDate && testCoordinator && testNumber) setBeginButtonEnabled(true);
        else setBeginButtonEnabled(false);
    }, [testSite, testDate, testCoordinator, testNumber]);

    const onDateChange = useCallback((date) => {
        if (isValid(date)) {
            setTestDate(format(date, 'MM/dd/yyyy'));
        }
    }, [setTestDate]);

    const onSubmit = useCallback((values) => {
        const reportData = {
            testSite: testSites.find(site => values.testSite === site.id),
            testNumber: values.testNumber === '' ? null : values.testNumber,
            testCoordinator: values.testCoordinator === '' ? null : values.testCoordinator,
            testDate,
        };

        if (isEditing) {
            const onSuccess = () => handleCloseDialog();
            const onError = (error) => {
                // TODO: for now we don't have any requirements to show an error if one occurs, so just log it and close the dialog
                // eslint-disable-next-line no-console
                console.error(error);
                handleCloseDialog();
            };

            // close the dialog whether the report was successfully updated or not
            dispatch(patchReport(reportDetails.id, reportData, onSuccess, onError));
        } else {
            // continue on to the Report Overview whether the report was successfully created or not
            dispatch(createNewReport(reportData, () => history.push('/report'), () => history.push('/report')));
        }
    }, [testDate, testSites, reportDetails, isEditing, handleCloseDialog, dispatch, history]);

    return (
        <Dialog
            classes={{
                paper: classes.dialogContainer,
            }}
            open={isDialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="report-dialog-title"
            fullWidth
        >
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <DialogTitle id="report-dialog-title" className={classes.dialogTitle} disableTypography>
                    <div className="col">
                        <div className="row justify-content-end">
                            <IconButton aria-label="cancel" onClick={handleCloseDialog}>
                                <CloseIcon
                                    classes={{
                                        root: classes.dialogCloseButton,
                                    }}
                                />
                            </IconButton>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <Typography variant="h1" component="h2">{isEditing ? 'Edit' : 'Start New'} Report</Typography>
                            </div>
                        </div>
                    </div>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <DialogContentText className={classes.dialogContentText}>
                        Please enter some basic information to get started
                    </DialogContentText>

                    <div className="row">
                        <div className={`col ${classes.inputWrapper}`}>
                            <TextInput
                                name="testNumber"
                                label="Test Number"
                                errors={errors}
                                inputRef={register()}
                                inputComponent={TextMaskAlphanumeric}
                                inputProps={{
                                    autoComplete: 'off',
                                    maxLength: 10,
                                }}
                                required
                            />
                        </div>
                    </div>

                    <div className="row pb-3">
                        <div className={`col ${classes.inputWrapper}`}>
                            <TextInput
                                name="testCoordinator"
                                label="Test Coordinator"
                                errors={errors}
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                required
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <Select
                                name="testSite"
                                label="Test Site"
                                errors={errors}
                                required
                                control={control}
                                displayEmpty
                            >
                                <MenuItem disabled value="">Select a Test Site</MenuItem>
                                {testSites.map(site => (
                                    <MenuItem
                                        key={site.id}
                                        value={site.id}
                                    >
                                        {site.siteName}, {site.address}, {site.city}, {site.state}  {site.zipcode}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>

                    <div className="row pb-1">
                        <div className={`col ${classes.inputWrapper}`}>
                            <RadioGroup
                                name="dateOfVisitType"
                                label="Test Date"
                                row
                                control={control}
                            >
                                <RadioButton
                                    value="today"
                                    label="Today"
                                    buttonStyle
                                />
                                <RadioButton
                                    value="selectDate"
                                    label="Select date"
                                    buttonStyle
                                />
                            </RadioGroup>
                        </div>
                    </div>

                    { dateOfVisitType === 'selectDate' && (
                        <div className="row pb-1">
                            <div className={`col ${classes.inputWrapper}`}>
                                <DatePicker
                                    name="testDate"
                                    label="Enter date of test"
                                    value={testDate}
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    initialFocusedDate={format(new Date(), 'MM/dd/yyyy')}
                                    onChange={onDateChange}
                                />
                            </div>
                        </div>
                    )}


                    <div className="row pt-3">
                        <div className="col">
                            <Alert severity="info">
                                Please double check this information before proceeding as it cannot be changed later.
                            </Alert>
                        </div>
                    </div>
                </DialogContent>

                <DialogActions className={classes.dialogActions}>
                    <Button
                        className={classes.outlineButton}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.outlineButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        onClick={handleCloseDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={`${classes.primaryButton}`}
                        classes={{
                            label: classes.buttonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.primaryButtonRippleChildPulsate,
                                ripplePulsate: classes.buttonRipplePulsate,
                            },
                        }}
                        type="submit"
                        disabled={!beginButtonEnabled}
                    >
                        {isEditing ? 'Update' : 'Begin'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

CreateEditReportDialog.defaultProps = {
    reportDetails: null,
};

CreateEditReportDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    reportDetails: PropTypes.object,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, DialogStyles);
export default withStyles(combinedStyles)(CreateEditReportDialog);
