import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Styles from './styles';

const Pill = ({
    type,
    classes,
    text,
}) => (
    <div className={`
            ${classes.pill}
            ${classes[type]}
        `}
    >
        <div className={classes.pillIcon} />
        <Typography variant="body2" component="div" className={classes.pillText}>{text}</Typography>
    </div>
);

Pill.propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary', 'italic', 'alarm']).isRequired,
    classes: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
};

export default withStyles(Styles)(Pill);
