import { v4 as uuid } from 'uuid';
import noop from 'lodash/noop';

import WebClient from '../utils/web-client';
import prepareContentType from '../utils/prepare-content-type';
import ReportsTypes from '../action-types/reports';
import { getUserId } from '../selectors/user';
import { getReportAnswersSelector, getReportAttachmentsSelector } from '../selectors/reports';
import { getSections } from '../selectors/current-report';
import { REPORT_STATUS, REQUEST_STATUS } from '../reducers/reports';
import { SECTION_PROGRESS } from '../reducers/current-report/constants';
import getFormattedDate from '../utils/get-formatted-date';
import getFormattedTime from '../utils/get-formatted-time';

import { setCurrentReportData } from './current-report';
import { APIError } from './app';

const getReportsBegin = () => ({
    type: ReportsTypes.GET_REPORTS_BEGIN,
});

const getReportsSuccess = ({ reports, total }) => ({
    type: ReportsTypes.GET_REPORTS_SUCCESS,
    payload: { reports, total },
});

const getReportsError = errorMsg => ({
    type: ReportsTypes.GET_REPORTS_ERROR,
    payload: { errorMsg },
});

export const getReports = possiblePayload => (
    async (dispatch) => {
        dispatch(getReportsBegin);

        let payload = possiblePayload;

        // if no payload is provided then use default values
        // note the test for testers. if we have this parameter,
        // we wont have pageNumber or pageSize because we are pull all data for the tester dashboard
        if (!payload || ((payload?.pageNumber === undefined || payload?.pageSize === undefined) && payload?.testers === undefined)) {
            payload = {
                pageNumber: 0,
                pageSize: 10,
                sortBy: 'testDate',
                sortOrder: 'asc',
            };
        }

        try {
            const { data } = await WebClient.post('/reports/list', payload);

            dispatch(getReportsSuccess(data));
        } catch (error) {
            dispatch(APIError('Error retrieving reports'));
            dispatch(getReportsError(error));
        }
    }
);

export const getReportsForCurrentUser = () => (
    async (dispatch, getState) => {
        const queryParams = {
            testers: [`${getUserId(getState())}`],
        };
        await getReports(queryParams)(dispatch);
    }
);

export const getSubmittedReports = () => (
    async (dispatch) => {
        const queryParams = {
            statuses: [REPORT_STATUS.SUBMITTED],
        };
        await getReports(queryParams)(dispatch);
    }
);

const postCreateReportBegin = (reportTempId, newReport) => ({
    type: ReportsTypes.POST_CREATE_REPORT_BEGIN,
    payload: { reportTempId, newReport },
});

const postCreateReportSuccess = (reportTempId, data) => ({
    type: ReportsTypes.POST_CREATE_REPORT_SUCCESS,
    payload: {
        reportTempId,
        data,
    },
});

const postCreateReportError = (reportTempId, errorMsg) => ({
    type: ReportsTypes.POST_CREATE_REPORT_ERROR,
    payload: {
        reportTempId,
        errorMsg,
    },
});

export const createNewReport = (data, onSuccess = noop, onError = noop) => (
    async (dispatch) => {
        const tempId = uuid();
        const newReport = {
            testSite: data.testSite,
            testNumber: data.testNumber,
            testCoordinator: data.testCoordinator,
            testDate: data.testDate,
        };

        // store the record before syncing it to the backend
        dispatch(postCreateReportBegin(tempId, {
            newReport,
            versions: [{ versionNumber: 1, isActiveVersion: true }],
        }));

        try {
            const payload = {
                siteId: newReport.testSite.id,
                testNumber: newReport.testNumber,
                testCoordinator: newReport.testCoordinator,
                testDate: newReport.testDate,
            };
            const response = await WebClient.post('/reports', payload);
            dispatch(postCreateReportSuccess(tempId, response.data));

            // set up the current report with the new report's details
            dispatch(setCurrentReportData(response.data.id));

            onSuccess();
        } catch (error) {
            dispatch(postCreateReportError(tempId, error));

            // set up the current report with the new report's details
            // use the temp id since we weren't able to POST the new report successfully
            dispatch(setCurrentReportData(tempId));

            onError();
        }
    }
);

const patchReportBegin = reportId => ({
    type: ReportsTypes.PATCH_REPORT_BEGIN,
    payload: { reportId },
});

const patchReportSuccess = (reportId, patchedData) => ({
    type: ReportsTypes.PATCH_REPORT_SUCCESS,
    payload: {
        reportId,
        patchedData,
    },
});

const patchReportError = (reportId, errorMsg, requestStatus = REQUEST_STATUS.ERROR) => ({
    type: ReportsTypes.PATCH_REPORT_ERROR,
    payload: {
        reportId,
        errorMsg,
        requestStatus,
    },
});

export const patchReport = (reportId, patchData, onSuccess, onError) => (
    async (dispatch) => {
        dispatch(patchReportBegin(reportId));

        try {
            const payload = { ...patchData };

            if (payload.testSite) {
                payload.siteId = payload.testSite.id;
                delete payload.testSite;
            }

            await WebClient.patch(`/reports/${reportId}`, payload);

            dispatch(patchReportSuccess(reportId, patchData));

            if (onSuccess) onSuccess();
        } catch (error) {
            dispatch(patchReportError(reportId, error));

            if (onError) onError(error);
        }
    }
);

export const sendReportBackToTester = (reportId, onSuccess, onError) => (
    async (dispatch) => {
        const patchData = {
            status: REPORT_STATUS.REOPENED,
        };
        await patchReport(reportId, patchData, onSuccess, onError)(dispatch);
    }
);

export const uploadOutcome = (reportId, outcomeData, onSuccess, onError) => (
    async (dispatch) => {
        const { outcome, outcomeFile } = outcomeData;

        dispatch(patchReportBegin(reportId));

        try {
            const payload = {
                outcome,
            };

            if (outcomeFile) {
                payload.outcomeFile = outcomeFile;
            }

            const [formData, config] = prepareContentType('form-data', payload);

            const response = await WebClient.patch(`/reports/${reportId}/outcome`, formData, config);

            dispatch(patchReportSuccess(reportId, {
                outcome: response.data.outcome,
                outcomeFile: response.data.outcomeFile,
            }));

            onSuccess();
        } catch (error) {
            dispatch(patchReportError(reportId, error));
            onError(error);
        }
    }
);

const deleteReportSuccess = reportId => ({
    type: ReportsTypes.DELETE_REPORT_SUCCESS,
    payload: { reportId },
});

export const deleteReport = (id, onComplete) => (
    async (dispatch) => {
        try {
            await WebClient.delete(`/reports/${id}`);

            dispatch(deleteReportSuccess(id));
        } catch (error) {
            // for now we're not doing anything if there's an error
            dispatch(APIError('Error deleting report'));
        }

        onComplete();
    }
);

const uploadAttachmentsBegin = () => ({
    type: ReportsTypes.UPLOAD_ATTACHMENTS_BEGIN,
});
const uploadAttachmentsError = () => ({
    type: ReportsTypes.UPLOAD_ATTACHMENTS_ERROR,
});

const uploadAttachmentsSuccess = (reportId, attachments) => {
    // attachments is an array of File objects which can't be serialized to/from localStorage
    // so instead we just store the file names
    const fileNames = attachments.map(attachment => ({ name: attachment.name }));
    return ({
        type: ReportsTypes.UPLOAD_ATTACHMENTS_SUCCESS,
        payload: {
            reportId,
            attachments: fileNames,
        },
    });
};

export const uploadReportAttachments = (reportId, attachments) => (
    async (dispatch, getState) => {
        try {
            dispatch(uploadAttachmentsBegin());
            const previousAttachments = getReportAttachmentsSelector(getState())(reportId);
            let newAttachments = attachments;
            let removedAttachments = [];

            // if attachments have been uploaded previously determine which ones
            // are being added and which ones have been removed
            if (previousAttachments && previousAttachments.length) {
                newAttachments = attachments.filter(
                    attachment => !previousAttachments.some(
                        prevAttachment => prevAttachment.name === attachment.name,
                    ),
                );
                removedAttachments = previousAttachments.filter(
                    prevAttachment => !attachments.some(
                        attachment => attachment.name === prevAttachment.name,
                    ),
                );
            }

            // upload any new Attachments
            if (newAttachments.length) {
                const uploadPayload = {
                    attachment: newAttachments,
                };
                const [formData, config] = prepareContentType('form-data', uploadPayload);

                await WebClient.post(`/reports/${reportId}/attachments`, formData, config);
            }

            // delete any removed Attachments
            if (removedAttachments.length) {
                const deletePayload = {
                    attachments: removedAttachments.map(file => file.name),
                };
                await WebClient.delete(`/reports/${reportId}/attachments`, { data: deletePayload });
            }
            dispatch(uploadAttachmentsSuccess(reportId, attachments));
        } catch (error) {
            // no requirements for error handling so do nothing for now
            dispatch(uploadAttachmentsError());
            dispatch(APIError('Error uploading attachments'));
        }
    }
);

const saveReportAnswers = (reportId, reportAnswers) => ({
    type: ReportsTypes.SAVE_REPORT_ANSWERS,
    payload: {
        reportId,
        reportAnswers,
    },
});

const patchReportAnswersBegin = reportId => ({
    type: ReportsTypes.PATCH_REPORT_ANSWERS_BEGIN,
    payload: { reportId },
});

const patchReportAnswersSuccess = reportId => ({
    type: ReportsTypes.PATCH_REPORT_ANSWERS_SUCCESS,
    payload: { reportId },
});

const patchReportAnswersError = (reportId, errorMsg, requestStatus = REQUEST_STATUS.ERROR) => ({
    type: ReportsTypes.PATCH_REPORT_ANSWERS_ERROR,
    payload: {
        reportId,
        errorMsg,
        requestStatus,
    },
});

const prepareReportAnswers = (questions, answers) => {
    const preparedAnswers = answers;

    Object.values(questions).forEach((question) => {
        const { conditional } = question.validation;

        // clear out any conditional values that are not required based on their condition
        if (conditional) {
            let clearFormValue = false;
            const conditionalQuestion = questions[conditional.on];
            const conditionalValue = preparedAnswers[conditionalQuestion.id];

            if (typeof conditional.value !== 'undefined') {
                clearFormValue = (conditionalValue !== conditional.value);
            } else if (typeof conditional.min !== 'undefined') {
                clearFormValue = (!conditionalValue || conditionalValue.length < conditional.min);
            }

            if (clearFormValue) {
                preparedAnswers[question.id] = null;
            }
        }

        // perform any further processing/serializing needed for API
        if (preparedAnswers[question.id] !== null) {
            // answers are expected to be strings, so convert arrays and numbers to strings
            if (Array.isArray(preparedAnswers[question.id])) {
                preparedAnswers[question.id] = preparedAnswers[question.id].join();
            }
            if (typeof preparedAnswers[question.id] === 'number') {
                preparedAnswers[question.id] = preparedAnswers[question.id].toString();
            }

            // the API doesn't want empty strings so replace with null
            if (preparedAnswers[question.id] === '') {
                preparedAnswers[question.id] = null;
            }
        }
    });

    return preparedAnswers;
};

const saveReportSectionData = (reportId, questions, answers, onComplete, onError) => (
    async (dispatch) => {
        // store the answers and then sync them to the backend
        dispatch(saveReportAnswers(reportId, answers));
        dispatch(patchReportAnswersBegin(reportId));

        try {
            const payload = {
                answers: prepareReportAnswers(questions, answers),
            };
            // TODO: add handling for when the report has not actually been created in
            // the backend yet and we're still using a tempId for offline support
            await WebClient.patch(`/reports/${reportId}/answers`, payload);
            dispatch(patchReportAnswersSuccess(reportId));
            onComplete();
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(patchReportAnswersError(reportId, { message: 'Unable to save your changes. Your session may have expired, if the problem persists please log out and back in again.' }, REQUEST_STATUS.SESSION_EXPIRED));
                // short circuit here so that we don't imply that the request was successful
                return;
            }
            dispatch(APIError('Error saving report answers'));
            dispatch(patchReportAnswersError(reportId, error));
            if (onError) onError(); // redirect the user back to the reports page
        }
    }
);

const setSectionToInProgress = (reportId, sectionId) => ({
    type: ReportsTypes.UPDATE_SECTION_PROGRESS,
    payload: {
        reportId,
        sectionId,
        progress: SECTION_PROGRESS.IN_PROGRESS,
    },
});

export const setSectionToCompleted = (reportId, sectionId) => ({
    type: ReportsTypes.UPDATE_SECTION_PROGRESS,
    payload: {
        reportId,
        sectionId,
        progress: SECTION_PROGRESS.COMPLETED,
    },
});

export const setSectionToFlagged = (reportId, sectionId) => ({
    type: ReportsTypes.UPDATE_SECTION_PROGRESS,
    payload: {
        reportId,
        sectionId,
        progress: SECTION_PROGRESS.FLAGGED,
    },
});

export const saveReportSectionDraft = (reportId, sectionId, questions, answers, onComplete) => (
    async (dispatch) => {
        const handleComplete = () => {
            dispatch(setSectionToInProgress(reportId, sectionId));
            onComplete();
        };

        const onError = onComplete(); // this naming is terrible, but the onComplete coming from the source is a redirect, which we still want on failure

        await saveReportSectionData(reportId, questions, answers, handleComplete, onError)(dispatch);
    }
);

export const completeReportSection = (reportId, sectionId, questions, answers, onComplete) => (
    async (dispatch) => {
        const handleComplete = () => {
            dispatch(setSectionToCompleted(reportId, sectionId));
            onComplete();
        };

        const onError = onComplete(); // this naming is terrible, but the onComplete coming from the source is a redirect, which we still want on failure

        await saveReportSectionData(reportId, questions, answers, handleComplete, onError)(dispatch);
    }
);

const prepareReport = (sections, answers) => {
    const preparedReport = [];

    // build array of sections with sectionAnswers array of objects
    // keyed by question with the answer as the value
    sections.forEach((section) => {
        const sectionAnswers = [];

        Object.values(section.questions).forEach((question) => {
            let answer = answers[question.id];
            const { column, options } = question;
            const { type } = question.validation;

            if (answer) {
                // format datetime values as appropriate (date or time respectively)
                if (type === 'date') {
                    answer = getFormattedDate(answer);
                } else if (type === 'time') {
                    answer = getFormattedTime(answer);
                } else if (!Number.isNaN(answer)) {
                    answer = `${answer}`;
                } else if (type === 'array') {
                    if (typeof answer === 'string') {
                        answer = answer.split(',');
                    }

                    if (options.length > 1) {
                        // replace checkbox id's with their names
                        answer = answer.map(value => options.find(option => option.id === value).name);
                        answer = answer.join(', ');
                    } else {
                        // when the checkbox is a single checkbox the answer should instead be Yes/No
                        answer = 'Yes';
                    }
                }
            } else if (type === 'array') {
                if (options.length > 1) {
                    answer = 'None';
                } else {
                    answer = 'No';
                }
            } else {
                // replace null and undefined values with 'N/A'
                // (these should only be conditional questions where the condition was not met)
                answer = 'N/A';
            }

            sectionAnswers.push({ question: column, answer, questionId: question.id });
        });

        preparedReport.push({
            section: section.title,
            sectionAnswers,
        });
    });

    return preparedReport;
};

export const submitReport = (reportId, onSuccess, onError) => (
    async (dispatch, getState) => {
        const sections = getSections(getState());
        const getReportAnswers = getReportAnswersSelector(getState());
        const reportAnswers = getReportAnswers(reportId);

        const reportData = prepareReport(sections, reportAnswers);

        dispatch(patchReportBegin(reportId));

        try {
            await WebClient.post(`/reports/${reportId}`, { answers: reportData });

            dispatch(patchReportSuccess(reportId, {
                status: REPORT_STATUS.SUBMITTED,
                submittedDate: new Date(),
            }));

            onSuccess();
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(patchReportError(reportId, { message: 'Unable to save your changes. Your session may have expired, if the problem persists please log out and back in again.' }, REQUEST_STATUS.SESSION_EXPIRED));
            } else {
                dispatch(patchReportError(reportId, error));
            }
            onError(error);
        }
    }
);

export const patchReportVersionSuccess = (reportId, versionId, payload) => ({
    type: ReportsTypes.PATCH_REPORT_VERSION_SUCCESS,
    payload: {
        reportId,
        versionId,
        ...payload,
    },
});

export const createReportVersionSuccess = (reportId, payload) => ({
    type: ReportsTypes.CREATE_REPORT_VERSION_SUCCESS,
    payload: {
        reportId,
        ...payload,
    },
});

export const submitReopenRequest = (reportId, payload, onSuccess, onError) => async (dispatch) => {
    try {
        const newVersion = await WebClient.post(`/reports/${reportId}/versions`, payload);
        const { currentVersionId, ...currentVersion } = payload;
        dispatch(patchReportVersionSuccess(
            reportId,
            currentVersionId,
            currentVersion,
        ));
        dispatch(createReportVersionSuccess(reportId, newVersion));
        dispatch(patchReportSuccess(reportId, {
            status: REPORT_STATUS.REOPENED,
        }));
        onSuccess(newVersion);
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(patchReportError(reportId, { message: 'Unable to save your changes. Your session may have expired, if the problem persists please log out and back in again.' }, REQUEST_STATUS.SESSION_EXPIRED));
        } else {
            dispatch(patchReportError(reportId, error));
        }
        onError(error);
    }
};

export const patchReportVersion = (reportId, versionId, payload, onSuccess, onError = noop) => async (dispatch) => {
    try {
        const { comment, flaggedAnswers } = payload;
        await WebClient.patch(`/reportVersions/${versionId}`, {
            comment,
            flaggedAnswers,
        });
        dispatch(patchReportVersionSuccess(reportId, versionId, { comment, flaggedAnswers }));
        onSuccess();
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(patchReportError(reportId, { message: 'Unable to save your changes. Your session may have expired, if the problem persists please log out and back in again.' }, REQUEST_STATUS.SESSION_EXPIRED));
        } else {
            dispatch(patchReportError(reportId, error));
        }
        onError(error);
    }
};

export const downloadReport = (reportId, onSuccess = noop, onError = noop) => async () => {
    try {
        const { data } = await WebClient.get(`reports/${reportId}/report`);
        const { url } = data;
        onSuccess(url);
    } catch (error) {
        onError(error);
    }
};
