import React from 'react';
import PropTypes from 'prop-types';
import {
    Switch,
    Route,
    Redirect,
    useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import TesterDashboard from '../../routes/dashboard/tester-dashboard';
import AdminDashboard from '../../routes/dashboard/admin-dashboard';
import ManageTesters from '../../routes/dashboard/manage-testers';
import InviteEditTester from '../../routes/dashboard/invite-edit-tester';
import TrainingVideos from '../../routes/dashboard/training-videos';
import ManageSites from '../../routes/dashboard/manage-sites';
import ManageUsers from '../../routes/dashboard/manage-users';
import CreateEditSite from '../../routes/dashboard/create-edit-site';
import ReportHistory from '../../routes/dashboard/report-history';
import { getRole } from '../../selectors/auth';

import DesktopNav from './components/desktop-nav';
import MobileNav from './components/mobile-nav';
import { ADMINISTRATIVE_ROLES, USER_ROLES } from '../../utils/constants';
import InviteEditUser from '../../routes/dashboard/invite-edit-user';

const MainContents = ({ location, role }) => {
    // Admin routes
    if (ADMINISTRATIVE_ROLES.includes(role)) {
        return (
            <Switch location={location}>
                <Route exact path="/dashboard">
                    <Redirect to="/dashboard/reports" />
                </Route>
                <Route exact path="/dashboard/reports/:reportId">
                    <ReportHistory />
                </Route>
                <Route exact path="/dashboard/reports">
                    <AdminDashboard />
                </Route>
                <Route exact path="/dashboard/testers">
                    <ManageTesters role={role} />
                </Route>
                <Route exact path="/dashboard/tester/:testerId?">
                    <InviteEditTester />
                </Route>
                <Route exact path="/dashboard/sites">
                    <ManageSites role={role} />
                </Route>
                <Route exact path="/dashboard/sites/:siteId">
                    <CreateEditSite />
                </Route>
                { role === USER_ROLES.admin.value && (
                    <>
                        <Route exact path="/dashboard/user/:userId?">
                            <InviteEditUser />
                        </Route>
                        <Route exact path="/dashboard/users">
                            <ManageUsers />
                        </Route>
                    </>
                )}
                {/* Anything else redirect to reports */}
                <Route path="*">
                    <Redirect to="/dashboard/reports" />
                </Route>
            </Switch>
        );
    }

    // Tester routes
    return (
        <Switch location={location}>
            <Route exact path="/dashboard">
                <Redirect to="/dashboard/reports" />
            </Route>
            <Route exact path="/dashboard/reports">
                <TesterDashboard />
            </Route>
            <Route exact path="/dashboard/training">
                <TrainingVideos />
            </Route>
            {/* Anything else redirect to reports */}
            <Route path="*">
                <Redirect to="/dashboard/reports" />
            </Route>
        </Switch>
    );
};

MainContents.propTypes = {
    location: PropTypes.object.isRequired,
    role: PropTypes.oneOf(Object.values(USER_ROLES).map(role => role.value)).isRequired,
};


const DashboardLayout = () => {
    const location = useLocation();
    const role = useSelector(getRole);

    return (
        <div className="container-fluid p-0">

            {/* Mobile */}
            <div className="row d-flex d-md-none no-gutters">
                <header className="col">
                    <MobileNav role={role} />
                </header>
                <div className="row no-gutters" style={{ maxWidth: '100%' }}>
                    <main className="col">
                        <MainContents location={location} role={role} />
                    </main>
                </div>
            </div>

            {/* Desktop */}
            <div className="row d-none d-md-flex" style={{ maxWidth: '100%' }}>
                <header>
                    <nav className="col">
                        <DesktopNav role={role} />
                    </nav>
                </header>
                <main className="col" style={{ paddingLeft: 220 }}>
                    <MainContents location={location} role={role} />
                </main>
            </div>

        </div>
    );
};

export default DashboardLayout;
