import Colors from '../../styles/colors';

const styles = () => ({
    root: {
        width: '100%',
    },
    filledSuccess: {
        color: Colors.darkGrey,
        backgroundColor: Colors.electricBlue,
    },
    filledError: {
        color: Colors.darkGrey,
        backgroundColor: Colors.error,
    },
    filledInfo: {
        color: Colors.darkGrey,
        backgroundColor: Colors.info,
    },
});

export default styles;
