import Colors from '../../styles/colors';

const styles = () => ({
    badgeWrapper: {
        width: '100%',
        paddingTop: 15,
        paddingBottom: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    badgeIcon: {
        display: 'inline',
        marginRight: 10,
        marginLeft: 10,
    },
    badgeSuccess: {
        backgroundColor: Colors.electricBlue,
    },
    badgeWarn: {
        backgroundColor: Colors.pastelRed,
    },
    badgeText: {
        display: 'inline',
    },
});

export default styles;
