import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';

import MultiSelect, { StyledInput, StyledMenuItem } from '../../../../components/multi-select';

const STATUSES = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' },
    { label: 'Invited', value: 'INVITED' },
];
const GENDERS = [
    'Female',
    'Male',
    'Non-binary',
    'Trans',
];
const AGES = [
    '18 - 24',
    '25 - 29',
    '30 - 34',
    '35 - 39',
    '40 - 44',
    '45 - 49',
    '50 - 54',
    '55 - 59',
    '60 - 64',
    '65 - 69',
    '70 - 74',
    '75 - 79',
    '80 - 84',
    '85 - 89',
    '90 - 94',
    '95 - 99',
];

const TesterFilter = ({ onChange, classes }) => {
    const [statusFilterValues, setStatusFilterValues] = useState([]);
    const [genderFilterValues, setGenderFilterValues] = useState([]);
    const [ageFilterValues, setAgeFilterValues] = useState([]);
    const [disabilityFilterValue, setDisabilityFilterValue] = useState('');

    useEffect(() => {
        onChange({
            statuses: statusFilterValues,
            genders: genderFilterValues,
            ages: ageFilterValues,
            disability: disabilityFilterValue,
        });
    }, [statusFilterValues, genderFilterValues, ageFilterValues, disabilityFilterValue]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="pr-3 pb-4">
                <MultiSelect
                    label="Filter testers by status values"
                    defaultValue="All Statuses"
                    options={STATUSES}
                    onChange={setStatusFilterValues}
                />
            </div>
            <div className="pr-3 pb-4">
                <MultiSelect
                    label="Filter testers by genders"
                    defaultValue="All Genders"
                    options={GENDERS}
                    onChange={setGenderFilterValues}
                />
            </div>
            <div className="pr-3 pb-4">
                <MultiSelect
                    label="Filter testers by ages"
                    defaultValue="All Ages"
                    options={AGES}
                    onChange={setAgeFilterValues}
                />
            </div>
            <div className="pr-3 pb-4">
                <Select
                    label="Filter testers by disability"
                    defaultValue=""
                    onChange={event => setDisabilityFilterValue(event.target.value)}
                    value={disabilityFilterValue}
                    renderValue={value => value || 'Disability (Yes or No)'}
                    displayEmpty
                    variant="outlined"
                    classes={{
                        outlined: classes.selectOutlined,
                        iconOutlined: classes.selectIcon,
                        iconOpen: classes.selectIconOpen,
                    }}
                    input={<StyledInput />}
                >
                    <StyledMenuItem value="">
                        Disability (Yes or No)
                    </StyledMenuItem>
                    <StyledMenuItem value="Yes">
                        Yes
                    </StyledMenuItem>
                    <StyledMenuItem value="No">
                        No
                    </StyledMenuItem>
                </Select>
            </div>
        </>
    );
};

TesterFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

export default TesterFilter;
