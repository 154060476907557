import React from 'react';
import {
    Switch,
    Route,
    Redirect,
    useLocation,
} from 'react-router-dom';

import Login from '../../routes/login';
import ForgotPassword from '../../routes/login/forgot-password';
import ResetPassword from '../../routes/login/reset-password';
import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme-public';

const PublicLayout = () => {
    const location = useLocation();

    return (
        <main>
            <Switch location={location}>
                <Route exact path="/login">
                    <Login />
                </Route>
                <Route exact path="/login/forgot-password">
                    <ForgotPassword />
                </Route>
                <Route path="/set-password/:resetToken">
                    <ResetPassword initialSetup />
                </Route>
                <Route path="/reset-password/:resetToken">
                    <ResetPassword />
                </Route>
                {/* Anything else redirect to login */}
                <Route path="*">
                    <Redirect to="/login" />
                </Route>
            </Switch>
        </main>
    );
};

export default withRoot(PublicLayout, themer);
