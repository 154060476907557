import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ActionMenu from '../../../../components/action-menu';
import { USER_ROLES } from '../../../../utils/constants';
import { UserRolesPropType } from '../../../../utils/prop-types';

const SiteActionMenu = ({
    site,
    onDeleteSite,
    role,
}) => {
    const history = useHistory();

    const handleEditSite = useCallback(() => {
        history.push(`/dashboard/sites/${site.id}`);
    }, [site]); // eslint-disable-line react-hooks/exhaustive-deps

    const actions = [
        { label: 'Edit', onClick: handleEditSite },
    ];

    if (role === USER_ROLES.admin.value) {
        actions.push({ label: 'Delete', onClick: () => onDeleteSite(site) });
    }

    return (
        <ActionMenu
            id={`${site.id}-actions`}
            defaultAction={{
                label: 'Edit',
                onClick: handleEditSite,
            }}
            actions={actions}
        />
    );
};

SiteActionMenu.propTypes = {
    site: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
    onDeleteSite: PropTypes.func,
    role: UserRolesPropType.isRequired,
};

SiteActionMenu.defaultProps = {
    onDeleteSite: () => {},
};

export default SiteActionMenu;
