import KeyMirror from 'keymirror';

export default KeyMirror({
    GET_SITES_FOR_FILTERS_BEGIN: true,
    GET_SITES_FOR_FILTERS_SUCCESS: true,
    GET_SITES_FOR_FILTERS_ERROR: true,
    GET_SITES_BEGIN: true,
    GET_SITES_SUCCESS: true,
    GET_SITES_ERROR: true,
    DELETE_SITES_SUCCESS: true,
    UPDATE_SITES_SUCCESS: true,
    CREATE_SITES_SUCCESS: true,
});
