import WebClient from '../utils/web-client';
import { isAuthenticated, getToken } from '../selectors/auth';

const internals = {
    lastToken: null,
};

export default (store) => {
    internals.maintainAuthToken(store);

    return store.subscribe(() => internals.maintainAuthToken(store));
};

internals.maintainAuthToken = (store) => {
    const newToken = (isAuthenticated(store.getState()) && getToken(store.getState())) || null;

    if (newToken === internals.lastToken) {
        return;
    }

    WebClient.updateAuth(newToken);

    internals.lastToken = newToken;
};
