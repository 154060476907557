import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';

import { getSections, getCurrentReportProgress } from '../../selectors/current-report';
import { SECTION_PROGRESS } from '../../reducers/current-report/constants';
import { patchReportVersionSuccess, submitReopenRequest, submitReport } from '../../actions/reports';

import ReportBlockButton from './components/report-block';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import InputStyles from '../../styles/inputs';
import Styles from './styles';
import ReportError from '../../layouts/report/components/report-error';
import SummaryHeader from './components/summary-header';
import { getRole } from '../../selectors/auth';
import { USER_ROLES } from '../../utils/constants';

const ReportOverview = ({ classes, report, currentVersion }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const sections = useSelector(getSections);
    const currentReportProgress = useSelector(getCurrentReportProgress);
    const role = useSelector(getRole);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { id: reportId } = report;

    const isReportComplete = currentReportProgress === SECTION_PROGRESS.COMPLETED;

    const onComplete = () => {
        setIsSubmitting(false);
        history.push('/dashboard/reports');
    };

    const handleTesterSubmit = () => {
        setIsSubmitting(true);

        const onError = (error) => {
            if (!error.response || error.response.status !== 401) {
                onComplete();
            }
            setIsSubmitting(false);
        };
        dispatch(submitReport(reportId, onComplete, onError));
    };

    const handleReopenSubmit = () => {
        const payload = {
            comment: currentVersion.comment,
            flaggedAnswers: currentVersion.flaggedAnswers,
            currentVersionId: currentVersion.id,
        };
        dispatch(submitReopenRequest(reportId, payload, onComplete, () => setIsSubmitting(false)));
    };

    const handleTesterCommentChange = (event) => {
        const { value } = event.target;
        dispatch(patchReportVersionSuccess(reportId, currentVersion.id, { comment: value }));
    };

    return (
        <>
            <div className="container pt-2" style={{ maxWidth: 810 }}>
                <SummaryHeader report={report} classes={classes} isTester={role === USER_ROLES.tester.value} />
                <div className="row pb-2">
                    <div className="col-6 col-md-4"><ReportBlockButton section={sections[0]} /></div>
                    <div className="col-6 col-md-4"><ReportBlockButton section={sections[1]} /></div>
                    <div className="col-6 col-md-4"><ReportBlockButton section={sections[2]} /></div>
                    <div className="col-6 col-md-4"><ReportBlockButton section={sections[3]} /></div>
                    <div className="col-6 col-md-4"><ReportBlockButton section={sections[4]} /></div>
                    <div className="col-6 col-md-4"><ReportBlockButton section={sections[5]} /></div>
                </div>
                {role !== USER_ROLES.tester.value && (
                    <div className="row pt-4">
                        <div className="col">
                            <FormLabel className={classes.inputLabel} style={{ display: 'block' }}>Message to Tester (optional)</FormLabel>
                            <textarea
                                onChange={handleTesterCommentChange}
                                className={classes.testerComment}
                                rows={5}
                            />
                        </div>
                    </div>
                )}
                <div className="row pt-4">
                    <div className="col">
                        {role === USER_ROLES.tester.value ? (
                            <Button
                                className={`${classes.primaryButton} ${classes.fullWidth} ${!isReportComplete && classes.primaryButtonDisabled}`}
                                classes={{
                                    label: classes.buttonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.primaryButtonRippleChildPulsate,
                                        ripplePulsate: classes.buttonRipplePulsate,
                                    },
                                }}
                                disabled={!isReportComplete || isSubmitting}
                                onClick={handleTesterSubmit}
                            >
                                { isSubmitting ? (
                                    <CircularProgress size={24} />
                                ) : 'Submit Report' }
                            </Button>
                        ) : (
                            <Button
                                className={`${classes.primaryButton} ${classes.fullWidth}`}
                                classes={{
                                    label: classes.buttonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.primaryButtonRippleChildPulsate,
                                        ripplePulsate: classes.buttonRipplePulsate,
                                    },
                                }}
                                onClick={handleReopenSubmit}
                            >
                                { isSubmitting ? (
                                    <CircularProgress size={24} />
                                ) : 'Submit Re-Answer Request' }
                            </Button>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <ReportError currentReport={report} />
                    </div>
                </div>
            </div>
        </>
    );
};

ReportOverview.propTypes = {
    classes: PropTypes.object.isRequired,
    report: PropTypes.object.isRequired,
    currentVersion: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(combinedStyles)(ReportOverview);
