/**
 * this utility is designed to make empty strings
 * from the values of a react hook form null, since text inputs are
 * submitted as '' by default.
 */
export default (object) => {
    const result = {};
    Object.keys(object).forEach((key) => {
        result[key] = object[key] === '' ? null : object[key];
    });
    return result;
};
