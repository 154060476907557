import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popper from '@material-ui/core/Popper';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

import Styles from './styles';

const Tooltip = ({ anchorEl, children, classes }) => {
    const [arrowRef, setArrowRef] = useState(null);

    return (
        <Popper
            className={classes.popper}
            open={!!anchorEl}
            anchorEl={anchorEl}
            modifiers={[
                {
                    name: 'arrow',
                    enabled: false,
                    options: {
                        element: arrowRef,
                    },
                },
            ]}
        >
            <span className="arrow" ref={setArrowRef} />
            <Box className={classes.popperBody}>
                {children}
            </Box>
        </Popper>
    );
};

Tooltip.propTypes = {
    anchorEl: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node, PropTypes.object]).isRequired,
    classes: PropTypes.object.isRequired,
};

Tooltip.defaultProps = {
    anchorEl: null,
};

export default withStyles(Styles)(Tooltip);
