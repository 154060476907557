import Colors from '../../../../styles/colors';

const styles = () => ({
    pill: {
        borderRadius: 20,
        display: 'flex',
        alignItems: 'flex-end',
        paddingLeft: 2,
        paddingRight: 10,
        maxWidth: 'fit-content',
        whiteSpace: 'nowrap',
    },
    pillIcon: {
        '& svg': {
            width: 15,
            height: 15,
        },
    },
    pillText: {
        fontSize: '0.875rem',
        paddingLeft: 5,
    },
    inconclusive: {
        backgroundColor: `rgba(${Colors.darkElectricBlueRGB}, 0.3)`,
        '& $pillIcon': {
            color: Colors.darkElectricBlue,
        },
    },
    negative: {
        backgroundColor: `rgba(${Colors.electricBlueRGB}, 0.3)`,
        '& $pillIcon': {
            color: Colors.darkGrey,
        },
    },
    positive: {
        backgroundColor: `rgba(${Colors.pastelRedRGB}, 0.1)`,
        '& $pillIcon': {
            color: Colors.pastelRed,
        },
    },
    draft: {
        fontStyle: 'italic',
        color: Colors.darkElectricBlue,
        backgroundColor: 'transparent',
    },
    pending: {
        backgroundColor: `rgba(${Colors.infoRGB}, 0.3)`,
        color: Colors.darkGrey,
        fontWeight: 500,
    },
    submitted: {
        backgroundColor: `rgba(${Colors.infoRGB}, 0.3)`,
        color: Colors.darkGrey,
        fontWeight: 500,
    },
    reopened: {
        backgroundColor: `rgba(${Colors.ultramarineBlueRGB}, 0.3)`,
        color: Colors.darkGrey,
        fontWeight: 500,
        '& $pillIcon': {
            color: Colors.ultramarineBlue,
        },
    },
});

export default styles;
