import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { yupResolver } from '@hookform/resolvers';

import TextInput from '../../../components/form/textinput';
import Select from '../../../components/form/select';
import Styles from './styles';
import { USER_ROLES } from '../../../utils/constants';
import { createInternalUser, updateInternalUser } from '../../../actions/user';
import { getInternalUser } from '../../../selectors/user';
import validationSchema from '../../../validation/validation-schema-internal-user';
import CombineStyles from '../../../utils/combine-styles';
import ButtonStyles from '../../../styles/buttons';
import InputStyles from '../../../styles/inputs';
import useSnackbar from '../../../utils/use-snackbar';
import NotificationSnackbar from '../../../components/notification-snackbar';

const InviteEditUser = ({ classes }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { handleOpenSnackbar, snackbarProps } = useSnackbar();
    const { userId } = useParams();
    const getUserById = useSelector(getInternalUser);
    const user = useMemo(() => getUserById(Number(userId)), [getUserById, userId]);
    const isEditing = !!user;

    const {
        control,
        register,
        errors,
        handleSubmit,
        reset,
        watch,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            email: user?.email || '',
            role: user?.role || USER_ROLES.attorney.value,
        },
    });

    const selectedRole = watch('role');

    const onSubmit = (data) => {
        const onError = () => {
            handleOpenSnackbar({ text: 'An unknown issue occurred.  Please try again later.', type: 'error' });
        };

        if (isEditing) {
            const onSuccess = () => {
                history.push('/dashboard/users');
            };
            dispatch(updateInternalUser(userId, data, onSuccess, onError));
        } else {
            const onSuccess = () => {
                reset({
                    firstName: '',
                    lastName: '',
                    email: '',
                    role: USER_ROLES.attorney.value,
                }, { submitCount: true });
                handleOpenSnackbar({ text: 'Your invitation has been sent!', type: 'success' });
            };
            dispatch(createInternalUser(data, onSuccess, onError));
        }
    };

    return (
        <div className="pt-5 pl-5">
            <div className="row">
                <div className="col">
                    <div className={classes.backLink}>
                        <Link to="/dashboard/users">Back to Manage Internal Users</Link>
                    </div>
                    <div>
                        <Typography variant="h1">{isEditing ? 'Edit ' : 'Invite New '} Internal User</Typography>
                    </div>
                    <div className={classes.description}>
                        <Typography variant="body1">
                            All questions are required
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="row align-items-top justify-content-start">
                <div className="col-6 col-xl-4 pt-4 pr-5">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row pb-3">
                            <div className="col">
                                <TextInput
                                    name="firstName"
                                    label="First Name"
                                    errors={errors}
                                    required
                                    inputRef={register()}
                                    inputProps={{
                                        autoComplete: 'off',
                                    }}
                                />
                            </div>
                            <div className="col">
                                <TextInput
                                    name="lastName"
                                    label="Last Name"
                                    errors={errors}
                                    required
                                    inputRef={register()}
                                    inputProps={{
                                        autoComplete: 'off',
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row pb-3">
                            <div className={`col ${classes.inputWrapper}`}>
                                <TextInput
                                    name="email"
                                    label="Email"
                                    errors={errors}
                                    required
                                    inputRef={register()}
                                    inputProps={{
                                        autoComplete: 'off',
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row pb-3">
                            <div className={`col ${classes.inputWrapper}`}>
                                <Select
                                    name="role"
                                    label="Role"
                                    errors={errors}
                                    required
                                    control={control}
                                    displayEmpty
                                >
                                    <MenuItem value={USER_ROLES.attorney.value}>{USER_ROLES.attorney.label}</MenuItem>
                                    <MenuItem value={USER_ROLES.admin.value}>{USER_ROLES.admin.label}</MenuItem>
                                    <MenuItem value={USER_ROLES.testCoordinator.value}>{USER_ROLES.testCoordinator.label}</MenuItem>
                                </Select>
                            </div>
                        </div>

                        {selectedRole && (
                            <Box className="pt-3">
                                <Typography variant="body1" style={{ fontWeight: 700 }}>
                                    {USER_ROLES[selectedRole].pluralLabel} can:
                                </Typography>
                                <ul>
                                    {USER_ROLES[selectedRole].permissions.map(permission => (
                                        <li key={permission}>{permission}</li>
                                    ))}
                                </ul>

                            </Box>
                        )}

                        <div className="row pt-4 pb-5">
                            <div className="col d-flex justify-content-start">
                                <Button
                                    className={classes.primaryButton}
                                    classes={{
                                        label: classes.buttonLabel,
                                    }}
                                    TouchRippleProps={{
                                        classes: {
                                            childPulsate: classes.primaryButtonRippleChildPulsate,
                                            ripplePulsate: classes.buttonRipplePulsate,
                                        },
                                    }}
                                    aria-label={isEditing ? 'Save updates to user' : null}
                                    type="submit"
                                >
                                    {isEditing ? 'Update' : 'Send Invitation'}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <NotificationSnackbar {...snackbarProps} />
        </div>
    );
};

InviteEditUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(combinedStyles)(InviteEditUser);
