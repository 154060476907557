import React, {
    useState,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import { useForm } from 'react-hook-form';

// eslint-disable-next-line import/no-unresolved
import { yupResolver } from '@hookform/resolvers';

import useQuery from '../../utils/use-query';

import { ForgotPass, ForgotPassBegin } from '../../actions/auth';

import Validation from '../../validation/validation-schema-login';
import ScrollToTop from '../../utils/scroll-to-top';
import TextInput from '../../components/form/textinput';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import InputStyles from '../../styles/inputs';
import Styles from './styles';

import StatusBanner from '../../components/feedback-banner';

import Logo from '../../images/logo_on_blue_bg.png';
import LSCLogo from '../../images/LSC Logo-White-Horizontal.png';

const ForgotPassword = ({ classes }) => {
    const forgotpassError = useSelector(state => state.auth.forgotPass.error);
    const forgotpassErrorMsg = useSelector(state => state.auth.forgotPass.errorMsg);
    const forgotpassCompleted = useSelector(state => state.auth.forgotPass.completed);
    const dispatch = useDispatch();

    const history = useHistory();
    const query = useQuery();

    const [errorMsg, setErrorMsg] = useState(null);

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        errors,
    } = useForm({
        resolver: yupResolver(Validation.forgot),
    });

    const encodeQueryParam = x => (
        x.replace(/\s/g, '+')
    );

    useEffect(() => {
        document.title = 'Forgot Password - Legal Services of Eastern Michigan - Fair Housing Center';
        // hydrate email address from query param in URL
        if (query.get('email')) {
            setValue('email', encodeQueryParam(query.get('email')));
        }
        // clear error messages when component loads
        dispatch(ForgotPassBegin());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // Only show the first error message
        if (Object.keys(errors).length) {
            // Relies on Object.keys insertion order property ordering, not ideal
            setErrorMsg(errors[Object.keys(errors)[0]].message);
            ScrollToTop();
        }
    }, [errors]);

    const onSubmit = (data) => {
        setErrorMsg(null);
        dispatch(ForgotPass(data.email));
    };

    return (
        <div className={classes.wrapper}>
            <div role="status" aria-live="polite" style={{ width: '100%', textAlign: 'center' }}>
                {errorMsg && <StatusBanner type="warn" text={errorMsg} />}
                {forgotpassError && <StatusBanner type="warn" text={forgotpassErrorMsg} />}
                {(forgotpassCompleted && !forgotpassError) && (<StatusBanner type="success" text="Success: A reset email has been sent to your account." />)}
            </div>

            <img src={Logo} alt="" className={classes.logo} />
            <img src={LSCLogo} alt="" className={classes.lscLogo} />

            <div className={classes.formWrapper}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.inputWrapper}>
                        <TextInput
                            name="email"
                            label="Email"
                            errors={errors}
                            required
                            inputRef={register()}
                            inputProps={{
                                autoComplete: 'on',
                                autoFocus: true,
                            }}
                            showError={false}
                        />
                    </div>
                    <div className={classes.inputWrapper}>
                        <Button
                            variant="contained"
                            className={classes.primaryButton}
                            classes={{
                                label: classes.buttonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.primaryButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                            type="submit"
                            fullWidth
                        >
                            Submit
                        </Button>
                        <Button
                            style={{ marginTop: 15 }}
                            className={classes.textButton}
                            fullWidth
                            onClick={() => history.push(getValues('email') ? `/login?email=${getValues('email')}` : '/login')}
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

ForgotPassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(InputStyles, ButtonStyles, Styles);
export default withStyles(combinedStyles)(ForgotPassword);
