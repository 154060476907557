import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

import { deleteReport } from '../../../../actions/reports';

import HeaderIcon from './images/delete_report.png';
import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import DialogStyles from '../../../../styles/dialog';

import Styles from './styles';

const DeleteReportDialog = ({
    classes,
    isDialogOpen,
    handleCloseDialog,
    reportDetails,
}) => {
    const {
        id,
        siteName,
        tester,
        testDate,
    } = reportDetails;
    const dispatch = useDispatch();

    const handleDelete = useCallback(() => {
        dispatch(deleteReport(id, handleCloseDialog));
    }, [id, dispatch, handleCloseDialog]);

    return (
        <Dialog
            classes={{
                paper: classes.dialogContainer,
            }}
            open={isDialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="delete-dialog-title"
            fullWidth
        >
            <DialogTitle id="delete-dialog-title" className={classes.dialogTitle} disableTypography>
                <div className="col">
                    <div className="row justify-content-end">
                        <IconButton aria-label="cancel" onClick={handleCloseDialog}>
                            <CloseIcon
                                classes={{
                                    root: classes.dialogCloseButton,
                                }}
                            />
                        </IconButton>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <img src={HeaderIcon} alt="" style={{ width: 55 }} />
                            <Typography variant="h1" component="h2">Are you sure you want to delete this report?</Typography>
                        </div>
                    </div>
                </div>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <DialogContentText className={classes.dialogContentText}>
                    Warning: this cannot be undone.
                </DialogContentText>


                <div className={`row ${classes.detailsWrapper}`}>
                    <div className="col pl-5">
                        <Typography variant="body1" className={classes.detailTitle}>Site</Typography>
                        <Typography variant="body1" className={classes.detailContent}>{siteName}</Typography>
                    </div>
                    <div className="col">
                        <Typography variant="body1" className={classes.detailTitle}>Tester</Typography>
                        <Typography variant="body1" className={classes.detailContent}>{tester}</Typography>
                    </div>
                    <div className="col">
                        <Typography variant="body1" className={classes.detailTitle}>Date of Visit/Phone Test</Typography>
                        <Typography variant="body1" className={classes.detailContent}>{testDate}</Typography>
                    </div>
                </div>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Button
                    className={classes.outlineButton}
                    classes={{
                        label: classes.buttonLabel,
                    }}
                    TouchRippleProps={{
                        classes: {
                            childPulsate: classes.outlineButtonRippleChildPulsate,
                            ripplePulsate: classes.buttonRipplePulsate,
                        },
                    }}
                    onClick={handleCloseDialog}
                >
                    Cancel, keep this report
                </Button>
                <Button
                    className={`${classes.deleteButton}`}
                    classes={{
                        label: classes.buttonLabel,
                    }}
                    TouchRippleProps={{
                        classes: {
                            childPulsate: classes.primaryButtonRippleChildPulsate,
                            ripplePulsate: classes.buttonRipplePulsate,
                        },
                    }}
                    onClick={handleDelete}
                >
                    Yes, delete this report
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DeleteReportDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    reportDetails: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, DialogStyles, Styles);
export default withStyles(combinedStyles)(DeleteReportDialog);
