import Colors from '../../../../styles/colors';

const styles = () => ({
    wrapper: {
        backgroundColor: Colors.persianBlue,
        minHeight: '100vh',
        width: 220,
        position: 'fixed',
        zIndex: 999,
    },
    logo: {
        width: 200,
        paddingTop: 15,
        paddingBottom: 15,
    },
    lscLogo: {
        width: 180,
        paddingBottom: 45,
    },
});

export default styles;
