import Colors from '../../../../styles/colors';

const styles = () => ({
    detailsWrapper: {
        background: `rgba(${Colors.pastelRedRGB}, 0.2)`,
        padding: '1rem 0',
        borderRadius: '6px',
    },
    detailTitle: {
        fontSize: '0.8125rem',
        fontWeight: 800,
    },
    detailContent: {
        fontSize: '1.0625rem',
    },
});

export default styles;
