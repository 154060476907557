import React from 'react';

import { ThemeContextProvider } from './theme-context';
import ThemeWrapper from './theme-wrapper';

function withRoot(Component, theme) {
    function WithRoot(props) {
        return (
            <ThemeContextProvider>
                <ThemeWrapper theme={theme}>
                    <Component {...props} />
                </ThemeWrapper>
            </ThemeContextProvider>
        );
    }

    return WithRoot;
}

export default withRoot;
