import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { yupResolver } from '@hookform/resolvers';

import { createNewTester, patchTester } from '../../../actions/testers';
import validationSchema from '../../../validation/validation-schema-tester';
import TextInput from '../../../components/form/textinput';
import TextMaskDate from '../../../components/form/text-mask-date';
import Select from '../../../components/form/select';
import RadioGroup from '../../../components/form/radiogroup';
import RadioButton from '../../../components/form/radiobutton';
import TextArea from '../../../components/form/textarea';
import ConditionalQuestions from '../../../components/form/conditional-questions';
import NotificationSnackbar from '../../../components/notification-snackbar';
import useSnackbar from '../../../utils/use-snackbar';

import states from './states';

import CombineStyles from '../../../utils/combine-styles';
import ButtonStyles from '../../../styles/buttons';
import InputStyles from '../../../styles/inputs';
import Styles from './styles';

const RACES = ['White', 'Black or African American', 'Hispanic', 'American Indian or Alaska Native', 'Asian', 'Native Hawaiian or Other Pacific Islander', 'Other'];
const GENDERS = ['Female', 'Male', 'Non-binary', 'Trans'];
const initialValues = {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    address: null,
    city: null,
    state: null,
    zip: null,
    race: null,
    raceOther: null,
    gender: null,
    hasDisability: null,
    disability: null,
    dob: null,
    comments: null,
};

const InviteEditTesterForm = ({ classes, testerId, tester }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { handleOpenSnackbar, snackbarProps } = useSnackbar();

    const isEditing = testerId !== null && tester !== null;

    const {
        control,
        register,
        errors,
        watch,
        handleSubmit,
        reset,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: !isEditing
            ? initialValues
            : {
                ...tester,
                // convert boolean to string for better handling in RadioGroup
                hasDisability: tester.hasDisability ? 'true' : 'false',
                race: RACES.includes(tester.race) ? tester.race : 'Other',
                raceOther: !RACES.includes(tester.race) ? tester.race : null,
            },
    });

    const raceValue = watch('race');
    const hasDisabilityValue = watch('hasDisability');

    const onSubmitNew = useCallback((values) => {
        const onSuccess = () => {
            reset(initialValues, { submitCount: true });
            handleOpenSnackbar({ text: 'Your invitation has been sent!', type: 'success' });
        };
        const onError = (error) => {
            let text = 'There was a problem inviting the tester.  Please try again later.';

            // 409 Conflict indicates that this is a tester that has already been added
            if (error.response.status === 409) {
                text = 'This tester has already been invited.';
            }

            handleOpenSnackbar({ text, type: 'error' });
        };

        const testerData = { ...values };

        if (values.raceOther) {
            testerData.race = values.raceOther;
        }

        delete testerData.raceOther;

        dispatch(createNewTester(testerData, onSuccess, onError));
    }, [dispatch, reset, handleOpenSnackbar]);

    const onSubmitEdit = useCallback((values) => {
        const onSuccess = () => {
            history.push('/dashboard/testers');
        };

        const onError = () => {
            handleOpenSnackbar({ text: 'There was a problem editing the tester.  Please try again later.', type: 'error' });
        };

        const testerData = { ...values };

        if (values.raceOther) {
            testerData.race = values.raceOther;
        }

        delete testerData.raceOther;

        dispatch(patchTester(testerId, testerData, onSuccess, onError));
    }, [dispatch, history, testerId, handleOpenSnackbar]);

    return (
        <>
            <form onSubmit={handleSubmit(isEditing ? onSubmitEdit : onSubmitNew)} noValidate>
                <div className="row pb-3">
                    <div className="col">
                        <TextInput
                            name="firstName"
                            label="First Name"
                            errors={errors}
                            required
                            inputRef={register()}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </div>
                    <div className="col">
                        <TextInput
                            name="lastName"
                            label="Last Name"
                            errors={errors}
                            required
                            inputRef={register()}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </div>
                </div>

                <div className="row pb-3">
                    <div className={`col ${classes.inputWrapper}`}>
                        <TextInput
                            name="email"
                            label="Email"
                            errors={errors}
                            required
                            inputRef={register()}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </div>
                </div>

                <div className="row pb-3">
                    <div className={`col ${classes.inputWrapper}`}>
                        <TextInput
                            name="phone"
                            label="Phone Number"
                            errors={errors}
                            required
                            inputRef={register()}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </div>
                </div>

                <div className="row pb-3">
                    <div className={`col ${classes.inputWrapper}`}>
                        <TextInput
                            name="address"
                            label="Address"
                            errors={errors}
                            required
                            inputRef={register()}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </div>
                </div>

                <div className="row pb-3">
                    <div className={`col ${classes.inputWrapper}`}>
                        <TextInput
                            name="city"
                            label="City"
                            errors={errors}
                            required
                            inputRef={register()}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </div>
                    <div className={`col-3 ${classes.inputWrapper}`}>
                        <Select
                            name="state"
                            label="State"
                            errors={errors}
                            required
                            control={control}
                            displayEmpty
                        >
                            <MenuItem disabled value=""> - </MenuItem>
                            {states.map(state => (
                                <MenuItem
                                    key={state}
                                    value={state}
                                >
                                    {state}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className={`col-3 ${classes.inputWrapper}`}>
                        <TextInput
                            name="zip"
                            label="Zip Code"
                            errors={errors}
                            required
                            inputRef={register()}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </div>
                </div>

                <div className="row pb-3">
                    <div className={`col ${classes.inputWrapper}`}>
                        <Select
                            name="race"
                            label="Race/Ethnicity"
                            errors={errors}
                            required
                            control={control}
                            displayEmpty
                            style={{ width: '100%' }}
                        >
                            <MenuItem disabled value="">Select</MenuItem>
                            {RACES.map(race => (
                                <MenuItem
                                    key={race}
                                    value={race}
                                >
                                    {race}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>

                <ConditionalQuestions condition={raceValue === 'Other'}>
                    <div className="row pb-3">
                        <div className={`col ${classes.inputWrapper}`}>
                            <TextInput
                                name="raceOther"
                                label="If other, please provide race/ethnicity"
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                            />
                        </div>
                    </div>
                </ConditionalQuestions>

                <div className="row pb-3">
                    <div className={`col ${classes.inputWrapper}`}>
                        <RadioGroup
                            name="gender"
                            label="Gender Identity"
                            errors={errors}
                            required
                            row
                            control={control}
                        >
                            {GENDERS.map(gender => (
                                <RadioButton
                                    key={gender}
                                    value={gender}
                                    label={gender}
                                    buttonStyle
                                />
                            ))}
                        </RadioGroup>
                    </div>
                </div>

                <div className="row pb-3">
                    <div className={`col ${classes.inputWrapper}`}>
                        <RadioGroup
                            name="hasDisability"
                            label="Disability Status"
                            errors={errors}
                            required
                            row
                            control={control}
                        >
                            <RadioButton
                                value="true"
                                label="Yes"
                                buttonStyle
                            />
                            <RadioButton
                                value="false"
                                label="No"
                                buttonStyle
                            />
                        </RadioGroup>
                    </div>
                </div>

                <ConditionalQuestions condition={hasDisabilityValue === 'true'}>
                    <div className="row pb-3">
                        <div className={`col ${classes.inputWrapper}`}>
                            <TextArea
                                name="disability"
                                label="If yes, please describe"
                                errors={errors}
                                required
                                inputRef={register()}
                                rowsMin={3}
                            />
                        </div>
                    </div>
                </ConditionalQuestions>

                <div className="row pb-3">
                    <div className={`col-4 ${classes.inputWrapper}`}>
                        <TextInput
                            name="dob"
                            label="Date of Birth"
                            placeholder="ex. 01/01/2000"
                            errors={errors}
                            required
                            inputRef={register()}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                            inputComponent={TextMaskDate}
                        />
                    </div>
                </div>

                <div className="row pb-3">
                    <div className={`col ${classes.inputWrapper}`}>
                        <TextArea
                            name="comments"
                            label="Comments"
                            errors={errors}
                            inputRef={register()}
                            rowsMin={3}
                        />
                    </div>
                </div>

                <div className="row pt-4 pb-5">
                    <div className="col d-flex justify-content-start">
                        <Button
                            className={`${classes.primaryButton}`}
                            classes={{
                                label: classes.buttonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.primaryButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                            aria-label={isEditing ? 'Save updates to tester' : null}
                            type="submit"
                        >
                            {isEditing ? 'Update' : 'Send Invitation'}
                        </Button>
                    </div>
                </div>
            </form>

            <NotificationSnackbar {...snackbarProps} />
        </>
    );
};

InviteEditTesterForm.defaultProps = {
    testerId: null,
    tester: null,
};

InviteEditTesterForm.propTypes = {
    classes: PropTypes.object.isRequired,
    testerId: PropTypes.string,
    tester: PropTypes.object,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(combinedStyles)(InviteEditTesterForm);
