import React from 'react';

export default () => (
    <svg viewBox="0 0 60 70" width="100%" height="100%">
        <g>
            <path d="M0.254754054,2.21456593 C0.340456243,10.5697308 1.54272473,18.7459945 1.08800703,27.1271484 C0.868979486,31.1501264 0.55473,35.1450604 0.716614622,39.1751703 C0.885642108,43.4082967 1.32845097,47.6228077 0.507112932,51.8179182 C0.171434851,53.5319237 2.81641577,54.2614896 3.15204509,52.5403523 C3.8757782,48.8313571 3.77101212,45.1340879 3.57104847,41.3801868 C3.33535831,36.9699725 3.49724903,32.6472747 3.76388754,28.2418352 C4.29478158,19.4756813 3.08775322,10.9530989 2.99730943,2.21535165 C2.97826382,0.463571429 0.23572673,0.461214286 0.254741865,2.21535165 L0.254754054,2.21456593 Z" id="nar-Fill-1" />
            <path d="M1.66626216,3.12593407 C6.38719608,4.00653846 11.3292419,3.07399231 16.1147176,3.28647363 C22.2495365,3.55798022 28.3989824,3.35258242 34.5344108,3.17314945 C36.2984912,3.12120769 36.303245,0.401451648 34.5344108,0.453369231 C29.1635493,0.609188462 23.7926878,0.746126374 18.4172554,0.63987967 C15.9127427,0.590301099 13.4201754,0.375456593 10.9135905,0.481697253 C8.06625689,0.602104945 5.22611486,1.02943077 2.3933473,0.500584615 C0.669735,0.179499451 -0.0683204054,2.80012857 1.6672373,3.12595824 L1.66626216,3.12593407 Z" id="nar-Fill-2" />
            <path d="M33.9724892,3.17791209 C36.9674339,5.83864286 39.1029189,9.2267033 41.6906838,12.2438462 C42.8381741,13.5824615 44.7689416,11.6488973 43.6309589,10.3196923 C41.0407562,7.30007143 38.9076481,3.91430769 35.9127643,1.25375824 C34.5986478,0.087456044 32.653558,2.00453846 33.9724892,3.17791209 Z" id="nar-Fill-3" />
            <path d="M44.6429069,52.7854939 C44.6214768,38.7471978 43.8572512,24.7185714 44.0334459,10.6784615 C44.0548745,8.92432418 41.3123314,8.92668132 41.2908784,10.6784615 C41.114708,24.7185714 41.8789092,38.7471978 41.9003378,52.7854939 C41.9027208,54.5372741 44.6452823,54.5372741 44.6429069,52.7854939 Z" id="nar-Fill-4" />
            <path d="M1.0568027,53.895163 C15.100577,55.9255696 29.1778716,52.9012345 43.2716216,54.1454191 C45.0333251,54.3012383 45.0190638,51.5790949 43.2716216,51.4256388 C29.4350635,50.2050637 15.5625473,53.2648147 1.78815405,51.2721786 C0.0574110811,51.0219225 -0.687775,53.6425559 1.05729027,53.8951328 L1.0568027,53.895163 Z" id="nar-Fill-5" />
            <path d="M35.9081324,10.4778022 L35.9081324,2.41758242 C35.9081324,0.663445055 33.1655649,0.663445055 33.1655649,2.41758242 L33.1655649,10.4778022 C33.1655649,12.2295824 35.9081324,12.2295824 35.9081324,10.4778022 Z" id="nar-Fill-6" />
            <path d="M34.7391892,11.8376923 L42.2574811,11.8376923 C44.0263153,11.8376923 44.0263153,9.11791209 42.2574811,9.11791209 L34.7391892,9.11791209 C32.970355,9.11791209 32.970355,11.8376923 34.7391892,11.8376923 Z" id="nar-Fill-7" />
            <path d="M8.73233514,23.6306593 C9.16085827,25.9467033 9.13229291,28.3288681 8.98945389,30.6730769 C8.88232311,32.4248571 11.6248785,32.4154286 11.7320215,30.6730769 C11.8915292,28.0736923 11.853438,25.4743077 11.3796808,22.9078022 C11.0582884,21.1843077 8.41569657,21.9162308 8.73231076,23.6302363 L8.73233514,23.6306593 Z" id="nar-Fill-8" />
            <path d="M14.3222973,31.5796703 C14.1675494,28.7111484 14.3008687,25.8355549 14.1699324,22.967033 C14.0889901,21.219967 11.3464286,21.2128956 11.4273649,22.967033 C11.5583011,25.8355549 11.4249819,28.7111484 11.5797297,31.5796703 C11.6725748,33.3243791 14.415118,33.3338077 14.3222973,31.5796703 Z" id="nar-Fill-9" />
            <path d="M13.9486986,23.837967 C16.8840992,22.5087802 19.9385272,21.4038846 22.9522432,20.2587967 C24.8305973,19.5457912 26.716143,18.8517033 28.6135731,18.1976868 C29.2897074,17.9639549 29.9705955,17.7420269 30.6562374,17.5365989 C31.7703903,17.2037099 31.3799705,17.6688104 30.5705352,16.9770615 C31.9037278,18.1150176 33.8511336,16.2002923 32.5108104,15.0529077 C30.7205232,13.5230615 25.7732361,16.3136165 24.0423712,16.9534297 C20.1951584,18.3770835 16.3052834,19.7983198 12.5650306,21.491111 C10.955692,22.2182593 12.3507691,24.5602923 13.9481989,23.8378582 L13.9486986,23.837967 Z" id="nar-Fill-10" />
            <path d="M12.8912865,33.0435165 C19.3192554,34.7173901 25.1091203,38.1714505 31.6321649,39.5407692 C33.3557772,39.9019923 34.0914557,37.2813571 32.3606518,36.9178132 C25.8376072,35.5484945 20.0477423,32.0944341 13.6197734,30.4205604 C11.9080455,29.9743473 11.1819355,32.5973516 12.8912865,33.0435165 Z" id="nar-Fill-11" />
            <path d="M32.8748527,38.613022 C34.5151519,36.0466978 33.6556922,31.7259341 33.5414795,28.871978 C33.3557833,24.2044121 34.5247204,19.4778571 33.1677102,14.9001648 C32.6701414,13.2215769 30.0227775,13.9368791 30.522778,15.6225989 C31.7512045,19.7613187 30.7251368,24.1433681 30.7846566,28.3717198 C30.817988,30.7798736 31.865472,35.1167747 30.5084984,37.2394121 C29.556218,38.7244121 31.9321347,40.0890165 32.8749076,38.6134451 L32.8748527,38.613022 Z" id="nar-Fill-12" />
            <path d="M28.9017865,36.322967 C29.8755199,30.1539011 28.8660783,23.8996154 29.8159757,17.7378022 C30.0802312,16.0237967 27.437682,15.2848022 27.1710435,17.0153681 C26.2187631,23.1868516 27.2305633,29.4429505 26.2568543,35.600533 C25.9854559,37.3145385 28.6280173,38.0511758 28.9017865,36.322967 Z" id="nar-Fill-13" />
            <path d="M32.5225851,22.9313736 C34.2652735,23.8403242 34.0533645,25.0019121 34.0533645,26.7655385 C34.0533645,27.4596264 34.0200331,28.1561319 33.9581364,28.8478626 C33.8890969,29.626989 32.6487737,30.0519396 32.0654601,30.4036978 C30.5584497,31.3150055 31.9345238,33.666467 33.4486283,32.7528022 C34.5723497,32.0728571 36.1507278,31.3858407 36.5173786,29.9763297 C36.7864001,28.9422692 36.7792572,27.8255275 36.7935369,26.765478 C36.8316281,24.0055659 36.5792753,21.9775165 33.9057351,20.582511 C32.3439952,19.767967 30.9584501,22.1147747 32.5225669,22.9316154 L32.5225851,22.9313736 Z" id="nar-Fill-14" />
        </g>
    </svg>
);
