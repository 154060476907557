import * as yup from 'yup';
import { USER_ROLES } from '../utils/constants';

const schema = yup.object().shape({
    firstName: yup.string()
        .required('Please provide First Name'),
    lastName: yup.string()
        .required('Please provide Last Name'),
    email: yup.string()
        .email('Email invalid')
        .required('Please provide Email'),
    role: yup.string().required('Please select Role').oneOf([USER_ROLES.attorney.value, USER_ROLES.admin.value, USER_ROLES.testCoordinator.value]),
});

export default schema;
