import React, { useState, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import { Logout } from '../../../../actions/auth';
import LogoImg from '../../../../images/logo_on_blue_bg_mobile.png';
import LSCLogo from '../../../../images/LSC Logo-White-Horizontal.png';

import CombineStyles from '../../../../utils/combine-styles';
import ButtonStyles from '../../../../styles/buttons';
import Styles from './styles';
import { ADMINISTRATIVE_ROLES, USER_ROLES } from '../../../../utils/constants';
import { UserRolesPropType } from '../../../../utils/prop-types';

const MobileNav = ({ classes, role }) => {
    const dispatch = useDispatch();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const showAdmin = ADMINISTRATIVE_ROLES.includes(role);
    const canEdit = role === USER_ROLES.admin.value || role === USER_ROLES.testCoordinator.value;

    const getLinkComponent = route => (
        forwardRef((props, ref) => <NavLink activeClassName={classes.navButtonActive} exact to={`/dashboard/${route}`} {...ref} {...props} />)
    );

    return (
        <>
            <Drawer
                elevation={0}
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                style={{ top: 60 }}
                classes={{
                    root: classes.hideDrawer,
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    BackdropProps: {
                        classes: {
                            root: classes.drawerModalRoot,
                        },
                    },
                }}
            >
                <nav>
                    { showAdmin ? (
                        <>
                            <Button
                                component={getLinkComponent('reports')}
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                Reports
                            </Button>
                            <Button
                                component={getLinkComponent('testers')}
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                {canEdit ? 'Manage Testers' : 'Testers'}
                            </Button>
                            {role === USER_ROLES.admin.value && (
                                <Button
                                    component={getLinkComponent('users')}
                                    className={classes.navButton}
                                    fullWidth
                                    role="link"
                                >
                                    Manage Internal Users
                                </Button>
                            )}
                        </>
                    ) : (
                        <>
                            <Button
                                component={getLinkComponent('reports')}
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                My Reports
                            </Button>
                            <Button
                                component={getLinkComponent('training')}
                                className={classes.navButton}
                                fullWidth
                                role="link"
                            >
                                Training Videos
                            </Button>
                        </>
                    )}

                    <Button
                        onClick={() => dispatch(Logout())}
                        className={classes.navButton}
                        fullWidth
                        role="link"
                    >
                        Log Out
                    </Button>
                </nav>
            </Drawer>
            <div className={`row no-gutters ${classes.wrapper}`}>
                <div className="col d-flex align-items-center">
                    <h1 className="offscreen">Legal Services of Eastern Michigan</h1>
                    <img className={classes.logo} src={LogoImg} alt="" />
                    <img className={classes.lscLogo} src={LSCLogo} alt="" />
                </div>
                <div className="col-auto">
                    <IconButton
                        color="inherit"
                        aria-label={drawerOpen ? 'Close Navigation' : 'Open Navigation'}
                        onClick={() => setDrawerOpen(!drawerOpen)}
                        className={classes.drawerIcon}
                    >
                        {drawerOpen ? <CloseIcon /> : <MenuIcon />}
                    </IconButton>
                </div>
            </div>
        </>
    );
};

MobileNav.propTypes = {
    classes: PropTypes.object.isRequired,
    role: UserRolesPropType.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(combinedStyles)(MobileNav);
