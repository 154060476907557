const questions = {
    guestCardContactFormFilledOut: {
        id: '500',
        column: 'Was a guest card/contact form filled out?',
        label: 'Was a guest card/contact form filled out?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    byWhom: {
        id: '500a',
        column: 'By whom?',
        label: 'By whom?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'guestCardContactFormFilledOut',
                value: 'Yes',
            },
        },
        options: [
            'Me',
            'Agent',
        ],
    },
    whatItemsRequired: {
        id: '500b',
        column: 'What items were required on the guest card?',
        label: 'What items were required on the guest card?',
        validation: {
            type: 'array',
            required: true,
            conditional: {
                on: 'guestCardContactFormFilledOut',
                value: 'Yes',
            },
        },
        options: [
            { id: '0', name: 'Name' },
            { id: '1', name: 'Address' },
            { id: '2', name: 'Phone number' },
            { id: '3', name: 'Email' },
            { id: '4', name: 'Number of occupants' },
            { id: '5', name: 'Other' },
        ],
    },
    whatItemsRequiredOtherDetails: {
        id: '500b1',
        column: 'Any other items were required on the guest card?',
        label: 'Other',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'whatItemsRequired',
                includes: '5',
            },
        },
    },
    wereYouAskedForDriversLicense: {
        id: '501',
        column: 'Were you asked for your driver\'s license?',
        label: 'Were you asked for your driver\'s license?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    wereYouAskedForDriversLicenseReason: {
        id: '501a',
        column: 'Reason given:',
        label: 'Reason given:',
        validation: {
            type: 'text',
            required: false,
            conditional: {
                on: 'wereYouAskedForDriversLicense',
                value: 'Yes',
            },
        },
    },
    whatDidAgentDoWithIt: {
        id: '501b',
        column: 'What did the agent do with your license?',
        label: 'What did the agent do with your license?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Look at it' },
            { id: '1', name: 'Make a copy of it' },
            { id: '2', name: 'Keep it during the tour' },
            { id: '3', name: 'Use it to fill in the guest card' },
        ],
    },
    didAgentIndicateIneligible: {
        id: '502',
        column: 'Did the agent indicate that you in any way might be ineligible to live at this site? ',
        label: 'Did the agent indicate that you in any way might be ineligible to live at this site? ',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    agentIndicatedIneligibleExplain: {
        id: '502a',
        column: 'If yes, explain:',
        label: 'If yes, explain:',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'didAgentIndicateIneligible',
                value: 'Yes',
            },
        },
    },
    wereAnySpecialsMentioned: {
        id: '504',
        column: 'Were there any specials mentioned?',
        label: 'Were there any specials mentioned?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    wasSpecialOfferedOrAsked: {
        id: '504a',
        column: 'Was it offered or asked?',
        label: 'Was it offered or asked?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'wereAnySpecialsMentioned',
                value: 'Yes',
            },
        },
        options: [
            'Offered',
            'Asked',
        ],
    },
    whatWereTheSpecials: {
        id: '504b',
        column: 'What were they?',
        label: 'What were they?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'wereAnySpecialsMentioned',
                value: 'Yes',
            },
        },
    },
    conclusionOfVisit: {
        id: '505',
        column: 'At the conclusion of the visit, were any arrangements made?',
        label: 'At the conclusion of the visit, please check the appropriate item:',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'The agent offered to call/email/text you',
            'The agent suggested you call/email/text',
            'You offered to call/email/text',
            'No arrangements were made',
        ],
    },
    communicationFormRequested: {
        id: '506',
        column: 'If arrangements were made, what communication form(s) did they request?',
        label: 'If arrangements were made, what communication form(s) did they request?',
        validation: {
            type: 'array',
            required: false,
            conditional: {
                on: 'conclusionOfVisit',
                notValue: 'No arrangements were made',
            },
        },
        options: [
            { id: '0', name: 'Call' },
            { id: '1', name: 'Email' },
            { id: '2', name: 'Text' },
            { id: '3', name: 'Other' },
        ],
    },
    communicationFormRequestedOtherDetails: {
        id: '506a',
        column: 'Any other communication form(s) requested?',
        label: 'Other',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'communicationFormRequested',
                includes: '3',
            },
        },
    },
    // Indicate which items were received; did you have to ask, or were they offered?
    receivedFlyer: {
        id: '508',
        column: 'Was a Flyer received?',
        validation: {
            type: 'array',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Flyer' },
        ],
    },
    receivedFlyerAskedOrOffered: {
        id: '508a',
        column: 'Did you have to ask, or were they offered?',
        label: 'Did you have to ask, or were they offered?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'receivedFlyer',
                min: 1,
            },
        },
        options: [
            'Asked',
            'Offered',
        ],
    },
    receivedNewsletter: {
        id: '509',
        column: 'Was a Newsletter received?',
        validation: {
            type: 'text',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Newsletter' },
        ],
    },
    receivedNewsletterAskedOrOffered: {
        id: '509a',
        column: 'Did you have to ask, or were they offered?',
        label: 'Did you have to ask, or were they offered?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'receivedNewsletter',
                min: 1,
            },
        },
        options: [
            'Asked',
            'Offered',
        ],
    },
    receivedFloorPlans: {
        id: '510',
        column: 'Were Floor Plans received?',
        validation: {
            type: 'text',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Floor Plans' },
        ],
    },
    receivedFloorPlansAskedOrOffered: {
        id: '510a',
        column: 'Did you have to ask, or were they offered?',
        label: 'Did you have to ask, or were they offered?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'receivedFloorPlans',
                min: 1,
            },
        },
        options: [
            'Asked',
            'Offered',
        ],
    },
    receivedBusinessCard: {
        id: '511',
        column: 'Was a Business Card received?',
        validation: {
            type: 'text',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Business Card' },
        ],
    },
    receivedBusinessCardAskedOrOffered: {
        id: '511a',
        column: 'Did you have to ask, or were they offered?',
        label: 'Did you have to ask, or were they offered?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'receivedBusinessCard',
                min: 1,
            },
        },
        options: [
            'Asked',
            'Offered',
        ],
    },
    receivedEventCalendar: {
        id: '512',
        column: 'Was a Community Event Calendar received?',
        validation: {
            type: 'text',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Community Event Calendar' },
        ],
    },
    receivedEventCalendarAskedOrOffered: {
        id: '512a',
        column: 'Did you have to ask, or were they offered?',
        label: 'Did you have to ask, or were they offered?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'receivedEventCalendar',
                min: 1,
            },
        },
        options: [
            'Asked',
            'Offered',
        ],
    },
    receivedApplication: {
        id: '513',
        column: 'Was an Application received?',
        validation: {
            type: 'text',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Application' },
        ],
    },
    receivedApplicationAskedOrOffered: {
        id: '513a',
        column: 'Did you have to ask, or were they offered?',
        label: 'Did you have to ask, or were they offered?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'receivedApplication',
                min: 1,
            },
        },
        options: [
            'Asked',
            'Offered',
        ],
    },
    receivedPolicies: {
        id: '514',
        column: 'Were any Policies received?',
        label: 'Policies',
        validation: {
            type: 'text',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Policies' },
        ],
    },
    receivedPoliciesAskedOrOffered: {
        id: '514a',
        column: 'Did you have to ask, or were they offered?',
        label: 'Did you have to ask, or were they offered?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'receivedPolicies',
                min: 1,
            },
        },
        options: [
            'Asked',
            'Offered',
        ],
    },
    receivedCreditCheckForm: {
        id: '515',
        column: 'Was a Credit Check Form received?',
        validation: {
            type: 'text',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Credit Check Form' },
        ],
    },
    receivedCreditCheckFormAskedOrOffered: {
        id: '515a',
        column: 'Did you have to ask, or were they offered?',
        label: 'Did you have to ask, or were they offered?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'receivedCreditCheckForm',
                min: 1,
            },
        },
        options: [
            'Asked',
            'Offered',
        ],
    },
    receivedCriminalCheckForm: {
        id: '516',
        column: 'Was a Criminal Check Form received?',
        label: 'Criminal Check Form',
        validation: {
            type: 'text',
            required: false,
            conditional: false,
        },
        options: [
            { id: '0', name: 'Criminal Check Form' },
        ],
    },
    receivedCriminalCheckFormAskedOrOffered: {
        id: '516a',
        column: 'Did you have to ask, or were they offered?',
        label: 'Did you have to ask, or were they offered?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'receivedCriminalCheckForm',
                min: 1,
            },
        },
        options: [
            'Asked',
            'Offered',
        ],
    },
};

export default questions;
