import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FormLabel from '@material-ui/core/FormLabel';

import { getRole } from '../../../../../selectors/auth';
import { USER_ROLES } from '../../../../../utils/constants';
import Checkbox from '../../../../../components/form/checkbox';
import { REPORT_STATUS, getFlaggedVersion } from '../../../../../reducers/reports';
import { getCurrentReport } from '../../../../../selectors/current-report';
import { patchReportVersionSuccess } from '../../../../../actions/reports';

const QuestionWrapper = ({
    render,
    questionId,
    classes,
}) => {
    const dispatch = useDispatch();
    const role = useSelector(getRole);
    const report = useSelector(getCurrentReport);
    const flaggedVersion = getFlaggedVersion(report);
    const { flaggedAnswers } = flaggedVersion;
    const reason = flaggedAnswers && flaggedAnswers[questionId]?.reason;
    const isFlagged = flaggedAnswers && !!flaggedAnswers[questionId];

    const handleCheck = (event) => {
        const { checked } = event.target;
        if (checked) {
            dispatch(patchReportVersionSuccess(
                report.id,
                flaggedVersion.id,
                {
                    flaggedAnswers: {
                        ...flaggedAnswers,
                        [questionId]: { reason: '' },
                    },
                },
            ));
        } else {
            const { [questionId]: removed, ...rest } = flaggedAnswers;
            dispatch(patchReportVersionSuccess(
                report.id,
                flaggedVersion.id,
                {
                    flaggedAnswers: rest,
                },
            ));
        }
    };

    const handleReasonChange = (event) => {
        const { value } = event.target;
        dispatch(patchReportVersionSuccess(
            report.id,
            flaggedVersion.id,
            {
                flaggedAnswers: {
                    ...flaggedAnswers,
                    [questionId]: {
                        ...flaggedAnswers[questionId],
                        reason: value,
                    },
                },
            },
        ));
    };

    if (role === USER_ROLES.tester.value) {
        return report.status === REPORT_STATUS.REOPENED ? (
            <div>
                {render({ disabled: !isFlagged })}
                {!!reason && (
                    <div className={`${classes.reanswerRequestReason} mt-2 pt-0`}>
                        <p className={classes.inputLabel}>
                            Reason for re-answer request
                        </p>
                        {reason}
                    </div>
                )}
            </div>
        ) : render({ disabled: false });
    }

    return (
        <div>
            <div className="row">
                <div className="col-auto">
                    <Checkbox
                        name={`flagged-${questionId}`}
                        inputProps={{ 'aria-label': 'Flag question for review' }}
                        value={isFlagged}
                        isChecked={isFlagged}
                        onChange={handleCheck}
                    />
                </div>
                <div className="col">
                    {render({ disabled: true })}
                    {isFlagged && (
                        <div className="pt-2">
                            <FormLabel className={classes.inputLabel}>
                                Reason for re-answer request (required)
                            </FormLabel>
                            <textarea
                                rows={4}
                                className={classes.textAreaBlock}
                                onChange={handleReasonChange}
                                value={flaggedAnswers[questionId]?.reason || ''}
                            />
                            <div className={classes.errorMessage}>
                                {flaggedAnswers[questionId]?.error}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

    );
};

QuestionWrapper.propTypes = {
    render: PropTypes.func.isRequired,
    questionId: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
};

export default QuestionWrapper;
