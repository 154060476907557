export default {
    persianBlue: '#2143AB',
    persianBlueRGB: '48,94,236',
    ultramarineBlue: '#305EEC',
    ultramarineBlueRGB: '48,94,236',
    amber: '#FF0000',
    white: '#FFFFFF',
    whiteRGB: '255,255,255',
    antiflashWhite: '#EDF1F5',
    lavenderGray: '#C1C9D2',
    lavenderGrayRGB: '193,201,210',
    darkElectricBlue: '#5C6E81',
    darkElectricBlueRGB: '92,110,129',
    darkGrey: '#191919',
    electricBlue: '#6AFDEE',
    electricBlueRGB: '106,253,238',
    pastelRed: '#FF5a66',
    pastelRedRGB: '255,90,102',
    carnelian: '#BA1824',
    carnelianRGB: '186,24,36',
    error: '#FF6A75',
    info: '#FFC000',
    infoRGB: '255,192,0',
};
