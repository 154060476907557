import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-unresolved
import { ErrorMessage } from '@hookform/error-message';

import InputStyles from '../../../styles/inputs';

const PasswordTextInput = ({
    classes,
    name,
    label,
    value,
    helperText,
    errors,
    required,
    disabled,
    readOnly,
    inputRef,
    inputProps,
    children,
    showError,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const isInvalid = !!errors[name];

    let describedBy = helperText ? `${name}-helper` : null;
    if (showError && isInvalid) {
        describedBy = describedBy ? `${describedBy} ${name}-error` : `${name}-error`;
    }

    const handleFocus = useCallback(() => {
        setIsFocused(true);
    }, [setIsFocused]);

    const handleBlur = useCallback(() => {
        setIsFocused(false);
    }, [setIsFocused]);

    const elementProps = {
        'aria-labelledby': `${name}-label`,
        'aria-describedby': describedBy,
        'aria-invalid': isInvalid,
        'aria-required': required,
        'aria-disabled': disabled,
        autoComplete: 'off',
        onFocus: handleFocus,
        onBlur: handleBlur,
        ...inputProps,
    };

    return (
        <>
            <Typography variant="body1" id={`${name}-label`} className={classes.inputLabel}>{label}</Typography>

            <InputBase
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                inputRef={inputRef}
                name={name}
                value={value}
                required={required}
                disabled={disabled}
                readOnly={readOnly}
                error={isInvalid}
                classes={{
                    root: classes.textInputRoot,
                    input: classes.textInputBackground,
                    focused: classes.textInputBackgroundFocused,
                    error: classes.textInputError,
                }}
                endAdornment={(
                    <InputAdornment position="end" className="mr-2">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={event => event.preventDefault()}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                )}
                inputProps={elementProps}
            />

            { children }

            { helperText && (
                <Collapse in={isFocused}>
                    <Typography variant="body1" id={`${name}-helper`} className={classes.inputHelper}>{helperText}</Typography>
                </Collapse>
            )}

            {showError && (
                <div id={`${name}-error`} className={classes.errorMessage} role="status" aria-live="polite">
                    <ErrorMessage name={name} errors={errors} />
                </div>
            )}
        </>
    );
};

PasswordTextInput.defaultProps = {
    value: undefined,
    helperText: null,
    errors: {},
    disabled: false,
    required: false,
    readOnly: false,
    inputRef: null,
    inputProps: {},
    children: null,
    showError: true,
};

PasswordTextInput.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    helperText: PropTypes.string,
    errors: PropTypes.object,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    inputProps: PropTypes.object,
    children: PropTypes.node,
    showError: PropTypes.bool,
};

export default withStyles(InputStyles)(PasswordTextInput);
