import WebClient from '../utils/web-client';
import SitesTypes from '../action-types/sites';
import { APIError } from './app';

const getSitesForReportsFiltersBegin = () => ({
    type: SitesTypes.GET_SITES_FOR_FILTERS_BEGIN,
});

const getSitesForReportsFiltersSuccess = () => ({
    type: SitesTypes.GET_SITES_FOR_FILTERS_SUCCESS,
});

const getSitesForReportsFiltersError = errorMsg => ({
    type: SitesTypes.GET_SITES_FOR_FILTERS_ERROR,
    payload: { errorMsg },
});

export const getSitesForReportsFilters = onSuccess => (
    async (dispatch) => {
        dispatch(getSitesForReportsFiltersBegin);

        try {
            const response = await WebClient.get('/testSites');

            dispatch(getSitesForReportsFiltersSuccess());
            const preparedData = response.data.map(site => ({
                label: site.siteName,
                value: site.id,
                ...site,
            }));
            const sortedByName = preparedData.sort((a, b) => a.label.localeCompare(b.label));

            if (onSuccess) onSuccess(sortedByName);
        } catch (error) {
            dispatch(getSitesForReportsFiltersError(error));
        }
    }
);

const getSitesBegin = () => ({
    type: SitesTypes.GET_SITES_BEGIN,
});

const getSitesSuccess = payload => ({
    type: SitesTypes.GET_SITES_SUCCESS,
    payload,
});

const getSitesError = errorMsg => ({
    type: SitesTypes.GET_SITES_ERROR,
    payload: { errorMsg },
});

const deleteSitesSuccess = siteId => ({
    type: SitesTypes.DELETE_SITES_SUCCESS,
    payload: { siteId },
});

const createSitesSuccess = site => ({
    type: SitesTypes.CREATE_SITES_SUCCESS,
    payload: { site },
});

const updateSitesSuccess = site => ({
    type: SitesTypes.UPDATE_SITES_SUCCESS,
    payload: { site },
});

// eslint-disable-next-line import/prefer-default-export
export const fetchSites = (payload = {}) => (
    async (dispatch) => {
        dispatch(getSitesBegin());

        try {
            const { data } = await WebClient.post('/testSites/list', payload);
            dispatch(getSitesSuccess({
                sites: data.testSites,
                total: data.total,
            }));
        } catch (error) {
            dispatch(getSitesError(error));
        }
    }
);

export const createSite = (site, onSuccess, onError) => (
    async (dispatch) => {
        try {
            const { data } = await WebClient.post('/testSites', site);
            dispatch(createSitesSuccess(data));
            onSuccess(data);
        } catch (error) {
            onError(error);
            dispatch(APIError('Error creating site.'));
        }
    }
);

export const updateSite = (siteId, siteData, onSuccess, onError) => (
    async (dispatch) => {
        try {
            const response = await WebClient.patch(`/testSites/${siteId}`, siteData);
            dispatch(updateSitesSuccess(response.data));
            onSuccess(response.data);
        } catch (error) {
            onError(error);
            dispatch(APIError('Error updating site.'));
        }
    }
);

export const deleteSite = (siteId, onSuccess, onError) => (
    async (dispatch) => {
        try {
            const response = await WebClient.delete(`/testSites/${siteId}`);
            dispatch(deleteSitesSuccess(siteId));
            onSuccess(response.data);
        } catch (error) {
            onError(error);
            dispatch(APIError('Error deleting site.'));
        }
    }
);
