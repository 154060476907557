import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ReportHome from '../../routes/report';
import ReportHeader from './components/header';

const ReportLayout = ({ currentReport, flaggedVersion }) => {
    const history = useHistory();

    const onBackButtonClick = useCallback(() => {
        history.push('/dashboard/reports');
    }, [history]);

    return (
        <React.Fragment>
            <ReportHeader
                onBackButtonClick={onBackButtonClick}
                backButtonLabel="Back to Dashboard"
            />
            {currentReport?.id && (
                <main>
                    <ReportHome report={currentReport} currentVersion={flaggedVersion} />
                </main>
            )}
        </React.Fragment>
    );
};

ReportLayout.propTypes = {
    currentReport: PropTypes.object.isRequired,
    flaggedVersion: PropTypes.object.isRequired,
};

export default ReportLayout;
