import KeyMirror from 'keymirror';

import VideosTypes from '../action-types/videos';

const REQUEST_STATUS = KeyMirror({
    ACTIVE: true,
    SUCCESS: true,
    ERROR: true,
});

const internals = {
    initial: () => ({
        data: [],
        requestStatus: null,
        errorMsg: null,
    }),
};

const VideosReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { type, payload } = action;

    switch (type) {
    case VideosTypes.GET_VIDEOS_BEGIN:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ACTIVE,
        };
    case VideosTypes.GET_VIDEOS_SUCCESS:
        return {
            ...state,
            data: payload.videos,
            requestStatus: REQUEST_STATUS.SUCCESS,
        };
    case VideosTypes.GET_VIDEOS_ERROR:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ERROR,
            errorMsg: payload.errorMsg,
        };
    default:
        // do nothing
    }
    return state;
};

export default VideosReducer;
