import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { withStyles } from '@material-ui/core/styles';

import TextInput from '../../components/form/textinput';
import TextArea from '../../components/form/textarea';
import CheckboxGroup from '../../components/form/checkboxgroup';
import FileUploader from '../../components/file-uploader';
import { getReportAttachmentsSelector } from '../../selectors/reports';
import { getCurrentReportId } from '../../selectors/current-report';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import InputStyles from '../../styles/inputs';
import Styles from './styles';
import QuestionWrapper from './components/section/question-wrapper';
import { getRole } from '../../selectors/auth';
import { USER_ROLES } from '../../utils/constants';

const TOTAL_ALLOWED_UPLOADS = 10;
const ALLOWED_FILE_TYPES = [
    // documents
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.csv',
    '.ppt',
    '.pptx',
    'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/pdf',
    'application/msword',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/csv',
    '.txt',
    '.rtf',
    // images
    '.gif',
    '.png',
    '.jpeg',
    '.jpg',
    '.heic',
    '.tif',
    '.tiff',
    // audio # https://en.wikipedia.org/wiki/Audio_file_format
    '.wv',
    '.wma',
    '.webm',
    '.wav',
    '.mp4',
    '.mp3',
    '.m4a',
    '.m4b',
    '.m4p',
    '.aiff',
    '.alac',
    '.flac',
    '.ogg',
    '.oga',
    '.opus',
    // movie
    '.mov',
    '.avi',
    '.mpg',
    '.mpeg',
    '.wmv',
    '.m4v',
    '.mkv',
    '.webm',
    '.flv',
    '.vob',
    '.ogv',
];

const ReportSection6 = ({
    classes,
    questions,
    formContext,
    setUploadedAttachments,
}) => {
    const reportId = useSelector(getCurrentReportId);
    const reportAttachments = useSelector(getReportAttachmentsSelector)(reportId);
    const role = useSelector(getRole);

    const [uploadedAttachmentsLocallyManagement, setUploadedAttachmentsLocallyManaged] = useState((reportAttachments && reportAttachments.length) ? reportAttachments : [null]);
    // send updates to parent component
    useEffect(() => {
        setUploadedAttachments(uploadedAttachmentsLocallyManagement);
    }, [setUploadedAttachments, uploadedAttachmentsLocallyManagement]);

    const {
        narrative,
        signature,
        completionTimeHours,
        completionTimeMinutes,
    } = questions;

    const {
        register,
        control,
        errors,
    } = formContext;


    const handleFileUploadChange = useCallback((fileNo, uploadedFile) => {
        const updatedAttachments = [...uploadedAttachmentsLocallyManagement];
        updatedAttachments[fileNo] = uploadedFile;
        setUploadedAttachmentsLocallyManaged(updatedAttachments);
    }, [setUploadedAttachmentsLocallyManaged, uploadedAttachmentsLocallyManagement]);

    const handleFileUploadClear = useCallback((fileNo) => {
        handleFileUploadChange(fileNo, null);
    }, [handleFileUploadChange]);

    const handleAddButtonClick = useCallback(() => {
        if (uploadedAttachmentsLocallyManagement.length >= TOTAL_ALLOWED_UPLOADS) return;

        const updatedAttachments = [...uploadedAttachmentsLocallyManagement];
        updatedAttachments.push(null);
        setUploadedAttachmentsLocallyManaged(updatedAttachments);
    }, [setUploadedAttachmentsLocallyManaged, uploadedAttachmentsLocallyManagement]);

    return (
        <React.Fragment>
            <Typography variant="h3" component="p">
                Provide a <b>COMPLETE CHRONOLOGICAL</b> summary of the site visit; indicate the order in which you
                met/spoke with different people and the <b>SEQUENCE OF EVENTS</b> and <b>CONVERSATIONS</b>. Use quotation
                marks to identify “exact words” used by you or the agent, as much as possible.
            </Typography>

            <div className={`row pb-4 ${classes.inputWrapper}`}>
                <div className="col">
                    <QuestionWrapper
                        questionId={narrative.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TextArea
                                name={narrative.id}
                                label={narrative.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                rowsMin={3}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>

            <div className={`row ${classes.inputWrapper}`}>
                <div className="col">
                    <Typography variant="h3" component="p" id="attachments-label" className={classes.inputLabel}>
                        Are there any pictures or documents you would like to attach to this report?
                    </Typography>
                    <Typography variant="body1" id="attachments-note" className="pb-3">
                        <ul>
                            <li>
                                Select up to 10 files to upload
                            </li>
                            <li>
                                Maximum file size is 10MB
                            </li>
                            <li>
                                Allowed file types include most: documents, images, videos, and audio files
                            </li>
                        </ul>
                    </Typography>

                    { uploadedAttachmentsLocallyManagement.map((attachment, fileNo) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className="pb-3" key={`attachment-${fileNo}`}>
                            <Typography id={`attachment-${fileNo}-label`} variant="body1" className={classes.inputLabel}>
                                Report Attachment {fileNo + 1}
                            </Typography>

                            <div className="d-flex justify-content-between">
                                <FileUploader
                                    name={`attachment-${fileNo}`}
                                    labelledBy={`attachment-${fileNo}-label`}
                                    describedBy="attachments-label attachments-note"
                                    allowedFileTypes={ALLOWED_FILE_TYPES}
                                    previousFilename={attachment ? attachment.name : null}
                                    onChange={uploadedFile => handleFileUploadChange(fileNo, uploadedFile)}
                                    onClear={() => handleFileUploadClear(fileNo)}
                                />
                                { (attachment && fileNo < (TOTAL_ALLOWED_UPLOADS - 1)) && (
                                    <IconButton
                                        color="primary"
                                        aria-label="Upload another file"
                                        onClick={handleAddButtonClick}
                                    >
                                        <AddBoxIcon />
                                    </IconButton>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={`row ${classes.inputWrapper} pt-4`}>
                <div className="col">
                    <hr className={classes.greyHr} />
                    <Typography variant="h3" className={classes.inputLabel}>
                        Time it took you to complete the report
                    </Typography>
                </div>
            </div>

            <div className="row pt-2 pb-4">
                <div className="col">
                    <TextInput
                        name={completionTimeHours.id}
                        label={completionTimeHours.label}
                        errors={errors}
                        required
                        inputRef={register()}
                        inputProps={{
                            autoComplete: 'off',
                            type: 'number',
                        }}
                        disabled={role !== USER_ROLES.tester.value}
                    />
                </div>
                <div className="col">
                    <TextInput
                        name={completionTimeMinutes.id}
                        label={completionTimeMinutes.label}
                        errors={errors}
                        required
                        inputRef={register()}
                        inputProps={{
                            autoComplete: 'off',
                            type: 'number',
                        }}
                        disabled={role !== USER_ROLES.tester.value}
                    />
                </div>
            </div>

            <div className={`row ${classes.inputWrapper} pt-4 pb-4`}>
                <div className="col">
                    <hr className={classes.greyHr} />
                    <CheckboxGroup
                        name={signature.id}
                        options={signature.options}
                        errors={errors}
                        control={control}
                        inputProps={{
                            disabled: role !== USER_ROLES.tester.value,
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

ReportSection6.propTypes = {
    classes: PropTypes.object.isRequired,
    questions: PropTypes.object.isRequired,
    formContext: PropTypes.object.isRequired,
    setUploadedAttachments: PropTypes.func.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(combinedStyles)(ReportSection6);
