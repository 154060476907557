import Colors from './colors';

const styles = theme => ({
    dialogContainer: {
        backgroundColor: Colors.antiflashWhite,
        borderRadius: 10,
        maxWidth: 680,

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 15,
        },
    },
    dialogTitle: {
        padding: theme.spacing(1),
        paddingTop: 0,
    },
    dialogCloseButton: {
        color: theme.typography.color,
        width: '1.4em',
        height: '1.4em',
    },
    dialogContent: {
        paddingTop: 0,
        paddingLeft: 80,
        paddingRight: 80,

        [theme.breakpoints.down('sm')]: {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
    dialogContentText: {
        color: theme.typography.color,
        textAlign: 'center',
        paddingBottom: 30,
    },
    dialogActions: {
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        paddingBottom: 50,
        justifyContent: 'flex-end',

        [theme.breakpoints.down('sm')]: {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
});

export default styles;
