import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import TextInput from '../../components/form/textinput';
import TextArea from '../../components/form/textarea';
import CheckboxGroup from '../../components/form/checkboxgroup';
import RadioGroup from '../../components/form/radiogroup';
import RadioButton from '../../components/form/radiobutton';
import Select from '../../components/form/select';
import ConditionalQuestions from '../../components/form/conditional-questions';

import UnitDescription from './components/unit-description';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import InputStyles from '../../styles/inputs';
import Styles from './styles';
import QuestionWrapper from './components/section/question-wrapper';

const ReportSection4 = ({
    classes,
    questions,
    formContext,
}) => {
    const {
        topicsDiscussedRates,
        topicsDiscussedRatesAgentSay,
        topicsDiscussedRatesYouSay,
        topicsDiscussedLease,
        topicsDiscussedLeaseAgentSay,
        topicsDiscussedLeaseYouSay,
        topicsDiscussedSecurityDeposit,
        topicsDiscussedSecurityDepositAgentSay,
        topicsDiscussedSecurityDepositYouSay,
        topicsDiscussedApplicationFee,
        topicsDiscussedApplicationFeeAgentSay,
        topicsDiscussedApplicationFeeYouSay,
        topicsDiscussedHoldingFee,
        topicsDiscussedHoldingFeeAgentSay,
        topicsDiscussedHoldingFeeYouSay,
        topicsDiscussedParking,
        topicsDiscussedParkingAgentSay,
        topicsDiscussedParkingYouSay,
        topicsDiscussedCreditCheck,
        topicsDiscussedCreditCheckAgentSay,
        topicsDiscussedCreditCheckYouSay,
        topicsDiscussedApplication,
        topicsDiscussedApplicationAgentSay,
        topicsDiscussedApplicationYouSay,
        topicsDiscussedPetPolicies,
        topicsDiscussedPetPoliciesAgentSay,
        topicsDiscussedPetPoliciesYouSay,
        topicsDiscussedOther,
        topicsDiscussedOtherAgentSay,
        topicsDiscussedOtherYouSay,
        heatWhoIsResponsible,
        heatToldOrAsked,
        waterWhoIsResponsible,
        waterToldOrAsked,
        trashWhoIsResponsible,
        trashToldOrAsked,
        sewageWhoIsResponsible,
        sewageToldOrAsked,
        electricityWhoIsResponsible,
        electricityToldOrAsked,
        gasWhoIsResponsible,
        gasToldOrAsked,
        amenitiesWhoIsResponsible,
        amenitiesToldOrAsked,
        amenitiesDiscussed,
        didYouInspectApartment,
        whatDidYouView,
        whatDidYouViewOther,
        describeAreaOutside,
        howManyUnitsInspected,
        viewFromUnitsShown,
        viewFromUnitsShownOtherDetails,
        wereYouToldAboutOtherUnits,
        howManyUnitsUnavailable,
        didTheAgentTakePartInTour,
        commentsAboutTheTour,
        wereAnyFeaturesPointedOut,
        wereAnyFeaturesPointedOutOtherDetails,
        unitOrComplexEnvironment,
        unitOrComplexEnvironmentAgentSay,
        tenants,
        tenantsAgentSay,
        schools,
        schoolsAgentSay,
        neighborhood,
        neighborhoodAgentSay,
    } = questions;

    const {
        register,
        watch,
        errors,
        control,
        setValue,
    } = formContext;

    // watch all of the conditional question triggers
    const values = watch([
        topicsDiscussedRates.id,
        topicsDiscussedLease.id,
        topicsDiscussedSecurityDeposit.id,
        topicsDiscussedApplicationFee.id,
        topicsDiscussedHoldingFee.id,
        topicsDiscussedParking.id,
        topicsDiscussedCreditCheck.id,
        topicsDiscussedApplication.id,
        topicsDiscussedPetPolicies.id,
        topicsDiscussedOther.id,
        heatWhoIsResponsible.id,
        waterWhoIsResponsible.id,
        trashWhoIsResponsible.id,
        sewageWhoIsResponsible.id,
        electricityWhoIsResponsible.id,
        gasWhoIsResponsible.id,
        amenitiesWhoIsResponsible.id,
        unitOrComplexEnvironment.id,
        tenants.id,
        schools.id,
        neighborhood.id,
    ]);
    const howManyUnitsInspectedValue = watch(howManyUnitsInspected.id);
    const howManyUnitsUnavailableValue = watch(howManyUnitsUnavailable.id);
    const didYouInspectApartmentValue = watch(didYouInspectApartment.id);
    const wereYouToldAboutOtherUnitsValue = watch(wereYouToldAboutOtherUnits.id);

    const getUnitDescriptionSection = useCallback((numberOfUnits, unavailable = false) => {
        const sections = [];

        // eslint-disable-next-line no-plusplus
        for (let unit = 1; unit <= numberOfUnits; unit++) {
            sections.push((<UnitDescription
                key={`unit-${unit}`}
                unitNumber={unit}
                questions={questions}
                errors={errors}
                register={register}
                control={control}
                unavailable={unavailable}
            />));
        }

        return sections;
    }, [questions, errors, register, control]);

    // when certain radio buttons are deselected, clear out their associated conditional answers
    useEffect(() => {
        if (didYouInspectApartmentValue === 'No') {
            setValue(howManyUnitsInspected.id, null);
            setValue(describeAreaOutside.id, []);
            setValue(viewFromUnitsShown.id, []);
            setValue(whatDidYouView.id, whatDidYouView.options[0]);
        }
        if (wereYouToldAboutOtherUnitsValue === 'No') {
            setValue(howManyUnitsUnavailable.id, 0);
        }
    }, [didYouInspectApartmentValue, wereYouToldAboutOtherUnitsValue]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            {/* TOPICS DISCUSSED */}
            <div className="row">
                <div className="col">
                    <Typography variant="body1" className={classes.inputLabel}>Select any of the topics you discussed with the Agent:</Typography>
                    <Typography variant="body1" className="pt-3">Please indicate exactly what the &quot;Agent&quot; said about each topic and what you, the &quot;Tester&quot; said about each topic</Typography>
                    <div className={classes.explainerBox}>
                        <Typography variant="body1" style={{ paddingBottom: 15 }}><i>Example: Rental Rates,</i></Typography>
                        <Typography variant="body1" style={{ paddingLeft: 45, paddingBottom: 5 }}><b>Agent:</b> A two bedroom is $675 a month,</Typography>
                        <Typography variant="body1" style={{ paddingLeft: 45 }}><b>Tester:</b> okay</Typography>
                    </div>
                </div>
            </div>

            <QuestionWrapper
                questionId={topicsDiscussedRates.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <hr className={classes.greyHr} />
                                <CheckboxGroup
                                    name={topicsDiscussedRates.id}
                                    options={topicsDiscussedRates.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedRates.id] && !!values[topicsDiscussedRates.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedRatesAgentSay.id}
                                            label={topicsDiscussedRatesAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disable
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedRatesYouSay.id}
                                            label={topicsDiscussedRatesYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />

            <div className="row">
                <div className="col">
                    <QuestionWrapper
                        questionId={topicsDiscussedLease.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <>
                                <CheckboxGroup
                                    name={topicsDiscussedLease.id}
                                    options={topicsDiscussedLease.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                                <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedLease.id] && !!values[topicsDiscussedLease.id].length}>
                                    <>
                                        <div className="row pb-3">
                                            <div className={`col ${classes.inputWrapper}`}>
                                                <TextInput
                                                    name={topicsDiscussedLeaseAgentSay.id}
                                                    label={topicsDiscussedLeaseAgentSay.label}
                                                    errors={errors}
                                                    required
                                                    inputRef={register()}
                                                    inputProps={{
                                                        autoComplete: 'off',
                                                    }}
                                                    disabled={disabled}
                                                />
                                            </div>
                                        </div>
                                        <div className="row pb-3">
                                            <div className={`col ${classes.inputWrapper}`}>
                                                <TextInput
                                                    name={topicsDiscussedLeaseYouSay.id}
                                                    label={topicsDiscussedLeaseYouSay.label}
                                                    errors={errors}
                                                    required
                                                    inputRef={register()}
                                                    inputProps={{
                                                        autoComplete: 'off',
                                                    }}
                                                    disabled={disabled}
                                                />
                                            </div>
                                        </div>
                                    </>
                                </ConditionalQuestions>
                            </>
                        )}
                    />
                </div>
            </div>

            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={topicsDiscussedSecurityDeposit.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedSecurityDeposit.id}
                                    options={topicsDiscussedSecurityDeposit.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedSecurityDeposit.id] && !!values[topicsDiscussedSecurityDeposit.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedSecurityDepositAgentSay.id}
                                            label={topicsDiscussedSecurityDepositAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedSecurityDepositYouSay.id}
                                            label={topicsDiscussedSecurityDepositYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={topicsDiscussedApplicationFee.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedApplicationFee.id}
                                    options={topicsDiscussedApplicationFee.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedApplicationFee.id] && !!values[topicsDiscussedApplicationFee.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedApplicationFeeAgentSay.id}
                                            label={topicsDiscussedApplicationFeeAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedApplicationFeeYouSay.id}
                                            label={topicsDiscussedApplicationFeeYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={topicsDiscussedHoldingFee.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedHoldingFee.id}
                                    options={topicsDiscussedHoldingFee.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedHoldingFee.id] && !!values[topicsDiscussedHoldingFee.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedHoldingFeeAgentSay.id}
                                            label={topicsDiscussedHoldingFeeAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedHoldingFeeYouSay.id}
                                            label={topicsDiscussedHoldingFeeYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={topicsDiscussedParking.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedParking.id}
                                    options={topicsDiscussedParking.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedParking.id] && !!values[topicsDiscussedParking.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedParkingAgentSay.id}
                                            label={topicsDiscussedParkingAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedParkingYouSay.id}
                                            label={topicsDiscussedParkingYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={topicsDiscussedCreditCheck.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedCreditCheck.id}
                                    options={topicsDiscussedCreditCheck.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedCreditCheck.id] && !!values[topicsDiscussedCreditCheck.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedCreditCheckAgentSay.id}
                                            label={topicsDiscussedCreditCheckAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedCreditCheckYouSay.id}
                                            label={topicsDiscussedCreditCheckYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={topicsDiscussedApplication.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedApplication.id}
                                    options={topicsDiscussedApplication.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedApplication.id] && !!values[topicsDiscussedApplication.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedApplicationAgentSay.id}
                                            label={topicsDiscussedApplicationAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedApplicationYouSay.id}
                                            label={topicsDiscussedApplicationYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={topicsDiscussedPetPolicies.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedPetPolicies.id}
                                    options={topicsDiscussedPetPolicies.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedPetPolicies.id] && !!values[topicsDiscussedPetPolicies.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedPetPoliciesAgentSay.id}
                                            label={topicsDiscussedPetPoliciesAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedPetPoliciesYouSay.id}
                                            label={topicsDiscussedPetPoliciesYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />


            <QuestionWrapper
                questionId={topicsDiscussedOther.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={topicsDiscussedOther.id}
                                    options={topicsDiscussedOther.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[topicsDiscussedOther.id] && !!values[topicsDiscussedOther.id].length}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedOtherAgentSay.id}
                                            label={topicsDiscussedOtherAgentSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <TextInput
                                            name={topicsDiscussedOtherYouSay.id}
                                            label={topicsDiscussedOtherYouSay.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            inputProps={{
                                                autoComplete: 'off',
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />

            {/* WHO IS RESPONSIBLE */}
            <Typography variant="h2" style={{ fontWeight: 700, paddingBottom: 15, paddingTop: 45 }}>
                Indicate who is responsible for the following utilities; were you told, or did you have to ask?
            </Typography>
            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={heatWhoIsResponsible.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pt-2 pb-2">
                            <div className="col">
                                <RadioGroup
                                    name={heatWhoIsResponsible.id}
                                    label={heatWhoIsResponsible.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    {heatWhoIsResponsible.options.map(option => (
                                        <RadioButton
                                            key={`${heatWhoIsResponsible.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder hideContainer condition={!!values[heatWhoIsResponsible.id] && values[heatWhoIsResponsible.id] !== 'I don\'t know'}>
                            <div className="row pt-1 pb-2">
                                <div className="col">
                                    <RadioGroup
                                        name={heatToldOrAsked.id}
                                        label={heatToldOrAsked.label}
                                        errors={errors}
                                        required
                                        row
                                        control={control}
                                    >
                                        {heatToldOrAsked.options.map(option => (
                                            <RadioButton
                                                key={`${heatToldOrAsked.id}-${option}`}
                                                value={option}
                                                label={option}
                                                buttonStyle
                                                disabled={disabled}
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={waterWhoIsResponsible.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pt-2 pb-2">
                            <div className="col">
                                <RadioGroup
                                    name={waterWhoIsResponsible.id}
                                    label={waterWhoIsResponsible.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    {waterWhoIsResponsible.options.map(option => (
                                        <RadioButton
                                            key={`${waterWhoIsResponsible.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder hideContainer condition={!!values[waterWhoIsResponsible.id] && values[waterWhoIsResponsible.id] !== 'I don\'t know'}>
                            <div className="row pt-1 pb-2">
                                <div className="col">
                                    <RadioGroup
                                        name={waterToldOrAsked.id}
                                        label={waterToldOrAsked.label}
                                        errors={errors}
                                        required
                                        row
                                        control={control}
                                    >
                                        {waterToldOrAsked.options.map(option => (
                                            <RadioButton
                                                key={`${waterToldOrAsked.id}-${option}`}
                                                value={option}
                                                label={option}
                                                buttonStyle
                                                disabled={disabled}
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={trashWhoIsResponsible.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pt-2 pb-2">
                            <div className="col">
                                <RadioGroup
                                    name={trashWhoIsResponsible.id}
                                    label={trashWhoIsResponsible.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    {trashWhoIsResponsible.options.map(option => (
                                        <RadioButton
                                            key={`${trashWhoIsResponsible.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder hideContainer condition={!!values[trashWhoIsResponsible.id] && values[trashWhoIsResponsible.id] !== 'I don\'t know'}>
                            <div className="row pt-1 pb-2">
                                <div className="col">
                                    <RadioGroup
                                        name={trashToldOrAsked.id}
                                        label={trashToldOrAsked.label}
                                        errors={errors}
                                        required
                                        row
                                        control={control}
                                    >
                                        {trashToldOrAsked.options.map(option => (
                                            <RadioButton
                                                key={`${trashToldOrAsked.id}-${option}`}
                                                value={option}
                                                label={option}
                                                buttonStyle
                                                disabled={disabled}
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={sewageWhoIsResponsible.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pt-2 pb-2">
                            <div className="col">
                                <RadioGroup
                                    name={sewageWhoIsResponsible.id}
                                    label={sewageWhoIsResponsible.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    {sewageWhoIsResponsible.options.map(option => (
                                        <RadioButton
                                            key={`${sewageWhoIsResponsible.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder hideContainer condition={!!values[sewageWhoIsResponsible.id] && values[sewageWhoIsResponsible.id] !== 'I don\'t know'}>
                            <div className="row pt-1 pb-2">
                                <div className="col">
                                    <RadioGroup
                                        name={sewageToldOrAsked.id}
                                        label={sewageToldOrAsked.label}
                                        errors={errors}
                                        required
                                        row
                                        control={control}
                                    >
                                        {sewageToldOrAsked.options.map(option => (
                                            <RadioButton
                                                key={`${sewageToldOrAsked.id}-${option}`}
                                                value={option}
                                                label={option}
                                                buttonStyle
                                                disabled={disabled}
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={electricityWhoIsResponsible.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pt-2 pb-2">
                            <div className="col">
                                <RadioGroup
                                    name={electricityWhoIsResponsible.id}
                                    label={electricityWhoIsResponsible.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    {electricityWhoIsResponsible.options.map(option => (
                                        <RadioButton
                                            key={`${electricityWhoIsResponsible.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder hideContainer condition={!!values[electricityWhoIsResponsible.id] && values[electricityWhoIsResponsible.id] !== 'I don\'t know'}>
                            <div className="row pt-1 pb-2">
                                <div className="col">
                                    <RadioGroup
                                        name={electricityToldOrAsked.id}
                                        label={electricityToldOrAsked.label}
                                        errors={errors}
                                        required
                                        row
                                        control={control}
                                    >
                                        {electricityToldOrAsked.options.map(option => (
                                            <RadioButton
                                                key={`${electricityToldOrAsked.id}-${option}`}
                                                value={option}
                                                label={option}
                                                buttonStyle
                                                disabled={disabled}
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={gasWhoIsResponsible.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pt-2 pb-2">
                            <div className="col">
                                <RadioGroup
                                    name={gasWhoIsResponsible.id}
                                    label={gasWhoIsResponsible.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    {gasWhoIsResponsible.options.map(option => (
                                        <RadioButton
                                            key={`${gasWhoIsResponsible.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder hideContainer condition={!!values[gasWhoIsResponsible.id] && values[gasWhoIsResponsible.id] !== 'I don\'t know'}>
                            <div className="row pt-1 pb-2">
                                <div className="col">
                                    <RadioGroup
                                        name={gasToldOrAsked.id}
                                        label={gasToldOrAsked.label}
                                        errors={errors}
                                        required
                                        row
                                        control={control}
                                    >
                                        {gasToldOrAsked.options.map(option => (
                                            <RadioButton
                                                key={`${gasToldOrAsked.id}-${option}`}
                                                value={option}
                                                label={option}
                                                buttonStyle
                                                disabled={disabled}
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={amenitiesWhoIsResponsible.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pt-2 pb-2">
                            <div className="col">
                                <RadioGroup
                                    name={amenitiesWhoIsResponsible.id}
                                    label={amenitiesWhoIsResponsible.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    {amenitiesWhoIsResponsible.options.map(option => (
                                        <RadioButton
                                            key={`${amenitiesWhoIsResponsible.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder hideContainer condition={!!values[amenitiesWhoIsResponsible.id] && values[amenitiesWhoIsResponsible.id] !== 'I don\'t know'}>
                            <div className="row pt-1 pb-2">
                                <div className="col">
                                    <RadioGroup
                                        name={amenitiesToldOrAsked.id}
                                        label={amenitiesToldOrAsked.label}
                                        errors={errors}
                                        required
                                        row
                                        control={control}
                                    >
                                        {amenitiesToldOrAsked.options.map(option => (
                                            <RadioButton
                                                key={`${amenitiesToldOrAsked.id}-${option}`}
                                                value={option}
                                                label={option}
                                                buttonStyle
                                                disabled={disabled}
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />
            <hr className={classes.greyHr} />

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={amenitiesDiscussed.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TextArea
                                name={amenitiesDiscussed.id}
                                label={amenitiesDiscussed.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                rowsMin={3}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>

            <QuestionWrapper
                questionId={didYouInspectApartment.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pb-3">
                            <div className={`col ${classes.inputWrapper}`}>
                                <RadioGroup
                                    name={didYouInspectApartment.id}
                                    label={didYouInspectApartment.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    { didYouInspectApartment.options.map(option => (
                                        <RadioButton
                                            key={`${didYouInspectApartment.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>

                        <ConditionalQuestions condition={didYouInspectApartmentValue === 'Yes'}>
                            <div className={`row pb-3 ${classes.inputWrapper}`}>
                                <div className="col">
                                    <RadioGroup
                                        name={whatDidYouView.id}
                                        label={whatDidYouView.label}
                                        errors={errors}
                                        required
                                        control={control}
                                    >
                                        { whatDidYouView.options.map(option => (
                                            <RadioButton
                                                key={`${whatDidYouView.id}-${option}`}
                                                value={option}
                                                label={option}
                                                conditional={option === 'Other' ? (
                                                    <div className="row">
                                                        <div className="col">
                                                            <TextArea
                                                                name={whatDidYouViewOther.id}
                                                                label={whatDidYouViewOther.label}
                                                                errors={errors}
                                                                required
                                                                inputRef={register()}
                                                                rowsMin={3}
                                                                hiddenLabel
                                                                disabled={disabled}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null}
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                            </div>

                            <div className={`row pb-3 ${classes.inputWrapper}`}>
                                <div className="col">
                                    <CheckboxGroup
                                        name={describeAreaOutside.id}
                                        label={describeAreaOutside.label}
                                        options={describeAreaOutside.options}
                                        errors={errors}
                                        control={control}
                                        inputProps={{
                                            disabled,
                                        }}
                                    />
                                </div>
                            </div>

                            {/* DESCRIBE ALL UNITS INSPECTED */}
                            <Typography variant="h2" component="p" className="pt-3">
                                Describe all units inspected:
                            </Typography>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <Select
                                        name={howManyUnitsInspected.id}
                                        label={howManyUnitsInspected.label}
                                        errors={errors}
                                        required
                                        control={control}
                                        displayEmpty
                                        disabled={disabled}
                                    >
                                        <MenuItem disabled value="">Select</MenuItem>
                                        {howManyUnitsInspected.options.map(option => (
                                            <MenuItem
                                                key={`${howManyUnitsInspected.id}-${option}`}
                                                value={option}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>

                            <ConditionalQuestions hideContainer condition={!!howManyUnitsInspectedValue}>
                                <>
                                    <hr className={classes.greyHr} />
                                    { getUnitDescriptionSection(howManyUnitsInspectedValue) }
                                </>
                            </ConditionalQuestions>

                            <div className={`row ${classes.inputWrapper}`}>
                                <div className="col">
                                    <CheckboxGroup
                                        name={viewFromUnitsShown.id}
                                        label={viewFromUnitsShown.label}
                                        options={viewFromUnitsShown.options}
                                        errors={errors}
                                        control={control}
                                        inputProps={{
                                            disabled,
                                        }}
                                        conditional={{
                                            Other: (
                                                <TextArea
                                                    name={viewFromUnitsShownOtherDetails.id}
                                                    label={viewFromUnitsShownOtherDetails.label}
                                                    errors={errors}
                                                    required
                                                    inputRef={register()}
                                                    rowsMin={3}
                                                    hiddenLabel
                                                    disabled={disabled}
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                            </div>

                        </ConditionalQuestions>
                    </>
                )}
            />

            <QuestionWrapper
                questionId={wereYouToldAboutOtherUnits.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pb-3">
                            <div className={`col ${classes.inputWrapper}`}>
                                <RadioGroup
                                    name={wereYouToldAboutOtherUnits.id}
                                    label={wereYouToldAboutOtherUnits.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    { wereYouToldAboutOtherUnits.options.map(option => (
                                        <RadioButton
                                            key={`${wereYouToldAboutOtherUnits.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>

                        <ConditionalQuestions condition={wereYouToldAboutOtherUnitsValue === 'Yes'}>
                            {/* DESCRIBE ALL UNITS UNAVAILABLE TO BE INSPECTED */}
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <Select
                                        name={howManyUnitsUnavailable.id}
                                        label={howManyUnitsUnavailable.label}
                                        errors={errors}
                                        required
                                        control={control}
                                        displayEmpty
                                        disabled={disabled}
                                    >
                                        <MenuItem disabled value="">Select</MenuItem>
                                        {howManyUnitsUnavailable.options.map(option => (
                                            <MenuItem
                                                key={`${howManyUnitsUnavailable.id}-${option}`}
                                                value={option}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>

                            <ConditionalQuestions hideBorder hideContainer condition={!!howManyUnitsUnavailableValue}>
                                { getUnitDescriptionSection(howManyUnitsUnavailableValue, true) }
                            </ConditionalQuestions>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={didTheAgentTakePartInTour.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <RadioGroup
                                name={didTheAgentTakePartInTour.id}
                                label={didTheAgentTakePartInTour.label}
                                errors={errors}
                                required
                                row
                                control={control}
                            >
                                { didTheAgentTakePartInTour.options.map(option => (
                                    <RadioButton
                                        key={`${didTheAgentTakePartInTour.id}-${option}`}
                                        value={option}
                                        label={option}
                                        buttonStyle
                                        disabled={disabled}
                                    />
                                ))}
                            </RadioGroup>
                        )}
                    />
                </div>
            </div>

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={commentsAboutTheTour.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TextArea
                                name={commentsAboutTheTour.id}
                                label={commentsAboutTheTour.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                rowsMin={3}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>

            <div className={`row pb-3 ${classes.inputWrapper}`}>
                <div className="col">
                    <QuestionWrapper
                        questionId={wereAnyFeaturesPointedOut.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <CheckboxGroup
                                name={wereAnyFeaturesPointedOut.id}
                                label={wereAnyFeaturesPointedOut.label}
                                options={wereAnyFeaturesPointedOut.options}
                                errors={errors}
                                control={control}
                                conditional={{
                                    Other: (
                                        <TextArea
                                            name={wereAnyFeaturesPointedOutOtherDetails.id}
                                            label={wereAnyFeaturesPointedOutOtherDetails.label}
                                            errors={errors}
                                            required
                                            inputRef={register()}
                                            rowsMin={3}
                                            hiddenLabel
                                            disabled={disabled}
                                        />
                                    ),
                                }}
                                inputProps={{
                                    disabled,
                                }}
                            />
                        )}
                    />
                </div>
            </div>

            <Typography variant="body1" className={classes.inputLabel}>Did the agent say anything, good or bad, about:</Typography>
            <hr className={classes.greyHr} />
            <QuestionWrapper
                questionId={unitOrComplexEnvironment.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className={`col ${classes.inputWrapper}`}>
                                <CheckboxGroup
                                    name={unitOrComplexEnvironment.id}
                                    options={unitOrComplexEnvironment.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[unitOrComplexEnvironment.id] && !!values[unitOrComplexEnvironment.id].length}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={unitOrComplexEnvironmentAgentSay.id}
                                        label={unitOrComplexEnvironmentAgentSay.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={tenants.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={tenants.id}
                                    options={tenants.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[tenants.id] && !!values[tenants.id].length}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={tenantsAgentSay.id}
                                        label={tenantsAgentSay.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={schools.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={schools.id}
                                    options={schools.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[schools.id] && !!values[schools.id].length}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={schoolsAgentSay.id}
                                        label={schoolsAgentSay.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={neighborhood.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row">
                            <div className="col">
                                <CheckboxGroup
                                    name={neighborhood.id}
                                    options={neighborhood.options}
                                    errors={errors}
                                    control={control}
                                    inputProps={{
                                        disabled,
                                    }}
                                />
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder condition={!!values[neighborhood.id] && !!values[neighborhood.id].length}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={neighborhoodAgentSay.id}
                                        label={neighborhoodAgentSay.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <hr className={classes.greyHr} />
        </React.Fragment>
    );
};

ReportSection4.propTypes = {
    classes: PropTypes.object.isRequired,
    questions: PropTypes.object.isRequired,
    formContext: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(combinedStyles)(ReportSection4);
