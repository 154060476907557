import Section1Questions from './questions/section1';
import Section2Questions from './questions/section2';
import Section3Questions from './questions/section3';
import Section4Questions from './questions/section4';
import Section5Questions from './questions/section5';
import Section6Questions from './questions/section6';

import Icon1 from './images/reports/setup-arrival';
import Icon2 from './images/reports/agent-employees';
import Icon3 from './images/reports/tour';
import Icon4 from './images/reports/rental-details';
import Icon5 from './images/reports/service-treatment';
import Icon6 from './images/reports/narrative';

export const ICON_MAP = {
    Icon1,
    Icon2,
    Icon3,
    Icon4,
    Icon5,
    Icon6,
};

export const SECTION_PROGRESS = {
    NOT_STARTED: 'Not Started',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    FLAGGED: 'Changes Required',
};

export const INITIAL_SECTION_PROGRESS = {
    1: SECTION_PROGRESS.NOT_STARTED,
    2: SECTION_PROGRESS.NOT_STARTED,
    3: SECTION_PROGRESS.NOT_STARTED,
    4: SECTION_PROGRESS.NOT_STARTED,
    5: SECTION_PROGRESS.NOT_STARTED,
    6: SECTION_PROGRESS.NOT_STARTED,
};

export const TOTAL_SECTIONS = Object.keys(INITIAL_SECTION_PROGRESS).length;

export const SECTION_QUESTIONS = {
    1: Section1Questions,
    2: Section2Questions,
    3: Section3Questions,
    4: Section4Questions,
    5: Section5Questions,
    6: Section6Questions,
};

export const TOTAL_QUESTIONS = Object.values(SECTION_QUESTIONS)
    .reduce(
        (accumulator, questions) => accumulator + Object.keys(questions).length,
        0,
    );
