import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import getFormattedDate from '../../../../utils/get-formatted-date';
import { REPORT_STATUS } from '../../../../reducers/reports';
import useReportDialog from '../../../dashboard/utils/use-report-dialog';
import EditReportDialog from '../../../dashboard/components/create-edit-report-dialog';

const SummaryHeader = ({
    isTester,
    report,
    classes,
}) => {
    const {
        handleOpenDialog,
        dialogProps,
    } = useReportDialog();

    const { testDate, testSite } = report;
    const { siteName } = testSite;

    return (
        <>
            <div className="row align-items-center">
                {/* DESKTOP */}
                <div className="col-auto d-none d-sm-block pl-0">
                    <Typography variant="h1" className="d-inline-block">Report: {siteName}</Typography>
                    <Typography variant="body1" className="d-inline-block pl-1">Visited {getFormattedDate(testDate)}</Typography>
                    { isTester && report.status === REPORT_STATUS.DRAFT && (
                        <IconButton color="primary" aria-label="edit details" component="span" onClick={() => handleOpenDialog(report)}>
                            <EditIcon style={{ width: 16 }} />
                        </IconButton>
                    )}
                </div>
                {/* Mobile */}
                <div className="col-auto d-block d-sm-none">
                    <Typography variant="h1">Report: {siteName}</Typography>
                    <Typography variant="body1" className="d-inline-block pt-1">Visited {getFormattedDate(testDate)}</Typography>
                    { isTester && report.status === REPORT_STATUS.DRAFT && (
                        <IconButton color="primary" aria-label="edit details" component="span" onClick={() => handleOpenDialog(report)}>
                            <EditIcon style={{ width: 16 }} />
                        </IconButton>
                    )}
                </div>
            </div>
            {/* DESKTOP */}
            <div className="row d-none d-sm-block">
                <div className="col">
                    <ul>
                        {isTester ? (
                            <>
                                <Typography variant="body1" component="li">You can start with any section. Once all 6 sections are complete, you can sign and submit the form.</Typography>
                                <Typography variant="body1" component="li">You can save and exit a section at any time. The section is complete once all the required questions are answered.</Typography>
                            </>
                        ) : (
                            <Typography variant="body1" component="li">Select the appropriate section for your question re-answer request.</Typography>
                        )}
                    </ul>
                </div>
            </div>
            {/* MOBILE */}
            <div className="row d-block d-sm-none">
                <div className="col">
                    <Accordion elevation={0} square>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="instructions-content"
                            id="instructions-header"
                            classes={{
                                root: classes.mobileInstructionsWrapper,
                                content: classes.mobileInstructionsSummaryContent,
                                expanded: classes.mobileInstructionsWrapperExpanded,
                                expandIcon: classes.mobileInstructionsExpandButton,
                            }}
                        >
                            <Typography className={classes.mobileInstructionsSummaryText}>View Instructions</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.mobileInstructionsContent}>
                            <ul>
                                <Typography variant="body1" component="li">You can start with any section. Once all 6 sections are complete, you can sign and submit the form.</Typography>
                                <Typography variant="body1" component="li">You can save and exit a section at any time. The section is complete once all the required questions are answered.</Typography>
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <EditReportDialog {...dialogProps} />
        </>
    );
};

SummaryHeader.propTypes = {
    isTester: PropTypes.bool,
    report: PropTypes.shape({
        testSite: PropTypes.shape({
            siteName: PropTypes.string.isRequired,
        }).isRequired,
        testDate: PropTypes.string.isRequired,
        status: PropTypes.oneOf(Object.values(REPORT_STATUS)).isRequired,
    }).isRequired,
    classes: PropTypes.object.isRequired,
};

SummaryHeader.defaultProps = {
    isTester: false,
};

export default SummaryHeader;
