import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

import { SECTION_PROGRESS } from '../../../../reducers/current-report/constants';

import Styles from './styles';

const ReportBlockButton = ({ classes, section }) => {
    const {
        id,
        progress,
        title,
        Icon,
    } = section;
    const history = useHistory();

    const handleClick = useCallback(() => (
        history.push(`/report/section/${id}`)
    ), [history, id]);

    return (
        <Card
            className={`
                ${classes.wrapper}
                ${progress === SECTION_PROGRESS.NOT_STARTED && classes.progressNotStarted}
                ${progress === SECTION_PROGRESS.IN_PROGRESS && classes.progressInProgress}
                ${progress === SECTION_PROGRESS.COMPLETED && classes.progressCompleted}
                ${progress === SECTION_PROGRESS.FLAGGED && classes.questionsFlagged}
            `}
            variant="outlined"
        >
            <CardActionArea
                className={classes.actionButton}
                onClick={handleClick}
            >
                <CardHeader
                    classes={{
                        root: classes.header,
                        title: classes.headerText,
                    }}
                    title={progress !== SECTION_PROGRESS.COMPLETED ? progress : <CheckIcon className={classes.headerIcon} />}
                />
                <CardContent className={classes.body}>
                    <div className={classes.bodyIcon}><Icon /></div>
                    <Typography variant="h3" className={classes.bodyText}>{title}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

ReportBlockButton.propTypes = {
    classes: PropTypes.object.isRequired,
    section: PropTypes.object.isRequired,
};

export default withStyles(Styles)(ReportBlockButton);
