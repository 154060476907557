const questions = {
    didYouSetupAppointment: {
        id: '1',
        column: 'Did you call to setup an appointment?',
        label: 'Did you call to setup an appointment?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
        ],
    },
    appointmentCallDate: {
        id: '1a',
        column: 'Date you called',
        label: 'Date you called',
        helperText: 'mm/dd/yyyy',
        validation: {
            type: 'date',
            required: true,
            conditional: {
                on: 'didYouSetupAppointment',
                value: 'Yes',
            },
        },
    },
    appointmentCallTime: {
        id: '1b',
        column: 'Exact time',
        label: 'Exact time',
        validation: {
            type: 'time',
            required: true,
            conditional: {
                on: 'didYouSetupAppointment',
                value: 'Yes',
            },
        },
    },
    appointmentCallTaker: {
        id: '1c',
        column: 'Who did you speak to?',
        label: 'Who did you speak to?',
        helperText: 'Enter the full name',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'didYouSetupAppointment',
                value: 'Yes',
            },
        },
    },
    appointmentCallSummary: {
        id: '1d',
        column: 'Summary of phone conversation',
        label: 'Summary of phone conversation',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'didYouSetupAppointment',
                value: 'Yes',
            },
        },
    },
    timeArrived: {
        id: '2',
        column: 'Exact time arrived/phone test started',
        label: 'Exact time arrived/phone test started',
        validation: {
            type: 'time',
            required: true,
            conditional: false,
        },
    },
    timeDeparted: {
        id: '3',
        column: 'Exact time departed/phone test ended',
        label: 'Exact time departed/phone test ended',
        validation: {
            type: 'time',
            required: true,
            conditional: false,
        },
    },
    totalTimeAtSite: {
        id: '4',
        column: 'Total time spent at site',
        label: 'Total time spent at site',
        validation: {
            type: 'text',
            required: false,
            conditional: false,
        },
    },
    wereYouGreetedImmediately: {
        id: '5',
        column: 'Were you immediately greeted when you arrived?',
        label: 'Were you immediately greeted when you arrived?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'Yes',
            'No',
            'I don\'t remember',
        ],
    },
    howLongDidYouWaitGreeting: {
        id: '5a',
        column: 'If not, how long did you wait until you were greeted?',
        label: 'If not, how long did you wait until you were greeted?',
        validation: {
            type: 'text',
            required: true,
            conditional: {
                on: 'wereYouGreetedImmediately',
                value: 'No',
            },
        },
        options: [
            'No wait time',
            '5 min',
            '10 min',
            '15 min',
            '20 min',
            '25 min',
            '30 min',
            '35 min',
            '40 min',
            'More than 45 min',
        ],
    },
    whoGreetedYou: {
        id: '6',
        column: 'Who greeted you?',
        label: 'Who greeted you?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    whoInterviewedYou: {
        id: '7',
        column: 'Who interviewed you?',
        label: 'Who interviewed you?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
    },
    howLongDidYouWaitInterview: {
        id: '8',
        column: 'How long did you have to wait until you were interviewed about your housing desires?',
        label: 'How long did you have to wait until you were interviewed about your housing desires?',
        validation: {
            type: 'text',
            required: true,
            conditional: false,
        },
        options: [
            'No wait time',
            '5 min',
            '10 min',
            '15 min',
            '20 min',
            '25 min',
            '30 min',
            '35 min',
            '40 min',
            'More than 45 min',
        ],
    },
};

export default questions;
