import { createSelector } from 'reselect';

import { ICON_MAP, SECTION_PROGRESS, SECTION_QUESTIONS } from '../reducers/current-report/constants';

import { getReports } from './reports';

const getCurrentReportState = state => state.currentReport;

export const getCurrentReportId = createSelector(
    getCurrentReportState,
    currentReport => currentReport.id,
);

export const getCurrentReport = createSelector(
    getCurrentReportId,
    getReports,
    (reportId, reports) => {
        if (!reportId) return null;

        return {
            id: reportId,
            ...reports[reportId],
        };
    },
);

export const getCurrentReportProgress = createSelector(
    getCurrentReport,
    (currentReport) => {
        if (!currentReport.sectionsProgress) return SECTION_PROGRESS.NOT_STARTED;

        const progress = {
            [SECTION_PROGRESS.NOT_STARTED]: 0,
            [SECTION_PROGRESS.IN_PROGRESS]: 0,
            [SECTION_PROGRESS.COMPLETED]: 0,
        };
        const sections = Object.values(currentReport.sectionsProgress);

        sections.forEach((sectionProgress) => {
            progress[sectionProgress] += 1;
        });

        if (progress[SECTION_PROGRESS.COMPLETED] === sections.length) return SECTION_PROGRESS.COMPLETED;
        if (progress[SECTION_PROGRESS.NOT_STARTED] === sections.length) return SECTION_PROGRESS.NOT_STARTED;

        return SECTION_PROGRESS.IN_PROGRESS;
    },
);

export const getSections = createSelector(
    getCurrentReportState,
    getCurrentReport,
    (currentReportMeta, currentReport) => currentReportMeta.sections.map(section => ({
        ...section,
        Icon: ICON_MAP[section.Icon],
        questions: SECTION_QUESTIONS[section.id],
        progress: currentReport.sectionsProgress[section.id],
    })),
);

export const getSectionByIdSelector = createSelector(
    getSections,
    sections => (
        sectionId => (sections.find(section => section.id === sectionId))
    ),
);
