// https://github.com/mui-org/material-ui/tree/master/examples/create-react-app-with-jss/src
import React from 'react';
import PropTypes from 'prop-types';
import { create } from 'jss';
import {
    createMuiTheme,
    createGenerateClassName,
    jssPreset,
    StylesProvider,
    ThemeProvider,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { useThemeState } from './theme-context';

// A theme with custom primary and secondary color.
// It's optional.
// In this project we are defining the theme externally, and bringing it in through props
// import theme from '../styles/material-theme';

// Create a JSS instance with the default preset of plugins.
// It's optional.
const jss = create(jssPreset());

// The standard class name generator.
// It's optional.
const generateClassName = createGenerateClassName();

const ThemeWrapper = ({ children, theme }) => {
    const themeOverride = useThemeState();

    // override the theme with any changes made by the user with the DisplayOptionsControlPanel
    const updatedTheme = {
        ...theme,
        typography: {
            ...theme.typography,
            fontSize: themeOverride.fontSize,
            htmlFontSize: themeOverride.fontSize,
        },
    };

    if (themeOverride.background) {
        updatedTheme.palette = {
            ...updatedTheme.palette,
            background: {
                ...theme.palette.background,
                default: themeOverride.background,
            },
        };
    }

    return (
        // StylesProvider (replaces JssProvider) allows customizing the JSS styling solution.
        <StylesProvider jss={jss} generateClassName={generateClassName}>
            {/* ThemeProvider makes the theme available down the React tree
            thanks to React context. */}
            <ThemeProvider theme={createMuiTheme(updatedTheme)}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StylesProvider>
    );
};

ThemeWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    theme: PropTypes.object.isRequired,
};

export default ThemeWrapper;
