import { createSelector } from 'reselect';
import { USER_ROLES } from '../utils/constants';

const getAuthState = state => state.auth;

export const isAuthenticated = createSelector(
    getAuthState,
    authState => authState.isAuthenticated,
);

const getCredentials = createSelector(
    getAuthState,
    authState => authState.credentials,
);

export const getToken = createSelector(
    getCredentials,
    credentials => (credentials ? credentials.token : null),
);

export const getRole = createSelector(
    getCredentials,
    credentials => (credentials ? credentials.role : null),
);

export const isUserAdmin = createSelector(
    getAuthState,
    authState => authState.credentials.role === USER_ROLES.admin.value,
);

export const isUserTestCoordinator = createSelector(
    getAuthState,
    authState => authState.credentials.role === USER_ROLES.testCoordinator.value,
);

export const isUserTester = createSelector(
    getAuthState,
    authState => authState.credentials.role === USER_ROLES.tester.value,
);

export const getAuthError = createSelector(
    getAuthState,
    authState => authState.error,
);

export const getAuthErrorMessage = createSelector(
    getAuthState,
    authState => authState.errorMsg,
);

export const isResetPasswordCompleted = createSelector(
    getAuthState,
    authState => authState.resetPass.completed,
);

export const getResetPasswordError = createSelector(
    getAuthState,
    authState => authState.resetPass.error,
);
