import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    format,
    parseISO,
    isValid as isDateTimeValid,
    intervalToDuration,
    formatDuration,
} from 'date-fns';
import { withStyles } from '@material-ui/core/styles';

import TextInput from '../../components/form/textinput';
import TextArea from '../../components/form/textarea';
import RadioGroup from '../../components/form/radiogroup';
import RadioButton from '../../components/form/radiobutton';
import DatePicker from '../../components/form/datepicker';
import TimePicker from '../../components/form/timepicker';
import ConditionalQuestions from '../../components/form/conditional-questions';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import InputStyles from '../../styles/inputs';
import Styles from './styles';
import QuestionWrapper from './components/section/question-wrapper';

const ReportSection1 = ({
    classes,
    questions,
    formContext,
}) => {
    const {
        didYouSetupAppointment,
        appointmentCallDate,
        appointmentCallTime,
        appointmentCallTaker,
        appointmentCallSummary,
        timeArrived,
        timeDeparted,
        totalTimeAtSite,
        wereYouGreetedImmediately,
        howLongDidYouWaitGreeting,
        whoGreetedYou,
        whoInterviewedYou,
        howLongDidYouWaitInterview,
    } = questions;

    const {
        register,
        watch,
        setValue,
        errors,
        control,
    } = formContext;

    const watchDidYouSetupAppointment = watch(didYouSetupAppointment.id);
    const watchWereYouGreetedImmediately = watch(wereYouGreetedImmediately.id);
    const watchTimeArrived = watch(timeArrived.id);
    const watchTimeDeparted = watch(timeDeparted.id);

    // set totalTimeAtSite with the difference in hours and mins between timeArrived and timeDeparted
    useEffect(() => {
        const arrived = typeof watchTimeArrived === 'string' ? parseISO(watchTimeArrived) : watchTimeArrived;
        const departed = typeof watchTimeDeparted === 'string' ? parseISO(watchTimeDeparted) : watchTimeDeparted;

        if (isDateTimeValid(arrived) && isDateTimeValid(departed)) {
            const totalCalculatedTime = formatDuration(
                intervalToDuration({ start: arrived, end: departed }),
                { format: ['hours', 'minutes'] },
            );
            setValue(totalTimeAtSite.id, totalCalculatedTime);
        } else {
            setValue(totalTimeAtSite.id, null);
        }
    }, [watchTimeArrived, watchTimeDeparted, setValue, totalTimeAtSite.id]);

    return (
        <React.Fragment>
            <QuestionWrapper
                questionId={didYouSetupAppointment.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pb-3">
                            <div className="col">{/* NOTE: no inputWrapper on the first form input */}
                                <RadioGroup
                                    name={didYouSetupAppointment.id}
                                    label={didYouSetupAppointment.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    { didYouSetupAppointment.options.map(option => (
                                        <RadioButton
                                            key={`${didYouSetupAppointment.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                        <ConditionalQuestions condition={watchDidYouSetupAppointment === 'Yes'}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <DatePicker
                                        name={appointmentCallDate.id}
                                        label={appointmentCallDate.label}
                                        helperText={appointmentCallDate.helperText}
                                        errors={errors}
                                        required
                                        initialFocusedDate={format(new Date(), 'MM/dd/yyyy')}
                                        control={control}
                                        inputProps={{
                                            disabled,
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TimePicker
                                        name={appointmentCallTime.id}
                                        label={appointmentCallTime.label}
                                        errors={errors}
                                        required
                                        control={control}
                                        inputProps={{
                                            disabled,
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextInput
                                        name={appointmentCallTaker.id}
                                        label={appointmentCallTaker.label}
                                        helperText={appointmentCallTaker.helperText}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        disabled={disabled}
                                        inputProps={{
                                            autoComplete: 'off',
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <TextArea
                                        name={appointmentCallSummary.id}
                                        label={appointmentCallSummary.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        inputProps={{
                                            disabled,
                                        }}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={timeArrived.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TimePicker
                                name={timeArrived.id}
                                label={timeArrived.label}
                                errors={errors}
                                required
                                control={control}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={timeDeparted.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TimePicker
                                name={timeDeparted.id}
                                label={timeDeparted.label}
                                errors={errors}
                                required
                                control={control}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={totalTimeAtSite.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={totalTimeAtSite.id}
                                label={totalTimeAtSite.label}
                                errors={errors}
                                required
                                readOnly
                                inputRef={register()}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>

            <QuestionWrapper
                questionId={wereYouGreetedImmediately.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pb-3">
                            <div className={`col ${classes.inputWrapper}`}>
                                <RadioGroup
                                    name={wereYouGreetedImmediately.id}
                                    label={wereYouGreetedImmediately.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    { wereYouGreetedImmediately.options.map(option => (
                                        <RadioButton
                                            key={`${didYouSetupAppointment.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>

                            </div>
                        </div>
                        <ConditionalQuestions condition={watchWereYouGreetedImmediately === 'No'}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <RadioGroup
                                        name={howLongDidYouWaitGreeting.id}
                                        label={howLongDidYouWaitGreeting.label}
                                        errors={errors}
                                        required
                                        row
                                        control={control}
                                    >
                                        { howLongDidYouWaitGreeting.options.map(option => (
                                            <RadioButton
                                                key={`${didYouSetupAppointment.id}-${option}`}
                                                value={option}
                                                label={option}
                                                buttonStyle
                                                disabled={disabled}
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />


            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={whoGreetedYou.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={whoGreetedYou.id}
                                label={whoGreetedYou.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={whoInterviewedYou.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <TextInput
                                name={whoInterviewedYou.id}
                                label={whoInterviewedYou.label}
                                errors={errors}
                                required
                                inputRef={register()}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                disabled={disabled}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="row pb-3">
                <div className={`col ${classes.inputWrapper}`}>
                    <QuestionWrapper
                        questionId={howLongDidYouWaitInterview.id}
                        classes={classes}
                        render={({ disabled }) => (
                            <RadioGroup
                                name={howLongDidYouWaitInterview.id}
                                label={howLongDidYouWaitInterview.label}
                                errors={errors}
                                required
                                row
                                control={control}
                            >
                                { howLongDidYouWaitInterview.options.map(option => (
                                    <RadioButton
                                        key={`${didYouSetupAppointment.id}-${option}`}
                                        value={option}
                                        label={option}
                                        buttonStyle
                                        disabled={disabled}
                                    />
                                ))}
                            </RadioGroup>
                        )}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

ReportSection1.propTypes = {
    classes: PropTypes.object.isRequired,
    questions: PropTypes.object.isRequired,
    formContext: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(combinedStyles)(ReportSection1);
