import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import TextArea from '../../components/form/textarea';
import CheckboxGroup from '../../components/form/checkboxgroup';
import RadioGroup from '../../components/form/radiogroup';
import RadioButton from '../../components/form/radiobutton';
import ConditionalQuestions from '../../components/form/conditional-questions';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import InputStyles from '../../styles/inputs';
import Styles from './styles';
import QuestionWrapper from './components/section/question-wrapper';

const ReportSection5 = ({
    classes,
    questions,
    formContext,
}) => {
    const {
        guestCardContactFormFilledOut,
        byWhom,
        whatItemsRequired,
        whatItemsRequiredOtherDetails,
        wereYouAskedForDriversLicense,
        wereYouAskedForDriversLicenseReason,
        whatDidAgentDoWithIt,
        didAgentIndicateIneligible,
        agentIndicatedIneligibleExplain,
        wereAnySpecialsMentioned,
        wasSpecialOfferedOrAsked,
        whatWereTheSpecials,
        conclusionOfVisit,
        communicationFormRequested,
        communicationFormRequestedOtherDetails,
        receivedFlyer,
        receivedFlyerAskedOrOffered,
        receivedNewsletter,
        receivedNewsletterAskedOrOffered,
        receivedFloorPlans,
        receivedFloorPlansAskedOrOffered,
        receivedBusinessCard,
        receivedBusinessCardAskedOrOffered,
        receivedEventCalendar,
        receivedEventCalendarAskedOrOffered,
        receivedApplication,
        receivedApplicationAskedOrOffered,
        receivedPolicies,
        receivedPoliciesAskedOrOffered,
        receivedCreditCheckForm,
        receivedCreditCheckFormAskedOrOffered,
        receivedCriminalCheckForm,
        receivedCriminalCheckFormAskedOrOffered,
    } = questions;

    const {
        register,
        watch,
        errors,
        control,
    } = formContext;

    const values = watch([
        guestCardContactFormFilledOut.id,
        wereYouAskedForDriversLicense.id,
        didAgentIndicateIneligible.id,
        wereAnySpecialsMentioned.id,
        conclusionOfVisit.id,
        receivedFlyer.id,
        receivedNewsletter.id,
        receivedNewsletterAskedOrOffered.id,
        receivedFloorPlans.id,
        receivedBusinessCard.id,
        receivedEventCalendar.id,
        receivedApplication.id,
        receivedPolicies.id,
        receivedCreditCheckForm.id,
        receivedCriminalCheckForm.id,
    ]);

    return (
        <React.Fragment>

            <QuestionWrapper
                questionId={guestCardContactFormFilledOut.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pb-3">
                            <div className="col">{/* NOTE: no inputWrapper on the first form input */}
                                <RadioGroup
                                    name={guestCardContactFormFilledOut.id}
                                    label={guestCardContactFormFilledOut.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    { guestCardContactFormFilledOut.options.map(option => (
                                        <RadioButton
                                            key={`${guestCardContactFormFilledOut.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>

                            </div>
                        </div>
                        <ConditionalQuestions condition={values[guestCardContactFormFilledOut.id] === 'Yes'}>
                            <>
                                <div className="row pb-3">
                                    <div className={`col ${classes.inputWrapper}`}>
                                        <RadioGroup
                                            name={byWhom.id}
                                            label={byWhom.label}
                                            errors={errors}
                                            required
                                            row
                                            control={control}
                                        >
                                            { byWhom.options.map(option => (
                                                <RadioButton
                                                    key={`${byWhom.id}-${option}`}
                                                    value={option}
                                                    label={option}
                                                    buttonStyle
                                                    disabled={disabled}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </div>
                                </div>

                                <div className={`row ${classes.inputWrapper}`}>
                                    <div className="col">
                                        <CheckboxGroup
                                            name={whatItemsRequired.id}
                                            label={whatItemsRequired.label}
                                            options={whatItemsRequired.options}
                                            errors={errors}
                                            control={control}
                                            inputProps={{ disabled }}
                                            conditional={{
                                                Other: (
                                                    <TextArea
                                                        name={whatItemsRequiredOtherDetails.id}
                                                        label={whatItemsRequiredOtherDetails.label}
                                                        errors={errors}
                                                        required
                                                        inputRef={register()}
                                                        rowsMin={3}
                                                        hiddenLabel
                                                        disabled={disabled}
                                                    />
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        </ConditionalQuestions>
                    </>
                )}
            />

            <QuestionWrapper
                questionId={wereYouAskedForDriversLicense.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pb-3">
                            <div className={`col ${classes.inputWrapper}`}>
                                <RadioGroup
                                    name={wereYouAskedForDriversLicense.id}
                                    label={wereYouAskedForDriversLicense.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    { wereYouAskedForDriversLicense.options.map(option => (
                                        <RadioButton
                                            key={`${wereYouAskedForDriversLicense.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                                <ConditionalQuestions condition={values[wereYouAskedForDriversLicense.id] === 'Yes'}>
                                    <>
                                        <div className={`row ${classes.inputWrapper}`}>
                                            <div className="col">
                                                <TextArea
                                                    name={wereYouAskedForDriversLicenseReason.id}
                                                    label={wereYouAskedForDriversLicenseReason.label}
                                                    errors={errors}
                                                    required
                                                    inputRef={register()}
                                                    rowsMin={3}
                                                    disabled={disabled}
                                                />
                                            </div>
                                        </div>

                                        <div className={`row ${classes.inputWrapper}`}>
                                            <div className="col">
                                                <CheckboxGroup
                                                    name={whatDidAgentDoWithIt.id}
                                                    label={whatDidAgentDoWithIt.label}
                                                    options={whatDidAgentDoWithIt.options}
                                                    errors={errors}
                                                    control={control}
                                                    inputProps={{ disabled }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                </ConditionalQuestions>

                            </div>
                        </div>
                    </>
                )}
            />

            <QuestionWrapper
                questionId={didAgentIndicateIneligible.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pb-3">
                            <div className={`col ${classes.inputWrapper}`}>
                                <RadioGroup
                                    name={didAgentIndicateIneligible.id}
                                    label={didAgentIndicateIneligible.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    { didAgentIndicateIneligible.options.map(option => (
                                        <RadioButton
                                            key={`${didAgentIndicateIneligible.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>

                            </div>
                        </div>
                        <ConditionalQuestions condition={values[didAgentIndicateIneligible.id] === 'Yes'}>
                            <div className={`row ${classes.inputWrapper}`}>
                                <div className="col">
                                    <TextArea
                                        name={agentIndicatedIneligibleExplain.id}
                                        label={agentIndicatedIneligibleExplain.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>
                )}
            />


            <QuestionWrapper
                questionId={wereAnySpecialsMentioned.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pb-3">
                            <div className={`col ${classes.inputWrapper}`}>
                                <RadioGroup
                                    name={wereAnySpecialsMentioned.id}
                                    label={wereAnySpecialsMentioned.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    { wereAnySpecialsMentioned.options.map(option => (
                                        <RadioButton
                                            key={`${wereAnySpecialsMentioned.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                        <ConditionalQuestions condition={values[wereAnySpecialsMentioned.id] === 'Yes'}>
                            <div className="row pb-3">
                                <div className={`col ${classes.inputWrapper}`}>
                                    <RadioGroup
                                        name={wasSpecialOfferedOrAsked.id}
                                        label={wasSpecialOfferedOrAsked.label}
                                        errors={errors}
                                        required
                                        row
                                        control={control}
                                    >
                                        { wasSpecialOfferedOrAsked.options.map(option => (
                                            <RadioButton
                                                key={`${wasSpecialOfferedOrAsked.id}-${option}`}
                                                value={option}
                                                label={option}
                                                buttonStyle
                                                disabled={disabled}
                                            />
                                        ))}
                                    </RadioGroup>
                                </div>
                            </div>
                            <div className={`row ${classes.inputWrapper}`}>
                                <div className="col">
                                    <TextArea
                                        name={whatWereTheSpecials.id}
                                        label={whatWereTheSpecials.label}
                                        errors={errors}
                                        required
                                        inputRef={register()}
                                        rowsMin={3}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>

                )}
            />


            <QuestionWrapper
                questionId={conclusionOfVisit.id}
                classes={classes}
                render={({ disabled }) => (
                    <>
                        <div className="row pb-3">
                            <div className={`col ${classes.inputWrapper}`}>
                                <RadioGroup
                                    name={conclusionOfVisit.id}
                                    label={conclusionOfVisit.label}
                                    errors={errors}
                                    required
                                    row
                                    control={control}
                                >
                                    { conclusionOfVisit.options.map(option => (
                                        <RadioButton
                                            key={`${conclusionOfVisit.id}-${option}`}
                                            value={option}
                                            label={option}
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                        <ConditionalQuestions hideBorder hideContainer condition={values[conclusionOfVisit.id] && values[conclusionOfVisit.id] !== 'No arrangements were made'}>
                            <div className={`row ${classes.inputWrapper}`}>
                                <div className="col">
                                    <CheckboxGroup
                                        name={communicationFormRequested.id}
                                        label={communicationFormRequested.label}
                                        options={communicationFormRequested.options}
                                        errors={errors}
                                        control={control}
                                        inputProps={{ disabled }}
                                        conditional={{
                                            Other: (
                                                <TextArea
                                                    name={communicationFormRequestedOtherDetails.id}
                                                    label={communicationFormRequestedOtherDetails.label}
                                                    errors={errors}
                                                    required
                                                    inputRef={register()}
                                                    rowsMin={3}
                                                    hiddenLabel
                                                    disabled={disabled}
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                        </ConditionalQuestions>
                    </>

                )}
            />

            {/* WHICH ITEMS WERE RECEIVED */}
            <Typography variant="h2" style={{ fontWeight: 700, paddingBottom: 15, paddingTop: 45 }}>
                Indicate which items were received; did you have to ask, or were they offered?
            </Typography>
            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={receivedFlyer.id}

                classes={classes}
                render={({ disabled }) => (
                    <div className="row">
                        <div className="col">
                            <CheckboxGroup
                                name={receivedFlyer.id}
                                options={receivedFlyer.options}
                                errors={errors}
                                control={control}
                                inputProps={{ disabled }}
                            />
                        </div>
                        <div className="col-auto">
                            <ConditionalQuestions hideBorder hideContainer condition={!!values[receivedFlyer.id] && !!values[receivedFlyer.id].length}>
                                <RadioGroup
                                    name={receivedFlyerAskedOrOffered.id}
                                    label={receivedFlyerAskedOrOffered.label}
                                    errors={errors}
                                    required
                                    row
                                    hiddenLabel
                                    control={control}
                                >
                                    { receivedFlyerAskedOrOffered.options.map(option => (
                                        <RadioButton
                                            key={`${receivedFlyerAskedOrOffered.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </ConditionalQuestions>
                        </div>
                    </div>
                )}
            />
            <hr className={classes.greyHr} />


            <QuestionWrapper
                questionId={receivedNewsletter.id}

                classes={classes}
                render={({ disabled }) => (
                    <div className="row">
                        <div className="col">
                            <CheckboxGroup
                                name={receivedNewsletter.id}
                                options={receivedNewsletter.options}
                                errors={errors}
                                control={control}
                                inputProps={{ disabled }}
                            />
                        </div>
                        <div className="col-auto">
                            <ConditionalQuestions hideBorder hideContainer condition={!!values[receivedNewsletter.id] && !!values[receivedNewsletter.id].length}>
                                <RadioGroup
                                    name={receivedNewsletterAskedOrOffered.id}
                                    label={receivedNewsletterAskedOrOffered.label}
                                    errors={errors}
                                    required
                                    row
                                    hiddenLabel
                                    control={control}
                                >
                                    { receivedNewsletterAskedOrOffered.options.map(option => (
                                        <RadioButton
                                            key={`${receivedNewsletterAskedOrOffered.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </ConditionalQuestions>
                        </div>
                    </div>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={receivedFloorPlans.id}

                classes={classes}
                render={({ disabled }) => (
                    <div className="row">
                        <div className="col">
                            <CheckboxGroup
                                name={receivedFloorPlans.id}
                                options={receivedFloorPlans.options}
                                errors={errors}
                                control={control}
                                inputProps={{ disabled }}
                            />
                        </div>

                        <div className="col-auto">
                            <ConditionalQuestions hideBorder hideContainer condition={!!values[receivedFloorPlans.id] && !!values[receivedFloorPlans.id].length}>
                                <RadioGroup
                                    name={receivedFloorPlansAskedOrOffered.id}
                                    label={receivedFloorPlansAskedOrOffered.label}
                                    errors={errors}
                                    required
                                    row
                                    hiddenLabel
                                    control={control}
                                >
                                    { receivedFloorPlansAskedOrOffered.options.map(option => (
                                        <RadioButton
                                            key={`${receivedFloorPlansAskedOrOffered.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </ConditionalQuestions>
                        </div>
                    </div>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={receivedBusinessCard.id}

                classes={classes}
                render={({ disabled }) => (

                    <div className="row">
                        <div className="col">
                            <CheckboxGroup
                                name={receivedBusinessCard.id}
                                options={receivedBusinessCard.options}
                                errors={errors}
                                control={control}
                                inputProps={{ disabled }}
                            />
                        </div>
                        <div className="col-auto">
                            <ConditionalQuestions hideBorder hideContainer condition={!!values[receivedBusinessCard.id] && !!values[receivedBusinessCard.id].length}>
                                <RadioGroup
                                    name={receivedBusinessCardAskedOrOffered.id}
                                    label={receivedBusinessCardAskedOrOffered.label}
                                    errors={errors}
                                    required
                                    row
                                    hiddenLabel
                                    control={control}
                                >
                                    { receivedBusinessCardAskedOrOffered.options.map(option => (
                                        <RadioButton
                                            key={`${receivedBusinessCardAskedOrOffered.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </ConditionalQuestions>
                        </div>
                    </div>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={receivedEventCalendar.id}

                classes={classes}
                render={({ disabled }) => (
                    <div className="row">
                        <div className="col">
                            <CheckboxGroup
                                name={receivedEventCalendar.id}
                                options={receivedEventCalendar.options}
                                errors={errors}
                                control={control}
                                inputProps={{ disabled }}
                            />
                        </div>
                        <div className="col-auto">
                            <ConditionalQuestions hideBorder hideContainer condition={!!values[receivedEventCalendar.id] && !!values[receivedEventCalendar.id].length}>
                                <RadioGroup
                                    name={receivedEventCalendarAskedOrOffered.id}
                                    label={receivedEventCalendarAskedOrOffered.label}
                                    errors={errors}
                                    required
                                    row
                                    hiddenLabel
                                    control={control}
                                >
                                    { receivedEventCalendarAskedOrOffered.options.map(option => (
                                        <RadioButton
                                            key={`${receivedEventCalendarAskedOrOffered.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </ConditionalQuestions>
                        </div>
                    </div>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={receivedApplication.id}

                classes={classes}
                render={({ disabled }) => (
                    <div className="row">
                        <div className="col">
                            <CheckboxGroup
                                name={receivedApplication.id}
                                options={receivedApplication.options}
                                errors={errors}
                                control={control}
                                inputProps={{ disabled }}
                            />
                        </div>
                        <div className="col-auto">
                            <ConditionalQuestions hideBorder hideContainer condition={!!values[receivedApplication.id] && !!values[receivedApplication.id].length}>
                                <RadioGroup
                                    name={receivedApplicationAskedOrOffered.id}
                                    label={receivedApplicationAskedOrOffered.label}
                                    errors={errors}
                                    required
                                    row
                                    hiddenLabel
                                    control={control}
                                >
                                    { receivedApplicationAskedOrOffered.options.map(option => (
                                        <RadioButton
                                            key={`${receivedApplicationAskedOrOffered.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </ConditionalQuestions>
                        </div>
                    </div>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={receivedPolicies.id}

                classes={classes}
                render={({ disabled }) => (
                    <div className="row">
                        <div className="col">
                            <CheckboxGroup
                                name={receivedPolicies.id}
                                options={receivedPolicies.options}
                                errors={errors}
                                control={control}
                                inputProps={{ disabled }}
                            />
                        </div>
                        <div className="col-auto">
                            <ConditionalQuestions hideBorder hideContainer condition={!!values[receivedPolicies.id] && !!values[receivedPolicies.id].length}>
                                <RadioGroup
                                    name={receivedPoliciesAskedOrOffered.id}
                                    label={receivedPoliciesAskedOrOffered.label}
                                    errors={errors}
                                    required
                                    row
                                    hiddenLabel
                                    control={control}
                                >
                                    { receivedPoliciesAskedOrOffered.options.map(option => (
                                        <RadioButton
                                            key={`${receivedPoliciesAskedOrOffered.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </ConditionalQuestions>
                        </div>
                    </div>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={receivedCreditCheckForm.id}

                classes={classes}
                render={({ disabled }) => (
                    <div className="row">
                        <div className="col">
                            <CheckboxGroup
                                name={receivedCreditCheckForm.id}
                                options={receivedCreditCheckForm.options}
                                errors={errors}
                                control={control}
                                inputProps={{ disabled }}
                            />
                        </div>
                        <div className="col-auto">
                            <ConditionalQuestions hideBorder hideContainer condition={!!values[receivedCreditCheckForm.id] && !!values[receivedCreditCheckForm.id].length}>
                                <RadioGroup
                                    name={receivedCreditCheckFormAskedOrOffered.id}
                                    label={receivedCreditCheckFormAskedOrOffered.label}
                                    errors={errors}
                                    required
                                    row
                                    hiddenLabel
                                    control={control}
                                >
                                    { receivedCreditCheckFormAskedOrOffered.options.map(option => (
                                        <RadioButton
                                            key={`${receivedCreditCheckFormAskedOrOffered.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </ConditionalQuestions>
                        </div>
                    </div>
                )}
            />

            <hr className={classes.greyHr} />

            <QuestionWrapper
                questionId={receivedCriminalCheckForm.id}

                classes={classes}
                render={({ disabled }) => (
                    <div className="row">
                        <div className="col">
                            <CheckboxGroup
                                name={receivedCriminalCheckForm.id}
                                options={receivedCriminalCheckForm.options}
                                errors={errors}
                                control={control}
                                inputProps={{ disabled }}
                            />
                        </div>
                        <div className="col-auto">
                            <ConditionalQuestions hideBorder hideContainer condition={!!values[receivedCriminalCheckForm.id] && !!values[receivedCriminalCheckForm.id].length}>
                                <RadioGroup
                                    name={receivedCriminalCheckFormAskedOrOffered.id}
                                    label={receivedCriminalCheckFormAskedOrOffered.label}
                                    errors={errors}
                                    required
                                    row
                                    hiddenLabel
                                    control={control}
                                >
                                    { receivedCriminalCheckFormAskedOrOffered.options.map(option => (
                                        <RadioButton
                                            key={`${receivedCriminalCheckFormAskedOrOffered.id}-${option}`}
                                            value={option}
                                            label={option}
                                            buttonStyle
                                            disabled={disabled}
                                        />
                                    ))}
                                </RadioGroup>
                            </ConditionalQuestions>
                        </div>
                    </div>
                )}
            />

        </React.Fragment>
    );
};

ReportSection5.propTypes = {
    classes: PropTypes.object.isRequired,
    questions: PropTypes.object.isRequired,
    formContext: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(combinedStyles)(ReportSection5);
