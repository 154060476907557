import KeyMirror from 'keymirror';

export default KeyMirror({
    GET_REPORTS_BEGIN: true,
    GET_REPORTS_SUCCESS: true,
    GET_REPORTS_ERROR: true,
    POST_CREATE_REPORT_BEGIN: true,
    POST_CREATE_REPORT_SUCCESS: true,
    POST_CREATE_REPORT_ERROR: true,
    PATCH_REPORT_BEGIN: true,
    PATCH_REPORT_SUCCESS: true,
    PATCH_REPORT_ERROR: true,
    DELETE_REPORT_SUCCESS: true,
    SAVE_REPORT_ANSWERS: true,
    PATCH_REPORT_ANSWERS_BEGIN: true,
    PATCH_REPORT_ANSWERS_SUCCESS: true,
    PATCH_REPORT_ANSWERS_ERROR: true,
    UPDATE_SECTION_PROGRESS: true,
    UPLOAD_ATTACHMENTS_BEGIN: true,
    UPLOAD_ATTACHMENTS_ERROR: true,
    UPLOAD_ATTACHMENTS_SUCCESS: true,
    PATCH_REPORT_VERSION_SUCCESS: true,
    CREATE_REPORT_VERSION_SUCCESS: true,
});
