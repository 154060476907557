import { useState, useCallback } from 'react';

const useReportDialog = () => {
    const [dialogValues, setDialogValues] = useState({});
    const [isDialogOpen, setDialogOpen] = useState(false);

    const handleOpenDialog = useCallback((reportDetails) => {
        setDialogValues(reportDetails);
        setDialogOpen(true);
    }, [setDialogValues, setDialogOpen]);

    const handleCloseDialog = useCallback(() => {
        setDialogOpen(false);
    }, [setDialogOpen]);

    return {
        isDialogOpen,
        handleOpenDialog,
        handleCloseDialog,
        dialogProps: {
            isDialogOpen,
            handleCloseDialog,
            reportDetails: dialogValues,
        },
    };
};

export default useReportDialog;
